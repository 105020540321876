import { DateTimeDisplay } from "components/Shared/DateDisplay";
import React from "react";
import { PNRResponseGeneralInfoModel } from "WebApiClient";
import { SpecifiedBooleanDisplay } from "./Pnr";

export const PnrGeneralCard: React.FC<{ data: PNRResponseGeneralInfoModel }> = ({ data }) => {
    const {
        creatorAgent,
        creatorPCC,
        isGroupbooking, isTicketIssued,
        pointOfTurnAround, receivedFrom, securedFlight,
        source,
        ticketingDate,
        ticketingDateType,
    } = data;

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">General info</div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover">
                    <tbody>
                            
                        {creatorAgent.length > 0 &&
                            <tr>
                                <td className="font-weight-bold">Creator Agent</td>
                                <td>{creatorAgent}</td>
                            </tr>
                        }
                        {creatorPCC.length > 0 &&
                            <tr>
                                <td className="font-weight-bold">Creator PCC</td>
                                <td>{creatorPCC}</td>
                            </tr>
                        }
                        {pointOfTurnAround.length > 0 &&
                            <tr>
                                <td className="font-weight-bold">Point of Turnaround</td>
                                <td>{pointOfTurnAround}</td>
                            </tr>
                        }
                        {receivedFrom.length > 0 &&
                            <tr>
                                <td className="font-weight-bold">Received from</td>
                                <td>{receivedFrom}</td>
                            </tr>
                        }
                        {source.length > 0 &&
                            <tr>
                                <td className="font-weight-bold">Source</td>
                                <td>{source}</td>
                            </tr>
                        }
                        {isGroupbooking !== undefined &&
                            <tr>
                                <td className="font-weight-bold">Group booking</td>
                                <td><SpecifiedBooleanDisplay value={isGroupbooking} /></td>
                            </tr>
                        }
                        {ticketingDateType.length > 0 &&
                            <tr>
                                <td className="font-weight-bold">Ticketing date type</td>
                                <td>{ticketingDateType}</td>
                            </tr>
                        }
                        {isTicketIssued !== undefined &&
                            <tr>
                                <td className="font-weight-bold">Ticket issued</td>
                                <td><SpecifiedBooleanDisplay value={isTicketIssued} /></td>
                            </tr>
                        }
                        {ticketingDate &&
                            <tr>
                                <td className="font-weight-bold">Ticketing date</td>
                                <td><DateTimeDisplay date={ticketingDate} /></td>
                            </tr>
                        }
                        {securedFlight !== undefined &&
                            <tr>
                                <td className="font-weight-bold">Secured flight</td>
                                <td><SpecifiedBooleanDisplay value={securedFlight} /></td>
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
        </div>
    );


}
