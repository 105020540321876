import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const VersionDifferenceModal: React.FC<{}> = props => {
    const [open, setOpen] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);
    const session = useSelector((state: any) => state.Session);
    const VersionDifferenceToBackend = session.VersionDifferenceToBackend;

    const NewBackendVersion = session.NewBackendVersion;
    const DeployInfo = session.DeployInfo;
    const dispatch = useDispatch();

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    useEffect(() => {
        if (VersionDifferenceToBackend) {
            if (!acknowledged) {
                if (inIframe()) {
                    setOpen(false);
                    clearCacheData();
                }
                if (!inIframe()) {
                    setOpen(true);
                }
            }
        }
    }, [VersionDifferenceToBackend])

    function OnToggleClose() {
        setOpen(false);
        setAcknowledged(true);
    }

    // const clearCacheData = () => {
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     caches.keys().then((keyList) => Promise.all(keyList.map((key) => {
    //         caches.delete(key)
    //         window.caches.delete(key)
    //     })));
    //     window.location.href = window.location.origin + window.location.pathname + '?cache_bust=' + new Date().getTime();
    //     window.location.replace(window.location.href.split("?")[0] + "?forceReload=" + new Date().getTime());
    // };


    const clearCacheData = () => {
        // Clear local storage
        localStorage.clear();
        // Clear session storage
        sessionStorage.clear();
        // Clear all caches
        caches.keys().then((keyList) => {
            return Promise.all(keyList.map((key) => caches.delete(key)));
        }).then(() => {
            // Force a hard reload with a unique query string to bypass cache
            window.location.replace(window.location.origin + window.location.pathname + '?cache_bust=' + new Date().getTime());
        });
    };

    return <Fragment>
        {session.VersionDifferenceToBackend &&
            <div className="alert alert-warning fw-bold"><span className="me-2">An update is available!</span>
                <div className="btn-group btn-group-sm">
                    {/* <a href={window.location.href} className="btn btn-sm btn-primary me-2">Reload now</a> */}
                    <button className="btn btn-primary me-2" onClick={() => clearCacheData()} >Reload now</button>
                    <button className="btn btn-sm btn-secondary" onClick={() => { setOpen(true) }}>Show details</button>
                </div>
            </div>
        }
        <Modal size="xl" isOpen={open} toggle={OnToggleClose}>
            <ModalHeader className="text-center" toggle={OnToggleClose}>A new Version of Agent is available!</ModalHeader>
            <ModalBody >
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-warning">
                            <div>
                                <h5 className="text-center">You are using an outdated version of Agent</h5>

                                <p className="text-center">New Version: {NewBackendVersion} <span className="text-muted">(Your Version: {DeployInfo?.Version})</span></p>
                                <p className="fw-bold">To avoid incorrect application behaviour, a reload is required.</p>
                                <div className="d-grid">
                                    {/* <a href={window.location.href} className="btn btn-primary" >Click to reload this page.</a> */}
                                    <button className="btn btn-primary" onClick={() => clearCacheData()} >Click to reload this page.</button>
                                </div>
                            </div>
                        </div>
                        <div className="">

                            <p>If this message keeps appearing, try the following steps in the following order <span className="fw-bold">to clear your browser cache:</span></p>
                            <ul>
                                <li>Click to button above to reload the page.</li>
                                <li>Open the page in a new tab or window.</li>
                                <li>If on a desktop computer: press STRG+F5</li>
                                <li>Ask your IT support for help.</li>
                                <li>If no IT support is available, Instructions for different browsers can be found on <a href="https://www.refreshyourcache.com/en/home/">https://www.refreshyourcache.com/en/home/</a></li>
                                <li>If none of the above steps worked: contact Hitchhiker support.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-secondary" onClick={OnToggleClose}>Later</button>
            </ModalFooter>
        </Modal>
    </Fragment>
}