import { faAlignJustify } from "@fortawesome/pro-regular-svg-icons";
import { faCogs, faConciergeBell, faHourglassStart, faLoveseat, faLuggageCart, faMapMarked, faPlane, faUsersClass, faWatch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterCriteria } from 'components/AvailableFares/types/AvailableFaresTypes';
import IncludedServiceIcon from "components/Shared/IncludedServiceIcon";
import { TimeRangePicker } from "components/Shared/TimeRangeSlider";
import React, { useEffect } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { FaresFilterState, FreeBaggageFilterCriteria } from "../types/AvailableFaresTypes";


interface OwnProps {
  Filter: FaresFilterState;
  TotalFaresAmount: number;
  FilteredFaresAmount: number;
  UpdateFilter: (filter: FaresFilterState) => void;
  InitialFilter: FaresFilterState;
  IsOpen: boolean;
  Toggle: () => void;

  AllFaresExpandActive: boolean;
  AllFaresExpandedToggle: () => void;

  AllFlightTimesExpandActive: boolean;
  AllFlightTimesExpandedToggle: () => void;

  AllCalculationExpandActive: boolean;
  AllCalculationExpandedToggle: () => void;

  cssActiveCheckerClean?: any,
}
const FaresFilter: React.FC<OwnProps> = (props) => {
  const { Filter, FilteredFaresAmount, TotalFaresAmount, UpdateFilter, InitialFilter, cssActiveCheckerClean } = props;




  // const FilterToggleIcon: JSX.Element = <React.Fragment>
  //   <div className="toggler">
  //     <div className="bg-white">
  //       <FontAwesomeIcon icon={faSlidersH} rotation={90} className="icon" />
  //     </div>
  //   </div>
  // </React.Fragment>

  // const FilterCloseIcon: JSX.Element = <React.Fragment>
  // </React.Fragment>

  function SetFilterStopAmount(legIndex: number, value: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.LegsMaxStopAmount[legIndex].MaxStopSelection = value;
    UpdateFilter(updatedFilter);
  }

  function SetFreeBaggage(value: FreeBaggageFilterCriteria) {
    let updatedFilter = { ...Filter };
    updatedFilter.FreeBaggage = value;
    UpdateFilter(updatedFilter);
  }
  function ToggleAllAirports() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAirportsActive;
    updatedFilter.ArrivalAirports.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.DepartureAirports.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAirportsActive = inverted;

    UpdateFilter(updatedFilter);
  }

  function ToggleArrivalAirport(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.ArrivalAirports[index].Checked = !updatedFilter.ArrivalAirports[index].Checked;
    UpdateFilter(updatedFilter);
  }
  function ToggleDepartureAirport(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.DepartureAirports[index].Checked = !updatedFilter.DepartureAirports[index].Checked;
    UpdateFilter(updatedFilter);
  }
  function ToggleAllAirlines() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAirlinesActive;

    updatedFilter.Airlines.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAirlinesActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleAirline(index: number) {
    cssActiveCheckerClean()
    let updatedFilter = { ...Filter };
    updatedFilter.Airlines[index].Checked = !updatedFilter.Airlines[index].Checked;
    if (updatedFilter.Airlines.every((el: any) => el.Checked == true)) {
      updatedFilter.SelectAllAirlinesActive = true;
    } else {
      updatedFilter.SelectAllAirlinesActive = false;
    }
    UpdateFilter(updatedFilter);
  }

  function ToggleAllAlliances() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAlliancesActive;
    updatedFilter.Alliances.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAlliancesActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleAlliance(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.Alliances[index].Checked = !updatedFilter.Alliances[index].Checked;
    UpdateFilter(updatedFilter);
  }

  function SetDepartureTimes(index: number, minHour: number, maxHour: number) {
    let updatedFilter = { ...Filter };

    updatedFilter.DepartureTime[index].MinHour = minHour;
    updatedFilter.DepartureTime[index].MaxHour = maxHour;

    UpdateFilter(updatedFilter);
  }

  function SetArrivalTimes(index: number, minHour: number, maxHour: number) {
    let updatedFilter = { ...Filter };

    updatedFilter.ArrivalTime[index].MinHour = minHour;
    updatedFilter.ArrivalTime[index].MaxHour = maxHour;

    UpdateFilter(updatedFilter);
  }

  function ToggleAllCabinClasses() {
    let updatedFilter = { ...Filter };

    const inverted = !Filter.SelectAllCabinClassesActive;
    updatedFilter.CabinClasses.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllCabinClassesActive = inverted;

    UpdateFilter(updatedFilter);
  }

  function ToggleCabinClass(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.CabinClasses[index].Checked = !updatedFilter.CabinClasses[index].Checked;
    UpdateFilter(updatedFilter);
  }
  function ToggleAllFareTypes() {
    let updatedFilter = { ...Filter };

    const inverted = !Filter.SelectAllFareTypesActive;
    updatedFilter.FareTypes.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllFareTypesActive = inverted;


    UpdateFilter(updatedFilter);
  }

  function ToggleFareType(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.FareTypes[index].Checked = !updatedFilter.FareTypes[index].Checked;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllQueryPCC() {
    let updatedFilter = { ...Filter };

    const inverted = !Filter.SelectAllQueryPCCActive;
    updatedFilter.QueryPCCs.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllQueryPCCActive = inverted;

    UpdateFilter(updatedFilter);
  }

  function ToggleQueryPCC(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.QueryPCCs[index].Checked = !updatedFilter.QueryPCCs[index].Checked;


    UpdateFilter(updatedFilter);
  }

  function ToggleAllBookingPCC() {
    let updatedFilter = { ...Filter };

    const inverted = !Filter.SelectAllBookingPCCActive;
    updatedFilter.BookingPCCs.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllBookingPCCActive = inverted;

    UpdateFilter(updatedFilter);
  }

  function ToggleBookingPCC(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.BookingPCCs[index].Checked = !updatedFilter.BookingPCCs[index].Checked;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllSource() {
    let updatedFilter = { ...Filter };

    const inverted = !Filter.SelectAllSourceActive;
    updatedFilter.Sources.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllSourceActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleSource(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.Sources[index].Checked = !updatedFilter.Sources[index].Checked;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllAllotmentTypes() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAllotmentTypesActive;
    updatedFilter.AllotmentTypes.forEach(element => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAllotmentTypesActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllotmentType(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.AllotmentTypes[index].Checked = !updatedFilter.AllotmentTypes[index].Checked;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllIncludedServices() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllIncludedServicesActive;
    updatedFilter.IncludedService.forEach(mainGroup => {
      mainGroup.Values.forEach(subGroup => {
        subGroup.Checked = inverted;
      });
    });
    updatedFilter.SelectAllIncludedServicesActive = inverted;
    UpdateFilter(updatedFilter);
  }
  function ToggleIncludedService(mainTypeIndex: number, subTypeIndex: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.IncludedService[mainTypeIndex].Values[subTypeIndex].Checked = !updatedFilter.IncludedService[mainTypeIndex].Values[subTypeIndex].Checked;


    UpdateFilter(updatedFilter);
  }

  const sortByFilter = (dataObj: any, label: string) => {
    return dataObj?.sort(function (a: any, b: any) {
      let textA: any = a?.[label].toUpperCase();
      let textB: any = b?.[label].toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
  }

  return (
    <Offcanvas backdrop={true} fade={true} scrollable={true} isOpen={props.IsOpen} size="xl" toggle={props.Toggle}>
      <OffcanvasHeader toggle={props.Toggle}>
        Filter {FilteredFaresAmount} / {TotalFaresAmount}
        {/* <span onClick={() => { UpdateFilter(InitialFilter) }} className="float-end clickme" title="Reset all filters"><FontAwesomeIcon icon={faTrashAlt} /></span> */}
        <button className="btn btn-danger ml-2" onClick={() => { UpdateFilter(InitialFilter) }}>Reset</button>
      </OffcanvasHeader>
      <OffcanvasBody>
        {Filter !== undefined &&
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="btn-group">
                    <button type="button" className={`btn btn-${props.AllFaresExpandActive ? "success" : "secondary"}`} onClick={props.AllFaresExpandedToggle}>Expand all fares</button>
                    <button type="button" className={`btn btn-${props.AllFlightTimesExpandActive ? "success" : "secondary"}`} onClick={props.AllFlightTimesExpandedToggle}>Expand all flight times</button>
                    <button type="button" className={`btn btn-${props.AllCalculationExpandActive ? "success" : "secondary"}`} onClick={props.AllCalculationExpandedToggle}>Expand all calculation</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                {/* <div className="card-header card-header-primary bg-primary text-white sticky-top">
              
            </div> */}
                <div className="card-body">


                  <div className="pb-2" >
                    <div className="filter-section"><FontAwesomeIcon icon={faHourglassStart} /> Stops</div>
                    <div className="filter-section-content">
                      {Filter.LegsMaxStopAmount.map((leg, index: number) =>
                        <div className="mb-3" key={`afg_filter_legMaxStop_${index}`}>
                          <div className="form-group mb-1">
                            <div className="font-weight-bold pb-1">Segment {leg.LegIndex + 1}:</div>
                            <select value={leg.MaxStopSelection} onChange={(e) => { SetFilterStopAmount(index, parseInt(e.target.value)) }} className="form-select  form-select-sm">
                              <option value={0}>Nonstop</option>
                              <option value={1}>Max. 1 Stop</option>
                              <option value={9999}>2+ Stops</option>
                            </select>
                          </div>
                          {/* {leg.MaxStopSelection !== 0 &&
                      <SimpleSlider Update={(value) => SetFilterStopDuration(index, value)} value={leg.MaxStopduration} />
                    } */}
                        </div>
                      )}

                    </div>
                  </div>
                  <div className="mt-4 pb-2" >
                    <div className="filter-section"><FontAwesomeIcon icon={faLuggageCart} /> Baggage included?</div>
                    <div className="filter-section-content">

                      <div className="form-check" onClick={() => SetFreeBaggage(FreeBaggageFilterCriteria.All)}>
                        <input type="checkbox" onChange={() => { }} checked={Filter.FreeBaggage === FreeBaggageFilterCriteria.All} className="form-check-input" />
                        <label className="form-check-label" >all</label>
                      </div>
                      <div className="form-check" onClick={() => SetFreeBaggage(FreeBaggageFilterCriteria.Yes)}>
                        <input type="checkbox" onChange={() => { }} checked={Filter.FreeBaggage === FreeBaggageFilterCriteria.Yes} className="form-check-input" />
                        <label className="form-check-label">yes</label>
                      </div>
                      <div className="form-check" onClick={() => SetFreeBaggage(FreeBaggageFilterCriteria.No)}>
                        <input type="checkbox" onChange={() => { }} checked={Filter.FreeBaggage === FreeBaggageFilterCriteria.No} className="form-check-input" />
                        <label className="form-check-label" >no</label>
                      </div>

                    </div>
                  </div>

                  <div className="mt-4 pb-2" >
                    <div className="filter-section"><FontAwesomeIcon icon={faMapMarked} /> Airports</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllAirports()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllAirportsActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="font-weight-bold">Departure:</div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {Filter.DepartureAirports.map((airport: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_depAr${index}`} onClick={() => ToggleDepartureAirport(index)}>
                              <input type="checkbox" className="form-check-input" checked={airport.Checked} readOnly />
                              <label className="form-check-label">{airport.Label}</label>
                            </div>
                          )}
                        </div>

                      </div>

                      <div className="font-weight-bold">Arrival:</div>
                      <div className="form-group mb-3">

                        <div className="custom-controls-stacked">
                          {Filter.ArrivalAirports.map((airport: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_arAr${index}`} onClick={() => ToggleArrivalAirport(index)}>
                              <input type="checkbox" className="form-check-input" checked={airport.Checked} readOnly />
                              <label className="form-check-label">{airport.Label}</label>
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-4 pb-2" >
                    <div className="filter-section"><FontAwesomeIcon icon={faPlane} /> Airlines</div>
                    <div className="filter-section-content">
                      <div onClick={() => { ToggleAllAirlines() }} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllAirlinesActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {sortByFilter(Filter.Airlines, 'Label').map((airline: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_arAr${index}`} onClick={() => ToggleAirline(index)}>
                              <input type="checkbox" className="form-check-input" checked={airline.Checked} readOnly />
                              <label className="form-check-label">{airline.Label}</label>
                            </div>
                          )}
                        </div>

                      </div>

                    </div>
                  </div>


                  <div className="mt-4 pb-2" >
                    <div className="filter-section"><FontAwesomeIcon icon={faUsersClass} /> Alliances</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllAlliances()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllAlliancesActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {Filter.Alliances.map((airline: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_all${index}`} onClick={() => ToggleAlliance(index)}>
                              <input type="checkbox" className="form-check-input" checked={airline.Checked} readOnly />
                              <label className="form-check-label">{airline.Label}</label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 pb-2" >
                    <div className="filter-section"><FontAwesomeIcon icon={faWatch} /> Departure time</div>
                    <div className="filter-section-content mb-3">
                      {Filter.DepartureTime.map((departureTime, index: number) =>
                        <div className="form-group" key={`afq_filter_depTime${index}`}>

                          <div className="mt-3">

                            <TimeRangePicker

                              Update={(values) => SetDepartureTimes(index, values[0], values[1])}
                              values={[departureTime.MinHour, departureTime.MaxHour]}
                              Label={`Segment ${index + 1}`}
                            />

                          </div>
                        </div>
                      )}

                    </div>
                  </div>

                  <div className="mt-4 pb-2">
                    <div className="filter-section"><FontAwesomeIcon icon={faWatch} /> Arrival time</div>
                    <div className="filter-section-content mb-3">
                      {Filter.ArrivalTime.map((arrivalTime, index: number) =>
                        <div className="form-group" key={`afq_filter_arrTime${index}`}>
                          {/* <TimeRangeSliderBS
Update={(values) => SetArrivalTimes(index, values[0], values[1])}
values={[arrivalTime.MinHour, arrivalTime.MaxHour]}
Label={`Segment ${index + 1}`}
/> */}
                          <div className="mt-3">
                            <TimeRangePicker
                              Update={(values) => SetArrivalTimes(index, values[0], values[1])}
                              values={[arrivalTime.MinHour, arrivalTime.MaxHour]}
                              Label={`Segment ${index + 1}`}
                            />
                          </div>
                        </div>
                      )}


                    </div>
                  </div>

                  <div className="mt-4 pb-2">
                    <div className="filter-section"><FontAwesomeIcon icon={faLoveseat} /> Cabin class</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllCabinClasses()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllCabinClassesActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">

                        {Filter.CabinClasses.map((fareType: FilterCriteria, index: number) =>
                          <div className="form-check" key={`afq_filter_cabclass${index}`} onClick={() => ToggleCabinClass(index)}>
                            <input type="checkbox" className="form-check-input" checked={fareType.Checked} readOnly />
                            <label className="form-check-label">{fareType.Label}</label>
                          </div>
                        )}

                      </div>

                    </div>
                  </div>

                  <div className="mt-4 pb-2">
                    <div className="filter-section"><FontAwesomeIcon icon={faCogs} /> Fare Type</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllFareTypes()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllFareTypesActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {Filter.FareTypes.map((fareType: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_faretype${index}`} onClick={() => ToggleFareType(index)}>
                              <input type="checkbox" className="form-check-input" checked={fareType.Checked} readOnly />
                              <label className="form-check-label">{fareType.Label}</label>
                            </div>
                          )}

                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="mt-4 pb-2">
                    <div className="filter-section"><FontAwesomeIcon icon={faCogs} /> Query PCC</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllQueryPCC()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllQueryPCCActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {Filter.QueryPCCs.map((pcc: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_querypcc${index}`} onClick={() => ToggleQueryPCC(index)}>
                              <input type="checkbox" className="form-check-input" checked={pcc.Checked} readOnly />
                              <label className="form-check-label">{pcc.Label}</label>
                            </div>
                          )}

                        </div>

                      </div>

                    </div>
                  </div>


                  <div className="mt-4 pb-2">
                    <div className="filter-section"><FontAwesomeIcon icon={faCogs} /> Booking PCC</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllBookingPCC()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllBookingPCCActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {Filter.BookingPCCs.map((pcc: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_bookingpcc${index}`} onClick={() => ToggleBookingPCC(index)}>
                              <input type="checkbox" className="form-check-input" checked={pcc.Checked} readOnly />
                              <label className="form-check-label">{pcc.Label}</label>
                            </div>
                          )}

                        </div>

                      </div>

                    </div>
                  </div>



                  <div className="mt-4 pb-2">
                    <div className="filter-section"><FontAwesomeIcon icon={faCogs} /> Source</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllSource()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllSourceActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {Filter.Sources.map((source: FilterCriteria, index: number) =>
                            <div className="form-check" key={`afq_filter_source${index}`} onClick={() => ToggleSource(index)}>
                              <input type="checkbox" className="form-check-input" checked={source.Checked} readOnly />
                              <label className="form-check-label">{source.Label}</label>
                            </div>
                          )}

                        </div>

                      </div>

                    </div>
                  </div>

                  {Filter.AllotmentTypes.length > 0 &&
                    <div className="mt-4 pb-2">
                      <div className="filter-section"><FontAwesomeIcon icon={faAlignJustify} /> Allotments</div>
                      <div className="filter-section-content">
                        <div onClick={() => ToggleAllAllotmentTypes()} className="form-check border-bottom pb-2 mb-2">
                          <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllAllotmentTypesActive}></input>
                          <label className="form-check-label">Select/Unselect all</label>
                        </div>
                        <div className="form-group mb-3">
                          <div className="custom-controls-stacked">
                            {Filter.AllotmentTypes.map((source: FilterCriteria, index: number) =>
                              <div className="form-check" key={`afq_filter_allotment${index}`} onClick={() => ToggleAllotmentType(index)}>
                                <input type="checkbox" className="form-check-input" checked={source.Checked} readOnly />
                                <label className="form-check-label">{source.Label}</label>
                              </div>
                            )}

                          </div>

                        </div>

                      </div>
                    </div>
                  }


                  <div className="mt-4 pb-2">
                    <div className="filter-section"><FontAwesomeIcon icon={faConciergeBell} /> Included Services</div>
                    <div className="filter-section-content">
                      <div onClick={() => ToggleAllIncludedServices()} className="form-check border-bottom pb-2 mb-2">
                        <input type="checkbox" className="form-check-input" onChange={() => { }} checked={Filter.SelectAllIncludedServicesActive}></input>
                        <label className="form-check-label">Select/Unselect all</label>
                      </div>
                      <div className="form-group mb-3">
                        {Filter.IncludedService!.map((mainGroup: any, mainIndex: number) =>
                          // <></>
                          // <IncServices data={mainGroup} />
                          <div key={`afq_filter_mainincluded${mainIndex}`}>
                            <div className="font-weight-bold">{mainGroup.MainLabel}</div>
                            {mainGroup.Values.map((subType: any, subIndex: number) =>
                              <div className="form-check" key={`afq_filter_mainincluded${mainIndex}_sub${subIndex}`} onClick={() => ToggleIncludedService(mainIndex, subIndex)}>
                                <input type="checkbox" className="form-check-input" checked={subType.Checked} readOnly />
                                <label className="form-check-label">
                                  <IncludedServiceIcon data={subType.Icon} /> {subType.Label}
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        }
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default FaresFilter;





