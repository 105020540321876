import { TariffSubTypeEnum } from "WebApiClient";

export function TariffSubtypeEnumDisplay(t: TariffSubTypeEnum): string {
    let display = "";
    switch (t) {
        case TariffSubTypeEnum.Corporate:
            display = "Corporate";
            break;
        case TariffSubTypeEnum.Net:
            display = "Net";
            break;
        case TariffSubTypeEnum.None:
            display = "None";
            break;
        case TariffSubTypeEnum.Published:
            display = "Published";
            break;

    }
    return display;
}
