import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { Fragment, useState } from "react";
import { Collapse } from "reactstrap";

interface FarePriceOverviewProps {
    FarePrice: number,
    Currency: string,
    Services: ServiceAddObj[],
    AssignablePassengers: AssignablePassengerOption[]
}
export function GetTotalFarePrice(FarePrice: number, Services: ServiceAddObj[],): number {
    const allServices = Services.flat();
    const prices = allServices.filter(e => e !== undefined && e.Price.equivalentPrice !== undefined).map(e => e.Price.equivalentPrice!.value);
    const total = prices.reduce(function (a, b) {
        return a + b;
    }, FarePrice);
    return total;
}
export const FarePriceOverviewCard: React.FC<FarePriceOverviewProps> = ({ Currency, FarePrice, Services, AssignablePassengers }) => {
    const [showDetailedPrices, setShowDetailedPrices] = useState<boolean>(false);



    const totalPriceDisplay = <SharedPriceDisplay price={{ currencyCode: Currency, isIATA: false, isIATASpecified: false, value: GetTotalFarePrice(FarePrice, Services), valueSpecified: true }} />

    return <div className="">
        <div className="row">

            <div className="col-12 mb-2">
                <div className="d-grid">

                    <button className="btn btn-outline btn-sm" onClick={() => { setShowDetailedPrices(!showDetailedPrices) }}>
                        <FontAwesomeIcon icon={faSearch} /> {showDetailedPrices ? "Hide" : "View"} price details
                    </button>
                </div>
            </div>
            <div className="col-12 mb-2">
                <Collapse isOpen={showDetailedPrices}>
                    <div className="card">

                        <table className="table table-sm table-striped table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Price</th>
                                    <th>Passenger</th>
                                    <th>Segments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Base fare</td>
                                    <td><SharedPriceDisplay price={{ currencyCode: Currency, isIATA: false, isIATASpecified: false, value: FarePrice, valueSpecified: true }} /></td>
                                    <td></td>
                                    <td></td>

                                </tr>
                                {Services.map((e, serviceIndex) =>

                                    <tr key={"Services1" + serviceIndex}>
                                        {e &&
                                            <Fragment>

                                                <td>{e.Service.text}</td>
                                                <td><SharedPriceDisplay price={e.Price.equivalentPrice} /></td>
                                                <td>{AssignablePassengers[e.PassengerIndex].Label}</td>
                                                <td>{e.Segments.map((s, sIndex) =>
                                                    <span key={"Services2" + sIndex}>{s.departure} - {s.arrival} <br /></span>
                                                )}</td>
                                            </Fragment>
                                        }
                                    </tr>
                                )}
                                <tr>
                                    <td className="font-weight-bold">Total</td>
                                    <td>{totalPriceDisplay}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </Collapse>
            </div>
        </div>
    </div>
}