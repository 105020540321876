import { faAlarmClock, faAlarmExclamation, faBriefcase, faCalendar, faCalendarCheck, faFingerprint, faPlane, faPlaneArrival, faPlaneDeparture, faServer, faSitemap, faSpinner, faTasks, faTerminal, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvariantDateInput } from "components/Shared/DateInput";
import { useAuthorizedServicesClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { State } from 'rootExports/rootReducer';
import { AuthorizedServicesClient, BookingManagerFilterOptionsResponse, BookingMangerFilterRequestData, BookingStatus, ENotificationActionRequiredEnumModel, WebAccessEnum } from "WebApiClient";
import { DefaultBookingManagerFilter } from "../types/BookingManagerTypes";
import { GetBookingStatusDisplay } from "./BookingStatusLabel";

interface BookingManagerFilterProps {
  FilterOpen: boolean;
  Toggle: () => void;
  OnUpdate: (filter: BookingMangerFilterRequestData) => void;
  OnSubmit: () => void;
  Filter: BookingMangerFilterRequestData
}

export const BookingManagerFilterModal: React.FC<BookingManagerFilterProps> = ({ FilterOpen, Toggle, OnUpdate, OnSubmit, Filter }) => {
  function OnApply() {
    OnSubmit();
    Toggle()
  }
  function OnOpened() {
    const autoselect = document.getElementById("filekeyinput");
    if (autoselect) {
      autoselect.focus();
    }
    else {
    }
  }

  return <Modal size="xl" scrollable={true} isOpen={FilterOpen} toggle={Toggle} onOpened={OnOpened} >
    <ModalHeader toggle={Toggle}>Filter bookings</ModalHeader>
    <ModalBody>
      <div className="card" id="bookinglist__filter">
        <div className="card-body">
          <BookingManagerFilter Filter={Filter} OnSubmit={OnApply} OnUpdate={OnUpdate} />
        </div>
      </div>

    </ModalBody>
    <ModalFooter>
      <button className="btn btn-secondary" onClick={Toggle}>Close</button> {``}
      <button className="btn btn-danger text-white" onClick={() => OnUpdate(DefaultBookingManagerFilter)}>Reset</button> {``}
      <button className="btn btn-primary" onClick={OnApply}>Apply</button>
    </ModalFooter>

  </Modal >

}

export const BookingManagerFilter: React.FC<{ OnUpdate: (filter: BookingMangerFilterRequestData) => void; OnSubmit: () => void; Filter: BookingMangerFilterRequestData }> = ({ OnUpdate, OnSubmit, Filter }) => {
  const filter = Filter;
  const bookingswebaccess = useSelector((state: State) => state.Session.FrontendSettings.bookingManagerWebAccess);



  const authServicesClient: AuthorizedServicesClient = useAuthorizedServicesClient();

  const [weboptions, setwebOptions] = useState<BookingManagerFilterOptionsResponse>({ branchGroups: [], branches: [], users: [] });
  const [weboptionsOptionsLoading, setweboptionsOptionsLoading] = useState(true);
  const [weboptionsOptionsError, setweboptionsOptionsError] = useState(false);


  const branchGroupOptions = weboptions.branchGroups;
  const branchOptions = weboptions.branches;
  const userOptions = weboptions.users;


  const branchgroupSelectionLocked = bookingswebaccess !== WebAccessEnum.Web;
  const branchSelectionLocked = !(bookingswebaccess === WebAccessEnum.Branchgroup || bookingswebaccess === WebAccessEnum.Web)
  const userSelectionLocked = bookingswebaccess === WebAccessEnum.User;

  const [webSelectionMode, setWebSelectionMode] = useState(true);

  useEffect(() => {
    if (webSelectionMode) {
      setweboptionsOptionsLoading(true);
      authServicesClient.getBookingManagerFilterOptions({ branch: filter.branch, branchgroup: filter.branchGroup }).then(res => {
        setwebOptions(res);
        setweboptionsOptionsError(false);
      }).catch(() => {
        setweboptionsOptionsError(true);
      }).finally(() => {
        setweboptionsOptionsLoading(false);
      });
    }
  }, [filter.branch, filter.branchGroup, webSelectionMode]);

  function UpdateBranchGroup(value: string, clearRelated: boolean) {
    let updatedFilter = { ...filter };
    updatedFilter.branchGroup = value.trim();
    if (clearRelated) {
      updatedFilter.branch = "";
      updatedFilter.responsibleAgent = "";
    }
    UpdateFilter(updatedFilter);
  }

  function UpdateBranch(value: string, clearRelated: boolean) {
    let updatedFilter = { ...filter };
    updatedFilter.branch = value.trim();
    if (clearRelated) {
      updatedFilter.responsibleAgent = "";
    }
    UpdateFilter(updatedFilter);
  }

  function UpdateResponsibleAgent(value: string) {
    let updatedFilter = { ...filter };
    updatedFilter.responsibleAgent = value.trim();
    UpdateFilter(updatedFilter);
  }

  // function OnBurgerStateChange(state: BurgerState) {
  //   SetFilterOpen(state.isOpen);
  // }

  function UpdateFilter(filter: BookingMangerFilterRequestData) {
    OnUpdate(filter);
  }

  const noBranchgroupSelected = branchgroupSelectionLocked ? false : !filter.branchGroup || filter.branchGroup.length === 0;
  const noBranchSelected = branchSelectionLocked ? false : (!filter.branch || filter.branch.length === 0);
  const branchgroupNotInOptions = filter.branchGroup ? filter.branchGroup !== "" ? !branchGroupOptions.some(e => e.label?.toLowerCase() === filter.branchGroup?.toLowerCase()) : true : false;
  const branchNotInOptions = filter.branch ? filter.branch !== "" ? !branchOptions.some(e => e.label?.toLowerCase() === filter.branch?.toLowerCase()) : true : false;
  const userNotInOptions = filter.responsibleAgent ? filter.responsibleAgent !== "" ? !userOptions.some(e => e.name?.toLowerCase() === filter.responsibleAgent?.toLowerCase()) : true : false;


  return (
    <div className="card-body">
      <div className="" onKeyDown={(e) => { if (e.which === 13) { OnSubmit() } }}>
        <div className="row">
          <div className="col-6 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faFingerprint} /> Filekey</div>
              <input placeholder="Filekey" value={filter.recordLocator} id="filekeyinput" onChange={(e) => { UpdateFilter({ ...filter, recordLocator: e.target.value }) }} className="form-control" />
            </div>
          </div>

          <div className="col-6 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faUser} /> Search by name</div>
              <input placeholder="Main Traveller" value={filter.mainTravellerLastName} id="maintravinput" onChange={(e) => { UpdateFilter({ ...filter, mainTravellerLastName: e.target.value }) }} className="form-control" />
            </div>
          </div>
          {!userSelectionLocked &&
            <div className="col-6 mb-1">
              <div className="form-group border p-1">
                <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faSitemap} /> User filters {weboptionsOptionsLoading && <FontAwesomeIcon icon={faSpinner} />}
                  {!branchgroupSelectionLocked &&
                    <div className="form-check form-check-inline float-end">
                      <input className="form-check-input" type="checkbox" checked={!webSelectionMode} onChange={() => { setWebSelectionMode(!webSelectionMode) }} />
                      <label className="form-check-label">Free input</label>
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="col-12 mb-2 mt-2">
                    {!branchgroupSelectionLocked &&
                      <div className="form-group mb-1">
                        <div className="font-weight-bold pb-1">Branchgroup</div>
                        {webSelectionMode ?
                          <select value={filter.branchGroup} onChange={(e) => UpdateBranchGroup(e.target.value, true)} className="form-select w-100">
                            <option value="">All</option>
                            {branchgroupNotInOptions &&
                              <option value={filter.branchGroup}>{filter.branchGroup}</option>
                            }
                            {branchGroupOptions.map((option, index) =>
                              <option key={`bknManager_bgoption_${index}`} value={option.label}>{option.label}</option>
                            )}
                          </select> :
                          <input placeholder="branchgroup" value={filter.branchGroup} onChange={(e) => UpdateBranchGroup(e.target.value, false)} className="form-control" />

                        }


                      </div>
                    }

                  </div>
                  {/* <div className="col-6 mb-2">
                <div className="switch switch-lg mt-1" onClick={() => { setWebSelectionMode(!webSelectionMode) }}>
                  <input
                    type="checkbox"
                    className="switch"
                    checked={!webSelectionMode}
                    readOnly={true}
                  />
                  <label>Free input</label>
                </div>

              </div> */}
                  <Fragment>
                    {!branchSelectionLocked &&
                      <Fragment>
                        <div className="col-12 mb-2">
                          <div className="font-weight-bold pb-1">Branch</div>
                          <div className="form-group mb-1">
                            {webSelectionMode ?
                              <select disabled={noBranchgroupSelected} value={filter.branch} onChange={(e) => UpdateBranch(e.target.value, true)} className="form-select w-100">
                                <option value={""}>{noBranchgroupSelected ? "Select branchgroup..." : "All"}</option>
                                {branchNotInOptions &&
                                  <option value={filter.branch}>{filter.branch}</option>
                                }
                                {branchOptions.map((option, index) =>
                                  <option key={`bknManager_boption_${index}`} value={option.label}>{option.label}</option>
                                )}
                              </select> :
                              <input placeholder="branch" value={filter.branch} onChange={(e) => UpdateBranch(e.target.value, false)} className="form-control" />

                            }
                          </div>
                        </div>
                      </Fragment>
                    }

                    <div className="col-12 mb-2">
                      <div className="form-group mb-1">
                        <div className="font-weight-bold pb-1">Membership members</div>
                        {webSelectionMode ?
                          <select value={filter.responsibleAgent} disabled={noBranchSelected} onChange={(e) => UpdateResponsibleAgent(e.target.value)} className="form-select w-100">
                            <option value={""}>{noBranchSelected ? "Select branch..." : "All"}</option>
                            {userNotInOptions &&
                              <option value={filter.responsibleAgent}>{filter.responsibleAgent}</option>
                            }
                            {userOptions.map((option, index) =>
                              <option key={`bknManager_uoption_${index}`} value={option.name}>{option.name}</option>
                            )}
                          </select> :
                          <input placeholder="agent" value={filter.responsibleAgent} onChange={(e) => UpdateResponsibleAgent(e.target.value)} className="form-control" />
                        }
                      </div>
                    </div>
                  </Fragment>

                </div>
              </div>

            </div>
          }
          <div className="col-6 mb-1">
            <div className="row">
              <div className="col-12 mb-1">
                <div className="form-group">
                  <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faPlane} /> Airline codes</div>
                  <input placeholder="airlines" value={filter.airlineCodes} onChange={(e) => { UpdateFilter({ ...filter, airlineCodes: e.target.value }) }} className="form-control" />
                </div>
              </div>
              <div className="col-12 mb-1">
                <div className="form-group">
                  <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faBriefcase} /> PCC</div>
                  <input placeholder="pcc" value={filter.pcc} onChange={(e) => { UpdateFilter({ ...filter, pcc: e.target.value }) }} className="form-control" />
                </div>
              </div>

              <div className="col-12 mb-2">
                <div className="form-group mb-1">
                  <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faTasks} /> Booking status</div>
                  <BookingStatusSelect value={filter.bookingStatus} OnChange={(e) => { UpdateFilter({ ...filter, bookingStatus: e }) }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-2">
            <div className="">

              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faAlarmExclamation} /> Ticketing time limit</div>
              <div className="row">
                <div className="col-6">

                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.ticketTimeLimitFrom}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, ticketTimeLimitFrom: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["right"]}
                    PlaceHolder="ticketing time limit from"

                  />

                </div>
                <div className="col-6">

                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.ticketTimeLimitTo}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, ticketTimeLimitTo: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["left"]}
                    PlaceHolder="ticketing time limit to"

                  />
                </div>
              </div>
            </div>

          </div>
          <div className="col-12 mb-2">
            <div className="">

              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faCalendarCheck} /> Ticketing date</div>
              <div className="row">
                <div className="col-6">

                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.ticketingDateFrom}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, ticketingDateFrom: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["right"]}
                    PlaceHolder="ticketing date from" />
                </div>
                <div className="col-6">
                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.ticketingDateTo}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, ticketingDateTo: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["left"]}
                    PlaceHolder="ticketing date to" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-2">
            <div className="">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faCalendar} /> Booking date</div>
              <div className="row">
                <div className="col-6">
                  {/* <input value={filter.bookingDateFrom} onChange={(e) => {UpdateFilter({ ...filter, bookingDateFrom: e.target.value }) }} placeholder="from" className="form-control" /> */}
                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.bookingDateFrom}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, bookingDateFrom: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["right"]}
                    PlaceHolder="booking date from" />
                </div>
                <div className="col-6">
                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.bookingDateTo}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, bookingDateTo: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["left"]}
                    PlaceHolder="booking date to" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-2">
            <div className="">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faAlarmClock} /> Departure date</div>
              <div className="row">
                <div className="col-6">
                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.firstDepartureDate}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, firstDepartureDate: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["right"]}
                    PlaceHolder="Departure date from" />
                </div>
                <div className="col-6">
                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.firstDepartureDateTo}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, firstDepartureDateTo: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["left"]}
                    PlaceHolder="Departure date to" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-2">
            <div className="">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faCalendarCheck} /> Arrival date</div>
              <div className="row">
                <div className="col-6">
                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.lastArrvialDate}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, lastArrvialDate: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["right"]}
                    PlaceHolder="Arrival date from"
                  />
                </div>
                <div className="col-6">
                  <InvariantDateInput
                    InputClassNames={"form-control"}
                    Value={filter.lastArrvialDateTo}
                    OnDateChange={(e) => { UpdateFilter({ ...filter, lastArrvialDateTo: e }) }}
                    InputGroup={false}
                    IsDob={false}
                    PopoverPosition={["left"]}
                    PlaceHolder="Arrival date to"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 mb-1">
            <div className="form-group mb-1">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faServer} /> GDS</div>
              <input placeholder="gds" value={filter.gds} onChange={(e) => { UpdateFilter({ ...filter, gds: e.target.value }) }} className="form-control" />
            </div>
          </div>
          <div className="col-6 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faTerminal} /> Status code</div>
              <input placeholder="status code" value={filter.statusCode} onChange={(e) => { UpdateFilter({ ...filter, statusCode: e.target.value }) }} className="form-control" />
            </div>
          </div>
          <div className="col-6 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faPlaneDeparture} /> Origin</div>
              <input placeholder="origin" value={filter.origin} onChange={(e) => { UpdateFilter({ ...filter, origin: e.target.value }) }} className="form-control" />
            </div>

          </div>
          <div className="col-6 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1"><FontAwesomeIcon icon={faPlaneArrival} /> Destination</div>
              <input placeholder="destination" value={filter.destination} onChange={(e) => { UpdateFilter({ ...filter, destination: e.target.value }) }} className="form-control" />
            </div>
          </div>

          <div className="col-12 mb-1">
            <div className="content-divider-dark" />

          </div>
          <div className="col-4 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1">Transaction ID</div>

              <input placeholder="transaction id" value={filter.transactionID} onChange={(e) => { UpdateFilter({ ...filter, transactionID: e.target.value }) }} className="form-control" />


            </div>
          </div>
          <div className="col-4 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1">Shopping cart ID</div>
              <input placeholder="shopping cart" value={filter.shoppingCartID} onChange={(e) => { UpdateFilter({ ...filter, shoppingCartID: e.target.value }) }} className="form-control" />
            </div>
          </div>
          <div className="col-4 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1">Booking ID</div>
              <input placeholder="booking" value={filter.bookingItemID} onChange={(e) => { UpdateFilter({ ...filter, bookingItemID: e.target.value }) }} className="form-control" />
            </div>
          </div>
          <div className="col-12 mb-1">
            <div className="content-divider-dark" />
          </div>
          <div className="col-4 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1">External reference</div>
              <input placeholder="reference" value={filter.externalReference} onChange={(e) => { UpdateFilter({ ...filter, externalReference: e.target.value }) }} className="form-control" />
            </div>
          </div>
          <div className="col-4 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1">Distribution channel</div>
              <input placeholder="distribution channel" value={filter.distributionChannel} onChange={(e) => { UpdateFilter({ ...filter, distributionChannel: e.target.value }) }} className="form-control" />
            </div>
          </div>
          <div className="col-4 mb-1">
            <div className="form-group">
              <div className="font-weight-bold pb-1">Web</div>
              <input placeholder="web" value={filter.web} onChange={(e) => { UpdateFilter({ ...filter, web: e.target.value }) }} className="form-control" />
            </div>
          </div>



        </div>
      </div>






    </div>
  );
};

export const BookingStatusSelect: React.FC<{ value: BookingStatus, OnChange: (val: BookingStatus) => void }> = ({ value, OnChange }) => {
  return <select value={value} className="form-select" onChange={(e) => OnChange(e.target.value as any as BookingStatus)}>
    <option value={BookingStatus.Unknown}>{GetBookingStatusDisplay(BookingStatus.Unknown)}</option>
    <option value={BookingStatus.Booked}>{GetBookingStatusDisplay(BookingStatus.Booked)}</option>
    <option value={BookingStatus.BookingError}>{GetBookingStatusDisplay(BookingStatus.BookingError)}</option>
    <option value={BookingStatus.CancelError}>{GetBookingStatusDisplay(BookingStatus.CancelError)}</option>
    <option value={BookingStatus.Canceled}>{GetBookingStatusDisplay(BookingStatus.Canceled)}</option>
    <option value={BookingStatus.NotProcessed}>{GetBookingStatusDisplay(BookingStatus.NotProcessed)}</option>
    <option value={BookingStatus.Queued}>{GetBookingStatusDisplay(BookingStatus.Queued)}</option>
    <option value={BookingStatus.OrderReshop}>{GetBookingStatusDisplay(BookingStatus.OrderReshop)}</option>
    <option value={BookingStatus.OrderReshopFailed}>{GetBookingStatusDisplay(BookingStatus.OrderReshopFailed)}</option>
    <option value={BookingStatus.TicketCanceled}>{GetBookingStatusDisplay(BookingStatus.TicketCanceled)}</option>
    <option value={BookingStatus.TicketedError}>{GetBookingStatusDisplay(BookingStatus.TicketedError)}</option>
    <option value={BookingStatus.TicketIssued}>{GetBookingStatusDisplay(BookingStatus.TicketIssued)}</option>
    <option value={BookingStatus.TicketCancelError}>{GetBookingStatusDisplay(BookingStatus.TicketCancelError)}</option>
    <option value={BookingStatus.TicketingInProgress}>{GetBookingStatusDisplay(BookingStatus.TicketingInProgress)}</option>
    <option value={BookingStatus.Unknown}>{GetBookingStatusDisplay(BookingStatus.Unknown)}</option>
  </select>
}
export const BookingActionRequiredSelect: React.FC<{ value: ENotificationActionRequiredEnumModel, OnChange: (val: ENotificationActionRequiredEnumModel) => void }> = ({ value, OnChange }) => {
  return <select value={value} className="form-select" onChange={(e) => OnChange(e.target.value as any as ENotificationActionRequiredEnumModel)}>
    <option value={ENotificationActionRequiredEnumModel.None}>All</option>
    <option value={ENotificationActionRequiredEnumModel.Urgent}>Urgent</option>
    <option value={ENotificationActionRequiredEnumModel.High}>High</option>
    <option value={ENotificationActionRequiredEnumModel.Medium}>Medium</option>
    <option value={ENotificationActionRequiredEnumModel.Low}>Low</option>
  </select>
}


export default BookingManagerFilter










