import { faFrown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import {
  AncillaryModel,
  ServiceGroupTypeEnumModel,
  ServiceModel,
} from "WebApiClient";
import { AncillaryGroup } from "./AncillaryGroup";
export interface AssignableSegmentOption {
  From: string;
  To: string;
}
export interface AssignablePassengerOption {
  PassengerType: string;
  Label: string;
  Index: number;
}
interface AncillarySelectionProps {
  PassengerOptions: AssignablePassengerOption[];
  SegmentOptions: AssignableSegmentOption[];
  Model: AncillaryModel;
  OnAddService(obj: ServiceAddObj): void;
  OnEditService(index: number, amount: number): void;
  AddedServices: ServiceAddObj[];
  IncludedServices: ServiceModel | any;
}
export interface GroupedService {
  Type: ServiceGroupTypeEnumModel;
  Services: ServiceModel[];
}
export const AncillarySelection: React.FC<AncillarySelectionProps> = ({
  Model,
  AddedServices,
  OnAddService,
  OnEditService,
  PassengerOptions,
  IncludedServices,
}) => {
  const { passengers, prices, segments, services } = Model;

  const [toggleAllGroups, setToggleAllGroups] = useState<boolean>(false);
  const [showIncludedServices, setShowIncludedServices] = useState<boolean>(false);
  const [showOptionalServices, setShowOptionalServices] = useState<boolean>(false);
  const [showIncludedServices2, setShowIncludedServices2] = useState<boolean>(false);
  const [serviceType, setServiceType] = useState("all");
  const grouped: GroupedService[] = GetGrouped(services);

  const countIncluded = services.filter(
    (e) => e.bookingCode.length === 0
  ).length;

  function GetGrouped(services: ServiceModel[]): GroupedService[] {
    let result: GroupedService[] = [];
    services.forEach((service) => {
      if (result.filter((e) => e.Type === service.type).length === 0) {
        const newGroup: GroupedService = {
          Type: service.type,
          Services: [service],
        };
        result.push(newGroup);
      } else {
        result.filter((e) => e.Type === service.type)[0].Services.push(service);
      }
    });
    return result;
  }

  function servicesDisplay(type: string = "") {
    if (type === "inclServ") {
      setShowOptionalServices(false);
      setShowIncludedServices2(true);
    }
    if (type === "oplServ") {
      setShowOptionalServices(true);
      setShowIncludedServices2(false);
    }
  }

  function showServices(services: any) {

    setServiceType(services);
    if (services === "all") {
      setShowIncludedServices(true);
    }
    if (services === "inclServ") {
      setShowIncludedServices(true);
    }
    if (services === "oplServ") {
      setShowIncludedServices(false);
    }
  }

  useEffect(()=>{
    showServices("oplServ")
  },[])


  return (
    <div className="row">
      <div className="col-12 mb-2">
        <div className="row">
          <div className="col-auto">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setToggleAllGroups(!toggleAllGroups);
              }}>
              Expand/Collapse all groups
            </button>
          </div>
          <div className="col-auto">
            <div
              className="custom-control custom-radio"
              onClick={() => setShowIncludedServices(!showIncludedServices)}
            >
              {/* <input type="checkbox" onChange={() => { }} checked={showIncludedServices} className="form-check-input" /> */}
              {/* <label className="form-check-label" >Show included services** ({countIncluded})</label> */}
              <React.Fragment>
                <div className="input-group">
                  <select
                    onChange={(e) => showServices(e.target.value)}
                    // onKeyDown={(e) => OnInputKeyDown(e)}
                    className="form-select "
                    style={{ height: "calc(1.9rem + 2px)" }}>
                    <option value="all">All</option>
                    <option value="inclServ">Included Services</option>
                    <option selected value="oplServ">Optional Services</option>
                  </select>
                </div>
                {/* <Popover position="top" isOpen={InputActive} containerStyle={{ zIndex: "9999" }} content={({ position, targetRect, popoverRect }) => (ToolTipContent(position, targetRect, popoverRect))}>
      </Popover> */}
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>

      {grouped.map((group, index) => (
        <div className="col-12 mb-2" key={"grps" + index}>
          <AncillaryGroup
            Group={group}
            ToggleAll={toggleAllGroups}
            Passengers={passengers}
            Prices={prices}
            Segments={segments}
            ShowIncluded={showIncludedServices}
            OnAddService={OnAddService}
            AddedServices={AddedServices}
            PassengerOptions={PassengerOptions}
            serviceType={serviceType}
            IncludedService={IncludedServices}
          />
        </div>
      ))}
      {grouped.length === 0 && (
        <div className="col-12">
          <Alert color="info">
            <h4 className="alert-heading">
              <FontAwesomeIcon icon={faFrown} /> No ancillary services found for
              this connection.
            </h4>
          </Alert>
        </div>
      )}
    </div>
  );
};