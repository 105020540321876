import { faCommentDots, faCommentsDollar, faCopyright, faIdBadge, faInfoCircle, faLoveseat, faTicket } from "@fortawesome/pro-regular-svg-icons";
import { DateDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import React from "react";
import { PnrResponsePassengerModel, PnrResponseSegmentModel } from "WebApiClient";
import { OsiTable } from "./Pnr.OsisCard";
import { SSRTable } from "./Pnr.SSRsCard";


export const PnrPassengersCard: React.FC<{ data: PnrResponsePassengerModel[], segments: PnrResponseSegmentModel[] }> = ({ data, segments }) => {

    // const [cardView, setCardView] = useState(false);

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Passengers <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="row">

                            {data.map((pax, index) =>
                                <div key={"pax_"+index} className="col-12 mb-2">
                                    <PNRPassengerCard data={pax} passengers={data} segments={segments} />
                                </div>
                            )}

                        </div>
                    </div>


                </div>

            </div>
        </div>
    );


}



export const PNRPassengerCard: React.FC<{ data: PnrResponsePassengerModel, passengers: PnrResponsePassengerModel[], segments: PnrResponseSegmentModel[] }> = ({ data, passengers, segments }) => {
    const { age, skElements, firstName, frequentFlyerAirline, frequentFlyerNumber,
        name, nameRemark, assistanceRequired, customerNameData, dateOfBirth, equivalentPrice,
        equivalentTax, foid, osi, passengerNumber,
        passengerTypeCode, passengerTypeName, remarks, seatPreferences, specialServiceRequests,
        ticketNumbers, title } = data;

    const TabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: [
            {
                Component: <div className="card">
                    <div className="card-body">
                        <div className="card-title">General Passenger Information</div>
                        <table className="table table-sm table-striped table-hover">
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold">Last name</td>
                                    <td>{name}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">First name</td>
                                    <td>{firstName}</td>
                                </tr>
                                {dateOfBirth &&
                                    <tr>
                                        <td className="font-weight-bold">Date of Birth</td>
                                        <td><DateDisplay date={dateOfBirth} /></td>
                                    </tr>
                                }
                                {customerNameData.length > 0 &&
                                    <tr>
                                        <td className="font-weight-bold">Name data</td>
                                        <td>{customerNameData}</td>
                                    </tr>
                                }

                                {passengerTypeCode.length > 0 &&
                                    <tr>
                                        <td className="font-weight-bold">Passenger type</td>
                                        <td>{passengerTypeName} ({passengerTypeCode})</td>
                                    </tr>
                                }

                                {age !== 0 &&
                                    <tr>
                                        <td className="font-weight-bold">Age</td>
                                        <td>{age.toString()}</td>
                                    </tr>
                                }
                                {frequentFlyerAirline.length > 0 &&
                                    <tr>
                                        <td className="font-weight-bold">Frequent flyer airline</td>
                                        <td>{frequentFlyerAirline}</td>
                                    </tr>
                                }

                                {frequentFlyerNumber.length > 0 &&
                                    <tr>
                                        <td className="font-weight-bold">Frequent flyer number</td>
                                        <td>{frequentFlyerNumber}</td>
                                    </tr>
                                }
                                {nameRemark.length > 0 &&
                                    <tr>
                                        <td className="font-weight-bold">Name remark</td>
                                        <td>{nameRemark}</td>
                                    </tr>
                                }


                                {equivalentPrice &&
                                    <tr>
                                        <td className="font-weight-bold">Equiv. Price</td>
                                        <td><SharedPriceDisplay price={equivalentPrice} /></td>
                                    </tr>
                                }
                                {equivalentTax &&
                                    <tr>
                                        <td className="font-weight-bold">Equiv. Tax</td>
                                        <td><SharedPriceDisplay price={equivalentTax} /></td>
                                    </tr>
                                }


                                <tr>
                                    <td className="font-weight-bold">Assistance required</td>
                                    <td>{assistanceRequired ? "Yes" : "No"}</td>
                                </tr>

                                {remarks.length > 0 &&
                                    <tr>
                                        <td className="font-weight-bold">Remarks</td>
                                        <td>{remarks.join(", ")}</td>
                                    </tr>
                                }
                            </tbody>


                        </table>
                    </div>
                </div>,
                Title: "General",
                Icon: faInfoCircle,
                AmountIndicator: undefined
            },
            ...seatPreferences.length > 0 ? [{
                Component: <div className="card">
                    <div className="card-body">
                        <div className="card-title">Seat preferences</div>
                        <table className="table table-sm table-striped table-hover table-bordered">
                            <thead>
                                <tr>

                                    <th className="font-weight-bold">Characteristics</th>
                                    <th className="font-weight-bold">Chargeable</th>
                                    <th className="font-weight-bold">Issued</th>
                                    <th className="font-weight-bold">Preference</th>
                                    <th className="font-weight-bold">Row</th>
                                    <th className="font-weight-bold">Seat</th>
                                    <th className="font-weight-bold">Segments</th>
                                    <th className="font-weight-bold">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {seatPreferences.map((seat, index) =>
                                    <tr key={"seat_"+index}>
                                        <td>{seat.characteristics}</td>
                                        <td>{seat.chargeable ? "Yes" : "No"}</td>
                                        <td>{seat.isIssued ? "Yes" : "No"}</td>
                                        <td>{seat.preference}</td>
                                        <td>{seat.row}</td>
                                        <td>{seat.seat}</td>
                                        <td>{seat.segmentNumber.join(",")}</td>
                                        <td>{seat.status}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>,
                Title: "Seat preferences",
                Icon: faLoveseat,
                AmountIndicator: seatPreferences.length
            },] : [],
            ...osi.length > 0 ? [{
                Component: <div className="card">
                    <div className="card-body">
                        <div className="card-title">OSI</div>
                        <OsiTable data={osi} />
                    </div>
                </div>,
                Title: "OSI",
                Icon: faCommentDots,
                AmountIndicator: osi.length
            },] : [],
            ...foid ? [{
                Component: <div className="card">
                    <div className="card-body">
                        <div className="card-title">FOID</div>
                        <table className="table table-sm table-striped table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <td>Carrier</td>
                                    <td>{foid.carrierCode}</td>

                                </tr>
                                <tr>
                                    <td>Issuer</td>
                                    <td>{foid.issuer}</td>
                                </tr>
                                <tr>
                                    <td>Number</td>
                                    <td>{foid.number}</td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>{foid.type}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>,
                Title: "FOID",
                Icon: faIdBadge,
                AmountIndicator: undefined
            },] : [],
            ...specialServiceRequests.length > 0 ? [{
                Component: <div className="card">
                    <div className="card-body">
                        <div className="card-title">Special service requests</div>
                        <SSRTable data={specialServiceRequests} pnrPassengers={passengers} segments={segments} />
                    </div>
                </div>,
                Title: "SSR",
                Icon: faCommentsDollar,
                AmountIndicator: specialServiceRequests.length
            },] : [],

            ...skElements.length > 0 ? [{
                Component: <div className="card">
                    <div className="card-body">
                        <div className="card-title">SK</div>
                        <table className="table table-sm table-striped table-hover table-bordered">
                            <thead>
                                <tr>

                                    <th className="font-weight-bold">Code</th>
                                    <th className="font-weight-bold">Text</th>
                                    <th className="font-weight-bold">Segments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {skElements.map((sk, index) =>
                                    <tr key={"sk_"+index}>
                                        <td>{sk.code}</td>
                                        <td>{sk.text}</td>
                                        <td>{sk.segmentNumbers.join(",")}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>,
                Title: "SK",
                Icon: faCopyright,
                AmountIndicator: skElements.length
            },] : [],




            ...ticketNumbers.length > 0 ? [{
                Component: <div className="card">
                    <div className="card-body">
                        <div className="card-title">Ticket numbers</div>
                        <table className="table table-sm table-striped table-hover table-bordered">
                            <thead>
                                <tr>

                                    <th className="font-weight-bold">EMD</th>
                                    <th className="font-weight-bold">Plating carrier</th>
                                    <th className="font-weight-bold">Segments</th>
                                    <th className="font-weight-bold">Status</th>
                                    <th className="font-weight-bold">Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticketNumbers.map((ticket, index) =>
                                    <tr key={"tickt_"+index}>
                                        <td>{ticket.isEMD ? "Yes" : "No"}</td>
                                        <td>{ticket.platingCarrier}</td>
                                        <td>{ticket.segmentNumbers.join(",")}</td>
                                        <td>{ticket.ticketStatus}</td>
                                        <td>{ticket.ticketnumber}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>,
                Title: "Ticket infos",
                Icon: faTicket,
                AmountIndicator: ticketNumbers.length
            },] : [],

        ]
    }
    return (
        <div className="card">

            <div className="card-header card-header-primary">#{passengerNumber} {title} {name}, {firstName}</div>
            <div className="card-body">
                <TabbedContent {...TabContent} />

            </div>




        </div>


    );


}

{/* <div className="col-12 mb-2">
                        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                            <button type="button" onClick={() => { setCardView(!cardView) }} className={`btn ${!cardView ? "btn-primary" : "btn-outline-secondary"} btn-sm`}><FontAwesomeIcon icon={faTable} /> Table</button>
                            <button type="button" onClick={() => { setCardView(!cardView) }} className={`btn ${cardView ? "btn-primary" : "btn-outline-secondary"}  btn-sm`}><FontAwesomeIcon icon={faListAlt} /> List</button>
                        </div>
                    </div> */}


{/* 
                        // <div className="col-12">
                        //     <table className="table table-sm table-striped table-hover">
                        //         <thead>
                        //             <tr>
                        //                 <th className="font-weight-bold">#</th>
                        //                 <th className="font-weight-bold">Name</th>
                        //                 <th className="font-weight-bold">Type</th>
                        //                 <th className="font-weight-bold">DOB</th>
                        //                 <th className="font-weight-bold">Price / Tax</th>
                        //                 <th className="font-weight-bold">Tickets</th>
                        //                 <th className="font-weight-bold">Assist. required</th>
                        //                 <th className="font-weight-bold">FF no.</th>
                        //                 <th className="font-weight-bold">SK</th>
                        //                 <th className="font-weight-bold">OSI</th>
                        //                 <th className="font-weight-bold">Seats</th>

                        //             </tr>
                        //         </thead>
                        //         <tbody>
                        //             {data.map((pax, index) =>
                        //                 <PNRPassengerTableRowItem key={index} data={pax} />
                        //             )}
                        //         </tbody>
                        //     </table>
                        // </div> */}
// const PNRPassengerTableRowItem: React.FC<{ data: PnrResponsePassengerModel }> = ({ data }) => {
//     const { skElements, firstName, frequentFlyerAirline, frequentFlyerNumber,
//         name, nameRemark, assistanceRequired, customerNameData, dateOfBirth, equivalentPrice,
//         equivalentTax, foid, osi, passengerNumber,
//         passengerTypeCode, passengerTypeName, remarks, seatPreferences, specialServiceRequests,
//         ticketNumbers, title } = data;
//     return <tr>
//         <td>{passengerNumber}</td>
//         <td>{title.length > 0 && <span>{title}</span>} {name}{firstName.length > 0 && <span>, {firstName}</span>}</td>

//         <td>{passengerTypeCode} ({passengerTypeName})</td>
//         <td><DateDisplay date={dateOfBirth} /></td>
//         <td><SharedPriceDisplay price={equivalentPrice} /> {equivalentTax && <Fragment> / <SharedPriceDisplay price={equivalentTax} /></Fragment>} </td>
//         <td>
//             {ticketNumbers.length > 0 &&
//                 <table className="table table-sm table-striped table-hover">
//                     <thead>
//                         <tr>
//                             <th className="font-weight-bold">Number</th>
//                             <th className="font-weight-bold">Status</th>
//                             <th className="font-weight-bold">Segments</th>
//                             <th className="font-weight-bold">Carrier</th>
//                             <th className="font-weight-bold">EMD</th>
//                         </tr>
//                     </thead>
//                     <tbody>

//                         {ticketNumbers.map((tktnr, tktIndex) =>

//                             <tr key={tktIndex}>
//                                 <td>{tktnr.ticketnumber}</td>
//                                 <td>{tktnr.ticketStatus}</td>
//                                 <td>{tktnr.segmentNumbers.join(",")}</td>
//                                 <td>{tktnr.platingCarrier}</td>
//                                 <td>{tktnr.isEMD ? "Yes" : "No"}</td>

//                             </tr>
//                         )}
//                     </tbody>
//                 </table>
//             }

//         </td>
//         <td>{assistanceRequired ? "Yes" : "No"}</td>
//         <td>{frequentFlyerNumber} {frequentFlyerAirline}</td>
//         <td>
//             {skElements.length > 0 &&
//                 <table className="table table-sm table-striped table-hover">
//                     <thead>
//                         <tr>
//                             <th className="font-weight-bold">Code</th>
//                             <th className="font-weight-bold">Text</th>
//                             <th className="font-weight-bold">Segments</th>
//                         </tr>
//                     </thead>
//                     <tbody>

//                         {skElements.map((sk, skIndex) =>

//                             <tr key={skIndex}>
//                                 <td>{sk.code}</td>
//                                 <td>{sk.text}</td>
//                                 <td>{sk.segmentNumbers.join(",")}</td>


//                             </tr>
//                         )}
//                     </tbody>
//                 </table>
//             }
//         </td>
//         <td>
//             {osi.length > 0 &&
//                 <OsiTable data={osi} />
//             }
//         </td>
//         <td>
//             {seatPreferences.length > 0 &&
//                 <table className="table table-sm table-striped table-hover">
//                     <thead>
//                         <tr>
//                             <th className="font-weight-bold">Segment</th>
//                             <th className="font-weight-bold">Row</th>
//                             <th className="font-weight-bold">Seat</th>
//                             <th className="font-weight-bold">Issued</th>
//                             <th className="font-weight-bold">Chargeable</th>
//                             <th className="font-weight-bold">Char.</th>
//                             <th className="font-weight-bold">Pref.</th>
//                             <th className="font-weight-bold">Status</th>
//                         </tr>
//                     </thead>
//                     <tbody>

//                         {seatPreferences.map((seat, seatIndex) =>

//                             <tr key={seatIndex}>
//                                 <td>{seat.segmentNumber}</td>
//                                 <td>{seat.row}</td>
//                                 <td>{seat.seat}</td>
//                                 <td>{seat.isIssued ? "Yes" : "No"}</td>
//                                 <td>{seat.chargeable ? "Yes" : "No"}</td>
//                                 <td>{seat.characteristics}</td>
//                                 <td>{seat.preference}</td>
//                                 <td>{seat.status}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>
//             }
//         </td>
//     </tr>
// }