import React from "react";
import { SingleBookingGeneralInfo } from '../../../WebApiClient';
interface BookingVariousInfoCardProps {
  Data: SingleBookingGeneralInfo;

}
const BookingVariousInfoCard: React.FC<BookingVariousInfoCardProps> = ({ Data }) => {
  const {
    bookingDate, ticketMethod, statusCode, source,
    responsibleAgent, platingCarrier, filekey, externalReference, distributionChannel,
    crs, cartId, branchgroup, branch, bookingVersion,
    bookingUTC, bookingType, bookingStatus, bookingId,
    destination, firstDepartureDate,
    lastArrivalDate, mainPassengerFirstName,
    mainPassengerLastName, origin, paymentInfo,
    ticketTimeLimit, ticketingDate, totalPrice, totalTax } = Data;

  return (
    <div className="card">
      <div className="card-header card-header-primary">Various Infos</div>
      <div className="card-body">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-sm table-striped table-hover table-bordered">
              <tbody>
                <tr>
                  <td className="font-weight-bold">Responsible Agent</td>
                  <td>{responsibleAgent}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Branch</td>
                  <td>{branch}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Branchgroup</td>
                  <td>{branchgroup}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Booking ID</td>
                  <td>{bookingId}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Cart ID</td>
                  <td>{cartId}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Booking Version</td>
                  <td>{bookingVersion ? bookingVersion.toString() : ""}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Booking type</td>
                  <td>{bookingType}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">External reference</td>
                  <td>{externalReference}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Distribution channel</td>
                  <td>{distributionChannel}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Source</td>
                  <td>{source}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Booking UTC</td>
                  <td>{bookingUTC ? bookingUTC.toString() : ""}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Status code</td>
                  <td>{statusCode}</td>
                </tr>



              </tbody>
            </table>
          </div>
        </div>





      </div>
    </div>)


}

export default BookingVariousInfoCard;



{/* <div className="col-auto">
              <label className="col-form-label font-weight-bold">Payment type</label>
              <input type="text" className="form-control-plaintext" readOnly={true} value={Data.paymentType} />
            </div> */}
{/* {(Data.bankCodeNumber && Data.bankCodeNumber.length > 0) &&

              <div className="col-auto">
                <label className="col-form-label font-weight-bold">Bank code number</label>
                <input type="text" className="form-control-plaintext" readOnly={true} value={Data.bankCodeNumber} />
              </div>
            } */}
{/* {(Data.bankName && Data.bankName.length > 0) &&

              <div className="col-auto">
                <label className="col-form-label font-weight-bold">Bank name</label>
                <input type="text" className="form-control-plaintext" readOnly={true} value={Data.bankName} />
              </div>
            } */}
{/* {(Data.creditCardExpiryDate && Data.creditCardExpiryDate.length > 0) &&

              <div className="col-auto">
                <label className="col-form-label font-weight-bold">Credit card expire date</label>
                <input type="text" className="form-control-plaintext" readOnly={true} value={Data.creditCardExpiryDate} />
              </div>
            } */}
{/* {(Data.creditCardType && Data.creditCardType.length > 0) &&

              <div className="col-auto">
                <label className="col-form-label font-weight-bold">Credit card type</label>
                <input type="text" className="form-control-plaintext" readOnly={true} value={Data.creditCardType} />
              </div>
            }
            {(Data.holderName && Data.holderName.length > 0) &&

              <div className="col-auto">
                <label className="col-form-label font-weight-bold">Credit card holder name</label>
                <input type="text" className="form-control-plaintext" readOnly={true} value={Data.holderName} />
              </div>
            }
            {(Data.number && Data.number.length > 0) &&

              <div className="col-auto">
                <label className="col-form-label font-weight-bold">Credit card number</label>
                <input type="text" className="form-control-plaintext" readOnly={true} value={Data.number} />
              </div>
            }
            {(Data.virtualPaymentProvider && Data.virtualPaymentProvider.length > 0) &&

              <div className="col-auto">
                <label className="col-form-label font-weight-bold">Virtual payment provider</label>
                <input type="text" className="form-control-plaintext" readOnly={true} value={Data.virtualPaymentProvider} />
              </div>
            } */}
{/* <div className="row">
         
         <Row className="mb-1">
         <Col sm={3}><p className="font-weight-bold">Payment type</p></Col>
         <Col sm={9}>
         {Data.paymentType}
         </Col>
         </Row>
         
         
         
         {(Data.bankCodeNumber && Data.bankCodeNumber.length > 0) &&
          <Row className="mb-1">
          <Col sm={3}><p className="font-weight-bold">Bank code number</p></Col>
          <Col sm={9}>
          {Data.bankCodeNumber}
          </Col>
          </Row>
        }
        {(Data.bankName && Data.bankName.length > 0) &&
          <Row className="mb-1">
          <Col sm={3}><p className="font-weight-bold">Bank name</p></Col>
          <Col sm={9}>
          {Data.bankName}
          </Col>
          </Row>
        }
        {(Data.creditCardExpiryDate && Data.creditCardExpiryDate.length > 0) &&
              <Row className="mb-1">
              <Col sm={3}><p className="font-weight-bold">Credit card expiry date</p></Col>
              <Col sm={9}>
              {Data.creditCardExpiryDate}
              </Col>
              </Row>
            }
            {(Data.creditCardType && Data.creditCardType.length > 0) &&
              <Row className="mb-1">
              <Col sm={3}><p className="font-weight-bold">Credit card type</p></Col>
              <Col sm={9}>
              {Data.creditCardType}
              </Col>
              </Row>
            }
            {(Data.holderName && Data.holderName.length > 2) &&
              <Row className="mb-1">
              <Col sm={3}><p className="font-weight-bold">Credit card holder name</p></Col>
              <Col sm={9}>
              {Data.holderName}
              </Col>
              </Row>
            }
            {(Data.number && Data.number.length > 0) &&
              <Row className="mb-1">
              <Col sm={3}><p className="font-weight-bold">Credit card number</p></Col>
              <Col sm={9}>
              {Data.number}
              </Col>
              </Row>
            }
            {(Data.virtualPaymentProvider && Data.virtualPaymentProvider.length > 0) &&
              <Row className="mb-1">
              <Col sm={3}><p className="font-weight-bold">Virtual payment provider</p></Col>
              <Col sm={9}>
              {Data.virtualPaymentProvider}
              </Col>
              </Row>
            }
          </div> */}