import React from "react";
import { AgentGenderEnum } from "WebApiClient";

export const GenderEnumDisplay: React.FC<{ gender: AgentGenderEnum }> = ({ gender }) => {
    function GetGender(gender: AgentGenderEnum) {
        let result = "";
        switch (gender) {
            case AgentGenderEnum.Male:
                result = "Male";
                break;
            case AgentGenderEnum.Female:
                result = "Female";
                break;
        }
        return result;
    }
    return <span>{GetGender(gender)}</span>
}