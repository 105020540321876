import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ENotificationActionRequiredEnumModel, ENotificationCategoryEnumModel } from "WebApiClient";
import { FilterNotificationState, SortNotificationBy } from "./Types";
export const NotificationsFilterInput: React.FC<{ data: FilterNotificationState, OnUpdate: (e: FilterNotificationState) => void }> = ({ OnUpdate, data }) => {
    const { SortBy, Descending, ActionRequired } = data;
    function AddOrRemoveActionRequired(val: ENotificationActionRequiredEnumModel) {
        let arrC = [...data.ActionRequired];
        if (arrC.includes(val)) {
            arrC.splice(arrC.indexOf(val), 1);
        }
        else { arrC.push(val) };
        OnUpdate({ ...data, ActionRequired: arrC })
    }
    function AddOrRemoveCategory(val: ENotificationCategoryEnumModel) {
        let arrC = [...data.Category];
        if (arrC.includes(val)) {
            arrC.splice(arrC.indexOf(val), 1);
        }
        else { arrC.push(val) };
        OnUpdate({ ...data, Category: arrC })
    }
    return <div className="card">
        <div className="card-header card-header-primary">
            Filter notifications
        </div>
        <div className="card-body card-body-primary">
            <div className="row">
                <div className="col-12">
                    <span className="font-weight-bold">Order by...</span>
                </div>
                <div className="col">


                    <div className="form-group mb-1">
                        <select className="form-control" value={SortBy} onChange={(e) => { OnUpdate({ ...data, SortBy: e.target.value as SortNotificationBy }) }}>
                            <option value={"Priority"}>Priority</option>
                            <option value={"Date"}>Date</option>



                        </select>

                    </div>
                </div>
                <div className="col">
                    <div className="form-check mb-1">
                        <input className="form-check-input" type="checkbox" onChange={() => OnUpdate({ ...data, Descending: !Descending })} checked={Descending} />
                        <label className="form-check-label pb-1">Descending</label>


                    </div>

                </div>
                <div className="col-12 mb-2">
                    <span className="font-weight-bold">Priority...</span> <span className="clickme" onClick={() => { OnUpdate({ ...data, ActionRequired: [] }) }} style={{ color: "red" }}><FontAwesomeIcon icon={faTimes} /> deselect all</span>
                </div>
                <div className="col-12 mb-2">
                    <div className="row">
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.ActionRequired.includes(ENotificationActionRequiredEnumModel.None)} onChange={() => AddOrRemoveActionRequired(ENotificationActionRequiredEnumModel.None)} />
                                <label className="form-check-label">None</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.ActionRequired.includes(ENotificationActionRequiredEnumModel.Low)} onChange={() => AddOrRemoveActionRequired(ENotificationActionRequiredEnumModel.Low)} />
                                <label className="form-check-label">Low</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.ActionRequired.includes(ENotificationActionRequiredEnumModel.Medium)} onChange={() => AddOrRemoveActionRequired(ENotificationActionRequiredEnumModel.Medium)} />
                                <label className="form-check-label">Medium</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.ActionRequired.includes(ENotificationActionRequiredEnumModel.High)} onChange={() => AddOrRemoveActionRequired(ENotificationActionRequiredEnumModel.High)} />
                                <label className="form-check-label">High</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.ActionRequired.includes(ENotificationActionRequiredEnumModel.Urgent)} onChange={() => AddOrRemoveActionRequired(ENotificationActionRequiredEnumModel.Urgent)} />
                                <label className="form-check-label">Urgent</label>
                            </div>
                        </div>


                    </div>

                </div>
                <div className="col-12 mb-2">
                    <span className="font-weight-bold">Categories...</span> <span className="clickme" onClick={() => { OnUpdate({ ...data, Category: [] }) }} style={{ color: "red" }}><FontAwesomeIcon icon={faTimes} /> deselect all</span>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.BookingFailed)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.BookingFailed)} />
                                <label className="form-check-label">Booking failed</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.OCN)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.OCN)} />
                                <label className="form-check-label">Order change</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.Other)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.Other)} />
                                <label className="form-check-label">Other</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.PaymentRejected)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.PaymentRejected)} />
                                <label className="form-check-label">Payment rejected</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.QueueRobot)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.QueueRobot)} />
                                <label className="form-check-label">Queue robot</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.Remark)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.Remark)} />
                                <label className="form-check-label">Remark</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.Reprice)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.Reprice)} />
                                <label className="form-check-label">Reprice</label>
                            </div>
                        </div>
                        <div className="col-auto">

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={data.Category.includes(ENotificationCategoryEnumModel.TTL)} onChange={() => AddOrRemoveCategory(ENotificationCategoryEnumModel.TTL)} />
                                <label className="form-check-label">Ticketing time limit</label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
}