import { faMedium } from "@fortawesome/free-brands-svg-icons";
import { faChalkboardTeacher, faCircle, faCoffee, faCookieBite, faCouch, faExchange, faFastForward, faGavel, faGlassMartini, faInfoCircle, faMapMarkerQuestion, faMapSigns, faMoneyCheckEditAlt, faPaw, faPlug, faRandom, faRedo, faShoppingBag, faSuitcase, faTachometerFastest, faTicketAlt, faTv, faUndoAlt, faUserCheck, faUtensils, faWifi, IconDefinition} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState, useEffect } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { IncludedServiceData, IncludedServiceMainType, IncludedServiceSubType } from "WebApiClient";

interface IncludedServiceIconProps {
    data: IncludedServiceData;
}


const IncludedServiceIcon: React.FC<IncludedServiceIconProps> = props => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const elemRef = useRef<HTMLSpanElement>(null);


    return <React.Fragment>
        {/* <Popover position="top" isOpen={open} containerStyle={{ zIndex: "100" }} content={({ position, targetRect, popoverRect }) => (ToolTipContent(position, targetRect, popoverRect))}> */}

        <Popover target={elemRef} isOpen={open} toggle={toggle}>
            <PopoverBody>
                {props.data.hint}
            </PopoverBody>
        </Popover>

        <span ref={elemRef}>
            <FontAwesomeIcon
                icon={GetIcon(props.data)}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onTouchStart={(e) => { e.stopPropagation(); setOpen(true) }}
                onTouchEnd={() => setOpen(false)}
            />
        </span>
    </React.Fragment >
};




function GetIcon(data: IncludedServiceData): IconDefinition {
    let iconClass: IconDefinition = faInfoCircle;
    switch (data.subType) {
        case IncludedServiceSubType.FreeBaggageAllowance:
            iconClass = faSuitcase;
            break;
        case IncludedServiceSubType.CarryOnBaggage:
            iconClass = faShoppingBag;
            break;
        case IncludedServiceSubType.Lunch:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.Snack:
            iconClass = faCookieBite;
            break;
        case IncludedServiceSubType.SnacksAndBeverage:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.NonAlcoholicDrink:
            iconClass = faCoffee;
            break;
        case IncludedServiceSubType.FreeAlcoholicDrink:
            iconClass = faGlassMartini;
            break;
        // INFLIGHT ENTERTAINMENT
        case IncludedServiceSubType.PersonalEntertainment:
            iconClass = faTv;
            break;
        case IncludedServiceSubType.InternetAccess:
            iconClass = faWifi;
            break;
        case IncludedServiceSubType.InSeatpower:
            iconClass = faPlug;
            break;
        case IncludedServiceSubType.Pet:
            iconClass = faPaw;
            break;
        case IncludedServiceSubType.Security:
            iconClass = faGavel;
            break;
        case IncludedServiceSubType.PriorityBoarding:
            iconClass = faFastForward;
            break;
        case IncludedServiceSubType.Ticket:
            iconClass = faTicketAlt;
            break;
        case IncludedServiceSubType.CheckIn:
            iconClass = faChalkboardTeacher;
            break;
        case IncludedServiceSubType.ReBooking:
            iconClass = faRedo;
            break;
        case IncludedServiceSubType.RefundAllowed:
            iconClass = faMoneyCheckEditAlt;
            break;
        case IncludedServiceSubType.PriorityCheckIn:
            iconClass = faUserCheck;
            break;
        case IncludedServiceSubType.FastTrack:
            iconClass = faTachometerFastest;
            break;
        case IncludedServiceSubType.ChangeAfterDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeBeforeDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeInCaseOfNoShow:
            iconClass = faMapMarkerQuestion;
            break;
        case IncludedServiceSubType.OpenReturnDate:
            iconClass = faExchange;
            break;
        case IncludedServiceSubType.Stopover:
            iconClass = faMapSigns;
            break;
        default:
            switch (data.type) {
                case IncludedServiceMainType.Baggage:
                    iconClass = faSuitcase;
                    break;
                case IncludedServiceMainType.Meal:
                    iconClass = faUtensils;
                    break;
                case IncludedServiceMainType.InFlightEntertainment:
                    iconClass = faTv;
                    break;
                case IncludedServiceMainType.Miles:
                    iconClass = faMedium;
                    break;
                case IncludedServiceMainType.Transfer:
                    iconClass = faRandom;
                    break;
                case IncludedServiceMainType.Seat:
                    iconClass = faCouch;
                    break;
                case IncludedServiceMainType.Other:
                    iconClass = faCircle;
                    break;
            }
    }
    return iconClass;
}

export function GetMaingroupName(maintype: IncludedServiceMainType): string {
    let result = "Unknown";
    switch (maintype) {
        case IncludedServiceMainType.Baggage:
            result = "Baggage";
            break;
        case IncludedServiceMainType.InFlightEntertainment:
            result = "In-flight entertainment"
            break;
        case IncludedServiceMainType.Meal:
            result = "Meal"
            break;
        case IncludedServiceMainType.Miles:
            result = "Miles"
            break;
        case IncludedServiceMainType.Other:
            result = "Other"
            break;
        case IncludedServiceMainType.Seat:
            result = "Seat"
            break;
        case IncludedServiceMainType.Transfer:
            result = "Transfer"
            break;
    }

    return result;
}

export default IncludedServiceIcon;