import { faShoppingCart } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RaisedExecptionCard } from "components/Shared/RaisedExceptionCard";
import React from "react";
import { SmartBookResponseModel } from "WebApiClient";
import BookResponseCard from "./BookResponseCard";




const SmartBookResponseCard: React.FC<{ Result: SmartBookResponseModel, index: number }> = ({ Result, index }) => {
    const { bookingResponse, raisedException, requestedGroupID, usedActions } = Result;
    // const [showEnhancedInformation, setShowEnhancedInformation] = useState(false);

    const labelCol = "col-xs-6 col-md-4 col-lg-2 mb-1";
    const valueCol = "col-auto mb-2";
    return (
        <div className="card">
            <div className="card-header card-header-primary"><FontAwesomeIcon icon={faShoppingCart} /> Booking #{(index + 1).toString()}
                {/* <span className="float-end">
                    <div className="switch switch-lg" onClick={() => { setShowEnhancedInformation(!showEnhancedInformation) }}>
                        <input
                            type="checkbox"
                            className="switch"
                            checked={showEnhancedInformation}
                            readOnly={true}
                        />
                        <label>Show enhanced information</label>
                    </div>
                </span> */}
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-info">
                            You are viewing the initial booking confirmation. The current status of the booking may differ.
                        </div>
                    </div>
                    {raisedException &&
                        <div className="col-12">
                            <RaisedExecptionCard data={raisedException} />
                        </div>
                    }
                    {bookingResponse ?
                        <BookResponseCard Result={bookingResponse} />
                        :
                        <div className="col-12 mb-2">

                            <div className="alert alert-info">
                                There are no booking details available.
                            </div>

                        </div>
                    }


                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-header card-header-primary">Enhanced booking information</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className={labelCol}><p className="font-weight-bold">Requested group ID</p></div>
                                            <div className={valueCol}>{requestedGroupID}</div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header card-header-primary">Used booking rule actions</div>
                                            <div className="card-body">
                                                <div className="row">
                                                    {usedActions.map((action, actionIndex) =>
                                                        <div className="col-12 mb-2" key={actionIndex}>
                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <div className="row">
                                                                        <div className={labelCol}><p className="font-weight-bold">Requested group ID</p></div>
                                                                        <div className={valueCol}>{action.actionID}</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}



                </div>
            </div>
        </div>

    );
}

export default SmartBookResponseCard;


