
function ErrorMessage() {
    return (
        <div className="loadingStyle">
            <div className="loader-container">
                <div className=""></div>
                <p style={{color:"red"}}>An Error occurred</p>
            </div>

        </div>
    )
}

export default ErrorMessage;