import { AllotmentInfoTable } from "components/Fare/Fare.AllotmentInfo";
import { DateDisplay, DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay, SurchargeDisplay } from "components/Shared/PriceDisplay";
import React, { Fragment, useState } from "react";
import { BookResponseDataModel, BookResponseLegModel, BookResponsePassengerModel, BookResponseSegmentModel } from "WebApiClient";

const BookResponseCard: React.FC<{ Result: BookResponseDataModel }> = ({ Result, }) => {
  const { source, additionalInformation, bookingItemID, bookingOnHold, createdPayment, creditCardApprovalCode, customerLastName, customerReferenceNumber, equivalentTax,
    equivalentTotalPrice, isBookingOnHold, legs, moduleInfo, notesAndInformation, onlineCheckinLink, passengers, paymentTokens, queue, recordCounter, recordLocator, responseInfo, session, shoppingCartID, specialDiscounts, surcharge, ticketOnDepartureInfo, usedCRSProfile, usedEmailAddress, warnings, webFareConfirmationPage } = Result;
  const [showEnhancedInformation, setShowEnhancedInformation] = useState(false);
  const labelCol = "col-xs-6 col-md-4 col-lg-2 mb-1";
  const valueCol = "col-auto mb-2";

  return (
    <Fragment>
      <div className="col-12 mb-2">
        <div className="card">
          <div className="card-header card-header-primary">
            General
          </div>
          <div className="card-body card-body-secondary">

            <div className="row">
              <div className="col-12 mb-2">
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Filekey</td>
                      <td>{recordLocator}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Main passenger</td>
                      <td>{customerLastName}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Total price</td>
                      <td><SharedPriceDisplay price={equivalentTotalPrice} /></td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Total tax</td>
                      <td><SharedPriceDisplay price={equivalentTax} /></td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Ticket on departure</td>
                      <td>{ticketOnDepartureInfo}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Booking on hold</td>
                      <td>{isBookingOnHold !== undefined ? isBookingOnHold ? "Yes" : "No" : ""}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">CheckIn link</td>
                      <td>{onlineCheckinLink}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Warnings</td>
                      <td>{warnings}</td>
                    </tr>



                  </tbody>
                </table>
              </div>
              <div className="col-12 mb-2">
                <p className="font-weight-bold">Notes and information {notesAndInformation.length === 0 ? <span className="font-italic"> - no info</span> : ""}</p>
                {notesAndInformation.map((e, index) =>
                  <table key={"note" + index} className="table table-sm">
                    <tbody>
                      <tr key={"notesAndInformation" + index}>
                        <td>{e}</td>
                      </tr>
                    </tbody>
                  </table>

                )}
              </div>
              <div className="col-12 mb-2">
                <p className="font-weight-bold">Booking on hold details {bookingOnHold !== undefined ? <span className="font-italic"> - no info</span> : ""}</p>
                {bookingOnHold &&
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Surcharge</td>
                        <td><SharedPriceDisplay price={bookingOnHold.equivSurcharge} /> {bookingOnHold.surchargeDeducted !== undefined ? bookingOnHold.surchargeDeducted ? " - Deducted" : " - Not deducted" : ""}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Time limit</td>
                        <td>{bookingOnHold.onHoldTimeLimit}</td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>

            </div>
          </div>
        </div>
      </div>
      {webFareConfirmationPage &&
        <div className="col-12 mb-2">
          <div className="card">
            <div className="card-header card-header-primary">
              Confirmation page
            </div>
            <div className="card-body card-body-secondary">
              {webFareConfirmationPage}
            </div>
          </div>
        </div>
      }
      <div className="col-12 mb-2">
        <BookResponsePassengers passengers={passengers} />
      </div>
      <div className="col-12 mb-2">
        <BookResponseFlight legs={legs} />
      </div>
      <div className="col-12 mb-2">
        <div className="card">
          <div className="card-header card-header-primary">
            Additional Information
          </div>
          <div className="card-body card-body-secondary">

            <div className="row">
              <div className="col-12 mb-2">
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Source</td>
                      <td>{source}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Booking ID</td>
                      <td>{bookingItemID}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Cart ID</td>
                      <td>{shoppingCartID}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Customer reference number</td>
                      <td>{customerReferenceNumber}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Queue</td>
                      <td>{queue}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Used email adress</td>
                      <td>{usedEmailAddress}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Record counter</td>
                      <td>{recordCounter}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Session</td>
                      <td>{session}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12 mb-2">
                Used CRS profile
                {usedCRSProfile &&
                  <table className="table table-sm">

                    <tbody>
                      <tr>
                        <td className="font-weight-bold">GDS</td>
                        <td>{usedCRSProfile.gds}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">GUID</td>
                        <td>{usedCRSProfile.guid}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Received from</td>
                        <td>{usedCRSProfile.receivedFrom}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Country</td>
                        <td>{usedCRSProfile.terminalCountry}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">PCC</td>
                        <td>{usedCRSProfile.terminalPCC}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">User</td>
                        <td>{usedCRSProfile.user}</td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
              <div className="col-12 mb-2">
                Further additional information
                <table className="table table-sm">
                  <tbody>
                    {additionalInformation.map((e, index) =>
                      <tr key={"additionalInformation1" + index}>
                        <td>{e}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

            </div>
          </div>

        </div>
      </div>

    </Fragment>
    // <div className="card">
    //   <div className="card-header card-header-primary">Booking information</div>
    //   <div className="card-body card-body-primary">
    //     <div className="row">
    //     </div>
    //   </div>
    // </div>
  );
};

const BookResponsePassenger: React.FC<{ passenger: BookResponsePassengerModel }> = ({ passenger }) => {
  const {
    apisErrorCode, apisErrorText, dateOfBirth, detailedTaxes, endorsement,
    equivalentPassengerFare, equivalentTotalTax, firstName, lastName,
    passengerType, paymentMethods, qSurcharge, seatReservations,
    ticketSegments, tourCode, type } = passenger;


  return (
    <div className="card">
      <div className="card-header card-header-primary">{firstName} {lastName}</div>
      <div className="card-body card-body-secondary">

        <div className="row">
          <div className="col-12 mb-2">
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td className="font-weight-bold">First name</td>
                  <td>{firstName}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Last name</td>
                  <td>{lastName}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Date of birth</td>
                  <td><DateDisplay date={dateOfBirth} /></td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Passenger type</td>
                  <td>{passengerType}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Type</td>
                  <td>{type}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Endorsement</td>
                  <td>{endorsement}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Tourcode</td>
                  <td>{tourCode}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Passenger fare</td>
                  <td><SharedPriceDisplay price={equivalentPassengerFare} /></td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Total Tax</td>
                  <td><SharedPriceDisplay price={equivalentTotalTax} /></td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Q-Surcharge</td>
                  <td><SharedPriceDisplay price={qSurcharge} /></td>
                </tr>
                <tr>
                  <td className="font-weight-bold">APIS errors</td>
                  <td>{apisErrorCode} {apisErrorText}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 mb-2">
            <p className="font-weight-bold">Detailed Taxes {detailedTaxes.length === 0 ? <span className="font-italic"> - no info</span> : ""}</p>
            {detailedTaxes.length > 0 &&
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Price</th>
                    <th>Action</th>
                    <th>Action specified</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {detailedTaxes.map((tax, index) => <tr key={"detailedTaxes" + index}>
                    <td><SharedPriceDisplay price={tax.price} /></td>
                    <td>{tax.action}</td>
                    <td>{tax.actionSpecified ? "Yes" : "No"}</td>
                    <td>{tax.type}</td>
                  </tr>)}
                </tbody>
              </table>
            }
          </div>
          <div className="col-12 mb-2">
            <p className="font-weight-bold">Payment methods {paymentMethods.length == 0 ? <span className="font-italic"> - no info</span> : ""}</p>
            {paymentMethods.length > 0 &&
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Booking fee</th>
                    <th>CVC code</th>
                    <th>CVC title</th>
                    <th>Legs</th>
                    <th>Passenger Type</th>
                    <th>Password supported</th>
                    <th>Payment fee</th>
                    <th>Payment type</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentMethods.map((method, index) => <tr key={"paymentMethods" + index}>
                    <td><SurchargeDisplay price={method.bookingFee} /></td>

                    <td>{method.creditCardCompanyCode}</td>
                    <td>{method.creditCardCompanyTitle}</td>
                    <td>{method.legSelection.join(", ")}</td>
                    <td>{method.passengerType}</td>
                    <td>{method.passwordSupported !== undefined && <span>{method.passwordSupported ? "Yes" : "No"}</span>}</td>
                    <td><SurchargeDisplay price={method.paymentFee} /></td>
                    <td>{method.paymentType}</td>



                  </tr>)}
                </tbody>
              </table>
            }
          </div>
          <div className="col-12 mb-2">
            <p className="font-weight-bold">Seat reservations {seatReservations.length == 0 ? <span className="font-italic"> - no info</span> : ""}</p>
            {seatReservations.length > 0 &&
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Segment</th>
                    <th>Column</th>
                    <th>Row</th>
                  </tr>
                </thead>
                <tbody>
                  {seatReservations.map((seat, index) => <tr key={"seatReservations" + index}>
                    <td>{seat.segmentIndex}</td>
                    <td>{seat.seatColumn}</td>
                    <td>{seat.seatRow}</td>

                  </tr>)}
                </tbody>
              </table>
            }
          </div>
          <div className="col-12 mb-2">
            <p className="font-weight-bold">Ticket segments {ticketSegments.length == 0 ? <span className="font-italic"> - no info</span> : ""}</p>
            {ticketSegments.length > 0 &&
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Farebase</th>
                    <th>Free baggage</th>
                    <th>Segments</th>
                    <th>Ticket designator</th>
                    <th>Ticket number</th>
                    <th>Ticket by supplier</th>
                  </tr>
                </thead>
                <tbody>
                  {ticketSegments.map((ticketsegment, index) => <tr key={"ticketSegments" + index}>
                    <td>{ticketsegment.farebase}</td>
                    <td>{ticketsegment.freeBaggageAllowance}</td>
                    <td>{ticketsegment.segmentNumbers.join(", ")}</td>
                    <td>{ticketsegment.ticketDesignator}</td>
                    <td>{ticketsegment.ticketNumber}</td>
                    <td>{ticketsegment.ticketbySupplier !== undefined ? <span>{ticketsegment.ticketbySupplier ? "Yes" : "No"}</span> : <span></span>}</td>
                  </tr>)}
                </tbody>
              </table>
            }
          </div>

        </div>
      </div>
    </div>
  );
};

const BookResponsePassengers: React.FC<{ passengers: BookResponsePassengerModel[] }> = ({ passengers }) => {
  return (
    <div className="card">
      <div className="card-header card-header-primary">Passengers</div>
      <div className="card-body">
        <div className="row">
          {passengers.map((passenger, index) => (
            <div className="col-12 mb-2" key={"passengers" + index}>
              <BookResponsePassenger passenger={passenger} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const BookResponseFlight: React.FC<{ legs: BookResponseLegModel[] }> = ({ legs }) => {
  return (
    <div className="card">
      <div className="card-header card-header-primary">Flight</div>
      <div className="card-body">
        <div className="row">
          {legs.map((leg, index) => (
            <div className="col-12 mb-2" key={"legs" + index}>
              <BookResponseFlightLeg leg={leg} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const BookResponseFlightLeg: React.FC<{ leg: BookResponseLegModel }> = ({ leg, }) => {
  const { seatPreferences, segments, tariffMatchCode } = leg;
  const firstDeparture = segments[0];
  const lastArrival = segments[segments.length - 1];
  return (
    <div className="card">

      <div className="card-body card-body-secondary">
        <h4 className="card-title">
          {firstDeparture.departure.name} ({firstDeparture.departure.iata}) -{" "}
          {lastArrival.arrival.name} ({lastArrival.arrival.iata})
        </h4>
        <div className="row">
          <div className="col-12 mb-2">
            <p> Seat preferences: {seatPreferences !== undefined ? seatPreferences ? "Yes" : "no" : ""} <br></br>
              Tarif match code: {tariffMatchCode}
            </p>
          </div>
          {segments.map((segment, index) => (
            <div className="col-12 mb-2" key={"segments" + index}>
              <BookResponseFlightSegment segment={segment} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const BookResponseFlightSegment: React.FC<{
  segment: BookResponseSegmentModel;
}> = ({ segment }) => {
  const { allotmentConfig, arrival, carrier, arrivalDate, bookingClass, brandCode, brandName, departure, departureDate, flightNumber, includedServiceIDs, segmentNumber, segmentStatus,
  } = segment;
  return (
    <div className="card">
      <div className="card-header">
        {departure.name} ({departure.iata}) - {arrival.name} ({arrival.iata})
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="card">
              <div className="card-body">
                {/* <h5 className="card-title">Segment information</h5> */}
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Departure</td>
                      <td>{departure.name} ({departure.iata})</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Departure date</td>
                      <td><DateTimeDisplay date={departureDate} /></td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Arrival</td>
                      <td>{arrival.name} ({arrival.iata})</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Arrival date</td>
                      <td><DateTimeDisplay date={arrivalDate} /></td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Carrier</td>
                      <td>{carrier.hint} ({carrier.code})</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Booking class</td>
                      <td>{bookingClass}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Brand code</td>
                      <td>{brandCode}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Brand name</td>
                      <td>{brandName}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Flight number</td>
                      <td>{flightNumber}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Included services</td>
                      <td>{includedServiceIDs.join(", ")}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Status</td>
                      <td>{segmentStatus}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Number</td>
                      <td>{segmentNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {allotmentConfig &&
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Allotment</h5>
              <AllotmentInfoTable info={allotmentConfig} small={true} segment={segment} />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default BookResponseCard;
