import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DefaultCabinClassState,
  ICabinClassState,
  IFareSearchInputState,
  ISegmentState,
} from "components/FareSearch/types/FareSearchTypes";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { CardHeader, Col } from "reactstrap";
import CabinClassInput from "../Passengers/Passengers.CabinClasses";
import Segment from "./FlightDetails.Segment";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";

interface FlightDetailsProps {
  IsModifySearch: boolean;
  Input: IFareSearchInputState;
  UpdateState: (state: IFareSearchInputState) => void;
  OnSubmit: () => void;
  isValidSearch: boolean;
}

const FlightDetails: React.FC<FlightDetailsProps> = (props) => {
  const maxSegments = 9;
  const currSegments = props.Input.Segments.length;
  const BodyClass: string = props.IsModifySearch
    ? ""
    : "card-body card-body-primary";
  const ContainerClass: string = props.IsModifySearch ? "" : "card";
  const cabinclasses = props.Input.CabinClasses;
  const inputRef = React.useRef<HTMLInputElement | any>();
  const [cabinContainer, setShowCabinContainer] = useState<boolean>(false);
  const searchRef = React.useRef<HTMLInputElement | any>();
  //cabin_show_hide
  const isAdmin = useSelector((state: State) => state.Session.isAdmin);

  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  let otherSetting = otherSettings.split("\n");
  let showCabin = otherSetting[8] == undefined ? "showCabinContainer=All" : otherSetting[8];


  useEffect(() => {
    if (showCabin == "showCabinContainer=All") {
      setShowCabinContainer(true);
    } else if (showCabin == "showCabinContainer=Admin" && isAdmin) {
      setShowCabinContainer(true);
    } else if (showCabin == "showCabinContainer=None") {
      setShowCabinContainer(false);
    }
  }, [showCabin]);

  //Effect to focus the departure of new segment when a new segment is added.
  useEffect(() => {
    if (currSegments > 1) {
      document.getElementById(`segment${currSegments - 1}_airport1`)?.focus();
    }
  }, [currSegments]);

  function UpdateCabinClasses(cabinClasses: ICabinClassState) {
    const newState: IFareSearchInputState = {
      ...props.Input,
      CabinClasses: cabinClasses,
    };
    props.UpdateState(newState);
  }

  function OnAddSegment(): void {
    const newSegments: ISegmentState[] = AddSegment(props.Input.Segments);
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    props.UpdateState(newState);
  }

  function OnAddReturnSegment(index: number): void {
    const newSegments: ISegmentState[] = AddReturnSegment(
      props.Input.Segments,
      index
    );
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    props.UpdateState(newState);
  }

  function OnToggleDirectFlights(): void {
    let newState: IFareSearchInputState = {
      ...props.Input,
      DirectFlightsOnly: !props.Input.DirectFlightsOnly,
    };
    props.UpdateState(newState);
  }
  function OnToggleCabinClassPerLeg(): void {
    let newState: IFareSearchInputState = {
      ...props.Input,
      CabinClassPerLeg: !props.Input.CabinClassPerLeg,
    };
    props.UpdateState(newState);
  }

  function OnToggleAvailFares() {
    let newState: IFareSearchInputState = {
      ...props.Input,
      AvailableFares: !props.Input.AvailableFares,
    };
    props.UpdateState(newState);
  }

  function OnRemoveSegment(index: number): void {
    let newSegments = [...props.Input.Segments];
    newSegments.splice(index, 1);
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    props.UpdateState(newState);
  }

  function OnUpdateSegment(index: number, segment: ISegmentState) {
    let newSegments = [...props.Input.Segments];
    newSegments[index] = segment;
    let newState: IFareSearchInputState = {
      ...props.Input,
      Segments: newSegments,
    };
    props.UpdateState(newState);
  }

  const returnTabId = (segId: number, inputTab: number) => {
    switch (segId) {
      case 1:
        if (inputTab == 1) return 4;
        if (inputTab == 2) return 9;
        if (inputTab == 3) return 10;
        break;
      case 2:
        if (inputTab == 1) return 7;
        if (inputTab == 2) return 12;
        if (inputTab == 3) return 13;
        break;
      case 3:
        if (inputTab == 1) return 10;
        if (inputTab == 1) return 15;
        if (inputTab == 1) return 16;
        break;
      default:
        return;
    }
  };

  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode == 13) {
      props.OnSubmit();
    }
  }
  const bookingRedirect = useSelector((state: any) => state.bookingRedirectSession.value);
  // console.log("bookingRedirect-------------", bookingRedirect);

  return (
    <React.Fragment>
      <Col>
        <div className={`${ContainerClass} flightSearchCardPosition`}>
          {!props.IsModifySearch && (
            <CardHeader className="card-header-primary">
              Flight details
            </CardHeader>
          )}
          <div className={`${BodyClass}`}>
            <div className="row">
              {props.Input.Segments.map((segment: ISegmentState, index) => (
                <div className="col-12" key={`search_seg_${index}`}>
                  <Segment
                    Input={props.Input}
                    SegmentIndex={index}
                    Segment={segment}
                    NextSegment={props.Input.Segments[index + 1]}
                    PreviousSegment={props.Input.Segments[index - 1]}
                    RemoveSegment={OnRemoveSegment}
                    AddReturnSegment={OnAddReturnSegment}
                    UpdateSegment={OnUpdateSegment}
                    UpdateState={props.UpdateState}
                    OnSubmit={props.OnSubmit}
                    isValidSearch={props.isValidSearch}
                    cabinContainer={cabinContainer}
                  />
                </div>
              ))}
              <div className="cabinOffContainer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{ width: cabinContainer ? "100%" : "81%" }}
                  className="col-12"
                  onKeyDown={OnKeyDown}
                >
                  {
                    bookingRedirect?.checkActiveBtnClicked == "Rebook" ?
                      null : <div className="d-grid">
                        <button
                          id="addFlight"
                          disabled={currSegments >= maxSegments}
                          onClick={() => OnAddSegment()}
                          className="btn btn-primary mt-2"
                          type="button"
                          ref={inputRef}
                          tabIndex={returnTabId(props.Input.Segments.length, 1)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          {""} <span className="d-inline-block">Add flight</span>
                        </button>
                      </div>
                  }

                </div>
                {
                  !cabinContainer && <div
                    className="col-auto btn-group btn-group-vertical cabinBtnOffDiv"
                    onKeyDown={OnKeyDown}
                  >
                    <button
                      id="searchBtn"
                      className="btn btn-lg btn-primary text-white cabinBtnOn"
                      style={{ height: '100%' }}
                      ref={searchRef}
                      onClick={props.OnSubmit}
                      disabled={props.isValidSearch ? false : true}
                    >
                      <FontAwesomeIcon
                        beat={props.isValidSearch ? true : false}
                        icon={faSearch}
                      />{" "}
                      Search
                    </button>
                  </div>
                }
              </div>
              {cabinContainer && (
                <div className="col-12">
                  <div className="content-divider-dark" />
                </div>
              )}
              <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
                {cabinContainer && (
                  <div className="row">
                    {!props.Input.CabinClassPerLeg && (
                      <div className="col-auto">
                        <CabinClassInput
                          CabinClasses={cabinclasses}
                          UpdateCabinClasses={UpdateCabinClasses}
                          ClassName="form-row"
                          disabled={props.Input.CabinClassPerLeg}
                          segmentIndex={props.Input.Segments.length}
                        />
                      </div>
                    )}
                    <div className="col-auto">
                      {/* <div className="d-block d-lg-inline-block ml-0 ml-lg-4 mt-3 mt-lg-0"> */}
                      <div
                        className="switch switch-lg mt-1"
                        onClick={() => OnToggleCabinClassPerLeg()}>
                        <input type="checkbox"
                          className="switch"
                          checked={props.Input!.CabinClassPerLeg}
                          readOnly={true}
                          tabIndex={returnTabId(props.Input.Segments.length, 2)}
                        />
                        <label>Cabin class per flight</label>
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="col-auto">
                      <div className="">
                        <div
                          className="switch switch-lg mt-1"
                          onClick={() => OnToggleDirectFlights()}
                        >
                          <input
                            type="checkbox"
                            className="switch"
                            checked={props.Input!.DirectFlightsOnly}
                            readOnly={true}
                            tabIndex={returnTabId(
                              props.Input.Segments.length,
                              3
                            )}
                          />
                          <label>Direct flights only</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {
                  cabinContainer && <div
                    className="col-auto btn-group btn-group-vertical cabinBtnOnDiv"
                    onKeyDown={OnKeyDown}
                  >
                    <button
                      id="searchBtn"
                      className="btn btn-lg btn-primary text-white cabinBtnOn"
                      ref={searchRef}
                      onClick={props.OnSubmit}
                      style={{ height: '100%' }}
                      disabled={props.isValidSearch ? false : true}>
                      <FontAwesomeIcon
                        beat={props.isValidSearch ? true : false}
                        icon={faSearch}
                      />{" "}
                      Search
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default FlightDetails;

function AddSegment(segments: ISegmentState[]): ISegmentState[] {
  const latestSegment = segments[segments.length - 1];
  const newDate = moment(latestSegment.DateInput).add(2, "weeks").toDate();
  const newSegment: ISegmentState = {
    DepartureInput: latestSegment.ArrivalInput,
    ArrivalInput: latestSegment.DepartureInput,
    Radius: 0,
    DateInput: newDate,
    CabinClasses: DefaultCabinClassState,
  };
  let newSegments = [...segments];
  newSegments.push(newSegment);
  return newSegments;
}

function AddReturnSegment(
  segments: ISegmentState[],
  index: number
): ISegmentState[] {
  const segment: ISegmentState = segments[index];
  const returnDate = moment(segment.DateInput).add(2, "weeks").toDate();
  const returnSegment: ISegmentState = {
    DepartureInput: segment.ArrivalInput,
    ArrivalInput: segment.DepartureInput,
    Radius: 0,
    DateInput: returnDate,
    CabinClasses: DefaultCabinClassState,
  };
  let newSegments = [...segments];
  newSegments.push(returnSegment);
  return newSegments;
}
