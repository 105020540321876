import getSymbolFromCurrency from 'currency-symbol-map'

export function currencyFormatter(currency: any) {
    if (currency=="EUR" || currency=="GBP" || currency=="USD" || currency=="JPY" ) {
        const curr = getSymbolFromCurrency(currency);
        return curr;
    }else{
        return currency;
    }
} 


// EUR
// £ = GBP
// $ = USD
// ¥ = JPY
