import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIMetaDataDisplay } from 'components/Shared/APIMetaDataDisplay';
import { TimeDisplay } from 'components/Shared/DateDisplay';
import { useUserInformation } from 'hooks/various';
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Alert, Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { State } from 'rootExports/rootReducer';
import { BaseApiResponseMetaData } from 'WebApiClient';




export const TidModal: React.FC<{ open: boolean, toggle: () => void }> = props => {
    const transactions = useSelector((state: State) => state.Session.Transactions);
    const userInfo = useUserInformation();
    const { open, toggle } = props;

    return <Modal scrollable={true} size="xl" centered isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Error reporting</ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-12 mb-2">
                    <Alert color="info">Please select a request to report an error.</Alert>
                </div>
                {transactions.length === 0 &&
                    <div className="col-12">
                        <p>There are no request to display.</p>
                    </div>
                }
                {transactions.map((e, index) => <div key={index} className="col-12 mb-2">
                    <TidModalCard key={"trnsaction" + index} data={e} />
                </div>)}
            </div>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggle} color="primary">close</Button>
        </ModalFooter>
    </Modal>
}
const TidModalCard: React.FC<{ data: BaseApiResponseMetaData }> = props => {
    const [open, setOpen] = useState(false);
    const { data } = props;

    return <div className="card">
        <div className="card-header card-header-primary clickme" onClick={() => { setOpen(!open) }}>
            <FontAwesomeIcon icon={data.errorOccured ? faExclamationTriangle : faCheckCircle} color={data.errorOccured ? "red" : "green"} /> {data.requestTitle} @ <TimeDisplay date={data.responseSentTime} />
        </div>
        <Collapse isOpen={open}>
            <div className="card-body">
                <APIMetaDataDisplay data={props.data} />
            </div>

            <div className="card-footer">
                <div className="d-grid">

                    <button className="btn btn-primary" onClick={() => { setOpen(false) }}>close</button>
                </div>
            </div>
        </Collapse>
    </div>

}

