import { faAngleDoubleDown, faAngleDoubleUp, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { BookingAvailableServiceModel, BookingAvailableServiceReferenceIDModel, BookingItemServicePassengerModel, PnrResponseSegmentModel, ServicePriceModel } from "WebApiClient";

export interface SelectedServicesOverviewProps {
    AvailableServices: BookingAvailableServiceModel[];
    AvailableServicesPrices: ServicePriceModel[];
    Passengers: BookingItemServicePassengerModel[],
    Segments: PnrResponseSegmentModel[],
    SelectedServices: string[];
    OnRemoveSelectionRange: (selectionRange: string[]) => void;
    OnAddSelectionRange: (selectionRange: string[]) => void;
    OnToggleSelection: (selection: string) => void;
    OnConfirm: () => void;


}

export const SelectedServicesOverview: React.FC<SelectedServicesOverviewProps> = ({ OnConfirm, AvailableServices, AvailableServicesPrices, OnAddSelectionRange, OnRemoveSelectionRange, OnToggleSelection, Passengers, Segments, SelectedServices }) => {
    const [open, setOpen] = useState(true);
    function Toggle() {
        setOpen(!open);
    }
    return <div className="card">
        <div className="card-header clickme card-header-primary" onClick={Toggle}>
            <h6 className="w-100 text-center">

                <span>Check and confirm selection <span className="float-end"><FontAwesomeIcon icon={open ? faAngleDoubleUp : faAngleDoubleDown} /></span></span>
            </h6>

        </div>
        <Collapse isOpen={open}>
            <div className="card-body">
                <div className="row">
                    {SelectedServices.length > 0 &&
                        <div className="col-12 mb-2">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Last name</th>
                                        <th>First name</th>
                                        <th>Service code</th>
                                        <th>Service description</th>
                                        <th>Segments</th>
                                        <th>Price</th>
                                        <th>Refundable</th>
                                        <th>Carrier</th>
                                        <th>EMD</th>
                                        <th>Last EMD date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SelectedServices.map((service, index) =>
                                        <SelectedServiceRow key={"selctSer_"+index} AvailableServices={AvailableServices} Passengers={Passengers} Segments={Segments} AvailableServicesPrices={AvailableServicesPrices} ID={service} OnToggleSelection={OnToggleSelection} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                    {!(SelectedServices.length > 0) &&
                        <div className="col-12 mb-2">
                            No services selected. Please select services below.
                        </div>
                    }
                    <div className="col-12">
                        <div className="d-grid">

                            <button className="btn btn-primary" onClick={OnConfirm} disabled={SelectedServices.length === 0} ><FontAwesomeIcon icon={faCheckCircle} /> Confirm and add selected services</button>
                        </div>
                    </div>
                </div>
            </div>

        </Collapse>
    </div>
}


export interface SelectedServiceRowProps {
    AvailableServices: BookingAvailableServiceModel[];
    AvailableServicesPrices: ServicePriceModel[];
    Passengers: BookingItemServicePassengerModel[],
    Segments: PnrResponseSegmentModel[],
    ID: string;
    OnToggleSelection: (selection: string) => void;


}

export const SelectedServiceRow: React.FC<SelectedServiceRowProps> = ({ AvailableServices, AvailableServicesPrices, OnToggleSelection, Passengers, Segments, ID }) => {

    const service: BookingAvailableServiceModel = AvailableServices.filter((e) => e.referenceIDs.filter(y => y.referenceID === ID).length === 1)[0];
    const reference: BookingAvailableServiceReferenceIDModel = service.referenceIDs.filter(e => e.referenceID === ID)[0];
    const passenger: BookingItemServicePassengerModel = Passengers.filter(e => e.id === reference.passengerID)[0];
    const price: ServicePriceModel = AvailableServicesPrices.filter(e => e.priceID === reference.priceID)[0];
    function GetSegmentOverview(): JSX.Element {
        let result: string[] = [];
        reference.segmentIDs.forEach((number) => {
            const segmentIndex = Segments.findIndex(e => e.segmentNumber === number);
            if (segmentIndex !== -1) {
                const segment = Segments[segmentIndex];
                result.push(`${segment.departure.iata} - ${segment.arrival.iata}`)
            }

        })
        return <span>
            {result.map((s, i) =>
                <span key={"selectedService_"+i}>
                    <span>{s}</span> <br />

                </span>
            )}
        </span>
    }


    return <tr>
        <td>{passenger.lastname}</td>
        <td>{passenger.firstname}</td>
        <td>{service.bookingCode}</td>
        <td>{service.text}</td>
        <td>{GetSegmentOverview()}</td>
        <td><SharedPriceDisplay price={price.equivalentPrice} /></td>
        <td>{service.refundable !== undefined ? service.refundable ? "Yes" : "No" : ""}</td>
        <td>{service.serviceCarrier}</td>
        <td>{service.emdRequired !== undefined ? service.emdRequired ? "Yes" : "No" : ""}</td>
        <td><DateTimeDisplay date={service.lastEMDDate} /></td>
    </tr>
}