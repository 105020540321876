import { AvailableFareModel, FarebookingFormOfIdentifikationModel } from "WebApiClient";

export interface BookPassengerValidatioFoidTabResult {
    Valid: boolean;
    DocumentType: boolean;
    DocumentID: boolean;

}

export function PassengerFoidValid(data: FarebookingFormOfIdentifikationModel | undefined, fare: AvailableFareModel): BookPassengerValidatioFoidTabResult {
    // const apisRequirenments = fare.apisRequirenments!;
    let foidTabValid = true;
    let documentIDValid = true;
    let documentTypeValid = true;
    const isSet = data !== undefined;

    if (data) {
        const documentIDRequired = true;
        documentIDValid = documentIDRequired ? (data.documentID.length > 0) : true;
        if (!documentIDValid) {
            foidTabValid = false;
        }

    }


    const foid: BookPassengerValidatioFoidTabResult = {
        Valid: foidTabValid,
        DocumentType: documentTypeValid,
        DocumentID: documentIDValid

    }

    return foid;
}