import React, { useState } from "react";
import { Alert } from "reactstrap";
import {
  FareBookingOnHoldEnum,
  FareBookingTicketGroupOptions,
} from "WebApiClient";
import { FareTicketGroupOptions } from "./../../../WebApiClient";

export interface FareBookingOptionsProps {
  AvailableTicketGroupOptions: FareTicketGroupOptions[];
  SelectedticketGroupOptions: FareBookingTicketGroupOptions[];
  OnBookingOnHoldSelectionChange: (index: number, value: number) => void;
  Icon: JSX.Element;
}

const FareBookingOptions: React.FC<FareBookingOptionsProps> = (props) => {
  function Show(): boolean {
    let result = false;
    props.AvailableTicketGroupOptions.forEach((t) => {
      if (t.bookOnHoldOptions!.bookOnHoldPossible) {
        result = true;
      }
    });
    return result;
  }

  function ShowBookOnHoldOption(
    fareticketgroup: FareTicketGroupOptions
  ): boolean {
    let result = false;
    if (fareticketgroup.bookOnHoldOptions!.bookOnHoldPossible) {
      result = true;
    }
    return result;
  }

  function OnBookingOnHoldchange(index: number, value: string) {
    const enumValue = parseInt(value);
    props.OnBookingOnHoldSelectionChange(index, enumValue);
  }

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="card">
      <div className="card-header card-header-primary" onClick={toggleCollapse}>
            {props.Icon} BOOKING OPTIONS
      </div>
      <div className={`collapse ${isCollapsed ? "" : "show"}`} id="collapseExample3">
        <div className="card-body card-body-primary">
          {Show() ? (
            <React.Fragment>
              {props.AvailableTicketGroupOptions.map((ticketgroup, tktGroupOptionIndex) => (
                <React.Fragment key={`tktgrpOption_${tktGroupOptionIndex}`}>
                  <div className="card">
                    <div className="card-header card-header-primary px-3 py-2 d-flex justify-content-between bg-secondary">
                      Ticketgroup {tktGroupOptionIndex + 1}
                    </div>
                    <div className="card-body">
                      {ShowBookOnHoldOption(ticketgroup) && (
                        <div className="row align-items-center mb-2">
                          <label className="col-12 col-md-4 col-lg-3 col-xl-2">Booking on hold</label>
                          <div className="col-12 col-md-6 col-lg-4">
                            <select
                              value={props.SelectedticketGroupOptions[tktGroupOptionIndex].bookingOnHoldSelection}
                              onChange={(e) => OnBookingOnHoldchange(tktGroupOptionIndex, e.target.value)}
                              className="form-select"
                            >
                              <option value={FareBookingOnHoldEnum.NotApplicable}>Book and pay</option>
                              {ticketgroup.bookOnHoldOptions!.canAutoCancel && (
                                <option value={FareBookingOnHoldEnum.Autocancel}>Hold PNR with auto cancel</option>
                              )}
                              {ticketgroup.bookOnHoldOptions!.canAutoConfirm && (
                                <option value={FareBookingOnHoldEnum.AutoConfirm}>Hold PNR with auto confirm</option>
                              )}
                              {ticketgroup.bookOnHoldOptions!.canOptionBooking && (
                                <option value={FareBookingOnHoldEnum.OptionBooking}>Option booking</option>
                              )}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : (
            <div className="row">
              <div className="col-12">
                <Alert color="info">There are no booking options available.</Alert>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FareBookingOptions;
