import { faAngleDoubleDown, faAngleDoubleUp, faInfo } from "@fortawesome/pro-light-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import React from "react";
import { Button, Collapse } from "reactstrap";
import { FareBookingInputPassengerModel } from "WebApiClient";
import { BookPassengerValidatioOtherTabResult } from "./FareBooking.Validate.Passenger.Other";

export interface IBookingPassengerGeneralTabProps {
    Passenger: FareBookingInputPassengerModel;
    OnUpdatePassenger: (data: FareBookingInputPassengerModel) => void;
    Index: number;
    Valid: BookPassengerValidatioOtherTabResult;
    IsOpen: boolean;
    Toggle: () => void;
    Next: () => void;
}

const BookingPassengerOtherTab: React.FC<IBookingPassengerGeneralTabProps> = props => {
    const passenger = props.Passenger;
    const IsValid = props.Valid;
    function ToggleAssistanceRequired() {
        let data = { ...passenger };
        data.assistanceRequired = !data.assistanceRequired;
        props.OnUpdatePassenger(data);
    }

    // function OnPasswordUpdate(value: string) {
    //     let data = { ...passenger };
    //     data.password = value;
    //     props.OnUpdatePassenger(props.Index, data);
    // }

    // function GetPasswordClassName(): string {
    //     let result = "";
    //     if (props.Valid.PasswordRequired) {
    //         result = props.Valid.Password ? "is-valid" : "is-invalid";
    //     }
    //     return result;
    // }
    
    return (
        <div className="row mt-3">
            <div className="col">
                {/* <button onClick={() => setOtherCollapseOpen(!otherCollapseOpen)} className="btn btn-primary mt-3">Other</button> */}
                <Button color="secondary" block onClick={props.Toggle} className="text-black" >
                    <h6>
                        <span className="float-start">
                            <ValidIndicatorIcon
                                IsValid={IsValid.Valid}
                                Icon={faInfo}
                                NoValueSet={false}
                            />
                            {' Other'}
                        </span>
                        <span className="float-end"><FontAwesomeIcon icon={props.IsOpen ? faAngleDoubleUp : faAngleDoubleDown} /></span>
                    </h6>
                </Button>
                <Collapse isOpen={props.IsOpen}>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-check" onClick={() => ToggleAssistanceRequired()}>
                                <input type="checkbox" checked={passenger.assistanceRequired} onChange={() => { }} className="form-check-input" />
                                <label className="form-check-label">Assistance required</label>
                            </div>
                            {/* <div className="form-group form-row align-items-center mt-4">
                                        <label className="col-12 col-md-4 col-lg-3 col-xl-2">Password{props.Valid.PasswordRequired?"*":""}</label>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <input
                                                type="text"
                                                value={passenger.password ? passenger.password : ""}
                                                onChange={(e) => OnPasswordUpdate(e.target.value)}
                                                className={`form-control form-control-sm  ${GetPasswordClassName()}`}
                                            />
                                        </div>
                                    </div> */}
                            <div className="row">
                                <div className="col">
                                    <Button color="secondary text-dark" onClick={props.Next}>
                                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                                        <span> Next</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>

    );
}

export default BookingPassengerOtherTab;

