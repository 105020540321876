import React, { useState, ChangeEvent, FormEvent } from "react";

interface FormData {
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  numberOfGuests: string;
}

interface customerInfo {
  setCustomerInfo: any;
  customerInfo: FormData;
}

const CustomerInfo: React.FC<customerInfo> = (props) => { 
//   const [formData, setFormData] = useState<FormData>({
//     firstName: "",
//     middleName: "",
//     lastName: "",
//     title: "",
//     gender: "Male",
//     dateOfBirth: "",
//     email: "",
//     phoneNumber: "",
//     numberOfGuests: "",
//   });
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    props.setCustomerInfo((prevFormData:any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // Here, you can perform further actions, like submitting the form to the server or handling the data.
    console.log(props.customerInfo);
  };

  return (
    <div className="card">
      <div className="card-body card-body-primary">
        <div className="container mt-4">
          <h3>Customer Information</h3>
          <form onSubmit={handleSubmit} className="customerInfos">
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="gender" className="form-label">
                  Gender
                </label>
                <select
                  className="form-select"
                  id="gender"
                  name="gender"
                  value={props.customerInfo.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <select
                  className="form-select"
                  id="title"
                  name="title"
                  value={props.customerInfo.title}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Title</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Dr.">Dr.</option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={props.customerInfo.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="middleName" className="form-label">
                  Middle Name(s)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="middleName"
                  name="middleName"
                  value={props.customerInfo.middleName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={props.customerInfo.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="dateOfBirth" className="form-label">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={props.customerInfo.dateOfBirth}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={props.customerInfo.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={props.customerInfo.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              {/* <div className="col-md-4">
                                <label htmlFor="checkInDate" className="form-label">
                                    Check-In Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="checkInDate"
                                    name="checkInDate"
                                    value={props.customerInfo.checkInDate}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="checkOutDate" className="form-label">
                                    Check-Out Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="checkOutDate"
                                    name="checkOutDate"
                                    value={props.customerInfo.checkOutDate}
                                    onChange={handleChange}
                                    required
                                />
                            </div> */}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="agreeCheckbox"
                required
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="agreeCheckbox">
                I agree to the terms and conditions
              </label>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary mt-3 px-5"
                disabled={!isChecked}
              >
                Book Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
