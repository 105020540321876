import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { Session_Reducer_isAdmin, Session_Reducer_SetFrontendSettings, Session_Reducer_SetToken } from 'rootExports/SessionReducer';
import { AuthorizedServicesClient, LoginClient } from "WebApiClient";
import { useAuthorizedServicesClient, useLoginClient } from "./useHttpClient";
import { UserInformation, useUserInformation } from "./various";

export class SessionHandler {

    private _authServicesclient: AuthorizedServicesClient;
    private _loginClient: LoginClient;
    private _dispatch: Dispatch<any>;
    // private _history: NavigateFunction
    private _userInfo: UserInformation;

    constructor(authServicesclient: AuthorizedServicesClient, loginClient: LoginClient, dispatch: Dispatch<any>, history: NavigateFunction, user: UserInformation) {
        this._authServicesclient = authServicesclient;
        this._dispatch = dispatch;
        // this._history = history;
        this._loginClient = loginClient;
        this._userInfo = user;
    }
    /**
     * RefreshSession
     */
    public RefreshSession() {
        this._authServicesclient.refreshToken().then(e => {
            if (e.success) {
                console.log("Session refreshed.")
                if (e.token) {
                    this._dispatch(Session_Reducer_isAdmin(e.isAdmin));
                    this._dispatch(Session_Reducer_SetToken(e.token));
                }
                if (e.agentFrontendSettings) {

                }
            }
            else {

            }
        }).catch(() => {
            console.error("Session could not be refreshed!.")
        }).finally(() => {

        });
    }
    public Logout() {
    }
}

export function useSessionHandler(): SessionHandler {
    const dispatch = useDispatch();
    const userInfo = useUserInformation();
    const authServicesClient = useAuthorizedServicesClient();
    const loginClient = useLoginClient();
    const navigate = useNavigate();
    return new SessionHandler(authServicesClient, loginClient, dispatch, navigate, userInfo)
}
// function RefreshSession(): void {
//     console.log("Session refresh hook called");
//     const dispatch = useDispatch();
//     const client = useAuthorizedServicesClient();

//     // const refresh = client.refreshToken().then(e => {
//     //     if (e.success) {
//     //         if (e.token) {
//     //             dispatch(Session_Reducer_SetToken(e.token));
//     //         }
//     //         if (e.agentFrontendSettings) {
//     //             dispatch(Session_Reducer_SetFrontendSettings(e.agentFrontendSettings))
//     //         }

//     //     }
//     //     else {

//     //     }


//     // }).catch(() => {

//     // }).finally(() => {

//     // });

// }

export function useLogoutSession(): void {

}