import { faClock, faFileAlt, faInfoCircle, faStar } from "@fortawesome/pro-regular-svg-icons";
import { faCalculator, faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { AvailableFareModel, FareIdentifier } from "WebApiClient";
import ShareFareModal from "./Fare.Buttons.ShareFareModal";
import { useSelector } from "react-redux";


interface FareButtonProps {
    ToggleFlightTimes: () => void;
    ToggleRulesModal: () => void;
    ToggleFlightDetailsModal: () => void;
    ToggleAncillariesModal: () => void;
    ToggleCalculation: () => void;
    ToggleCalculationDebug: () => void;
    ShowCalcDebug: boolean;
    SelectedConnections: number[];
    BookFare: () => void;
    IsFareBooking: boolean;
    Identifier: FareIdentifier;
    Fare: AvailableFareModel
}

const FareButtons: React.FC<FareButtonProps> = props => {
    const [showSendFareModal, setSendFareModalOpen] = useState(false);

    function ToggleSendFareModal() {
        setSendFareModalOpen(!showSendFareModal);
    }

    const bookingRedirect: any = useSelector((state: any) => state.bookingRedirectSession.value);

    return (
        <React.Fragment>
            <div className="bg-secondary py-2 px-2 clearfix align-items-center">
                <span className="float-start">
                    {/* {hasDebugInfo &&
                        <button className="btn btn-sm btn-info text-uppercase me-1" onClick={ToggleDebugModal}>
                            <FontAwesomeIcon icon={faBug} className="me-1" />DEBUG</button>
                    } */}
                    <button className="btn btn-sm btn-outline me-1 toggleFlightTimes" onClick={props.ToggleFlightTimes}>
                        <FontAwesomeIcon icon={faClock} className="mr-0 mr-md-1 me-1" />
                        <span className="d-none d-md-inline-block">Flight times</span>
                    </button>
                    <button className="btn btn-sm btn-outline me-1" title="Calculation" onClick={props.ToggleCalculation} >
                        <FontAwesomeIcon icon={faCalculator} className="mr-0 mr-md-1 me-1" />
                        <span className="d-none d-md-inline-block">Calculation</span></button>
                    {props.ShowCalcDebug &&
                        <button className="btn btn-danger btn-sm btn-outline me-1" title="Calculation" onClick={props.ToggleCalculationDebug} >
                            <FontAwesomeIcon icon={faCalculator} className="mr-0 mr-md-1 me-1" />
                            <span className="d-none d-md-inline-block">Calculation details</span></button>
                    }
                </span>
                <span className="float-end">
                    <button
                        onClick={props.ToggleRulesModal}
                        className="btn btn-sm btn-primary text-uppercase ms-1"
                        type="button" aria-hidden="true">
                        <FontAwesomeIcon icon={faFileAlt} className="me-1" />Rules
                    </button>

                    <button
                        onClick={props.ToggleFlightDetailsModal}
                        className="btn btn-sm btn-primary text-uppercase ms-1"
                        type="button" aria-hidden="true">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-1" />Details
                    </button>

                    {/* {!props.IsFareBooking &&
                        <button onClick={props.ToggleAncillariesModal}
                            className="btn btn-sm btn-primary text-uppercase ms-1"
                            type="button" aria-hidden="true">
                            <FontAwesomeIcon icon={faStar} className="me-1" />Ancillaries
                        </button>
                    } */}

                    {bookingRedirect?.checkActiveBtnClicked !== "Rebook" && (
                        !props.IsFareBooking && (
                            <button onClick={props.ToggleAncillariesModal}
                                className="btn btn-sm btn-primary text-uppercase ms-1"
                                type="button" aria-hidden="true">
                                <FontAwesomeIcon icon={faStar} className="me-1" />Ancillaries
                            </button>
                        )
                    )}

                    {!props.IsFareBooking &&
                        <button className="btn btn-sm btn-primary text-uppercase ms-1" onClick={props.BookFare}> <FontAwesomeIcon icon={faShoppingCart} className="me-1" />
                            Book
                        </button>
                    }
                </span>
            </div>
            <ShareFareModal IsOpen={showSendFareModal} Toggle={ToggleSendFareModal} Identifier={props.Identifier} />
        </React.Fragment>
    );
};

export default FareButtons;