import { useSessionHandler } from "hooks/useSessionHandler";
import React, { useEffect } from "react";




const FareDisplayPage: React.FC<{}> = props => {
    const DEBUG = false;
    const sessionHandler = useSessionHandler();
    useEffect(() => {
        document.title = `Fare display`;
        if (DEBUG) {
            console.log(`Fare display Page initialized.`)
        }
        sessionHandler.RefreshSession();
    }, [])
    return <div className="center-container container content-wrapper" id="screen-AvailableFares">
        <div className="row">
            <div className="col-12 ">
                <div className="card">
                    Under construction
                                         </div>
            </div>
        </div>
    </div>
}
// interface FareDisplayPageState {
//     ModifySearchInput: IFareSearchInputState | undefined
// }
// class FareDisplayPage extends Component<AllProps, FareDisplayPageState> {
//     // constructor(props: AllProps) {
//     //     super(props);
//     //     this.state = {
//     //         ModifySearchInput: props.FareSearchInput
//     //     }
//     // }

//     componentDidMount() {
//         this.props.SetPage(ActivePageEnum.FareDisplayActivePage);
//         this.props.RefreshToken();
//         document.title = `Faredisplay`;
//     }

//     // OnSubmitModifySearch(data: IFareSearchInputState) {
//     //     if (this.state.ModifySearchInput) {
//     //         // this.props.SubmitSearch({Search:data, Source:SubmitSearchSource.Faredisplay});
//     //     }
//     // }

//     // OnModifySearch(data: IFareSearchInputState) {
//     //     this.setState({ ModifySearchInput: data })
//     // }
//     render() {
//         return (
//             <React.Fragment>

//                 {(this.props.HasFareResult && this.state.ModifySearchInput && !this.props.IsLoading) ?
//                     (
//                         <FareDisplay
//                             DateFormat={this.props.DateFormat}
//                             ModifySearchState={this.state.ModifySearchInput}
//                             SubmitModifySearch={(data: IFareSearchInputState) => this.OnSubmitModifySearch(data)}
//                             ResetModifySearch={() => { }}
//                             UpdateModifySearch={(data: IFareSearchInputState) => this.OnModifySearch(data)}
//                         />
//                     )
//                     :
//                     (
//                         <React.Fragment>
//                             <div className="center-container container content-wrapper" id="screen-AvailableFares">
//                                 <div className="row">
//                                     <div className="col-12 ">
//                                         <div className="card">
//                                             No fares
//                                     </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </React.Fragment>
//                     )
//                 }

//             </React.Fragment>
//         );

//     }

// };

export default FareDisplayPage;
// type AllProps = PropsFromState & DispatchProps;

// interface PropsFromState {
//     HasFareResult: boolean,
//     IsLoading: boolean,
//     FareSearchInput: IFareSearchInputState | undefined,
//     DateFormat: string;
// }
// const mapStateToProps = (state: State) => ({
//     HasFareResult: state.FareDisplay.HasLoadedFares,
//     FareSearchInput: state.FareDisplay.FareSearchInput,
//     IsLoading: state.FareDisplay.IsLoading,
//     DateFormat: state.Session.FrontendSettings!.dateFormat!,
// });

// interface DispatchProps {
//     SetPage: typeof Session_Reducer_SetActivePage;
//     // SubmitSearch: typeof Faresearch_Saga_SubmitSearch,
//     RefreshToken: typeof Session_Saga_RefreshToken;
// }
// const mapDispatchToProps = (dispatch: Dispatch) => ({
//     SetPage: (activePage: ActivePageEnum) =>
//         dispatch(Session_Reducer_SetActivePage(activePage)),
//     // SubmitSearch: (data: SubmitSearchPayload) =>
//     //     dispatch(Faresearch_Saga_SubmitSearch(data)),
//     RefreshToken: () => dispatch(Session_Saga_RefreshToken()),
// });
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(FareDisplayPage);
