import { DeployInfo } from "hooks/GetAppInfo";
import { AgentFrontendSettings, BaseApiResponseMetaData, StartPage, WebAccessEnum } from "WebApiClient";

const DefaultFrontendSettings: AgentFrontendSettings = {
  showBookingManagerTab: false,
  showFareQueryTab: false,
  otherSettingsAsKeyValueText: "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse",
  b2cSettingsText: "{\"HotelTab\":\"None\",\"AirPortSearchType\":\"All\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowDateInput\":\"Left\",\"ShowDefaultFilter\":\"On\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":false},\"Business\":{\"Checked\":true,\"preSelect\":false},\"First\":{\"Checked\":false,\"preSelect\":false}},\"TermsConditionsLink\":\"https://dev.flight.agentplus.io/\",\"ShowBackgroundModalColor\":\"Off\",\"showMarqueeText\":\"Flight will be delayed for 4 hours\",\"showMarqueeFrom\":\"Fri May 17 2024 10:17:28 GMT+0530 (India Standard Time)\",\"showMarqueeTo\":\"Fri May 17 2024 10:20:28 GMT+0530 (India Standard Time)\"}",
  startPage: StartPage.Query,
  enableCalculationDeugger: false,
  logoID: "",
  waitScreenID: "",
  locale: "DE",
  dateFormat: "DD.MM.YYYY",
  preferredCountry: "DE",
  bookingManagerWebAccess: WebAccessEnum.User,
  customerProfileData: undefined,
  allowedBookingManagerGlobalFeatures: [],
  budgetInfo: undefined,
  currency: "",
  paymentDataSettings: "",
  showAgencyCalcTooltip:true,
  showConsolidatorCalcTooltip:true
}

export const DefaultSessionState: SessionState = {
  isAdmin: false,
  isLoggedIn: false,
  webID: '',
  JwtToken: "",
  WebName: "",
  FrontendSettings: DefaultFrontendSettings,
  paymentDataSetting: "",
  ShowCustomerWaitScreen: false,
  ShowGeneralWaitScreen: false,
  WaitscreenProgress: { Percentage: 0, Label: "" },
  VersionDifferenceToBackend: false,
  NewBackendVersion: "",
  Transactions: [],
  DeployInfo: undefined
};

export interface SessionState {
  readonly webID: String;
  readonly isAdmin: boolean;
  readonly isLoggedIn: boolean;
  readonly JwtToken: string;
  readonly WebName: string;
  readonly paymentDataSetting: string;
  readonly FrontendSettings: AgentFrontendSettings;
  readonly WaitscreenProgress: WaitScreenProgress;
  readonly ShowCustomerWaitScreen: boolean;
  readonly ShowGeneralWaitScreen: boolean;
  readonly VersionDifferenceToBackend: boolean;
  readonly NewBackendVersion: string;
  readonly Transactions: BaseApiResponseMetaData[];
  readonly DeployInfo: DeployInfo | undefined
}

export interface TransactionIDInfo {
  readonly TransactionID: string;
  readonly Time: string;
  readonly Label: string;
  readonly Success: boolean;
}

export interface WaitScreenProgress {
  Percentage: number;
  Label: string;
}

export interface DeepLoginData {
  WebID: string;
  Deeplink: string;
  Query: string
}
