import { IFareSearchInputState } from 'components/FareSearch/types/FareSearchTypes';
import { IncludedServiceData, IncludedServiceMainType } from 'WebApiClient';


export interface AvailFaresResultPayload {
  Guid: string;
  Input: IFareSearchInputState
}

export enum FreeBaggageFilterCriteria {
  All = 0, No = 1, Yes = 2
}
export interface FaresFilterState {
  // FilterOpen: boolean;
  SelectAllAirlinesActive: boolean;
  Airlines: FilterCriteria[];
  SelectAllAlliancesActive: boolean;
  Alliances: FilterCriteria[];
  SelectAllCabinClassesActive: boolean;
  CabinClasses: FilterCriteria[];
  SelectAllAllotmentTypesActive: boolean;
  AllotmentTypes: FilterCriteria[];
  SelectAllAirportsActive: boolean;
  DepartureAirports: FilterCriteria[];
  ArrivalAirports: FilterCriteria[];
  SelectAllFareTypesActive: boolean;
  SelectAllQueryPCCActive: boolean;
  SelectAllBookingPCCActive: boolean;
  SelectAllTicketPCCActive: boolean;
  SelectAllSourceActive: boolean;
  FareTypes: FilterCriteria[];
  SelectAllIncludedServicesActive: boolean;
  IncludedService: IncludedServiceMainFilterCriteria[];
  LegsMaxStopAmount: LegMaxStopFilterCriteria[];
  DepartureTime: LegTimeFilterCriteria[];
  ArrivalTime: LegTimeFilterCriteria[];
  FreeBaggage: FreeBaggageFilterCriteria;
  QueryPCCs: FilterCriteria[];
  BookingPCCs: FilterCriteria[];
  Sources: FilterCriteria[];
}
export interface IncludedServiceMainFilterCriteria {
  Maingroup: IncludedServiceMainType;
  MainLabel: string;
  Values: IncludedServiceSubFilterCriteria[];
}

export interface IncludedServiceSubFilterCriteria extends FilterCriteria {
  Icon: IncludedServiceData
}

export interface LegTimeFilterCriteria {
  LegIndex: number;
  MinHour: number;
  MaxHour: number;
}
export interface LegMaxStopFilterCriteria {
  LegIndex: number;
  MaxStopSelection: number
  MaxStopduration: number
}

export interface FilterCriteria {
  Label: string;
  Value: string;
  Checked: boolean;
}






export const DefaultAvailableFaresState: AvailableFaresState = {
  SubmittedSearches: []
};

export interface AvailableFaresState {
  SubmittedSearches: string[];
}

