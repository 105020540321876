import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React from "react";
import { PnrResponseTicketInfoModel } from "WebApiClient";

export const PnrTicketInfoCard: React.FC<{ data: PnrResponseTicketInfoModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Ticket infos <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <div className="row">
                    {data.map((osi, index) =>
                        <div className="col-12 mb-2" key={"osiTkt_"+index}>
                            <TicketItem data={osi} tktIndex={index} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );


}

const TicketItem: React.FC<{ data: PnrResponseTicketInfoModel, tktIndex: number }> = ({ data, tktIndex }) => {
    const { dateOfIssuse, dateOfIssuseSpecified,
        documentInfo, equivalentBaseFare, fareCalculation,
        firstName, formOfPaymentAmount, formOfPaymentType,
        grandTotal, iataNumber, lastName, pointOfIssue, taxes,
        ticketStatus, totalAmount, totalFee } = data;

    return (
        <div className="card">
            <div className="card-header card-header-primary">Ticket #{tktIndex + 1}</div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <table className="table table-sm table-striped table-hover table-bordered">
                            <tbody>
                                <tr>

                                    <td className="font-weight-bold">Name</td>
                                    <td>{firstName} {lastName}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Status</td>
                                    <td>{ticketStatus}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">IATA number</td>
                                    <td>{iataNumber ? iataNumber : ""}</td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">Equiv. Base fare</td>
                                    <td><SharedPriceDisplay price={equivalentBaseFare} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Grand total</td>
                                    <td><SharedPriceDisplay price={grandTotal} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Total amount</td>
                                    <td><SharedPriceDisplay price={totalAmount} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Total fee</td>
                                    <td><SharedPriceDisplay price={totalFee} /></td>
                                </tr>

                                <tr>
                                    <td className="font-weight-bold">Date of issue</td>
                                    <td>{dateOfIssuseSpecified ? <DateTimeDisplay date={dateOfIssuse} /> : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Calculation</td>
                                    <td>{fareCalculation}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">FOP type</td>
                                    <td>{formOfPaymentType}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">FOP amount</td>
                                    <td>{formOfPaymentAmount}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Point of issue</td>
                                    <td>{pointOfIssue}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {taxes.length > 0 &&
                        <div className="col-12 mb-2">
                            <div className="card">
                                <div className="card-header card-header-primary">Taxes</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Action</th>
                                                <th className="font-weight-bold">Price</th>
                                                <th className="font-weight-bold">Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {taxes.map((tax, index) =>
                                                <tr key={"serTax_"+index}>
                                                    <td>{tax.action}</td>
                                                    <td><SharedPriceDisplay price={tax.price} /></td>
                                                    <td>{tax.type}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {documentInfo.length > 0 &&
                        <div className="col-12 mb-2">
                            <div className="card">
                                <div className="card-header card-header-primary">Document infos</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Arrival</th>
                                                <th className="font-weight-bold">Departure</th>
                                                <th className="font-weight-bold">Booking class</th>
                                                <th className="font-weight-bold">Baggage</th>
                                                <th className="font-weight-bold">Company</th>
                                                <th className="font-weight-bold">Coupon number</th>
                                                <th className="font-weight-bold">Coupon status</th>
                                                <th className="font-weight-bold">Details</th>
                                                <th className="font-weight-bold">Fare base</th>
                                                <th className="font-weight-bold">Flight date</th>
                                                <th className="font-weight-bold">Flight info</th>
                                                <th className="font-weight-bold">NVA</th>
                                                <th className="font-weight-bold">NVB</th>
                                                <th className="font-weight-bold">Stops</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentInfo.map((docInfo, index) =>
                                                <tr key={"docInfo"+index}>
                                                    <td>{docInfo.arrival}</td>
                                                    <td>{docInfo.departure}</td>
                                                    <td>{docInfo.bookingClass}</td>
                                                    <td>{docInfo.baggage}</td>
                                                    <td>{docInfo.company}</td>
                                                    <td>{docInfo.couponNumber}</td>
                                                    <td>{docInfo.couponStatus}</td>
                                                    <td>{docInfo.documentDetails}</td>
                                                    <td>{docInfo.fareBase}</td>
                                                    <td>{docInfo.flightDateSpecified ? <DateTimeDisplay date={docInfo.flightDate} /> : ""}</td>
                                                    <td>{docInfo.flightInfo}</td>
                                                    <td>{docInfo.nva}</td>
                                                    <td>{docInfo.nvb}</td>
                                                    <td>{docInfo.stopIndicator}</td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    );


}


