import { RemarkTypeModelEnum } from "WebApiClient";

export function GetRemarkLabel(type: RemarkTypeModelEnum): string {
    let result = "";
    switch (type) {
        case RemarkTypeModelEnum.Coded:
            result = "Coded";
            break;
        case RemarkTypeModelEnum.Confidential:
            result = "Confidential";
            break;
        case RemarkTypeModelEnum.General:
            result = "General";
            break;
        case RemarkTypeModelEnum.Historical:
            result = "Historical";
            break;
        case RemarkTypeModelEnum.Invoice:
            result = "Invoice";
            break;
        case RemarkTypeModelEnum.Itinerary:
            result = "Itinerary";
            break;
        case RemarkTypeModelEnum.None:
            result = "None";
            break;
        case RemarkTypeModelEnum.Other:
            result = "Other";
            break;
    }

    return result;
}