import { faCodeBranch, faLineColumns } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IFareSearchInputState } from "../types/FareSearchTypes";

interface McreOverrideInputProps {
    Input: IFareSearchInputState
    IsModifySearch: boolean;
    UpdateState: (state: IFareSearchInputState) => void
}
export const McreOverrideInput: React.FC<McreOverrideInputProps> = props => {
    const BodyClass: string = props.IsModifySearch ? "" : "card-body card-body-secondary";
    const ContainerClass: string = props.IsModifySearch ? "" : "card h-100";
    return <React.Fragment>
        <div className="col-12 col-lg-6 mt-4">
            <div className={`${ContainerClass}`}>
                {!props.IsModifySearch &&
                    <div className="card-header card-header-primary">Rules</div>
                }
                <div className={`${BodyClass}`}>
                    <div className="row">
                        <div className="col">
                            <div className="input-group mb-3">
                                <span className="input-group-text" >

                                    <FontAwesomeIcon icon={faCodeBranch} />
                                </span>

                                <input type="text" value={props.Input.overrideMultiChannelBranch} onChange={(e) => props.UpdateState({ ...props.Input, overrideMultiChannelBranch: e.target.value })} placeholder="Branch" className="form-control" />
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="input-group mb-3">
                                <span className="input-group-text" >

                                    <FontAwesomeIcon icon={faLineColumns} />
                                </span>

                                <input type="text" value={props.Input.overrideMultiChannelBranchgroup} onChange={(e) => props.UpdateState({ ...props.Input, overrideMultiChannelBranchgroup: e.target.value })} placeholder="Branchgroup" className="form-control" />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </React.Fragment>
}