import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import React, { Fragment, useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { AvailableFareModel, FareBookingPassengerView } from "WebApiClient";
import { AncillariesModal, ServiceAddObj } from "./AncillariesModal";
import { CalculationDebugModal } from "./CalculationDebugModal";
import FareButtons from "./Fare.Buttons";
import FareCalculation from "./Fare.Calculation";
import FareHeader from "./Fare.Header";
import FareInfoBox from "./Fare.InfoBox";
import FareLeg from "./Fare.Leg";
import FarePriceDetailsModal from "./Fare.PriceDetailsModal";
import { FareFilterApplicability } from "./FareFilter.Applicability";
import FareRuleModal from "./FareRulesModal";
import { FlightDetailsModal } from "./FlightDetailsModal";
import _ from "lodash";
interface IFareProps {
  ShowAllLegsActive: boolean;
  ShowAllFlightTimesActive: boolean;
  ShowAllCalculationActive: boolean;
  FareData: AvailableFareModel;

  //available fares display properties
  Applicability?: FareFilterApplicability;
  IsFareBooking: boolean;
  BookFare?: (payload: SetBookingPayload) => void;

  // fare booking properties
  SelectedConnections?: number[];
  SelectConnection?: (legIndex: number, ConnectionIndex: number) => void;
  AddedServices?: ServiceAddObj[];
  SetAddedServices?(services: ServiceAddObj[]): void;
  BookPassengers?: AssignablePassengerOption[];
  ShowCalcDebugger: boolean;
  myRefs?: any;
  key?: any;
  isActivate?: boolean;
}
const Fare: React.FC<IFareProps> = (props) => {
  const OriginalFareIndex: number = props.FareData.fareIndex;
  const [flightDetailsModalOpen, setFlightDetailsModalOpen] = useState(false);
  const [renderError, setRenderError] = useState(false);
  const [Status, setStatus] = useState(false);
  function ToggleflightDetailsModal() {
    setFlightDetailsModalOpen(!flightDetailsModalOpen);
  }
  const [calculationDebugModalOpen, setCalculationDebugOpen] = useState(false);
  function ToggleCalculationDebugModal() {
    setCalculationDebugOpen(!calculationDebugModalOpen);
  }

  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  function ToggleRulesModal() {
    setRulesModalOpen(!rulesModalOpen);
  }

  const [AncillariesModalOpen, setAncillariesModalOpen] = useState(false);
  function ToggleAncillariesModal() {
    setAncillariesModalOpen(!AncillariesModalOpen);
  }

  const [PriceDetailsModalOpen, setPriceDetailsOpen] = useState(false);
  function TogglePriceDetailsModal() {
    setPriceDetailsOpen(!PriceDetailsModalOpen);
  }
  const PlatingCarriers = props.FareData.legs.map((e) => e.platingCarrier!);
  const DistinctPlatingCarriers = GetDistinctPlatingCarriers();
  const [showLegs, setShowLegs] = useState(false);
  const [showFlightTimes, setShowFlightTimes] = useState(false);
  const [showCalculation, setShowCalculation] = useState(false);
  const ShowFare = props.Applicability ? props.Applicability.Applicable : true;
  const [internalSelectedConnections, setSelectedConnection] = useState<
    number[]
  >([]);
  const [internaladdedServices, setInternalAddedServices] = useState<
    ServiceAddObj[]
  >([]);

  const _addedServices =
    props.AddedServices !== undefined
      ? props.AddedServices
      : internaladdedServices;
  const _setAddedServices =
    props.SetAddedServices !== undefined
      ? props.SetAddedServices
      : (services: ServiceAddObj[]) => {
          setInternalAddedServices(services);
        };

  useEffect(() => {
    try {
      if (internalSelectedConnections.length === 0) {
        let initiSelection: number[] = [];
        props.FareData.legs.forEach(() => {
          initiSelection.push(0);
        });
        setSelectedConnection(initiSelection);
      }

      internalSelectedConnections.forEach((val, index) => {
        if (
          props.Applicability !== undefined &&
          props.Applicability.ApplicableConnections !== undefined
        ) {
          if (props.Applicability.ApplicableConnections[index]) {
            if (
              !props.Applicability.ApplicableConnections[index].includes(val)
            ) {
              SetSelectedConnection(
                index,
                props.Applicability.ApplicableConnections[index][0]
              );
            }
          }
        }
      });
    } catch {
      console.error(
        `An error occured in fare ${props.FareData.identifier.fareIndex} during connection selection in the useEffect hook.`
      );
      setRenderError(true);
    }
  }, [
    internalSelectedConnections,
    props.Applicability,
    props.FareData.legs,
    SetSelectedConnection,
  ]);

  // Effect to expand / collapse fare if expanded externally
  useEffect(() => {
    setShowLegs(props.ShowAllLegsActive);
  }, [props.ShowAllLegsActive]);

  // Effect to expand / collapse flight times if expanded externally
  useEffect(() => {
    setShowFlightTimes(props.ShowAllFlightTimesActive);
  }, [props.ShowAllFlightTimesActive]);

  // Effect to expand / collapse calculation if expanded externally
  useEffect(() => {
    setShowCalculation(props.ShowAllCalculationActive);
  }, [props.ShowAllCalculationActive]);

  function GetDistinctPlatingCarriers() {
    const result = [];
    const map = new Map();
    for (const item of PlatingCarriers) {
      if (!map.has(item.code!)) {
        map.set(item.code!, true); // set any value to Map
        result.push(item);
      }
    }
    return result;
  }

  function SetSelectedConnection(legindex: number, connectionIndex: number) {
    if (_addedServices.length > 0) {
      _setAddedServices([]);
    }
    if (props.SelectedConnections === undefined) {
      let selectedConns = [...internalSelectedConnections];
      selectedConns[legindex] = connectionIndex;
      setSelectedConnection(selectedConns);
    } else {
      if (props.SelectConnection) {
        props.SelectConnection(legindex, connectionIndex);
      }
    }
  }

  function ToggleLegs() {
    setShowLegs(!showLegs);
  }

  function ToggleFlightTimes() {
    setShowFlightTimes(!showFlightTimes);
  }
  function ToggleCalculation() {
    setShowCalculation(!showCalculation);
  }

  function BookFare() {
    if (props.BookFare && !props.IsFareBooking) {
      const payload: SetBookingPayload = {
        Fare: props.FareData,
        SelectedConnections: internalSelectedConnections,
        AddedServices: _addedServices,
        OnSelectConnection: SetSelectedConnection,
      };
      props.BookFare(payload);
    }
  }

  function GetSelectedConnectionIndexByLegIndex(legIndex: number): number {
    if (props.SelectedConnections === undefined) {
      return internalSelectedConnections[legIndex];
    } else {
      return props.SelectedConnections[legIndex];
    }
  }

  const SelectedLegConnections =
    props.SelectedConnections !== undefined
      ? props.SelectedConnections
      : internalSelectedConnections;

  function GetAssignablePassengers(
    p: FareBookingPassengerView[]
  ): AssignablePassengerOption[] {
    let result: AssignablePassengerOption[] = [];
    p.forEach((element, index) => {
      const pData = element.bookingData;
      const useCustomLabel =
        (pData.firstName !== undefined && pData.firstName.length > 0) ||
        (pData.lastName !== undefined && pData.lastName.length > 0);
      const label = useCustomLabel
        ? `${pData.title ? pData.title : ""} ${
            pData.firstName ? pData.firstName : ""
          } ${pData.lastName ? pData.lastName : ""} (${pData.passengerType})`
        : `${pData.passengerType} ${index + 1}`;
      const option: AssignablePassengerOption = {
        Index: index,
        Label: label,
        PassengerType: pData.passengerType,
      };
      result.push(option);
    });
    return result;
  }
  const AssignablePassengers = props.BookPassengers
    ? props.BookPassengers
    : GetAssignablePassengers(props.FareData.bookingPassengers!);

  return (
    <React.Fragment>
      <div className={`row singlefare ${!ShowFare ? "d-none" : ""}`}>
        {!renderError && (
          <Fragment>
            <div className={`col-12 col-md-9 p-0 ${props.FareData.fareIndex}`}>
              <div
                className={`fare__single_fare mb-0 mb-md-2 ${
                  showLegs ? "boxShadow-light mb-md-3" : ""
                }`}
              >
                {/* Logo / Text / Price */}
                <FareHeader
                  ToggleFare={ToggleLegs}
                  OriginalFareIndex={OriginalFareIndex}
                  Data={props.FareData.fareHeader!}
                  PlatingCarriers={DistinctPlatingCarriers}
                  IsExpanded={showLegs}
                  TotalPrice={props.FareData.totalPrice}
                  TotalTax={props.FareData.totalTax}
                  Currency={props.FareData.currency!}
                  Services={_addedServices}
                  myRefs={props.myRefs!}
                  FareIndex={props.FareData.fareIndex!}
                  fareData={props.FareData}
                />

                {showLegs && (
                  <React.Fragment>
                    {props.FareData.legs.map(
                      (leg, index) =>
                        internalSelectedConnections[index] !== undefined && (
                          <FareLeg
                            LegIndex={leg.index}
                            SelectedConnection={GetSelectedConnectionIndexByLegIndex(
                              leg.index
                            )}
                            GetSelectedConnectionIndexByLegIndex={
                              GetSelectedConnectionIndexByLegIndex!
                            }
                            Data={leg}
                            isActivate={props.isActivate}
                            key={"FareLeg" + index}
                            ShowFlightTimes={showFlightTimes}
                            SelectConnection={SetSelectedConnection}
                            ShowLegPlatingCarrier={
                              DistinctPlatingCarriers.length > 1
                            }
                            ApplicableConnections={
                              props.Applicability &&
                              props.Applicability.ApplicableConnections
                                ? props.Applicability.ApplicableConnections[
                                    leg.index
                                  ]
                                : undefined
                            }
                            Fare={props.FareData}
                            ToggleFlightTimes={ToggleFlightTimes}
                            setStatus={setStatus}
                          />
                        )
                    )}
                    <FareCalculation
                      OriginalFareIndex={OriginalFareIndex}
                      Show={showCalculation}
                      Data={props.FareData.calculationResult!}
                      Currency={props.FareData.currency!}
                      AddedServices={_addedServices}
                      AssignalbePassengers={AssignablePassengers}
                    />

                    <FareButtons
                      ToggleFlightTimes={ToggleFlightTimes}
                      ToggleCalculation={ToggleCalculation}
                      ToggleRulesModal={ToggleRulesModal}
                      ToggleAncillariesModal={ToggleAncillariesModal}
                      ToggleFlightDetailsModal={ToggleflightDetailsModal}
                      BookFare={BookFare}
                      ToggleCalculationDebug={ToggleCalculationDebugModal}
                      ShowCalcDebug={props.ShowCalcDebugger}
                      SelectedConnections={internalSelectedConnections}
                      IsFareBooking={props.IsFareBooking}
                      Identifier={props.FareData.identifier!}
                      Fare={props.FareData}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
            <FareInfoBox
              Info={props.FareData.fareInfoBox!}
              ShowDetails={showLegs}
              ToggleFare={ToggleLegs}
              FareIndex={props.FareData.fareIndex}
              SelectedConnections={SelectedLegConnections}
              Legs={props.FareData.legs}
              Status={Status}
            />
            <FareRuleModal
              IsOpen={rulesModalOpen}
              Identifier={props.FareData!.identifier!}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleRulesModal}
            />
            <FlightDetailsModal
              IsOpen={flightDetailsModalOpen}
              Identifier={props.FareData!.identifier!}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleflightDetailsModal}
              testConnection={GetSelectedConnectionIndexByLegIndex}
              GetSelectedConnectionIndexByLegIndex={
                GetSelectedConnectionIndexByLegIndex
              }
              setStatus={setStatus}
            />
            <CalculationDebugModal
              IsOpen={calculationDebugModalOpen}
              Identifier={props.FareData!.identifier!}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleCalculationDebugModal}
              testConnection={GetSelectedConnectionIndexByLegIndex}
              GetSelectedConnectionIndexByLegIndex={
                GetSelectedConnectionIndexByLegIndex
              }
              setStatus={setStatus}
            />
            <AncillariesModal
              IsOpen={AncillariesModalOpen}
              SelectedConnections={SelectedLegConnections}
              SetSelectedConnection={SetSelectedConnection}
              Fare={props.FareData}
              Toggle={ToggleAncillariesModal}
              AddedServices={_addedServices}
              SetAddedServices={_setAddedServices}
              Passengers={AssignablePassengers}
            />
            <FarePriceDetailsModal
              IsOpen={PriceDetailsModalOpen}
              Toggle={TogglePriceDetailsModal}
            />
          </Fragment>
        )}
        {renderError && (
          <div className="col-12">
            <Alert color="danger">
              An error occured. This fare cannot be displayed.
            </Alert>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Fare;
