import { BookingNotifications } from "components/BookingNotifications/BookingNotifications";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BookingItemModel } from "WebApiClient";

interface BookingManagerManageNotificationModalProps {
  Booking: BookingItemModel;
  Toggle: () => void;
  IsOpen: boolean;
  OnUpdate: (data: BookingItemModel) => void;
}

export const BookingManagerManageNotificationModal: React.FC<
  BookingManagerManageNotificationModalProps
> = (props) => {
  return (
    <Modal
      size="xl"
      centered
      toggle={props.Toggle}
      isOpen={props.IsOpen}
      scrollable={true}>
      <ModalHeader toggle={props.Toggle}>
        Manage notifications for{" "}
        <strong> {props.Booking.latestVersion.generalInfo.filekey}</strong>
      </ModalHeader>

      <ModalBody>
        <BookingNotifications
          Booking={props.Booking}
          OnUpdate={props.OnUpdate}
        />
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={props.Toggle}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BookingManagerManageNotificationModal;
