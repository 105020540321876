import { faSave, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BaseApiResponse, ENotificationActionRequiredEnumModel, ENotificationCategoryEnumModel, UpdateNotificationModel } from "WebApiClient";
export const UpdateNotificationComponent: React.FC<{ notification: UpdateNotificationModel, OnUpdate: (updateModel: UpdateNotificationModel) => void, OnConfirm: () => void, OnCancel: () => void }> = ({ OnUpdate, notification, OnCancel, OnConfirm }) => {
    const { username, branchgroup, actionRequired, branch, category, confidential, notificationID, readOnly, text, title } = notification;
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const [errorOccured, setErrorOccured] = useState(false);
    const bookClient = useBookingClient();
    const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
    const isCreate = notificationID !== undefined && notificationID.length > 0;
    function OnConfirmEdit() {
        setFetching(true);
        setResponse(undefined);
        bookClient.createOrUpdateNotification(notification)
            .then(updateResponse => {
                setResponse(updateResponse);
                dispatch(Session_Reducer_PushTransaction(updateResponse.responseMetaData));
                if (updateResponse.responseMetaData.errorOccured) {
                    setErrorOccured(true)
                }
                else {
                    setErrorOccured(false);
                    OnConfirm();
                    toast.success(`Notification ${isCreate ? "created" : "updated."}`);
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
            })
    }
    return <div className="card">
        <div className="card-header card-header-primary">{isCreate ? "Update" : "Create new"} notification

        </div>
        <div className="card-body card-body-primary">
            {fetching ?
                <Fragment>
                    <WaitingCard />
                </Fragment> : <Fragment>
                    {response && !errorOccured &&
                        <div className="col-12 mb-2">
                            <Alert color="success">
                                Notification updated.
                            </Alert>
                        </div>
                    }
                    {errorOccured &&
                        <div className="col-12 mb-2">
                            <ErrorDisplay data={response?.responseMetaData} />
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-6 col-xs-12">



                            <div className="form-group mb-1">
                                <div className="pb-1">Title</div>
                                <input className="form-control" value={title} onChange={(e) => { OnUpdate({ ...notification, title: e.target.value }) }}></input>

                            </div>
                            <div className="form-group mb-1">
                                <div className="pb-1">Text</div>
                                <input className="form-control" value={text} onChange={(e) => { OnUpdate({ ...notification, text: e.target.value }) }}></input>

                            </div>
                            <div className="form-group mb-1">
                                <div className="pb-1">Category</div>
                                <select className="form-control" value={category} onChange={(e) => { OnUpdate({ ...notification, category: e.target.value as any as ENotificationCategoryEnumModel }) }}>
                                    <option value={ENotificationCategoryEnumModel.BookingFailed}>Booking failed</option>
                                    <option value={ENotificationCategoryEnumModel.OCN}>Order change</option>
                                    <option value={ENotificationCategoryEnumModel.Other}>Other</option>
                                    <option value={ENotificationCategoryEnumModel.PaymentRejected}>Payment rejected</option>
                                    <option value={ENotificationCategoryEnumModel.QueueRobot}>Queue robot</option>
                                    <option value={ENotificationCategoryEnumModel.Remark}>Remark</option>
                                    <option value={ENotificationCategoryEnumModel.Reprice}>Reprice</option>
                                    <option value={ENotificationCategoryEnumModel.TTL}>Ticketing time limit</option>



                                </select>

                            </div>
                            <div className="form-group mb-1">
                                <div className="pb-1">Priority</div>
                                <select className="form-control" value={actionRequired} onChange={(e) => { OnUpdate({ ...notification, actionRequired: e.target.value as any as ENotificationActionRequiredEnumModel }) }}>
                                    <option value={ENotificationActionRequiredEnumModel.None}>None</option>
                                    <option value={ENotificationActionRequiredEnumModel.Low}>Low</option>
                                    <option value={ENotificationActionRequiredEnumModel.Medium}>Medium</option>
                                    <option value={ENotificationActionRequiredEnumModel.High}>High</option>
                                    <option value={ENotificationActionRequiredEnumModel.Urgent}>Urgent</option>


                                </select>

                            </div>

                        </div>
                        <div className="col-md-6 col-xs-12 mb-2">
                            <div className="form-group mb-1">
                                <div className="pb-1">Branchgroup</div>
                                <input className="form-control" value={branchgroup} onChange={(e) => { OnUpdate({ ...notification, branchgroup: e.target.value }) }}></input>

                            </div>
                            <div className="form-group mb-1">
                                <div className="pb-1">Branch</div>
                                <input className="form-control" value={branch} onChange={(e) => { OnUpdate({ ...notification, branch: e.target.value }) }}></input>

                            </div>
                            <div className="form-group mb-1">
                                <div className="pb-1">User</div>
                                <input className="form-control" value={username} onChange={(e) => { OnUpdate({ ...notification, username: e.target.value }) }}></input>

                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" onChange={() => OnUpdate({ ...notification, confidential: !confidential })} checked={confidential} />
                                <label className="form-check-label pb-1">Confidential</label>


                            </div>
                            <div className="form-check mb-1">
                                <input className="form-check-input" type="checkbox" onChange={() => OnUpdate({ ...notification, readOnly: !readOnly })} checked={readOnly} />
                                <label className="form-check-label pb-1">Readonly</label>


                            </div>
                        </div>
                        <div className="col-12 mb-2">
                            <div className="d-grid">
                                <button className="btn btn-primary" onClick={OnConfirmEdit}><FontAwesomeIcon icon={faSave} /> Save</button>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-grid">
                                <button className="btn btn-danger" onClick={OnCancel}><FontAwesomeIcon icon={faTimes} /> Close</button>
                            </div>

                        </div>
                    </div>
                </Fragment>
            }



            {/* <div className="col-12 mb-2">
            <div className="custom-control custom-radio" onClick={() => OnUpdate({ ...notification, readOnly: !readOnly })}>
              <label className="form-check-label" >Readonly</label>
            </div>
          </div>
          <div className="col-12 mb-2">
            <div className="custom-control custom-radio" onClick={() => OnUpdate({ ...notification, confidential: !confidential })}>
              <input type="checkbox" onChange={() => { }} checked={confidential} className="form-check-input" />
              <label className="form-check-label" >Confidential</label>
            </div>
          </div> */}

        </div>
    </div>
}