import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { ServiceBookingTypeEnumDisplay } from "components/Shared/ServiceBookingTypeEnumDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { AncillaryTypeEnumModel, BaseApiResponse, BookingIdentifier, BookingItemAddedServiceModel, BookingItemServicePassengerModel, BookingItemServicesManagementModel, BookingServiceRequestModel, PnrResponseSegmentModel, RemoveServiceFromBookingRequestModel, ServiceBookingTypeEnumModel, SSRRequestDataModel } from "WebApiClient";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";





interface RemoveBookingServicesProps {
    model: BookingItemServicesManagementModel;
    Identifier: BookingIdentifier;
    OnRefresh: () => void;

}

export const RemoveBookingServices: React.FC<RemoveBookingServicesProps> = ({ model, Identifier, OnRefresh }) => {
    const { addedServices, passengers, segments } = model;
    const [selectedServices, setSelectedServices] = useState<number[]>([]);
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
    const bookClient = useBookingClient();
    const [errorOccured, setErrorOccured] = useState(false);

    function SelectAll() {
        let sel: number[] = [];
        addedServices.forEach((e, i) => {
            sel.push(i);
        });
        setSelectedServices(sel);
    }
    function DeSelectAll() {

        setSelectedServices([]);
    }
    function OnAddMarkRemove(index: number) {
        let sCopy: number[] = [...selectedServices];
        const iOf = sCopy.indexOf(index);
        if (iOf === -1) {
            sCopy.push(index);
        }
        else {
            sCopy.splice(iOf, 1);
        }
        setSelectedServices(sCopy);
    }
    function OnConfirmDelete() {
        let payload: BookingItemAddedServiceModel[] = [];
        selectedServices.forEach((s) => {
            const arrItem: BookingItemAddedServiceModel = addedServices[s];
            payload.push(arrItem);
        });
        if (payload.length > 0) {
            OnRemove(payload);
        }
    }

    function OnRemove(removeServices: BookingItemAddedServiceModel[]) {

        const ssrServices: SSRRequestDataModel[] = [];
        let serviceRequests: BookingServiceRequestModel[] = [];

        removeServices.forEach((s) => {
            if (s.bookingType === ServiceBookingTypeEnumModel.SSR) {
                const ssrPayLoad: SSRRequestDataModel = { carrierCode: s.carrier, code: s.bookingTypeCode, freeText: s.bookingTypeText, passengerSelection: [s.passengerID], segments: s.segmentNumber };
                ssrServices.push(ssrPayLoad)
            }
            if (s.bookingType === ServiceBookingTypeEnumModel.SVC) {
                let a: BookingServiceRequestModel = { carrier: s.carrier, code: s.bookingTypeCode, text: s.bookingTypeText, passengerSelection: [s.passengerID], segmentSelection: s.segmentNumber, date: undefined, location: undefined, quantity: undefined, id: s.addedServiceID, extensions: s.extensions, serviceType: AncillaryTypeEnumModel.None }
                serviceRequests.push(a);
            }
        })
        let request: RemoveServiceFromBookingRequestModel = { bookingIndentifier: Identifier, services: serviceRequests, ssrServices: ssrServices, seats: undefined }

        setFetching(true);
        bookClient.removeServices(request)
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                setErrorOccured(false);


            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
                setSelectedServices([]);
            })

    }


    return <div className="row">
        {fetching &&
            <div className="col-12">
                <WaitingCard />
            </div>
        }
        {!fetching &&
            <Fragment>

                {errorOccured &&
                    <div className="col-12 mb-2">
                        <ErrorDisplay data={response?.responseMetaData} />
                    </div>
                }
                {response &&
                    <div className="col-12 mb-2">
                        <Alert color={errorOccured ? "danger" : "success"}>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    {errorOccured &&
                                        <span>Services could not be removed.</span>
                                    }
                                    {!errorOccured &&
                                        <span>Services removed.</span>
                                    }
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" onClick={OnRefresh}>Refresh</button>

                                </div>
                            </div>
                        </Alert>
                    </div>
                }
                {!response &&
                    <div className="col-12">
                        <div className="card">

                            <div className="card-body card-body-primary">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <div className="btn-group btn-group-sm" role="group">

                                            <button className="btn btn-secondary" onClick={SelectAll}>Select all</button>
                                            <button className="btn btn-secondary" onClick={DeSelectAll}>Remove selection</button>
                                        </div>
                                    </div>
                                    <div className="col-12">

                                        <table className="table table-sm table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Last name</th>
                                                    <th>First name</th>
                                                    <th>Service code</th>
                                                    <th>Service description</th>
                                                    <th>Segments</th>
                                                    <th>Price</th>
                                                    <th>Refundable</th>
                                                    <th>Carrier</th>
                                                    <th>NVB</th>
                                                    <th>NVA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {addedServices.map((service, serviceIndex) =>
                                                    // <div className="col-12 mb-2" >
                                                    <AddedService key={"adser_"+serviceIndex} OnRemove={OnAddMarkRemove} model={service} passengers={passengers} segments={segments} index={serviceIndex} IsSelected={selectedServices.indexOf(serviceIndex) !== -1} />

                                                    // </div>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-12 mb-2">
                                        <div className="d-grid">

                                            <button className="btn btn-primary" disabled={selectedServices.length === 0} onClick={OnConfirmDelete}><FontAwesomeIcon icon={faCheckCircle} /> Confirm selection and remove services</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }





            </Fragment>
        }

    </div>
}

const AddedService: React.FC<{ model: BookingItemAddedServiceModel, passengers: BookingItemServicePassengerModel[], segments: PnrResponseSegmentModel[], OnRemove: (index: number) => void; index: number, IsSelected: boolean }> = ({ model, passengers, segments, OnRemove, index, IsSelected: IsMarked }) => {
    const { addedServiceID, bookingType, bookingTypeCode, bookingTypeID, bookingTypeText, carrier, exchangeable, extensions, lastEMDDate, notValidAfter, notValidBefore, passengerID, price, refundable, segmentNumber, serviceCode, serviceText } = model;

    function GetSegmentOverview(): JSX.Element {
        let result: string[] = [];
        segmentNumber.forEach((number) => {
            const segmentIndex = segments.findIndex(e => e.segmentNumber === number);
            if (segmentIndex !== -1) {
                const segment = segments[segmentIndex];
                result.push(`${segment.departure.iata} - ${segment.arrival.iata}`)
            }

        })
        return <span>
            {result.map((s, i) =>
                <span key={"adService_"+i}>
                    <span>{s}</span> <br />

                </span>
            )}
        </span>
    }

    function PassengerView(): JSX.Element {
        const iOf = passengers.findIndex(e => e.id === passengerID)
        if (iOf !== -1) {
            const pax = passengers[iOf];
            return <Fragment>
                <td>{pax.lastname}</td>
                <td>{pax.firstname}</td>
            </Fragment>
        }
        else {
            return <tr>
                <td></td>
                <td></td>
            </tr>
        }
    }
    return <tr>
        <td><input checked={IsMarked} onChange={() => { OnRemove(index) }} className="" type="checkbox" /></td>
        {PassengerView()}
        <td>{bookingTypeCode} (<ServiceBookingTypeEnumDisplay type={bookingType} />)</td>
        <td>{serviceText}</td>
        <td>{GetSegmentOverview()}</td>
        <td><SharedPriceDisplay price={price} /></td>
        <td>{refundable ? refundable ? "Yes" : "No" : ""}</td>
        <td>{carrier}</td>
        <td><DateTimeDisplay date={notValidBefore} /></td>
        <td><DateTimeDisplay date={notValidAfter} /></td>
    </tr>

}

const PassengerDisplay: React.FC<{ id: BookingItemServicePassengerModel }> = ({ }) => {




    return <div className="card">

    </div>
}
const SegmentDisplay: React.FC<{ segments: PnrResponseSegmentModel[], segmentId: number }> = ({ segmentId, segments }) => {




    return <div className="card">

    </div>
}






