import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateDisplay } from 'components/Shared/DateDisplay';
import { useInfoClient } from 'hooks/useHttpClient';
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Alert, Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { State } from 'rootExports/rootReducer';
import { ChangelogMessageAffectEnum, ReleaseVersionInfo, VersionInformationModel } from 'WebApiClient';

export const AppInfoModal: React.FC<{ isOpen: boolean, Toggle: () => void }> = props => {
    const [fapimodulefilter, setfapimodulefilter] = useState("");
    const [infoError, setInfoError] = useState(false);
    const [infoModalLoading, setInfoModalLoading] = useState(false);
    const [infoResponse, setInfoResponse] = useState<VersionInformationModel | undefined>(undefined);
    const DeployInfo = useSelector((state: State) => state.Session.DeployInfo);
    // console.log("DeployInfo", DeployInfo);

    const infoClient = useInfoClient();

    function OnInfoRefresh() {
        setInfoModalLoading(true);
        infoClient.getVersion()
            .then(response => {
                setInfoResponse(response);
                setInfoError(false);
            })
            .catch(() => {
                setInfoError(true);
            })
            .finally(() => {
                setInfoModalLoading(false);
            })
    }

    useEffect(() => {
        OnInfoRefresh();
    }, [])

    return <Modal scrollable={true} size="xl" centered isOpen={props.isOpen} toggle={props.Toggle}>
        <ModalHeader toggle={props.Toggle}>AgentPlus App information</ModalHeader>
        <ModalBody>
            {infoModalLoading &&
                <div><FontAwesomeIcon icon={faSpinner} spin /> Loading App information...</div>
            }
            {infoError &&
                <div>An error has occured.</div>
            }
            {infoResponse &&
                <div className="row">
                    <div className="col-xl-6 col-md-12 mb-2">
                        <ReleaseNotesCard infoResponse={infoResponse} />
                    </div>
                    <div className="col-xl-6 col-md-12 mb-2">
                        <div className="row">
                            <div className="col-12 mb-2">
                                <EmailSignupCard />
                            </div>
                            <div className="col-12 mb-2">
                                <Card>
                                    <CardHeader className="card-header-primary">AgentPlus Information</CardHeader>
                                    <CardBody className="card-body">
                                        <table className='table table-sm table-striped table-hover'>
                                            <tbody>
                                                <tr>
                                                    <td>Frontend Environment</td>
                                                    <td>{DeployInfo?.DeployedEnvironment}</td>
                                                </tr>
                                                <tr>
                                                    <td>Frontend Version</td>
                                                    <td>{DeployInfo?.Version}</td>

                                                </tr>
                                                <tr>
                                                    <td>Backend Environment</td>
                                                    <td>{infoResponse.agentBackendEnvironment}</td>
                                                </tr>
                                                <tr>
                                                    <td>Backend Version</td>
                                                    <td>{infoResponse.agentBackendVersion}</td>
                                                </tr>
                                                <tr>
                                                    <td>Hash</td>
                                                    <td>{infoResponse.buildInfo.deployedGitCommitHashShort}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className="col-12">
                                <Card>
                                    <CardHeader className="card-header-primary">Flight API Information</CardHeader>
                                    <CardBody className="card-body">
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                Server: {infoResponse.flightApiServer}
                                            </div>
                                            <div className="col-12">
                                                <h5>Submodule details</h5>
                                                <div className="input-group input-group-sm mb-3">
                                                    <span className="input-group-text">Filter</span>

                                                    <input value={fapimodulefilter} onChange={(e) => setfapimodulefilter(e.target.value)} />
                                                </div>

                                                <table className='table table-sm table-hover table-striped'>
                                                    <thead>
                                                        <tr className='font-weight-bold'>
                                                            <th>Module</th>
                                                            <th>Major</th>
                                                            <th>Minor</th>
                                                            <th>Build</th>
                                                            <th>Revision</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {infoResponse.flightApiVersions?.filter(e => e.module?.toLowerCase().includes(fapimodulefilter.toLowerCase())).map((e, index) =>
                                                            <tr key={index}>
                                                                <td>
                                                                    {e.module ? e.module : "Unkown Module"}
                                                                </td>
                                                                <td>{e.major}</td>
                                                                <td>{e.minor}</td>
                                                                <td>{e.build}</td>
                                                                <td>{e.revision}</td>

                                                            </tr>

                                                        )}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>

                                    </CardBody>
                                </Card>

                            </div>
                        </div>

                    </div>
                </div>
            }
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={OnInfoRefresh}>Refresh</Button>
            <Button onClick={props.Toggle} color="primary">Ok</Button>
        </ModalFooter>
    </Modal>
}

const ReleaseNotesCard: React.FC<{ infoResponse: VersionInformationModel }> = ({ infoResponse }) => {
    // console.log("infoResponse", infoResponse);
    const infoClient = useInfoClient();
    const [moreReleaseNotes, setMoreReleaseNotes] = useState<ReleaseVersionInfo[]>([]);
    const [infoModalLoading, setInfoModalLoading] = useState(false);
    // console.log("infoModalLoading", infoModalLoading, moreReleaseNotes);
    const [moreResultsAmount, setMoreResultsAmount] = useState(5);
    const skip = infoResponse.latestReleaseInfo ? 1 : 0;

    function OnInfoRefresh() {
        setInfoModalLoading(true);
        infoClient.getReleaseInfos(undefined, skip, moreResultsAmount)
            .then(response => {
                setMoreReleaseNotes(response);
            })
            .catch(() => {
            })
            .finally(() => {
                setInfoModalLoading(false);
            })
    }

    useEffect(() => {
        OnInfoRefresh();
    },[])

    return <Card>
        <CardHeader className="card-header-primary">Release notes</CardHeader>
        <CardBody className="card-body-primary">
            <div className="row">

                {infoResponse.latestReleaseInfo ?
                    <div className="col-12 mb-2">
                        <ReleaseVersionCard infoResponse={infoResponse.latestReleaseInfo} />
                    </div> : <div className="col-12 mb-2">
                        No release notes for the current release yet.
                    </div>
                }
                <div className="col-12 mb-2">
                    <div className="content-divider-dark" />
                </div>
                <div className="col-12 mb-2">
                    <div className="input-group">
                        <span className="input-group-text">Show last...</span>

                        <input type="number" className="form-control" min={1} value={moreResultsAmount} onChange={(e) => { setMoreResultsAmount(parseInt(e.target.value)) }} />

                        <div className="input-group-append">
                            <button className="btn btn-sm btn-primary" onClick={OnInfoRefresh}>Search</button>
                        </div>
                    </div>

                </div>
                {infoModalLoading ?
                    <div className="col-12">
                        <div><FontAwesomeIcon icon={faSpinner} spin /> Loading...</div>
                    </div> :
                    <Fragment>
                        {moreReleaseNotes.map((r, i) =>

                            <div key={"mRN"+i} className="col-12 mb-2">
                                <ReleaseVersionCard infoResponse={r} />
                            </div>
                        )}

                    </Fragment>
                }
            </div>
        </CardBody>
    </Card>
}

const EmailSignupCard: React.FC<{}> = ({ }) => {
    const [emailInput, setEmailInput] = useState("");
    const [response, setResponse] = useState("");
    const infoClient = useInfoClient();

    function OnSendEmailSignup() {
        infoClient.sendSignupMail(emailInput).then(response => {
                setResponse("Mail sent.")
            })
            .catch(() => {
                setResponse("Mail could not be sent. Please check your email or try again later.")
            })
    }

    return <Card>
        <CardHeader className="card-header-primary"> Signup for automatic email updates</CardHeader>
        <CardBody className="card-body-primary">
            <div className="row">


                <div className="col-12 mb-2">
                    <p>Sign up with your email to receive automatic updates for general notifications or when a new version is released.</p>
                </div>
                <div className="col-12 mb-2">
                    <div className="input-group">
                        <input type="text" className="form-control" value={emailInput} placeholder="your@mail.com" onChange={(e) => { setEmailInput(e.target.value) }} />

                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={OnSendEmailSignup}>Sign up</button>
                        </div>
                    </div>
                </div>
                {response.length > 0 &&
                    <div className="col-12">
                        <Alert color="info">
                            {response}
                        </Alert>
                    </div>
                }
            </div>
        </CardBody>
    </Card>
}

const ReleaseVersionCard: React.FC<{ infoResponse: ReleaseVersionInfo }> = ({ infoResponse }) => {
    const generalReleaseNotes = infoResponse.releaseNotes.filter(e => e.affectsValue === ChangelogMessageAffectEnum.General);
    const agentReleaseNotes = infoResponse.releaseNotes.filter(e => e.affectsValue === ChangelogMessageAffectEnum.Agent);
    const adminReleaseNotes = infoResponse.releaseNotes.filter(e => e.affectsValue === ChangelogMessageAffectEnum.Admin);
    const RestAPIReleaseNotes = infoResponse.releaseNotes.filter(e => e.affectsValue === ChangelogMessageAffectEnum.RestAPI);
    return <Card>
        <CardHeader className="card-header-primary">{infoResponse.version}</CardHeader>
        <CardBody>
            Date: <DateDisplay date={infoResponse.date} />
            {generalReleaseNotes.length > 0 &&
                <Fragment>
                    <h4>General</h4>
                    <ul>
                        {generalReleaseNotes.map((m, i) =>
                            <li key={"gRN"+i}>{m.message}</li>
                        )}
                    </ul>
                </Fragment>
            }
            {agentReleaseNotes.length > 0 &&
                <Fragment>
                    <h4>Agent</h4>
                    <ul>
                        {agentReleaseNotes.map((m, i) =>
                            <li key={"arN"+i}>{m.message}</li>
                        )}
                    </ul>
                </Fragment>
            }
            {adminReleaseNotes.length > 0 &&
                <Fragment>
                    <h4>Admin</h4>
                    <ul>

                        {adminReleaseNotes.map((m, i) =>
                            <li key={"adRN"+i}>{m.message}</li>
                        )}
                    </ul>
                </Fragment>
            }
            {RestAPIReleaseNotes.length > 0 &&
                <Fragment>
                    <h4>RestAPI</h4>
                    <ul>

                        {RestAPIReleaseNotes.map((m, i) =>
                            <li key={"rRN"+i}>{m.message}</li>
                        )}
                    </ul>
                </Fragment>
            }
        </CardBody>
    </Card>
}