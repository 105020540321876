import { TariffTypeEnum } from "WebApiClient";

export function TariffTypeEnumDisplay(t: TariffTypeEnum): string {
    let display = "";
    switch (t) {
        case TariffTypeEnum.Consolidator:
            display = "Consolidator";
            break;
        case TariffTypeEnum.Touroperator:
            display = "Touroperator";
            break;
        case TariffTypeEnum.None:
            display = "None";
            break;
    }
    return display;
}
