import React from "react";
import { PnrResponseSKElementModel } from "WebApiClient";

export const PnrSksCard: React.FC<{ data: PnrResponseSKElementModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">SK <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">Carrier</th>
                            <th className="font-weight-bold">Text</th>
                            <th className="font-weight-bold">Keyword</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((osi, index) =>
                            <SkItem data={osi} key={"skItem_"+index} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );


}

const SkItem: React.FC<{ data: PnrResponseSKElementModel }> = ({ data }) => {
    const { carrier, text, keyword } = data;

    return (
        <tr>
            <td>{carrier}</td>
            <td>{text}</td>
            <td>{keyword}</td>

        </tr>
    );


}


