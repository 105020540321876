import React from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";


const style = {
    display: 'block'
}

const GeneralWaitScreen: React.FC<{ show?: boolean }> = ({ show }) => {
    const session = useSelector((state: State) => state.Session);

    return (
        <React.Fragment>
            {(session.ShowGeneralWaitScreen || show) &&
                <div>
                    <div id="generalWaitscreen" style={style}></div>
                    <div id="epic-overlay"></div>
                </div>
            }
        </React.Fragment>
    );
};


export default GeneralWaitScreen