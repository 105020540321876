import * as moment from "moment";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";

const timeFormat: string = 'HH:mm';

export function useDateFormat(): string {
    const dateFormat = useSelector((state: State) => state.Session.FrontendSettings.dateFormat!.toUpperCase());
    return dateFormat;
}

export function useLocalizedPrice(price: number, currency: string, multiplier: number | undefined): string {
    const resultPrice = multiplier !== undefined ? (price * multiplier) : price;
    const locale = useSelector((state: State) => state.Session.FrontendSettings.locale);
    const formatted: string = ` ${currency} ${resultPrice.toLocaleString(locale, { minimumFractionDigits: 2 })}`;
    return formatted;
}
export function useFormatDate(date: Date | undefined): string {
    const dateFormat = useDateFormat();
    const formatted: string = date ? `${moment(date).format(dateFormat)}` : "";
    return formatted;
}
export function useFormatDateTime(date: Date | undefined): string {
    const dateFormat = useDateFormat();
    const formatted: string = date ? `${moment(date).format(`${dateFormat} ${timeFormat}`)}` : "";
    return formatted;
}
export function useFormatTime(date: Date | undefined, showSeconds: boolean = false): string {
    const formatted: string = date ? `${moment(date).format(`${timeFormat}${showSeconds ? ":ss" : ""}`)}` : "";
    return formatted;
}


