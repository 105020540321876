import { faExternalLink, faTasksAlt } from "@fortawesome/pro-regular-svg-icons";
import { faHistory } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { Collapse } from "reactstrap";
import { AgentPlusBookingManagerFeature, BookingItemModel } from "WebApiClient";
import { BookingPnrCard } from "./Booking.PNRCard";
import VersionItem from "./Booking.VersionItem";
interface BookingDetailsProps {
  Booking: BookingItemModel | undefined;
  refresh?: () => void | any;
  OnSync?:any;
}

const BookingDetails: React.FC<BookingDetailsProps> = (props) => {
  const [historyCollapseOpen, setHistoryCollpaseOpen] = useState(false);
  const latestVersion = props.Booking?.latestVersion;

  function ToggleHistoryCollapse() {
    setHistoryCollpaseOpen(!historyCollapseOpen);
  }
  const [pnrSource, setPnrSource] = useState(false);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mb-2">
          <div className="card">
            <div className="card-body p-1">
              <span className="fw-bold me-2">Source</span>
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  onClick={() => {
                    setPnrSource(false);
                  }}
                  className={`btn ${
                    !pnrSource ? "btn-primary" : "btn-outline-secondary"
                  }  btn-sm`}
                >
                  <FontAwesomeIcon icon={faTasksAlt} /> Booking manager
                </button>
                {latestVersion?.allowedActions.includes(
                  AgentPlusBookingManagerFeature.RetrievePNR
                ) && (
                  <button
                    type="button"
                    onClick={() => {
                      setPnrSource(true);
                    }}
                    className={`btn ${
                      pnrSource ? "btn-primary" : "btn-outline-secondary"
                    } btn-sm`}
                  >
                    <FontAwesomeIcon icon={faExternalLink} /> Passenger name
                    record
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {pnrSource ? (
          <Fragment>
            <div className="col-12">
              <BookingPnrCard Identifier={latestVersion!.bookingIdentifier} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="col-12 mb-2">
              {latestVersion && (
                <React.Fragment>
                  <div className="row">
                    <div className="col">
                      <VersionItem Data={latestVersion} IsLatest={true} refresh={props.refresh} OnSync={props.OnSync}/>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="col-12">
              {props.Booking &&
                props.Booking.historyItems &&
                props.Booking.historyItems.length > 0 && (
                  <div className="row mb-2">
                    <div className="col-12">
                      <div className="card">
                        <div
                          className="card-header card-header-primary clickme"
                          onClick={ToggleHistoryCollapse}
                        >
                          <FontAwesomeIcon icon={faHistory} />{" "}
                          {props.Booking.historyItems!.length} earlier versions
                          of this booking
                        </div>
                        <Collapse isOpen={historyCollapseOpen}>
                          {historyCollapseOpen && (
                            <div className="card-body card-b">
                              {props.Booking.historyItems!.map(
                                (version, index) => (
                                  <VersionItem
                                    key={`bknvh${index}`}
                                    Data={version}
                                    IsLatest={false}
                                    refresh={props.refresh}
                                  />
                                )
                              )}
                            </div>
                          )}
                        </Collapse>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default BookingDetails;
