import { AgentApiURL, AgentHotelUrl } from "Constants";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import {
  ApiCredentialsOptions,
  AuthorizedServicesClient,
  AvailableFaresClient,
  BookingManagerClient,
  FaresDisplayClient,
  InfoClient,
  LoginClient,
  CustomerProfilesClient,
  ApiHotelCredentialsOptions,
  AvailableHotelsClient,
  PaymentDataSettingsClient,
  AdminFrontendServicesClient,
  ReportingClient,
} from "WebApiClient";

function useApiCredentials(): ApiCredentialsOptions {
  const jwtToken = useSelector((state: State) => state.Session.JwtToken);
  return new ApiCredentialsOptions(AgentApiURL, jwtToken);
}

export function useAvailableFaresClient(): AvailableFaresClient {
  const apiCredentials = useApiCredentials();
  const client = new AvailableFaresClient(apiCredentials);
  return client;
}

export function useReportingClient(): ReportingClient {
  const apiCredentials = useApiCredentials();
  const client = new ReportingClient(apiCredentials);
  return client;
}

export function useFareDisplayClient(): FaresDisplayClient {
  const apiCredentials = useApiCredentials();
  const client = new FaresDisplayClient(apiCredentials);
  return client;
}

export function usePaymentDataSettingsClient(): PaymentDataSettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new PaymentDataSettingsClient(apiCredentials);
  return client;
}

export function useBookingClient(): BookingManagerClient {
  const apiCredentials = useApiCredentials();
  const client = new BookingManagerClient(apiCredentials);
  return client;
}
export function useAuthorizedServicesClient(): AuthorizedServicesClient {
  const apiCredentials = useApiCredentials();
  const client = new AuthorizedServicesClient(apiCredentials);
  return client;
}
export function useInfoClient(): InfoClient {
  const apiCredentials = useApiCredentials();
  const client = new InfoClient(apiCredentials);
  return client;
}

export function useLoginClient(): LoginClient {
  const apiCredentials = useApiCredentials();
  const client = new LoginClient(apiCredentials);
  return client;
}

export function useCustomerProfilesClient(): CustomerProfilesClient {
  const apiCredentials = useApiCredentials();
  const client = new CustomerProfilesClient(apiCredentials);
  return client;
}

// ---------------Hotels-----------

function useHotelApiCredentials(): ApiHotelCredentialsOptions {
  const jwtToken = useSelector((state: State) => state.Session.JwtToken);
  return new ApiHotelCredentialsOptions(AgentHotelUrl, jwtToken);
}

export function useAvailableHotelsClient(): AvailableHotelsClient {
  const apiCredentials = useHotelApiCredentials();
  const client = new AvailableHotelsClient(apiCredentials);
  return client;
}

export function useAdminFrontendServicesClient(): AdminFrontendServicesClient {
  const apiCredentials = useApiCredentials();
  const client = new AdminFrontendServicesClient(apiCredentials);
  return client;
}
