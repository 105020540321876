import { ServiceBookingTypeEnumModel } from "WebApiClient";
export const ServiceBookingTypeEnumDisplay: React.FC<{ type: ServiceBookingTypeEnumModel }> = ({ type }) => {
    function GetLabel(): string {
        let result = "???";
        switch (type) {
            case ServiceBookingTypeEnumModel.None:
                result = "None";
                break;
            case ServiceBookingTypeEnumModel.SSR:
                result = "SSR";
                break;
            case ServiceBookingTypeEnumModel.SVC:
                result = "SVC";
                break;
            case ServiceBookingTypeEnumModel.Seat:
                result = "Seat";
                break;
            default:
                result = "???";
        }
        return result;
    }
    return <span>{GetLabel()}</span>
}