import { QueryFareTypeEnum } from "WebApiClient";

export function QueryFareTypeDisplay(t: QueryFareTypeEnum): string {
    let display = "";
    switch (t) {
        case QueryFareTypeEnum.CharterFare:
            display = "Charter";
            break;
        case QueryFareTypeEnum.CorpFare:
            display = "Corporate";
            break;
        case QueryFareTypeEnum.MixedFares:
            display = "Mixed";
            break;
        case QueryFareTypeEnum.NegoFare:
            display = "Negotiated";
            break;
        case QueryFareTypeEnum.NetFare:
            display = "Net";
            break;
        case QueryFareTypeEnum.PublishedFare:
            display = "Published";
            break;
        case QueryFareTypeEnum.Unknown:
            display = "Unknown";
            break;
        case QueryFareTypeEnum.WebFare:
            display = "Web";
            break;



    }
    return display;
}
