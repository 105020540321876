import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dropdownItem, IFareSearchInputState, rdcData } from "components/FareSearch/types/FareSearchTypes";
import React, { useEffect, useState } from "react";
import { DropdownList } from "react-widgets";
import { State } from "rootExports/rootReducer";
import { useSelector } from "react-redux";
import { faTags } from '@fortawesome/pro-regular-svg-icons';


type Settings = {
  landResident: boolean;
  generalLargeFamily: boolean;
  specialLargeFamily: boolean;
};

const SpecialDiscount: React.FC<OwnProps> = (props) => {
  const otherSettings: string = useSelector((state: State) => {
    const settings = state.Session.FrontendSettings.otherSettingsAsKeyValueText;
    return settings === null || settings === "menuPosition=L\nshowFareHeader=Off"
      ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
      : settings;
  });

  const BodyClass: string = props.IsModifySearch ? "" : "card-body card-body-secondary";
  const ContainerClass: string = props.IsModifySearch ? "" : "card h-100";
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<rdcData[]>([]);
  const [selectedItem, setSelectedItem] = useState<rdcData | null>(null);

  const rdcValues: rdcData[] = [
    { code: "RC", description: "Resident Canary Islands" },
    { code: "DC", description: "Between Canary Islands" },
    { code: "BP", description: "Balearic" },
    { code: "BI", description: "Between Balear Islands" },
    { code: "RM", description: "to/from Melilla" },
    { code: "RE", description: "to/from Malaga" }
  ];

  function OnSearch() {
    setItems(rdcValues);
  }

  const ListItem = ({ item }: { item: rdcData }) => (
    <span>
      <strong>{item.code} ({item.description})</strong>
    </span>
  );

  function OnIncludeUpdate(selectedItem: rdcData) {
    setSelectedItem(selectedItem);
    const newState: IFareSearchInputState = {
      ...props.State,
      discountCode: selectedItem.code,
    };
    props.Update(newState);
  }

  function OnUpdate() {
    setSelectedItem(selectedItem);
    const newState: IFareSearchInputState = {
      ...props.State,
      islandResident: settings.landResident,
      generalLargeFamily: settings.generalLargeFamily,
      specialLargeFamily: settings.specialLargeFamily,
    };
    props.Update(newState);
  }

  const [settings, setSettings] = useState({
    landResident: false,
    generalLargeFamily: false,
    specialLargeFamily: false,
  });

  const handleToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.currentTarget;
    const input = target.querySelector('input') as HTMLInputElement;
    if (input) {
      const { name } = input;
      // Use type assertion to inform TypeScript of the valid keys
      setSettings((prevSettings) => ({
        ...prevSettings,
        [name as keyof Settings]: !prevSettings[name as keyof Settings], // Toggle the current value
      }));
    }
  };

  useEffect(() => {
    OnUpdate()
  }, [settings.landResident, settings.generalLargeFamily, settings.specialLargeFamily])

  return (
    <React.Fragment>
      <div className="col-12 col-lg-6 mt-4">
        <div className={ContainerClass}>
          {!props.IsModifySearch &&
            <div className="card-header card-header-primary">Special Discount</div>
          }
          <div className={BodyClass}>
            <div className="input-group">
              <span className="input-group-text specialDiscount">
                <span className="fa-stack">
                  <FontAwesomeIcon icon={faTags} className="fa-stack-1x" />
                </span>
              </span>
              <span className="w-75">
                <DropdownList
                  containerClassName="form-control discountContainer"
                  onChange={OnIncludeUpdate}
                  value={selectedItem}
                  textField="code"
                  busy={isLoading}
                  filter={false}
                  data={items}
                  onClick={OnSearch}
                  renderListItem={ListItem}
                  placeholder="special discount"
                  dataKey="code"
                />
              </span>
            </div>
            <div className='d-flex mt-3'>
              <div
                className="switch switch-lg mt-1 me-1"
                onClick={handleToggle}
                role="button"
                aria-pressed={settings.landResident}
                tabIndex={0}
              >
                <input
                  type="checkbox"
                  className="switch-input"
                  name="landResident"
                  checked={settings.landResident}
                  readOnly
                />
                <label className="switch-label">Island Resident</label>
              </div>
              <div
                className="switch switch-lg mt-1 ms-3"
                onClick={handleToggle}
                role="button"
                aria-pressed={settings.generalLargeFamily}
                tabIndex={1}
              >
                <input
                  type="checkbox"
                  className="switch-input"
                  name="generalLargeFamily"
                  checked={settings.generalLargeFamily}
                  readOnly
                />
                <label className="switch-label">General Large Family</label>
              </div>
              <div
                className="switch switch-lg mt-1 ms-3"
                onClick={handleToggle}
                role="button"
                aria-pressed={settings.specialLargeFamily}
                tabIndex={2}
              >
                <input
                  type="checkbox"
                  className="switch-input"
                  name="specialLargeFamily"
                  checked={settings.specialLargeFamily}
                  readOnly
                />
                <label className="switch-label">Special Large Family</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

interface OwnProps {
  IsModifySearch: boolean;
  State: IFareSearchInputState;
  Update: (state: IFareSearchInputState) => void;
}

export default SpecialDiscount;
