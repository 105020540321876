
import { Adress } from './../../../WebApiClient';

// export interface FareBookingState {
//   Fare?: AvailableFareModel,
//   SelectedConnections?: number[],
//   Status: BookingProcessStatus,
//   ErrorMessages: string[],
//   SuccessInfo?: ShoppingCardResultInfo,
//   TicketGroupOptions: FareBookingTicketGroupOptions[],
//   BookTransactionID: string,
//   ValidUntil: Date
//   Address: Adress,
//   AddressBehavior: BookingAddressBehavior,
//   EmergencyPhoneBehavior: BookingAddressBehavior,
//   EmergencyPhone: string;
//   WebFarePasswordBehavior: BookingAddressBehavior,
//   WebFarePassword: string;
// }
export const defaultAdress: Adress = {
  firstName: "",
  lastName: "",
  title: "",
  street: "",
  zip: "",
  city: "",
  country: ""
}
// export const DefaultFareBookingState: FareBookingState = {
//   Status: BookingProcessStatus.UserInput,
//   ErrorMessages: [],
//   TicketGroupOptions: [],
//   BookTransactionID: "",
//   ValidUntil: new Date(),
//   Address: defaultAdress,
//   AddressBehavior: BookingAddressBehavior.EmptyUserInput,
//   EmergencyPhoneBehavior: BookingAddressBehavior.EmptyUserInput,
//   WebFarePasswordBehavior: BookingAddressBehavior.EmptyUserInput,
//   WebFarePassword: "",
//   EmergencyPhone: "",

// }

// export interface SetBookingActionPayload {
//   fare: AvailableFareModel,
//   selectedConnections: number[],
//   validUntil: Date,
//   addressBehavior: BookingAddressBehavior,
//   Address: Adress,
//   webfarePassword: string,
//   webfarePasswordBehavior: BookingAddressBehavior,

//   emergencyPhone: string,
//   emergencyPhoneBehavior: BookingAddressBehavior,


// }

// export interface AddPassengerAncillaryPayload {
//   PassengerIndex: number;
//   Ancillary: AncillarySegmentBookRequest
// }
