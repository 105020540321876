import Segment from "components/FareSearch/component/FlightDetails/FlightDetails.Segment";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvailableFareConnectionModel, SegmentAllotmentInformation } from "WebApiClient";


export const AllotmentModal: React.FC<{ connection: AvailableFareConnectionModel, isopen: boolean, toggle: () => void }> = props => {

    return <Modal centered isOpen={props.isopen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Allotment Info for connection</ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        {props.connection.segments?.map((seg, index) =>
                            <div className="col-12 mb-2" key={"props.connection.segments"+index}>
                                <div className="card">
                                    <div className="card-header">{seg.departureAirport?.iata} - {seg.arrivalAirport?.iata}</div>
                                    <div className="card-body">
                                        {seg.allotmentInformation ?
                                            <AllotmentInfoTable info={seg.allotmentInformation} segment={seg} /> : <p>No allotment information</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
}

export const AllotmentInfoTable: React.FC<{ info: SegmentAllotmentInformation, small?: boolean, segment: any }> = props => {
    const { affiliateCode, id, manatoryPrograms, matchCode, programs, remark, supplierField, types } = props.info;
    const segmentData = props.segment;
    return <table className={`table ${props.small !== undefined && props.small === true ? "table-sm" : ""}`}>
        <tbody>
            <tr>
                <td className="font-weight-bold">Type(s)</td>
                <td>{types.join(",")}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">ID</td>
                <td>{id}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Affiliate code</td>
                <td>{affiliateCode}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Mandatory programs</td>
                <td>{manatoryPrograms ? "Yes" : "No"}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Programs</td>
                <td>{programs.join(",")}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Match code</td>
                <td>{matchCode}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Remark</td>
                <td>{remark}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Supplier</td>
                <td>{supplierField}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Total seats</td>
                <td>{segmentData.totalSeats}</td>
            </tr>
            <tr>
                <td className="font-weight-bold">Available seats</td>
                <td>{segmentData.freeSeats}</td>
            </tr>
        </tbody>
    </table>
}