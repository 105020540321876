import { faAddressCard } from "@fortawesome/pro-light-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountrySelect from "components/Shared/CountrySelect";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import React, { Fragment } from "react";
import { AgentApisAdressType } from "WebApiClient";
import { FareBookingPassengerApisAddress } from '../../../WebApiClient';
import { ApisAddressValidResult } from "./APISInput.Validate.Adress";

export interface BookingPassengerApisAddressProps {
    Data: FareBookingPassengerApisAddress | undefined;
    IsValid: ApisAddressValidResult;
    OnUpdatePassenger: (data: FareBookingPassengerApisAddress | undefined) => void
}

const BookingPassengerApisAddress: React.FC<BookingPassengerApisAddressProps> = ({ Data, IsValid, OnUpdatePassenger }) => {

    function OnAddAddress() {
        let d: FareBookingPassengerApisAddress = {
            city: "",
            country: "",
            municipality: "",
            state: "",
            street: "",
            type: AgentApisAdressType.Residence,
            zip: ""

        }
        OnUpdatePassenger(d);
    }
    function OnRemoveAddress() {
        OnUpdatePassenger(undefined);
    }

    function OnStreetUpdate(value: string) {
        if (Data) {

            let data = { ...Data };
            data.street = value;
            OnUpdatePassenger(data);
        }
    }

    function OnZIPUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.zip = value;
            OnUpdatePassenger(data);
        }

    }

    function OnCityUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.city = value;
            OnUpdatePassenger(data);
        }

    }
    function OnStateUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.state = value;
            OnUpdatePassenger(data);
        }

    }

    function OnMunicipalityUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.municipality = value;
            OnUpdatePassenger(data);
        }

    }
    function OnCountryUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.country = value;
            OnUpdatePassenger(data);
        }

    }
    function OnTypeUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.type = parseInt(value);
            OnUpdatePassenger(data);
        }

    }


    function GetValidClassName(required: boolean, isValid: boolean): string {
        let result = "";
        if (required) {
            result = isValid ? "is-valid" : "is-invalid";
        }
        return result;
    }

    return (<React.Fragment>

        <div className="card mb-2">
            <div className="card-body">
                <h5 className="card-title"><ValidIndicatorIcon
                    IsValid={IsValid.Valid}
                    Icon={faAddressCard}
                    NoValueSet={Data === undefined}
                /> Address
                    {Data &&
                        <button className="btn btn-outline-danger btn-sm" onClick={OnRemoveAddress}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button>


                    }
                </h5>
                {Data &&
                    <Fragment>

                        <div className="row align-items-center mb-2">
                            <label className="col-12 col-md-4 col-lg-3 col-xl-2">Type{IsValid.AddressRequired ? "*" : ""}</label>
                            <div className="col-12 col-md col-lg-4">
                                <select value={Data.type}
                                    className={`form-select ${GetValidClassName(IsValid.AddressRequired, IsValid.Type)}`}
                                    onChange={(e) => OnTypeUpdate(e.target.value)}>
                                    <option value={AgentApisAdressType.Unknown}>- select -</option>
                                    <option value={AgentApisAdressType.Residence}>Residence</option>
                                    <option value={AgentApisAdressType.Destination}>Destination</option>
                                </select>
                            </div>
                        </div>
                        {Data.type !== AgentApisAdressType.Unknown &&
                            <React.Fragment>
                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - STREET - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                <div className="row align-items-center mb-2">
                                    <label className="col-12 col-md-4 col-lg-3 col-xl-2">Street{IsValid.AddressRequired ? "*" : ""}</label>
                                    <div className="col-12 col-md col-lg-4">
                                        <input
                                            value={Data.street ? Data.street : ""}
                                            onChange={(e) => OnStreetUpdate(e.target.value)}
                                            type="text"
                                            className={`form-control   ${GetValidClassName(IsValid.AddressRequired, IsValid.Street)}`}
                                        />
                                    </div>
                                </div>
                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // STREET - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ZIP - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                <div className="row align-items-center mb-2">
                                    <label className="col-12 col-md-4 col-lg-3 col-xl-2">Zip code{IsValid.AddressRequired ? "*" : ""}</label>
                                    <div className="col-12 col-md col-lg-4">
                                        <input
                                            value={Data.zip ? Data.zip : ""}
                                            onChange={(e) => OnZIPUpdate(e.target.value)}
                                            type="text"
                                            className={`form-control   ${GetValidClassName(IsValid.AddressRequired, IsValid.ZipCode)}`}
                                        />
                                    </div>
                                </div>
                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // ZIP - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CITY - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                <div className="row align-items-center mb-2">
                                    <label className="col-12 col-md-4 col-lg-3 col-xl-2">City{IsValid.AddressRequired ? "*" : ""}</label>
                                    <div className="col-12 col-md col-lg-4">
                                        <input
                                            value={Data.city ? Data.city : ""}
                                            onChange={(e) => OnCityUpdate(e.target.value)}
                                            type="text"
                                            className={`form-control   ${GetValidClassName(IsValid.AddressRequired, IsValid.City)}`}

                                        />
                                    </div>
                                </div>
                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // CITY - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - MUNICIPALITY- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                <div className="row align-items-center mb-2">
                                    <label className="col-12 col-md-4 col-lg-3 col-xl-2">Municipality{IsValid.MunicipalityRequired ? "*" : ""}</label>
                                    <div className="col-12 col-md col-lg-4">
                                        <input value={Data.municipality ? Data.municipality : ""} onChange={(e) => OnMunicipalityUpdate(e.target.value)} type="text" className={`form-control  ${GetValidClassName(IsValid.MunicipalityRequired, IsValid.Municipality)}`} />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2">
                                    <label className="col-12 col-md-4 col-lg-3 col-xl-2">State{IsValid.AddressRequired ? "*" : ""}</label>
                                    <div className="col-12 col-md col-lg-4">
                                        <input value={Data.state ? Data.state : ""} onChange={(e) => OnStateUpdate(e.target.value)} type="text" className={`form-control  ${GetValidClassName(IsValid.AddressRequired, IsValid.State)}`} />
                                    </div>
                                </div>
                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // MUNICIPALITY- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - COUNTRY- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                <div className="row align-items-center mb-2">
                                    <label className="col-12 col-md-4 col-lg-3 col-xl-2">Country{IsValid.AddressRequired ? "*" : ""}</label>
                                    <div className="col-12 col-md col-lg-4">
                                        <CountrySelect
                                            Value={Data.country}
                                            Onchange={OnCountryUpdate}
                                            Valid={IsValid.Country}
                                            ID={`addressc`}
                                            Required={IsValid.AddressRequired}
                                            disabled={false}
                                        />
                                    </div>
                                </div>

                            </React.Fragment>
                        }
                    </Fragment>
                }
                {!Data &&
                    <button className="btn btn-primary" onClick={OnAddAddress}>Add address</button>

                }


            </div>
        </div>
    </React.Fragment>
    );
}

export default BookingPassengerApisAddress

