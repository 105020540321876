import { AgentApisVisaType, FareAPISRequirements, FareBookingPassengerApisVisa } from "WebApiClient";

export interface ApisVisaValidResult {
    Valid: boolean;
    VisaInfoRequired: boolean;
    AppliedCountry: boolean;
    DateOfIssue: boolean;
    Number: boolean;
    PlaceOfIssue: boolean;

}
// const defaultApisPassportValid:ApisPassportValidResult = {
//     Valid:true,
//     FullPassportInfoRequired:false,
//     NumberRequired:false,
// }



export function PassengerVisaValid(visaInput: FareBookingPassengerApisVisa | undefined, apisRequirenments: FareAPISRequirements): ApisVisaValidResult {

    let visaTabValid: boolean = true;
    const visaRequired: boolean = visaInput ? visaInput.type !== AgentApisVisaType.Unknown : false;
    let countryValid: boolean = true;
    let dateOfIssueValid: boolean = true;
    let numberValid: boolean = true;
    let placeOfIssueValid: boolean = true;

    if (visaInput) {

        const countryRequired = visaRequired;
        countryValid = countryRequired ? (visaInput?.appliedCountry !== null && visaInput?.appliedCountry !== "") : true;
        if (!countryValid) {
            visaTabValid = false;
        }

        const dateOfIssue = visaRequired;
        dateOfIssueValid = dateOfIssue ? (visaInput?.dateOfIssue !== undefined && visaInput?.dateOfIssue !== null) : true;
        if (!dateOfIssueValid) {
            visaTabValid = false;
        }

        const numberRequired = visaRequired;
        numberValid = numberRequired ? (visaInput?.number !== null && visaInput?.number !== "") : true;
        if (!numberValid) {
            visaTabValid = false;
        }

        const placeOfIssueRequired = visaRequired;
        placeOfIssueValid = placeOfIssueRequired ? (visaInput?.placeOfIssue !== null && visaInput?.placeOfIssue !== "") : true;
        if (!placeOfIssueValid) {
            visaTabValid = false;
        }
    }
    else {
        if (visaRequired) {
            visaTabValid = false
        }
    }



    const result: ApisVisaValidResult = {
        Valid: visaTabValid,
        VisaInfoRequired: visaRequired,
        AppliedCountry: countryValid,
        DateOfIssue: dateOfIssueValid,
        Number: numberValid,
        PlaceOfIssue: placeOfIssueValid,



    }

    return result;
}