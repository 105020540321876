import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: [] }
export const airlineCodeSlice = createSlice({
    name: 'airlineCode',
    initialState,
    reducers: {
        airline_Code_Reducer(state, action) {
            state.value = action.payload
        },
    },
})

export const { airline_Code_Reducer } = airlineCodeSlice.actions