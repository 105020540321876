import {
  faArrowRight,
  faKeyboard,
  faRedo,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Airlines from "components/FareSearch/component/Airlines/Airlines";
import FlightDetails from "components/FareSearch/component/FlightDetails/FlightDetails";
import Passengers from "components/FareSearch/component/Passengers/Passengers";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { ValidationErrorMessage } from "components/Shared/ErrorMessage";
import {
  KeyBoardModal,
  KeyBoardShortcutDescription,
} from "components/Shared/KeyBoardModal";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import { useQuery } from "hooks/various";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import { State } from "rootExports/rootReducer";
import {
  Session_Reducer_HideCustomerWaitScreen,
  Session_Reducer_HideGeneralWaitScreen,
  Session_Reducer_PushTransaction,
  Session_Reducer_SetWatiScreenInfo,
  Session_Reducer_ShowCustomerWaitScreen,
  Session_Reducer_ShowGeneralWaitScreen,
} from "rootExports/SessionReducer";
import {
  AgentFrontendFareSearchOptions,
  AvailableFaresSearchRequest,
  CustomerProfileData,
  FareSearchSubmitApiResponse,
} from "WebApiClient";
import { GetInitialInput } from "../Business/GetInitialInput";
import { ValidateSearch } from "../Business/ValidateSearch";
import { IFareSearchInputState } from "../types/FareSearchTypes";
import { McreOverrideInput } from "./McreOverrideInput";
import { GetRequestData } from "./ParseInput";
import SpecialDiscount from "./SpecialDiscount/specialDiscount";
// import fareData from "./res";

interface FareSearchProps {
  IsModifySearch: boolean;
  OnSearchSubmitted: (response: FareSearchSubmitApiResponse) => void;
  LastSubmitted: AvailableFaresSearchRequest | undefined;
  OnReset: () => void;
  Config: AgentFrontendFareSearchOptions;
  Init: IFareSearchInputState;
  submitResult: FareSearchSubmitApiResponse | undefined;
  GoToResults?: () => void;
  isDisable: boolean
}

interface FareSearchShellProps {
  IsModifySearch: boolean;
  OnSearchSubmitted: (response: FareSearchSubmitApiResponse) => void;
  LastSubmitted: AvailableFaresSearchRequest | undefined;
  submitResult: FareSearchSubmitApiResponse | undefined;
  GoToResults?: () => void;
  OnReset: () => void;
  isDisable: boolean;
}

const FareSearchShell: React.FC<FareSearchShellProps> = (props) => {
  const [errorOccured, setErrorOccured] = useState(false);
  const [fetchingConfig, setFetchingConfig] = useState(false);
  const [configuration, setConfiguration] = useState<
    AgentFrontendFareSearchOptions | undefined
  >(undefined);
  const [initInput, setInitInput] = useState<IFareSearchInputState | undefined>(
    undefined
  );
  const availFareClient = useAvailableFaresClient();
  const submitResult = props.submitResult;
  const customerProfileData: CustomerProfileData | undefined = useSelector(
    (state: State) => state.Session.FrontendSettings.customerProfileData?.data
  );
  const query = useQuery();

  useEffect(() => {
    SetInitialInput();
  }, []);

  function SetInitialInput() {
    setFetchingConfig(true);
    availFareClient
      .getFareSearchConfiguration()
      .then((response) => {
        setConfiguration(response);
        const initialInput: IFareSearchInputState = GetInitialInput(
          response,
          props.LastSubmitted,
          customerProfileData,
          query
        );
        setInitInput(initialInput);
        setErrorOccured(false);
      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setFetchingConfig(false);
      });
    props.OnReset();
  }

  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode == 82 && e.ctrlKey) {
      e.preventDefault();
      SetInitialInput();
    }
  }

  return (
    <React.Fragment>
      {fetchingConfig ? (
        <div className="card-body">
          <WaitingCard />
        </div>
      ) : (
        <React.Fragment>
          {initInput && configuration && (
            <div onKeyDown={OnKeyDown}>
              <FareSearch
                GoToResults={props.GoToResults}
                IsModifySearch={props.IsModifySearch}
                LastSubmitted={props.LastSubmitted}
                OnSearchSubmitted={props.OnSearchSubmitted}
                OnReset={SetInitialInput}
                Config={configuration}
                Init={initInput}
                submitResult={submitResult}
                isDisable={props.isDisable}
              />
            </div>
          )}
          {errorOccured && (
            <div className="row">
              <div className="col-12">
                <ErrorDisplay data={undefined} />
              </div>
              <div className="col-12">
                <div className="d-grid">
                  <button className="btn btn-primary" onClick={SetInitialInput}>
                    Retry
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* <Fab /> */}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const FareSearch: React.FC<FareSearchProps> = (props) => {
  const DEBUG = false;
  const availFareClient = useAvailableFaresClient();
  const dispatch = useDispatch();
  const [errorOccured, setErrorOccured] = useState(false);
  const configuration = props.Config;
  const [isRuleHide, setIsRuleHide] = useState<boolean>(false);
  const [isSpecialDiscount, setIsSpecialDiscount] = useState<boolean>(false);
  const [input, setInput] = useState<IFareSearchInputState>(props.Init);
  const [keyBoardModalOpen, setKeyboardModalOpen] = useState(false);
  const submitResult = props.submitResult;


  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );




  function ToggleKeyBoardModal() {
    setKeyboardModalOpen(!keyBoardModalOpen);
  }
  const isValidSearch = ValidateSearch(input, configuration);
  function OnUpdate(state: IFareSearchInputState): void {
    setInput(state);
  }
  function OnSubmit() {
    if (isValidSearch) {
      if (DEBUG) {
        console.log("Submitting search...");
      }
      const requestData: AvailableFaresSearchRequest = GetRequestData(input);
      dispatch(Session_Reducer_ShowCustomerWaitScreen());
      dispatch(Session_Reducer_ShowGeneralWaitScreen());
      dispatch(
        Session_Reducer_SetWatiScreenInfo({
          Label: "Searching fares",
          Percentage: 25,
        })
      );

      availFareClient
        .searchAvailableFares(requestData)
        .then((response) => {
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          // dispatch(Session_Reducer_PushTransaction(fareData.responseMetaData));
          if (DEBUG) {
            console.log("Faresearch response received!");
          }
          props.OnSearchSubmitted(response);
          // props.OnSearchSubmitted(fareData);
        })
        .catch(() => {
          setErrorOccured(true);
        })
        .finally(() => {
          dispatch(Session_Reducer_HideCustomerWaitScreen());
          dispatch(Session_Reducer_HideGeneralWaitScreen());
        });
    } else {
      toast.error("Could not submit query. There are Invalid fields.");
    }
  }
  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode == 13) {
      OnSubmit();
    }
  }
  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None\n[]\nfalse\nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None\n[]\nfalse\nfalse\n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });
  let settingArr = otherSettings.split("\n");
  let fieldContainer: string[];
  if (settingArr[7]) {
    try {
      fieldContainer = JSON.parse(settingArr[7]).Container.split("#").filter((item: any) => item !== "");
    } catch (error) {
      // Provide a default value or error indicator
      fieldContainer = [];
    }
  } else {
    fieldContainer = [];
  }

  useEffect(() => {
    try {
      const isRuleHide = fieldContainer.includes("HIDERULES");
      const isSpecialDiscount = fieldContainer.includes("SPECIALDISCOUNT");
      setIsRuleHide(isRuleHide);
      setIsSpecialDiscount(isSpecialDiscount);
    } catch (error) {
      setIsRuleHide(false); // Handle parsing error
    }
  }, [fieldContainer]);

  return (
    <React.Fragment>
      <div
        style={{ position: "relative" }}
        className="card-body screen-query"
        onKeyDown={OnKeyDown}>
        <div>
          {(errorOccured ||
            props.submitResult?.responseMetaData?.errorOccured === true) && (
              <ErrorDisplay data={submitResult?.responseMetaData} />
            )}
          {submitResult?.result && (
            <React.Fragment>
              <ValidationErrorMessage
                error={!submitResult.result.validationResult.isValid}
                messages={submitResult.result.validationResult.errorSummary}
              />
            </React.Fragment>
          )}
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <Row>
                  <FlightDetails
                    UpdateState={OnUpdate}
                    IsModifySearch={false}
                    Input={input}
                    OnSubmit={OnSubmit}
                    isValidSearch={isValidSearch}
                  />
                </Row>
                <Row>
                  <Passengers
                    IsModifySearch={false}
                    Input={input}
                    IsAvailableFares={input!.AvailableFares}
                    UpdateState={OnUpdate}
                    Configuration={configuration}
                    setInput={setInput!}
                    isDisable={props.isDisable}
                  />
                  <Airlines
                    State={input}
                    Update={OnUpdate}
                    IsModifySearch={false}
                  />
                </Row>
                <Row className="">
                  {!isRuleHide ? (
                    <McreOverrideInput
                      IsModifySearch={false}
                      Input={input}
                      UpdateState={OnUpdate}
                    />
                  ) : null}

                  {isSpecialDiscount ?
                    <SpecialDiscount
                      State={input}
                      Update={OnUpdate}
                      IsModifySearch={false}
                    /> : null
                  }

                </Row>
              </div>
              <div className="col-12 mt-2">
                <button
                  className="btn  btn-cancel"
                  onClick={ToggleKeyBoardModal}
                >
                  <FontAwesomeIcon icon={faKeyboard} /> Shortcuts
                </button>
              </div>

            </div>
          </React.Fragment>

        </div>
        <div style={{ position: "absolute", bottom: "0", right: "15px" }}>
          <div className="btn-group btn-group-vertical">
            <button className="btn  btn-cancel mb-2" onClick={props.OnReset}>
              <FontAwesomeIcon icon={faRedo} /> Reset
            </button>
            {props.GoToResults && (
              <button
                className="btn  btn-cancel nowrap"
                onClick={props.GoToResults}
              >
                <FontAwesomeIcon icon={faArrowRight} /> Results
              </button>
            )}
          </div>
        </div>
      </div>
      <KeyBoardModal
        open={keyBoardModalOpen}
        toggle={ToggleKeyBoardModal}
        Shortcuts={shortcuts}
      />
    </React.Fragment>
  );
};

const shortcuts: KeyBoardShortcutDescription[] = [
  {
    Description: "Submit Query",
    Key: "strg+Enter",
  },
  {
    Description: "Submit Query",
    Key: "Enter",
  },
  {
    Description: "Reset Query",
    Key: "strg+R",
  },
];

export default FareSearchShell;
