import React from "react";
import { PnrResponseLocationModel } from "WebApiClient";

export const PnrLocationsCard: React.FC<{ data: PnrResponseLocationModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Locations <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">BranchID</th>
                            <th className="font-weight-bold">Queue</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((location, index) =>
                            <LocationItem key={"locI_"+index} data={location} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );


}

const LocationItem: React.FC<{ data: PnrResponseLocationModel }> = ({ data }) => {
    const { branchID, queue } = data;

    return (
        <tr>
            <td>{branchID.length > 0 ? branchID : "No data"}</td>
            <td>{queue.length > 0 ? queue : "No data"}</td>

        </tr>
    );


}


