
function LoadingSpinner() {
    return (
        <div className="loadingStyle">
            <div className="loader-container">
                <div className="loader"></div>
                <p>Loading hotel data...</p>
            </div>

        </div>
    )
}

export default LoadingSpinner;