import { BookingMangerPassengersTableCell } from "components/Shared/Helpers/BookingManagement/BookingPassengerHelpers";
import { BookingMangerSegmentsTableCell } from "components/Shared/Helpers/BookingManagement/BookingSegmentsHelpers";
import React from "react";
import { BookingItemPassengerModel, BookingManagerServiceInfoModel, PnrResponsePassengerModel, PnrResponseSegmentModel, PnrResponseSSRModel } from "WebApiClient";

interface ServicesCardProps {
    data: BookingManagerServiceInfoModel[],
    pnrPassengers?: PnrResponsePassengerModel[],
    bookingPassengers?: BookingItemPassengerModel[],
    segments: PnrResponseSegmentModel[]
}
export const BookingManagerServicesCard: React.FC<ServicesCardProps> = props => {

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Services <span className="badge badge-primary badge-pill align-top">{props.data.length}</span></div>
            <div className="card-body">
                <ServicesTable {...props} />
            </div>
        </div>
    );
}


export const ServicesTable: React.FC<ServicesCardProps> = ({ data, bookingPassengers, pnrPassengers, segments }) => {
    return <table className="table table-sm table-striped table-hover table-bordered">
        <thead>
            <tr>
                <th className="font-weight-bold">Code</th>
                <th className="font-weight-bold">Text</th>
                <th className="font-weight-bold">Carrier</th>
                <th className="font-weight-bold">Segments</th>
                <th className="font-weight-bold">Passengers</th>
            </tr>
        </thead>
        <tbody>
            {data.map((service, index) =>
                <tr key={"dataService" + index}>
                    <td>{service.code}</td>
                    <td>{service.text}</td>
                    <td>{service.carrier}</td>
                    <BookingMangerSegmentsTableCell numbers={service.segmentSelection} segments={segments} />
                    <BookingMangerPassengersTableCell numbers={service.passengerSelection} bookingItemPassengers={bookingPassengers} pnrPassengers={pnrPassengers} />
                </tr>
            )}
        </tbody>
    </table>
}

const SSRItem: React.FC<{ data: PnrResponseSSRModel }> = ({ data }) => {
    const { chargeable, code, segmentNumbers, status, text } = data;

    return (
        <tr>
            <td>{chargeable ? "Yes" : "No"}</td>
            <td>{code}</td>
            <td>{segmentNumbers.join(",")}</td>
            <td>{status}</td>
            <td>{text}</td>
        </tr>
    );


}