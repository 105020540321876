import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BookingManagerSalesReportRequestData } from "WebApiClient";
import { DefaultBookingManagerFilter } from "../types/BookingManagerTypes";
import BookingManagerFilter from "./BookingManager.Filter";

interface DownloadSalesReportModalProps {

  Toggle: () => void
  IsOpen: boolean

}
interface DateOption {
  Format: string,
  Example: string
}



const DateOptions: DateOption[] = [
  { Format: "yyyy-MM-dd", Example: "2015-05-29" },
  { Format: "dd.MM.yyyy", Example: "29.05.2015" },
  { Format: "dd-MM-yyyy", Example: "29-05-2015" },
  { Format: "dd/MM/yyyy", Example: "29/05/2015" },
  { Format: "MM/dd/yyyy", Example: "05/29/2015" },
  { Format: "MM-dd-yyyy", Example: "05-29-2015" },
  { Format: "MM.dd.yyyy", Example: "05.29.2015" },
  { Format: "dddd, dd MMMM yyyy", Example: "Friday, 29 May 2015" },
]
const DownloadSalesReportModal: React.FC<DownloadSalesReportModalProps> = props => {
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const defaultModel: BookingManagerSalesReportRequestData = { ...DefaultBookingManagerFilter, seperator: ",", dateFormat: DateOptions[0].Format };
  const [model, setModel] = useState<BookingManagerSalesReportRequestData>({ ...defaultModel });

  const [errorOccured, setErrorOccured] = useState(false);
  function OnSubmit() {


    toast.info("Your download will begin shortly. You can continue working in the meantime.");

    bookClient.downloadSalesReport(model)
      .then(response => {
        const fileName = `salesreport.csv`;
        const a = document.createElement('a');
        a.href = URL.createObjectURL(response!.data);
        a.download = fileName;
        a.click();
        a.remove();
        toast.success("Sales report saved to disk.");

      })
      .catch(() => {
        setErrorOccured(true);
        toast.error("Error downloading sales report.");

      })
      .finally(() => {
        setFetching(false);
      })


  }

  return (
    <React.Fragment>

      <Modal centered toggle={props.Toggle} isOpen={props.IsOpen} size="xl" scrollable={true} >
        <ModalHeader toggle={props.Toggle}>Download sales report</ModalHeader>
        <ModalBody>
          <div className="row">

            {fetching &&
              <div className="col-12">
                <WaitingCard />
              </div>
            }
            {!fetching &&
              <React.Fragment>
                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col-12 mb-2">
                      <div className="card card-primary">
                        <div className="card-header card-header-primary">CSV options</div>
                        <div className="card-body">

                          <div className="row">
                            <div className="col-md-4 col-xs-12 mb-2">
                              <div className="input-group">
                                <label className="input-group-text">Seperator</label>

                                <select className="form-select" onChange={(e) => { setModel({ ...model, seperator: e.target.value }) }}>

                                  <option value={","}>, (comma)</option>
                                  <option value={";"}>; (semicolon)</option>

                                </select>

                              </div>
                            </div>
                            <div className="col-md-8 col-xs-12">
                              <div className="input-group">
                                <label className="input-group-text">Date format</label>

                                <select className="form-select" onChange={(e) => { setModel({ ...model, dateFormat: e.target.value }) }}>
                                  {DateOptions.map((e, i) =>
                                    <option key={"dateOptions"+i} value={e.Format}>{e.Example}</option>
                                  )}


                                </select>

                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="card card-primary">
                        <div className="card-header card-header-primary">Booking filters</div>
                        <BookingManagerFilter Filter={model} OnSubmit={() => { OnSubmit() }} OnUpdate={(e) => { setModel({ ...e, seperator: model.seperator, dateFormat: model.dateFormat }) }} />

                      </div>

                    </div>
                  </div>
                </div>

              </React.Fragment>
            }

          </div>

        </ModalBody>

        <ModalFooter>

          <Button color="primary" onClick={() => { OnSubmit() }}><FontAwesomeIcon icon={faDownload} /> Download report</Button>
          <Button color="primary" onClick={props.Toggle}>close</Button>
        </ModalFooter>


      </Modal>

    </React.Fragment>
  );


}













export default DownloadSalesReportModal



