import { faPlaneArrival, faPlaneDeparture } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PnrResponseSegmentModel } from "WebApiClient";

export function GetBookingSegementByID(id: number, segments: PnrResponseSegmentModel[]): PnrResponseSegmentModel | undefined {
    let filter = segments.filter(e => e.segmentNumber === id)[0];
    return filter;
}

export const BookingMangerSegmentsTableCell: React.FC<{ active?: any, numbers: number[], segments: PnrResponseSegmentModel[] }> = ({ active, numbers, segments }) => {
    return <td className={active ? "activeTR" : ""}>
        {numbers.length === 0 ?
            <span>All segments</span> :
            <span>{numbers.map((pN, i) => {
                const segment = GetBookingSegementByID(pN, segments)
                return <span key={"fontI" + i}>
                    {segment ?
                        <span><FontAwesomeIcon icon={faPlaneDeparture} />{segment.departure.iata} {"->"} <FontAwesomeIcon icon={faPlaneArrival} />{segment.arrival.iata}</span> : <span>Segment "{pN}"</span>
                    }
                    <br /></span>
            }
            )}
            </span>
        }
    </td>
}

export const BookingMangerSegmentsTableData = (numbers: any, segments: any) => {
    return numbers.map((pN: any, i: any) => {
        const segment = GetBookingSegementByID(pN, segments);
        if (segment) {
            return {
                departureIata: segment?.departure.iata,
                arrivalIata: segment?.arrival.iata
            }
        } else {
            let data = { pN }
            return data
        }
    }
    )
}