import { useAvailableFaresClient } from "hooks/useHttpClient";
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FareIdentifier } from "WebApiClient";


interface ShareFareModalProps {
    IsOpen: boolean;
    Toggle: () => void;
    Identifier: FareIdentifier
}
const ShareFareModal: React.FC<ShareFareModalProps> = props => {
    const UseFaresClient = useAvailableFaresClient();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    function OnSubmit() {
        setLoading(true);
        // let identitifer: ShareFarePerMailRequest = { ...props.Identifier, email: "" };
        // UseFaresClient.shareFarePerEmail(identitifer)
        //     .then(res => { alert("Mail send") })
        //     .catch(() => { })
        //     .finally(() => { setLoading(false) });
    }
    return (
        <Modal size="lg" isOpen={props.IsOpen} toggle={props.Toggle} centered>
            <ModalHeader toggle={props.Toggle}>Share fare</ModalHeader>
            <ModalBody>
                <div className="form-row">
                    <div className="col-2">
                        <label>Email</label>
                    </div>
                    <div className="col-2">
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="email" />
                    </div>
                    <div className="col-2">
                        <button className="btn btn-primary" onClick={OnSubmit} disabled={loading}>Share</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};


export default ShareFareModal;