import React from "react";
import {
  AgentGenderEnum,
  GetPnrResult,
  RemarkTypeModelEnum,
  ServiceBookingTypeEnumModel,
  SingleBookingContactPhoneType,
  SingleBookingContactType,
} from "WebApiClient";
import { BookingPnrCardData } from "./Pnr";

const PnrMockData1: GetPnrResult = {
  skElements: [{ carrier: "LH", keyword: "ABC", text: "Hello bla" }],
  contacts: [
    {
      additionalStreet1: "Street 1",
      additionalStreet2: "Street 2",
      city: "FFH",
      companyName: "HitchHiker",
      contactID: "1",
      contactType: SingleBookingContactType.Contact,
      country: "Germany",
      countryCode: "DE",
      firstName: "Daniel",
      gender: AgentGenderEnum.Male,
      id: 1,
      idCardNumber: "1",
      idCardType: "ID",
      lastName: "Keuper",
      password: "PASSWORD",
      region: "HESSEN",
      street: "Berner Straße 44",
      title: "MR",
      zipCode: "555XXX",
      phoneNumbers: [
        {
          countryCode: "DE",
          area: "+49",
          number: "123234232",
          type: SingleBookingContactPhoneType.Mobile,
        },
        {
          countryCode: "DE",
          area: "+49",
          number: "123234232",
          type: SingleBookingContactPhoneType.Mobile,
        },
        {
          countryCode: "DE",
          area: "+49",
          number: "123234232",
          type: SingleBookingContactPhoneType.Mobile,
        },
        {
          countryCode: "DE",
          area: "+49",
          number: "123234232",
          type: SingleBookingContactPhoneType.Mobile,
        },
      ],
    },
  ],
  enhancedRemarks: [
    {
      passengerSelection: [],
      remark: "TEST REMARK",
      remarkType: RemarkTypeModelEnum.General,
      segmentSelection: [],
      typeCode: "XXX",
    },
  ],
  infos: [
    {
      bookingStatus: "STATUS",
      createdDate: new Date(),
      crs: "1A",
      passengerNameRecordType: "TYPE",
      pnrType: "PNRTYPE",
      recordLocator: "ABCD12",
      session: "SESSION",
      unknownPNRType: "UNKOWN",
    },
  ],

  locations: [{ branchID: "BRANCHID", queue: "asadsaf4wrt43fgdssfd" }],

  osiElements: [{ carrier: "LH", text: "DSDSFC DFDSFSW" }],
  passengers: [
    {
      age: 30,
      firstName: "Daniel",
      assistanceRequired: true,
      customerNameData: "NAME_DATA",
      title: "Mr.",
      dateOfBirth: new Date(),
      equivalentPrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      equivalentTax: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      foid: {
        carrierCode: "LH",
        issuer: "ISSUER",
        number: "ABCDE123",
        type: "TYPE",
      },
      frequentFlyerAirline: "LH",
      frequentFlyerNumber: "yy",
      name: "",
      nameRemark: "",
      originalPassengerNumber: "0",
      osi: [
        { carrier: "LH", text: "HELLO" },
        { carrier: "LH", text: "HELLO" },
        { carrier: "LH", text: "HELLO" },
        { carrier: "LH", text: "HELLO" },
        { carrier: "LH", text: "HELLO" },
      ],
      passengerNumber: 1,
      passengerType: "ADT",
      passengerTypeCode: "ADT",
      passengerTypeName: "ADULT",
      price: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      remarks: ["R1", "R2", "R3", "R4", "R5", "R5", "R6", "R7", "R8"],
      seatPreferences: [
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
        {
          characteristics: "Extra pay",
          chargeable: true,
          isIssued: true,
          preference: "CHAR",
          row: "1",
          seat: "1",
          segmentNumber: [1],
          status: "CONFIRMED",
        },
      ],
      skElements: [
        { code: "ABC", segmentNumbers: [1], text: "SKMSG" },
        { code: "ABC", segmentNumbers: [1], text: "SKMSG" },
        { code: "ABC", segmentNumbers: [1], text: "SKMSG" },
        { code: "ABC", segmentNumbers: [1], text: "SKMSG" },
        { code: "ABC", segmentNumbers: [1], text: "SKMSG" },
        { code: "ABC", segmentNumbers: [1], text: "SKMSG" },
        { code: "ABC", segmentNumbers: [1], text: "SKMSG" },
      ],
      specialServiceRequests: [
        {
          chargeable: true,
          code: "ABC",
          passengerNumbers: [1],
          segmentNumbers: [1],
          status: "COBFIRMED",
          text: "MEAL",
          id: "",
        },
        {
          chargeable: true,
          code: "ABC",
          passengerNumbers: [1],
          segmentNumbers: [1],
          status: "COBFIRMED",
          text: "MEAL",
          id: "",
        },
        {
          chargeable: true,
          code: "ABC",
          passengerNumbers: [1],
          segmentNumbers: [1],
          status: "COBFIRMED",
          text: "MEAL",
          id: "",
        },
        {
          chargeable: true,
          code: "ABC",
          passengerNumbers: [1],
          segmentNumbers: [1],
          status: "COBFIRMED",
          text: "MEAL",
          id: "",
        },
        {
          chargeable: true,
          code: "ABC",
          passengerNumbers: [1],
          segmentNumbers: [1],
          status: "COBFIRMED",
          text: "MEAL",
          id: "",
        },
        {
          chargeable: true,
          code: "ABC",
          passengerNumbers: [1],
          segmentNumbers: [1],
          status: "COBFIRMED",
          text: "MEAL",
          id: "",
        },
        {
          chargeable: true,
          code: "ABC",
          passengerNumbers: [1],
          segmentNumbers: [1],
          status: "COBFIRMED",
          text: "MEAL",
          id: "",
        },
      ],
      tax: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      ticketNumbers: [
        {
          segmentNumbers: ["1"],
          isEMD: true,
          platingCarrier: "LH",
          ticketStatus: "TICKETED",
          ticketnumber: "sdadasdasdcacasca",
        },
        {
          segmentNumbers: ["1"],
          isEMD: true,
          platingCarrier: "LH",
          ticketStatus: "TICKETED",
          ticketnumber: "sdadasdasdcacasca",
        },
        {
          segmentNumbers: ["1"],
          isEMD: true,
          platingCarrier: "LH",
          ticketStatus: "TICKETED",
          ticketnumber: "sdadasdasdcacasca",
        },
        {
          segmentNumbers: ["1"],
          isEMD: true,
          platingCarrier: "LH",
          ticketStatus: "TICKETED",
          ticketnumber: "sdadasdasdcacasca",
        },
        {
          segmentNumbers: ["1"],
          isEMD: true,
          platingCarrier: "LH",
          ticketStatus: "TICKETED",
          ticketnumber: "sdadasdasdcacasca",
        },
        {
          segmentNumbers: ["1"],
          isEMD: true,
          platingCarrier: "LH",
          ticketStatus: "TICKETED",
          ticketnumber: "sdadasdasdcacasca",
        },
        {
          segmentNumbers: ["1"],
          isEMD: true,
          platingCarrier: "LH",
          ticketStatus: "TICKETED",
          ticketnumber: "sdadasdasdcacasca",
        },
      ],
    },
  ],
  payments: [
    { passengerNumbers: [1], paymentText: "PAYMENT_TEXT", segmentNumbers: [1] },
    { passengerNumbers: [1], paymentText: "PAYMENT_TEXT", segmentNumbers: [1] },
    { passengerNumbers: [1], paymentText: "PAYMENT_TEXT", segmentNumbers: [1] },
    { passengerNumbers: [1], paymentText: "PAYMENT_TEXT", segmentNumbers: [1] },
    { passengerNumbers: [1], paymentText: "PAYMENT_TEXT", segmentNumbers: [1] },
  ],
  phones: [
    { number: "23243wedqer", type: "PHONE" },
    { number: "23243wedqer", type: "PHONE" },
    { number: "23243wedqer", type: "PHONE" },
    { number: "23243wedqer", type: "PHONE" },
    { number: "23243wedqer", type: "PHONE" },
    { number: "23243wedqer", type: "PHONE" },
  ],

  segments: [
    {
      airline: {
        code: "AF",
        hyperlink: "http://www.airfrance.fr",
        hint: "Air France",
        allianceCode: "*S",
        allianceName: "Skyteam",
      },
      airlineRecordLocator: "",
      allotment: undefined,
      arrival: {
        iataCode: "CDGA",
        iata: "CDG",
        code: "A",
        name: "Charles De Gaulle Intl Arpt",
        cityCode: "PAR",
        cityName: "Paris",
        countryCode: "FR",
        countryName: "FRANCE",
        stateCode: "          ",
        stateName: "",
        continentCode: "EU",
        continentName: "Europe",
        icao: "",
        areaCode: 427,
        longitude: "+002.5477",
        latitude: "+049.0151",
      },
      arrivalDate: new Date(),
      arrivalTerminal: "",
      departureTerminal:"",
      bookStatus: "",
      bookingClass: "L",
      brandCode: "LIGHT2",
      brandName: "LIGHT2",
      cabinClass: "",
      checkInTime: new Date(),
      departure: {
        iataCode: "FRAA",
        iata: "FRA",
        code: "A",
        name: "Frankfurt International Airport",
        cityCode: "FRA",
        cityName: "Frankfurt",
        countryCode: "DE",
        countryName: "GERMANY",
        stateCode: "          ",
        stateName: "",
        continentCode: "EU",
        continentName: "Europe",
        icao: "",
        areaCode: 429,
        longitude: "+008.5743",
        latitude: "+050.0493",
      },
      departureDate: new Date(),
      equipmentName: "",
      fareTimeLimit: "",
      fareType: "0",
      flightNumber: "1519",
      includedServiceIDs: [],
      isAllotment: false,
      isElectronicTicketingPermitted: false,
      mealCode: "",
      mileages: "",
      operatingCarrier: "",
      operatingCarrierCode: "",
      originalSegmentNumber: undefined,
      segmentNumber: 1,
      segmentStatus: "HK",
      allotmentCategory: undefined,
      allotmentID: "",
      bookingItemID: "9a29ee5e-0c0f-416e-9beb-1219c6a5a7c0",
      cartID: "41bf36c6-6aed-470e-bb94-ac8d08081381",
      codeShare: "",
      corporateCode: "",
      extensions: "",
      farebase: "XL53MIL4",
      fareCalculation: "",
      fareSelectionCurrency: "",
      freeBaggage: "",
      isRoundTrip: false,
      numberOfStops: 0,
      requestedCurrency: "",
      secureDataNeeded: 0,
      subFareType: 0,
      travelTime: "",
    },
  ],
  services: [
    {
      carrierCode: "LH",
      chargeable: true,
      isIssued: true,
      passengerNumbers: [1],
      segmentNumbers: [1],
      serviceCode: "ABAG",
      serviceID: "123",
      serviceText: "BAGGAGE ABC",
      status: "BOOKED",
    },
    {
      carrierCode: "LH",
      chargeable: true,
      isIssued: true,
      passengerNumbers: [1],
      segmentNumbers: [1],
      serviceCode: "ABAG",
      serviceID: "123",
      serviceText: "BAGGAGE ABC",
      status: "BOOKED",
    },
    {
      carrierCode: "LH",
      chargeable: true,
      isIssued: true,
      passengerNumbers: [1],
      segmentNumbers: [1],
      serviceCode: "ABAG",
      serviceID: "123",
      serviceText: "BAGGAGE ABC",
      status: "BOOKED",
    },
    {
      carrierCode: "LH",
      chargeable: true,
      isIssued: true,
      passengerNumbers: [1],
      segmentNumbers: [1],
      serviceCode: "ABAG",
      serviceID: "123",
      serviceText: "BAGGAGE ABC",
      status: "BOOKED",
    },
  ],

  specialDiscounts: [
    {
      code: "ABC",
      text: "DISCOUNT",
      discountID: "123",
      passengerSelections: [1],
      segmentSelections: [1],
    },
    {
      code: "ABC",
      text: "DISCOUNT",
      discountID: "123",
      passengerSelections: [1],
      segmentSelections: [1],
    },
    {
      code: "ABC",
      text: "DISCOUNT",
      discountID: "123",
      passengerSelections: [1],
      segmentSelections: [1],
    },
    {
      code: "ABC",
      text: "DISCOUNT",
      discountID: "123",
      passengerSelections: [1],
      segmentSelections: [1],
    },
    {
      code: "ABC",
      text: "DISCOUNT",
      discountID: "123",
      passengerSelections: [1],
      segmentSelections: [1],
    },
  ],
  ssrElements: [
    {
      chargeable: true,
      code: "ABC",
      passengerNumbers: [1],
      segmentNumbers: [1],
      status: "COBFIRMED",
      text: "MEAL",
      id: "",
    },
  ],
  storedFares: [
    {
      status: "TICKET",
      number: 1,
      passengerNumber: [1],
      fareDetails: {
        baseFare: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        equivalentBaseFare: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        fareCalculation: "XXXABC",
        fareIssueID: "ASASA",
        grandTotal: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        totalAmount: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        totalFee: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        totalTax: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
      },
      lastTicketingDate: new Date(),
      lastTicketingDateSpecified: true,
      numberSpecified: true,
      storedFareCreationDate: new Date(),
      storedFareCreationDateSpecified: true,
      taxes: [
        {
          status: "STATUS",
          price: {
            currencyCode: "EUR",
            valueSpecified: true,
            value: 30,
            isIATA: false,
            isIATASpecified: true,
          },
          action: "ACTION",
          actionSpecified: true,
          type: "TYPE",
        },
      ],
      ticketingDetails: {
        commission: "COMMISION",
        endorsment: "ENDORSEMENT",
        formOfPayment: "FOP",
        originalIssuedDetails: "ISSUER_DETAILS",
        tourcode: "TOURCODE",
        validatingCarrier: "CARRIER",
      },
      ticketingSegments: [
        {
          numberSpecified: true,
          number: 1,
          designator: "DESIGNATOR",
          fareBase: "FAREBASE",
          freeBaggageAllowance: "BG ALLOW",
          nva: "NVA",
          nvb: "NVB",
          originalNumber: 1,
          originalNumberSpecified: true,
          stopIndicator: "STOP",
          ticketStatus: "TKT_STATUS",
        },
      ],
    },
    {
      status: "TICKET",
      number: 1,
      passengerNumber: [1],
      fareDetails: {
        baseFare: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        equivalentBaseFare: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        fareCalculation: "XXXABC",
        fareIssueID: "ASASA",
        grandTotal: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        totalAmount: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        totalFee: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
        totalTax: {
          currencyCode: "EUR",
          valueSpecified: true,
          value: 30,
          isIATA: false,
          isIATASpecified: true,
        },
      },
      lastTicketingDate: new Date(),
      lastTicketingDateSpecified: true,
      numberSpecified: true,
      storedFareCreationDate: new Date(),
      storedFareCreationDateSpecified: true,
      taxes: [
        {
          status: "STATUS",
          price: {
            currencyCode: "EUR",
            valueSpecified: true,
            value: 30,
            isIATA: false,
            isIATASpecified: true,
          },
          action: "ACTION",
          actionSpecified: true,
          type: "TYPE",
        },
      ],
      ticketingDetails: {
        commission: "COMMISION",
        endorsment: "ENDORSEMENT",
        formOfPayment: "FOP",
        originalIssuedDetails: "ISSUER_DETAILS",
        tourcode: "TOURCODE",
        validatingCarrier: "CARRIER",
      },
      ticketingSegments: [
        {
          numberSpecified: true,
          number: 1,
          designator: "DESIGNATOR",
          fareBase: "FAREBASE",
          freeBaggageAllowance: "BG ALLOW",
          nva: "NVA",
          nvb: "NVB",
          originalNumber: 1,
          originalNumberSpecified: true,
          stopIndicator: "STOP",
          ticketStatus: "TKT_STATUS",
        },
      ],
    },
  ],
  storedServices: [
    {
      fareCalculation: "CALC",
      passengerNumber: 1,
      serviceID: "SID",
      serviceCode: "SCODE",
      platingCarrier: "LH",
      coupons: [
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
      ],
      documentType: "DOC-TYPE",
      emdRequired: true,
      equivalentServicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      extensions: "EX",
      lastEMDDate: new Date(),
      serviceCreationDate: new Date(),
      servicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
    },
    {
      fareCalculation: "CALC",
      passengerNumber: 1,
      serviceID: "SID",
      serviceCode: "SCODE",
      platingCarrier: "LH",
      coupons: [
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
        {
          carrier: "LH",
          extensions: "EXT",
          lastEMDDate: new Date(),
          notValidAfter: new Date(),
          notValidBefore: new Date(),
          refundable: true,
          segmentNumber: 1,
          serviceCode: "ABAG",
          serviceText: "BAGAGE",
          bookingType: ServiceBookingTypeEnumModel.SSR,
          bookingTypeCode: "ABAG",
          bookingTypeID: "TYPE_ID",
          bookingTypeText: "BOOKING_TYPE_TEXT",
          exchangeable: true,
        },
      ],
      documentType: "DOC-TYPE",
      emdRequired: true,
      equivalentServicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      extensions: "EX",
      lastEMDDate: new Date(),
      serviceCreationDate: new Date(),
      servicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
    },
  ],
  ticketInfo: [
    {
      baseFare: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      fareCalculation: "FARE_CALC",
      dateOfIssuse: new Date(),
      dateOfIssuseSpecified: true,
      documentInfo: [
        {
          arrival: "ARR",
          baggage: "BAG",
          bookingClass: "B_CLASS",
          company: "COMPANY",
          couponNumber: "COUP_NO",
          couponStatus: "COUP_STATUS",
          departure: "FRA",
          documentDetails: "DETAILS",
          fareBase: "FARE_BASE",
          flightDate: new Date(),
          flightDateSpecified: true,
          flightInfo: "FLIGHT_INFO",
          nva: "NVA",
          nvb: "NVB",
          stopIndicator: "STOP_IND",
        },
        {
          arrival: "ARR",
          baggage: "BAG",
          bookingClass: "B_CLASS",
          company: "COMPANY",
          couponNumber: "COUP_NO",
          couponStatus: "COUP_STATUS",
          departure: "FRA",
          documentDetails: "DETAILS",
          fareBase: "FARE_BASE",
          flightDate: new Date(),
          flightDateSpecified: true,
          flightInfo: "FLIGHT_INFO",
          nva: "NVA",
          nvb: "NVB",
          stopIndicator: "STOP_IND",
        },
        {
          arrival: "ARR",
          baggage: "BAG",
          bookingClass: "B_CLASS",
          company: "COMPANY",
          couponNumber: "COUP_NO",
          couponStatus: "COUP_STATUS",
          departure: "FRA",
          documentDetails: "DETAILS",
          fareBase: "FARE_BASE",
          flightDate: new Date(),
          flightDateSpecified: true,
          flightInfo: "FLIGHT_INFO",
          nva: "NVA",
          nvb: "NVB",
          stopIndicator: "STOP_IND",
        },
        {
          arrival: "ARR",
          baggage: "BAG",
          bookingClass: "B_CLASS",
          company: "COMPANY",
          couponNumber: "COUP_NO",
          couponStatus: "COUP_STATUS",
          departure: "FRA",
          documentDetails: "DETAILS",
          fareBase: "FARE_BASE",
          flightDate: new Date(),
          flightDateSpecified: true,
          flightInfo: "FLIGHT_INFO",
          nva: "NVA",
          nvb: "NVB",
          stopIndicator: "STOP_IND",
        },
        {
          arrival: "ARR",
          baggage: "BAG",
          bookingClass: "B_CLASS",
          company: "COMPANY",
          couponNumber: "COUP_NO",
          couponStatus: "COUP_STATUS",
          departure: "FRA",
          documentDetails: "DETAILS",
          fareBase: "FARE_BASE",
          flightDate: new Date(),
          flightDateSpecified: true,
          flightInfo: "FLIGHT_INFO",
          nva: "NVA",
          nvb: "NVB",
          stopIndicator: "STOP_IND",
        },
      ],
      equivalentBaseFare: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      firstName: "DANIEL",
      formOfPaymentAmount: "AMOUNT",
      formOfPaymentType: "TYPE",
      grandTotal: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      iataNumber: "IATA_NO",
      lastName: "KEUPER",
      pointOfIssue: "POINT_ISSUE",
      taxes: [
        {
          action: "ACT",
          actionSpecified: true,
          price: {
            currencyCode: "EUR",
            valueSpecified: true,
            value: 30,
            isIATA: false,
            isIATASpecified: true,
          },
          type: "TYPE",
        },
      ],
      ticketStatus: "TKT_STATUS",
      totalAmount: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      totalFee: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
    },
  ],
  ticketedSevices: [
    {
      associatedTicketIDs: ["1"],
      coupons: [
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
      ],
      dateOfIssue: new Date(),
      documentNumber: "DOC_NO",
      equivalentServicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      fareCalculation: "FARE_CALC",
      formOfPayment: "FOP",
      iataNumber: "IATA_NO",
      passengerID: "1",
      platingCarrier: "LH",
      pointOfIssue: "POINT_ISSUE",
      servicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      serviceText: "SERVICE_TEXT",
      status: "SERVICE_STATUSS",
    },
    {
      associatedTicketIDs: ["1"],
      coupons: [
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
        {
          arrival: "FRA",
          carrier: "LH",
          couponStatus: "COUP_STAT",
          departure: "LON",
          documentNumber: "DOC_NO",
          documentType: "DOC_TYPE",
          extensions: "EXTEN",
          notValidAfter: new Date(),
          serviceText: "SERVICE_TEXT",
          notValidBefore: new Date(),
          refundable: true,
          serviceCode: "SERVICE_CODE",
        },
      ],
      dateOfIssue: new Date(),
      documentNumber: "DOC_NO",
      equivalentServicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      fareCalculation: "FARE_CALC",
      formOfPayment: "FOP",
      iataNumber: "IATA_NO",
      passengerID: "1",
      platingCarrier: "LH",
      pointOfIssue: "POINT_ISSUE",
      servicePrice: {
        currencyCode: "EUR",
        valueSpecified: true,
        value: 30,
        isIATA: false,
        isIATASpecified: true,
      },
      serviceText: "SERVICE_TEXT",
      status: "SERVICE_STATUSS",
    },
  ],

  ticketingDates: [
    { ticketingDate: new Date(), ticketingDateType: "TICKETED" },
    { ticketingDate: new Date(), ticketingDateType: "TICKETED" },
    { ticketingDate: new Date(), ticketingDateType: "TICKETED" },
    { ticketingDate: new Date(), ticketingDateType: "TICKETED" },
    { ticketingDate: new Date(), ticketingDateType: "TICKETED" },
    { ticketingDate: new Date(), ticketingDateType: "TICKETED" },
  ],
  generalInfos: {
    backOffice: "",
    ticketingDateType: "",
    source: "",
    pointOfTurnAround: "",
    receivedFrom: "",
    numberOfInfos: 0,
    numberOfPhones: 0,
    isGroupbooking: false,
    isTicketIssued: false,
    creatorAgent: "",
    securedFlight: false,
    creatorPCC: "",
    ticketingDate: undefined,
  },
  freeTextResult: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   

    Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.   
    
    Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.   
    
    Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.   
    
    Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.   
    
    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, At accusam aliquyam diam diam dolore dolores duo eirmod eos erat, et nonumy sed tempor et et invidunt justo labore Stet clita ea et gubergren, kasd magna no rebum. sanctus sea sed takimata ut vero voluptua. est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur`,
};
export const PnrMock1: React.FC<{}> = ({}) => {
  return (
    <div className="card">
      <div className="card-body">
        <BookingPnrCardData data={PnrMockData1} />
      </div>
    </div>
  );
};
