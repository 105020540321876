import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { ServiceBookingTypeEnumDisplay } from "components/Shared/ServiceBookingTypeEnumDisplay";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import React from "react";
import { PnrServiceFareDataModel } from "WebApiClient";
interface Header {
    label: string;
    key: keyof any;
}

export const PnrStoredServicesCard: React.FC<{ data: PnrServiceFareDataModel[] }> = ({ data }) => {

    const TabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: data.map((e, i) => {
            return {
                Component: <ServiceItem key={"ServiceI_" + i} data={e} />
                ,
                Title: `#${i + 1} ${e.serviceCode}`,
                Icon: faInfoCircle,
                AmountIndicator: undefined
            }
        })

    }

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Stored services<span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <TabbedContent {...TabContent} />
            </div>
        </div>
    );


}

const ServiceItem: React.FC<{ data: PnrServiceFareDataModel }> = ({ data }) => {
    const { coupons, documentType, emdRequired,
        equivalentServicePrice, extensions,
        fareCalculation, lastEMDDate,
        passengerNumber,
        platingCarrier, serviceCode, serviceCreationDate,
        serviceID, servicePrice } = data;

    const headers: Header[] = [
        { label: "Type", key: "bookingType" },
        { label: "Type Code", key: "bookingTypeCode" },
        { label: "Type ID", key: "bookingTypeID" },
        { label: "Type Text", key: "bookingTypeText" },
        { label: "Carrier", key: "carrier" },
        { label: "Exchangeable", key: "exchangeable" },
        { label: "Extensions", key: "extensions" },
        { label: "Last EMD date", key: "lastEMDDate" },
        { label: "NVB", key: "notValidBefore" },
        { label: "NVA", key: "notValidAfter" },
        { label: "Refundable", key: "refundable" },
        { label: "Segment", key: "segmentNumber" },
        { label: "Service code", key: "serviceCode" },
        { label: "Service text", key: "serviceText" },
    ];

    // Function to check if a column has any non-empty value in it
    const isColumnNotEmpty = (coupons: any[], key: keyof any): boolean => {
        return coupons.some(coupon => {
            const value = coupon[key];
            return value !== undefined && value !== null && value !== ""; // Check if there's any non-empty value
        });
    };

    // Function to check if "Service code" should be displayed based on "Type Code"
    const shouldShowServiceCode = (coupons: any[]): boolean => {
        return coupons.every(coupon => coupon.bookingTypeCode !== coupon.serviceCode);
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-2">
                        <table className="table table-sm table-striped table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold">Code</td>
                                    <td>{serviceCode}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">ID</td>
                                    <td>{serviceID}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Carrier</td>
                                    <td>{platingCarrier}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Document type</td>
                                    <td>{documentType}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Calculation</td>
                                    <td>{fareCalculation}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Extensions</td>
                                    <td>{extensions}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Passenger number</td>
                                    <td>{passengerNumber ? passengerNumber.toString() : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">EMD required</td>
                                    <td>{emdRequired ? "Yes" : "No"}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Equiv. price</td>
                                    <td><SharedPriceDisplay price={equivalentServicePrice} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Service price</td>
                                    <td><SharedPriceDisplay price={servicePrice} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Last EMD date</td>
                                    <td><DateTimeDisplay date={lastEMDDate} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Creation date</td>
                                    <td><DateTimeDisplay date={serviceCreationDate} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {coupons.length > 0 &&
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header card-header-primary">Coupons</div>
                                <div className="card-body">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                {headers.map((header, index) =>
                                                    (header.key !== "bookingTypeCode" || shouldShowServiceCode(coupons)) &&
                                                    isColumnNotEmpty(coupons, header.key) && (
                                                        <th key={index} className="font-weight-bold">{header.label}</th>
                                                    )
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coupons.map((coupon, index) => (
                                                <tr key={"coupon_" + index}>
                                                    {isColumnNotEmpty(coupons, "bookingType") && (
                                                        <td><ServiceBookingTypeEnumDisplay type={coupon.bookingType} /></td>
                                                    )}
                                                    {isColumnNotEmpty(coupons, "bookingTypeCode") && shouldShowServiceCode(coupons) && <td>{coupon.bookingTypeCode}</td>}
                                                    {isColumnNotEmpty(coupons, "bookingTypeID") && <td>{coupon.bookingTypeID}</td>}
                                                    {isColumnNotEmpty(coupons, "bookingTypeText") && <td>{coupon.bookingTypeText}</td>}
                                                    {isColumnNotEmpty(coupons, "carrier") && <td>{coupon.carrier}</td>}
                                                    {isColumnNotEmpty(coupons, "exchangeable") && (
                                                        <td>{coupon.exchangeable !== undefined ? coupon.exchangeable ? "Yes" : "No" : ""}</td>
                                                    )}
                                                    {isColumnNotEmpty(coupons, "extensions") && <td>{coupon.extensions}</td>}
                                                    {isColumnNotEmpty(coupons, "lastEMDDate") && <td><DateTimeDisplay date={coupon.lastEMDDate} /></td>}
                                                    {isColumnNotEmpty(coupons, "notValidBefore") && <td><DateTimeDisplay date={coupon.notValidBefore} /></td>}
                                                    {isColumnNotEmpty(coupons, "notValidAfter") && <td><DateTimeDisplay date={coupon.notValidAfter} /></td>}
                                                    {isColumnNotEmpty(coupons, "refundable") && (
                                                        <td>{coupon.refundable !== undefined ? coupon.refundable ? "Yes" : "No" : ""}</td>
                                                    )}
                                                    {isColumnNotEmpty(coupons, "segmentNumber") && (
                                                        <td>{coupon.segmentNumber ? coupon.segmentNumber.toString() : ""}</td>
                                                    )}
                                                    {isColumnNotEmpty(coupons, "serviceCode")  && <td>{coupon.serviceCode}</td>}
                                                    {isColumnNotEmpty(coupons, "serviceText") && <td>{coupon.serviceText}</td>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};