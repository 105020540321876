import {
  AirportType,
  ICabinClassState,
  IFareSearchInputState,
  ISegmentState,
} from "components/FareSearch/types/FareSearchTypes";
import React, { useState, useEffect } from "react";
import CabinClassInput from "../Passengers/Passengers.CabinClasses";
import AirportInput from "./FlightDetails.Segment.Airport";
import SegmentButtons from "./FlightDetails.Segment.Buttons";
import DepartureDate from "./FlightDetails.Segment.DepartureDate";

import { ApiCredentialsOptions, PublicServicesClient } from "WebApiClient";
import { dropdownItem } from "components/FareSearch/types/FareSearchTypes";
import { AgentApiURL } from "Constants";

interface OwnProps {
  Input: IFareSearchInputState;
  SegmentIndex: number;
  Segment: ISegmentState;
  NextSegment?: ISegmentState;
  PreviousSegment?: ISegmentState;
  RemoveSegment: (index: number) => void;
  AddReturnSegment: (index: number) => void;
  UpdateSegment: (index: number, segment: ISegmentState) => void;
  UpdateState: (state: IFareSearchInputState) => void;
  OnSubmit: () => void;
  isValidSearch: boolean;
  cabinContainer?: boolean;
}

const Segment: React.FC<OwnProps> = (props) => {
  const index = props.SegmentIndex;
  const segment = props.Segment;
  const UpdateSegment = props.UpdateSegment;
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isInvalidDay, setIsInvalidDay] = useState<boolean>(false);
  const [isInvalidMonth, setIsInvalidMonth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsDept, setItemsDept] = useState<dropdownItem[]>([]);
  const [itemsAriv, setItemsAriv] = useState<dropdownItem[]>([]);

  function OnUpdateCabinClass(cabinClasses: ICabinClassState) {
    let newSegment = { ...segment };
    newSegment.CabinClasses = cabinClasses;
    props.UpdateSegment(index, newSegment);
  }

  function OnSelectNextElemAirport(type: AirportType) {
    let id = "";
    if (type === AirportType.Departure) {
      id = `segment${props.SegmentIndex}_airport1`;
    } else {
      id = `segment${props.SegmentIndex}_date`;
    }
    (document.getElementById(id) as HTMLInputElement).select();
  }

  const [flightDetailsCollector, setflightDetailsCollector] = useState({
    arrivalL: undefined,
    departureL: undefined,
    DayL: undefined,
    MonthL: undefined,
  });

  function segmentShrotHand(flightDetailsCollector: any) {
    let fileDetailsSegment = { ...segment };
    let currentMonth = new Date().getMonth() + 1;
    let currentDate = new Date().getDate();
    var Year;

    if (
      flightDetailsCollector?.MonthL > 12 ||
      flightDetailsCollector?.DayL > 31 ||
      flightDetailsCollector?.DayL < 1 ||
      flightDetailsCollector?.MonthL < 1
    ) {
      setIsInvalid(true);
      if (
        flightDetailsCollector?.DayL > 31 ||
        flightDetailsCollector?.DayL < 1
      ) {
        setIsInvalidDay(true);
        setIsInvalidMonth(false);
      }
      if (
        flightDetailsCollector?.MonthL > 12 ||
        flightDetailsCollector?.MonthL < 1
      ) {
        setIsInvalidMonth(true);
        setIsInvalidDay(false);
      }
    } else {
      setIsInvalid(false);
    }

    //aiport api callling
    if (flightDetailsCollector.departureL.length > 2) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(
        new ApiCredentialsOptions(AgentApiURL, "")
      );
      client
        .getAirportsByPrefix(flightDetailsCollector.departureL)
        .then((result) => {
          if (result.length > 0) {
            const list: dropdownItem[] = [];
            result.forEach((e) => {
              const text = `[${e.iata}] - ${e.cityName} - ${e.name}`;
              list.push({ value: e.iata!, text: text, image: e.countryCode! });
            });
            setItemsDept(list);
          } else {
            setItemsDept([]);
          }
        })
        .catch(() => {
          setItemsDept([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (flightDetailsCollector.arrivalL.length > 2) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(
        new ApiCredentialsOptions(AgentApiURL, "")
      );
      client
        .getAirportsByPrefix(flightDetailsCollector.arrivalL)
        .then((result) => {
          if (result.length > 0) {
            const list: dropdownItem[] = [];
            result.forEach((e) => {
              const text = `[${e.iata}] - ${e.cityName} - ${e.name}`;
              list.push({ value: e.iata!, text: text, image: e.countryCode! });
            });
            setItemsAriv(list);
          } else {
            setItemsAriv([]);
          }
        })
        .catch(() => {
          setItemsAriv([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (
      flightDetailsCollector?.MonthL < currentMonth ||
      (flightDetailsCollector?.MonthL === currentMonth &&
        flightDetailsCollector?.DayL < currentDate)
    ) {
      Year = new Date().getFullYear() + 1;
    } else {
      Year = new Date().getFullYear();
    }

    var myDate = new Date();
    if (isInvalid == true) {
      fileDetailsSegment.DepartureInput = itemsDept.length
        ? itemsDept[0].text
        : flightDetailsCollector.departureL;
      fileDetailsSegment.ArrivalInput = itemsAriv.length
        ? itemsAriv[0].text
        : flightDetailsCollector.arrivalL;
      fileDetailsSegment.DateInput = undefined;
      props.UpdateSegment(index, fileDetailsSegment);
    } else {
      myDate = new Date(
        `${Year}-${flightDetailsCollector?.MonthL}-${flightDetailsCollector?.DayL}`
      );
      fileDetailsSegment.DepartureInput = itemsDept.length
        ? itemsDept[0].text
        : flightDetailsCollector.departureL;
      fileDetailsSegment.ArrivalInput = itemsAriv.length
        ? itemsAriv[0].text
        : flightDetailsCollector.arrivalL;
      fileDetailsSegment.DateInput = myDate;
      props.UpdateSegment(index, fileDetailsSegment);
    }
  }

  useEffect(() => {
    if (
      flightDetailsCollector.arrivalL != undefined &&
      flightDetailsCollector.departureL != undefined &&
      flightDetailsCollector.MonthL != undefined &&
      flightDetailsCollector.DayL != undefined
    ) {
      segmentShrotHand(flightDetailsCollector);
      // searchRef.current?.focus()
      document.getElementById(`addFlight`)?.focus();
    }
  }, [flightDetailsCollector, isInvalid, itemsAriv.length, itemsDept.length]);

  const departureRef = React.useRef<HTMLInputElement | any>();
  const arrivalRef = React.useRef<HTMLInputElement | any>();
  const inputeDateRef = React.useRef<HTMLInputElement | any>();
  const searchRef = React.useRef<HTMLInputElement | any>();

  const returnTabId = (segId: any, InputTab: any): any => {
    switch (segId) {
      case 0:
        if (InputTab == 1) {
          return 1;
        }
        if (InputTab == 2) return 2;
        if (InputTab == 3) return 3;
        break;
      case 1:
        if (InputTab == 1) return 4;
        if (InputTab == 2) return 5;
        if (InputTab == 3) return 6;
        break;
      case 2:
        if (InputTab == 1) return 7;
        if (InputTab == 2) return 8;
        if (InputTab == 3) return 9;
        break;
      case 3:
        if (InputTab == 1) return 10;
        if (InputTab == 2) return 11;
        if (InputTab == 3) return 12;
        break;
      case 4:
        if (InputTab == 1) return 13;
        if (InputTab == 2) return 14;
        if (InputTab == 3) return 15;
        break;
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      <div className="row container__Flight">
        <div className="col-12 col-sm-6 col-lg-4 mb-1">
          <AirportInput
            SegmentIndex={index}
            AirportType={AirportType.Departure}
            Segment={segment}
            UpdateSegment={UpdateSegment}
            OnSelectNextElem={OnSelectNextElemAirport}
            departureCollector={flightDetailsCollector.departureL}
            setdepartureCollector={setflightDetailsCollector}
            isInValidMonth={isInvalid}
            reference={departureRef}
            tabIndex={returnTabId(props.SegmentIndex, 1)}
          />

          {isInvalidDay && isInvalid && (
            <span style={{ color: "red", fontSize: "14px" }}>
              Please select valid Day. <strong>"DepArvDDMM"</strong>
            </span>
          )}

          {isInvalidMonth && isInvalid && (
            <span style={{ color: "red", fontSize: "14px" }}>
              Please select valid Month. <strong>"DepArvDDMM"</strong>
            </span>
          )}
        </div>

        <div className="col-12 col-sm-6 col-lg-4 mb-1">
          <AirportInput
            SegmentIndex={index}
            AirportType={AirportType.Arrival}
            Segment={segment}
            UpdateSegment={UpdateSegment}
            OnSelectNextElem={OnSelectNextElemAirport}
            arrivalCollector={flightDetailsCollector.arrivalL}
            setdepartureCollector={setflightDetailsCollector}
            isInValidMonth={isInvalid}
            reference={arrivalRef}
            tabIndex={returnTabId(props.SegmentIndex, 2)}
          />
        </div>

        <div className="col-8 col-sm-6 col-lg-4 mb-1">
          <div className="d-flex">
            <div
              style={{
                width: props.Input.Segments.length > 1 ? "90%" : "100%",
              }}
            >
              <DepartureDate
                SegmentIndex={index}
                Segment={segment}
                PreviousSegment={props.PreviousSegment}
                NextSegment={props.NextSegment}
                UpdateSegment={UpdateSegment}
                btnRef={searchRef}
                reference={inputeDateRef}
                tabIndex={returnTabId(props.SegmentIndex, 3)}
              />
            </div>
            {props.Input.Segments.length > 1 && (
              <div className="removeBtn">
                <SegmentButtons
                  SegmentIndex={index}
                  Segment={segment}
                  NextSegment={props.NextSegment}
                  RemoveSegment={props.RemoveSegment}
                  AddReturnSegment={props.AddReturnSegment}
                />
              </div>
            )}
          </div>
        </div>

        {props.Input.CabinClassPerLeg && (
          <CabinClassInput
            CabinClasses={segment.CabinClasses}
            UpdateCabinClasses={OnUpdateCabinClass}
            ClassName="col-12 mb-2"
            disabled={false}
            segmentIndex={props.Input.Segments.length}
          />
        )}

      </div>
    </React.Fragment>
  );
};

export default Segment;