import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


interface FarePriceDetailsModalProps {
    IsOpen: boolean;
    Toggle: () => void;
}



const FarePriceDetailsModal: React.FC<FarePriceDetailsModalProps> = props => {
    return (
        <Modal size="xl" scrollable={true} isOpen={props.IsOpen} centered={true} toggle={props.Toggle}>
            <ModalHeader toggle={props.Toggle}>Price details</ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                {/* <Button color="primary" onClick={FetchRules}>Refresh</Button>{' '} */}
                <Button color="primary" onClick={props.Toggle}>close</Button>
            </ModalFooter>
        </Modal>
    );
};



export default FarePriceDetailsModal;