import { useUserInformation } from "hooks/various";
import { useNavigate } from "react-router-dom";

export const NoMatch: React.FC<{}> = Allprops => {
  const userInfo = useUserInformation();
  const navigate = useNavigate();

  return <div style={{ position: "absolute", top: "30%", left: "50%" }}>
    <div className="alert alert-warning">
      <h1>Page not found</h1>
      <p>The requested page could not be found.</p>
      {userInfo.UserName === "???" ?
        <p>Please user your Deeplink or Web Login to start a new session.</p> :
        <p></p>
      }
      <p></p>
    </div>
  </div>
}


export default NoMatch
