import { BookingStatusLabel } from "components/BookingManager/component/BookingStatusLabel";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { useState } from "react";
import { BookingItemVariousElement } from './../../../WebApiClient';

export const BookingAdditionalsCard: React.FC<{ Data: BookingItemVariousElement[] }> = ({ Data }) => {
  const [collapseOpen, setCollapseOpen] = useState(true);
  function ToggleCollapse() {
    setCollapseOpen(!collapseOpen)
  }



  return (
    <div className="card">
      <div className="card-header card-header-primary">Additionals</div>
      <div className="card-body card-body-secondary">
        <div className="row">
          <div className="col-12">
            {Data.length > 0 ?
              <div className="row">
                {Data.map((additional, index) =>
                  <AdditionalItem data={additional} key={"Data"+index} index={index} />

                )}

              </div>


              :
              <span>
                No data available.
              </span>
            }

          </div>
        </div>

      </div>

    </div>
  );


}

export default BookingAdditionalsCard;

const AdditionalItem: React.FC<{ data: BookingItemVariousElement, index: number }> = ({ data, index }) => {
  const { bookedInVersion,
    bookingDate, bookingStatus, bookingItemID, carrierCode,
    cartID, code, equivalentPrice, extensions,
    externalReference, passengerSelection, price,
    remarkType, segmentSelection, serviceDate, serviceLocation,
    serviceQuantity, status, text,
    timeStamp, type } = data;

  return (
    <div className="col-12 mb-2" >
      <div className="card">
        <div className="card-header">Info #{(index + 1).toString()}</div>

        <div className="card-body">
          <table className="table">
            <tbody>
              <tr>
                <td className="font-weight-bold">Booked in version</td>
                <td>{bookedInVersion ? bookedInVersion.toString() : ""}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Booking date</td>
                <td><DateTimeDisplay date={bookingDate} /></td>
              </tr>
              <tr>
                <td className="font-weight-bold">Booking status</td>
                <td><BookingStatusLabel Status={bookingStatus} /></td>
              </tr>
              <tr>
                <td className="font-weight-bold">Booking ID</td>
                <td>{bookingItemID}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Cart ID</td>
                <td>{cartID}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Carrier code</td>
                <td>{carrierCode}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Code</td>
                <td>{code}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Price</td>
                <td><SharedPriceDisplay price={price} /></td>
              </tr>
              <tr>
                <td className="font-weight-bold">Equiv price</td>
                <td><SharedPriceDisplay price={equivalentPrice} /></td>
              </tr>
              <tr>
                <td className="font-weight-bold">Extensions</td>
                <td>{extensions}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">External reference</td>
                <td>{externalReference}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Passengers</td>
                <td>{passengerSelection.join(",")}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Segments</td>
                <td>{segmentSelection.join(",")}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Remark type</td>
                <td>{remarkType}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Service date</td>
                <td><DateTimeDisplay date={serviceDate} /></td>
              </tr>
              <tr>
                <td className="font-weight-bold">Service location</td>
                <td>{serviceLocation}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Service quantity</td>
                <td>{serviceQuantity}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Status</td>
                <td>{status ? status.toString() : ""}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Text</td>
                <td>{text}</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Timestamp</td>
                <td><DateTimeDisplay date={timeStamp} /></td>
              </tr>
              <tr>
                <td className="font-weight-bold">Type</td>
                <td>{type.toString()}</td>
              </tr>


            </tbody>
          </table>

        </div>
      </div>
    </div>
  );


}