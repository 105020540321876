import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BookingItemPassengerModel, PnrResponsePassengerModel } from "WebApiClient";
const _ = require('lodash');

interface NormalizedPassenger {
    Firstname: string,
    LastName: string,
    PassengerNumber: string
}

function GetPNRPassengerByID(id: number, passengers: PnrResponsePassengerModel[]): NormalizedPassenger | undefined {
    let filter = passengers.filter(e => e.passengerNumber === id)[0];
    let result: NormalizedPassenger | undefined = undefined;
    if (filter) {
        result = { Firstname: filter.firstName, LastName: filter.name, PassengerNumber: id.toString() };
    }

    return result;
}
function GetItemPassengerByID(id: number, passengers: BookingItemPassengerModel[]): NormalizedPassenger | undefined {
    let filter = passengers.filter(e => e.passengerNumber === id)[0];
    let result: any;
    if (filter) {
        result = { Firstname: filter.firstName, LastName: filter.lastName, PassengerNumber: id.toString() };
    }

    return result;
}
export const BookingMangerPassengersTableCell: React.FC<{singleSSR?:any,bookingRemarkElements?:any,remark?:string, active?: any, numbers: number[], pnrPassengers?: PnrResponsePassengerModel[], bookingItemPassengers?: BookingItemPassengerModel[] }> = ({singleSSR,bookingRemarkElements,remark, active, numbers, pnrPassengers, bookingItemPassengers }) => {

    function checkingMatchPassenger(){
        // Replace '//' with '@' in o1.text
        let formattedText = singleSSR?.text.replace('//', '@').replace(/^00(\d+)/, '+$1');        // Combine code and formattedText with a space in between
        let combined = `${singleSSR?.code} ${formattedText}`;

        // Find the matching object in obj2
        let match = _.find(bookingRemarkElements, (o2:any) => {
            let adjustedRemark = o2.remark.replace(/(CTCE|CTCM)\d+/g, '$1');
            return adjustedRemark === combined;
        })        
        if (match) {
            // Check if the remark contains CTCE or CTCM followed by a number
            let passengerNumberMatch = match.remark.match(/CTC[EM](\d+)/);
            if (passengerNumberMatch) {
                return `p${passengerNumberMatch[1]}`;
            }
        }
        return 'All passengers';
    }

    function processString(s: string): string {
        const elements = s.split(' ');
        const uniqueValues = elements.reduce((acc, val, idx, arr) => {
            if (idx % 2 === 0 && val === arr[idx + 1]) {
                acc.push(val);
            }
            return acc;
        }, [] as string[]);

        // If no unique values found, return the original string
        return uniqueValues.length > 0 ? uniqueValues.join(' ') : s;
    }

    function getNumbersAfterPattern(str:any) {
        let matches = str.match(/(CTCM|CTCE)(\d+)/g);
        if (!matches) return [];
      
        return matches.map((match:any) => match.replace(/(CTCM|CTCE)/, ''));
      } 
    return <td className={active ? "activeTR" : ""} >
        {
        remark?.match(/-(\d+)/)?.[1] != undefined ? 
            <span>
                P{remark?.match(/-(\d+)/)?.[1]}
            </span>
        :
        numbers?.length === 0 ?
            <span>
                {bookingRemarkElements !=undefined ? checkingMatchPassenger():
                
                remark != undefined ? /(CTCM|CTCE)/.test(processString(remark))?`P${getNumbersAfterPattern(processString(remark))}`: 'All passengers' : 'All passengers'}</span> :
                
            <span>{numbers?.map((pN, i) => {
                let passenger: NormalizedPassenger | undefined = undefined;
                if (pnrPassengers) {
                    passenger = GetPNRPassengerByID(pN, pnrPassengers)
                }
                if (bookingItemPassengers) {
                    passenger = GetItemPassengerByID(pN, bookingItemPassengers)
                }
                return <span key={"fontA_" + i}>
                    <FontAwesomeIcon icon={faUser} />{" "}
                    {passenger ?
                        <span>{passenger.LastName},{passenger.Firstname}</span> : <span>Passenger "{pN}"</span>
                    }
                    <br /></span>
            }

            )}

            </span>
        }
    </td>
}



export const BookingMangerPassengersTableData = (numbers: any, bookingItemPassengers: any, pnrPassengers: any,) => {
    return numbers.map((pN: any, i: any) => {
        let passenger: any;
        passenger = GetPNRPassengerByID(pN, pnrPassengers)
        if (bookingItemPassengers) {
            passenger = GetItemPassengerByID(pN, bookingItemPassengers)
        }
        if (passenger) {
            return {
                fName: passenger.Firstname,
                lName: passenger.LastName
            }
        } else {
            return pN
        }
    })
}

