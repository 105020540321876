import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faUserCircle,
} from "@fortawesome/pro-light-svg-icons";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FrquentFlyperInput } from "components/FareSearch/component/Passengers/Passengers.AvailFaresPassengers.Modal";
import { DateDisplay } from "components/Shared/DateDisplay";
import DateInput from "components/Shared/DateInput";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import * as moment from "moment";
import React, { Fragment, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import {
  AgentGenderEnum,
  BonusProgramModel,
  FareBookingInputPassengerModel,
} from "WebApiClient";
import { BookPassengerValidationGeneralTabResult } from "./FareBooking.Validate.Passenger.General";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
export interface IBookingPassengerGeneralTabProps {
  Passenger: FareBookingInputPassengerModel;
  DobLocked: boolean;
  OnUpdatePassenger: (data: FareBookingInputPassengerModel) => void;
  Index: number;
  Valid: BookPassengerValidationGeneralTabResult;
  IsOpen: boolean;
  Toggle: () => void;
  Next: () => void;
}
export interface passengerConfig {
  PassengerDOB: string;
  PassengerGender: string;
}
const BookingPassengerGeneralTab: React.FC<IBookingPassengerGeneralTabProps> = (
  props
) => {
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
    showFareHeader=Off
    showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\n{"PassengerDOB":"No","PassengerGender":"Unselected"}`
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );

  let settingArr = otherSettings.split("\n");
  var passengerConfig: passengerConfig =
    settingArr[5] == undefined
      ? { PassengerDOB: "No", PassengerGender: "Unselected" }
      : JSON.parse(settingArr[5]);
  const passenger = props.Passenger;
  const IsValid = props.Valid;

  function OnTitleUpdate(value: string) {
    let data = { ...passenger };
    // data.gender = GetGenderFromTitle(value);
    data.title = value;
    props.OnUpdatePassenger(data);
  }
  function OnFirstNameUpdate(value: string) {
    let data = { ...passenger };
    data.firstName = value;
    props.OnUpdatePassenger(data);
  }
  function OnMiddleNameUpdate(value: string) {
    let data = { ...passenger };
    data.middleName = value;
    props.OnUpdatePassenger(data);
  }
  function OnLastNameUpdate(value: string) {
    let data = { ...passenger };
    data.lastName = value;
    props.OnUpdatePassenger(data);
  }
  function OnGenderUpdate(value: string) {
    let data = { ...passenger };
    data.gender = parseInt(value);
    props.OnUpdatePassenger(data);
  }
  function OnDateOfBirthUpdate(value: Date | undefined) {
    let data = { ...passenger };
    data.dateOfBirth = value;
    props.OnUpdatePassenger(data);
  }
  function OnAddFrequentFlyer() {
    let data = { ...passenger };
    let ffC = data.frequentFlyerCards ? [...data.frequentFlyerCards] : [];
    ffC.push({
      carrierCodes: [],
      extensions: undefined,
      holderFirstName: undefined,
      holderLastName: undefined,
      holderTitle: undefined,
      programNumber: "",
      programType: undefined,
    });

    props.OnUpdatePassenger({ ...data, frequentFlyerCards: ffC });
  }

  function OnEditFrequentFlyer(
    index: number,
    val: BonusProgramModel | undefined
  ) {
    let data = { ...passenger };
    if (data.frequentFlyerCards) {
      let ffC = [...data.frequentFlyerCards];
      if (val) {
        ffC[index] = val;
      } else {
        ffC.splice(index, 1);
      }
      props.OnUpdatePassenger({ ...data, frequentFlyerCards: ffC });
    }
  }

  return (
    <div className="row">
      <div className="col">
        <Button
          color="secondary"
          block
          onClick={props.Toggle}
          className="text-black"
        >
          <h6>
            <span className="float-start">
              <ValidIndicatorIcon
                IsValid={IsValid.Valid}
                Icon={faUserCircle}
                NoValueSet={false}
              />
              {" General"}
            </span>
            <span className="float-end">
              <FontAwesomeIcon
                icon={props.IsOpen ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </span>
          </h6>
        </Button>

        <Collapse isOpen={props.IsOpen}>
          <div className="card">
            <div className="card-body">
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - NAME - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
              <div className="row align-items-center mb-2">
                <label className="col-12 col-md-4 col-lg-3 col-xl-2 align-middle">
                  Full name*
                </label>
                <div className="col-12 col-md-6 col-lg-3 align-middle">
                  <input
                    value={passenger.firstName ? passenger.firstName : ""}
                    onChange={(e) => OnFirstNameUpdate(e.target.value)}
                    type="text"
                    className={`form-control   ${
                      IsValid.FirstName ? "is-valid" : "is-invalid"
                    }`}
                    placeholder="First name"
                  />
                </div>
                <div className="col-12 col-md-6 offset-md-4 col-lg-3 offset-lg-0 mt-2 mt-lg-0 align-middle">
                  <input
                    value={passenger.middleName ? passenger.middleName : ""}
                    onChange={(e) => OnMiddleNameUpdate(e.target.value)}
                    type="text"
                    className="form-control pax_firstname"
                    placeholder="Middle name(s)"
                  />
                </div>
                <div className="col-12 col-md-6 offset-md-4 col-lg-3 offset-lg-0 mt-2 mt-lg-0 align-middle">
                  <input
                    value={passenger.lastName ? passenger.lastName : ""}
                    onChange={(e) => OnLastNameUpdate(e.target.value)}
                    type="text"
                    className={`form-control   ${
                      IsValid.LastName ? "is-valid" : "is-invalid"
                    }`}
                    placeholder="Last name"
                  />
                </div>
              </div>

              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TITLE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
              {props.Valid.IsAdult && (
                <div className="row align-items-center mb-2">
                  <label className="col-12 col-md-4 col-lg-3 col-xl-2 align-middle">
                    Title
                  </label>
                  <div className="col-12 col-md-6 col-lg-3 align-middle">
                    <input
                      value={passenger.title ? passenger.title : ""}
                      onChange={(e) => OnTitleUpdate(e.target.value)}
                      type="text"
                      className={`form-control   ${
                        IsValid.Title ? "" : "is-invalid"
                      }`}
                      placeholder="Title"
                    />
                  </div>
                  {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GENDER - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                  {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //GENDER  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                </div>
              )}
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // TITLE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
              <div className="row align-items-center mb-2">
                <label className="col-12 col-md-4 col-lg-2 col-xl-2">
                  Gender
                </label>
                <div className="col-12 col-md-6 col-lg-3">
                  <select
                    aria-label="Default select example"
                    value={passenger.gender}
                    className={`form-select ${
                      props.Valid.Gender ? "is-valid" : "is-invalid"
                    }`}
                    onChange={(e) => OnGenderUpdate(e.target.value)}
                  >
                    <option value={AgentGenderEnum.Unknown}>- select -</option>
                    <option value={AgentGenderEnum.Male}>Male</option>
                    <option value={AgentGenderEnum.Female}>Female</option>
                  </select>
                </div>
              </div>

              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - DATE OF BIRTH - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
              <div className="row align-items-center mb-2">
                <label className="col-12 col-md-4 col-lg-2 col-xl-2">
                  Date of birth*
                </label>
                <div className="col-12 col-md-6 col-lg-3">
                  {props.DobLocked && (
                    <DateDisplay date={passenger.dateOfBirth}></DateDisplay>
                  )}
                  {/* <input value={passenger.dateOfBirth} type="text" className="form-control " readOnly /> */}
                  {!props.DobLocked && (
                    <DateInput
                      OnDateChange={(date: Date | undefined) =>
                        OnDateOfBirthUpdate(date)
                      }
                      Value={passenger.dateOfBirth}
                      InputGroup={false}
                      MaxDate={moment().toDate()}
                      IsDob={true}
                      MinDate={moment().subtract(120, "years").toDate()}
                      InputClassNames={`form-control  ${
                        passengerConfig.PassengerDOB == "No"
                          ? null
                          : IsValid.DateOfBirth
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                    />
                  )}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // DATE OF BIRTH - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

              <React.Fragment>
                {/* <div className="row align-items-center mb-2 mt-4">
                                    <label className="col-12 col-md-4 col-lg-3 col-xl-2">Emergency phone*</label>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <input
                                            type="text"
                                            value={passenger.emergencyPhone ? passenger.emergencyPhone : ""}
                                            onChange={(e) => OnEmergencyPhoneUpdate(e.target.value)}
                                            className={`form-control   ${IsValid.EmergencyPhone ? "is-valid" : "is-invalid"}`}
                                        />
                                    </div>
                                </div> */}
              </React.Fragment>
              <div className="row mb-2">
                {passenger.frequentFlyerCards && (
                  <Fragment>
                    {passenger.frequentFlyerCards.map((ff, i) => (
                      <div
                        key={"frequentFlyerCards" + i}
                        className="col-12 mb-2"
                      >
                        <FrquentFlyperInput
                          OnUpdate={(e) => {
                            OnEditFrequentFlyer(i, e);
                          }}
                          data={ff}
                          index={i}
                        />
                      </div>
                    ))}
                  </Fragment>
                )}
                <div className="col-12">
                  <button
                    className="btn btn-secondary"
                    onClick={OnAddFrequentFlyer}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add frequent flyer
                    card
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Button color="secondary text-dark" onClick={props.Next}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                    <span> Next</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default BookingPassengerGeneralTab;
