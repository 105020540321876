import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AncillaryMatrix } from "components/AncillarySelection/AncillaryMatrix";
import {
  AncillarySelection,
  AssignablePassengerOption,
} from "components/AncillarySelection/AncillarySelection";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BugReportModal } from "components/Shared/BugReportModal";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  AvailableFareModel,
  GetAncillariesApiResponse,
  ServiceModel,
  ServicePriceModel,
  ServiceSegmentModel,
} from "WebApiClient";

interface AncillariesModalProps {
  IsOpen: boolean;
  Toggle: () => void;
  SelectedConnections: number[];
  SetSelectedConnection: (legindex: number, connectionIndex: number) => void;
  Fare: AvailableFareModel;
  AddedServices: ServiceAddObj[];
  SetAddedServices(services: ServiceAddObj[]): void;
  Passengers: AssignablePassengerOption[];
}

export const AncillariesModal: React.FC<AncillariesModalProps> = (props) => {
  return (
    <Modal
      size="xl"
      scrollable={true}
      isOpen={props.IsOpen}
      toggle={props.Toggle}
    >
      <ModalHeader toggle={props.Toggle}>Ancillaries</ModalHeader>
      <ModalBody>
        {props.IsOpen && (
          <AncillaryComponent
            Fare={props.Fare}
            SelectedConnections={props.SelectedConnections}
            SetSelectedConnection={props.SetSelectedConnection}
            AddedServices={props.AddedServices}
            SetAddedServices={props.SetAddedServices}
            Passengers={props.Passengers}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {/* <Button color="primary" onClick={FetchRules}>Refresh</Button>{' '} */}
        <Button color="primary" onClick={props.Toggle}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface AncillaryComponentProps {
  SelectedConnections: number[];
  SetSelectedConnection: (legindex: number, connectionIndex: number) => void;
  Fare: AvailableFareModel;
  AddedServices: ServiceAddObj[];
  SetAddedServices(services: ServiceAddObj[]): void;
  Passengers: AssignablePassengerOption[];
}

export const AncillaryComponent: React.FC<AncillaryComponentProps> = (
  props
) => {
  const addedServices = props.AddedServices;
  const [expand, setExpand] = useState(false);
  const ToggleExpand = () => {
    setExpand(!expand);
    if (!expand) OnRefresh();
  };

  const dispatch = useDispatch();
  const client = useAvailableFaresClient();

  const [flightTimes, setflightTimes] = useState(false);
  const ToggleFlightTimes = () => {
    setflightTimes(!flightTimes);
  };

  const [bugReportModalOpen, setBugReportOpen] = useState(false);
  const ToggleBugReportModal = () => {
    setBugReportOpen(!bugReportModalOpen);
  };

  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);

  const [response, setResponse] = useState<
    GetAncillariesApiResponse | undefined
  >(undefined);
  const responseMetaData = response?.responseMetaData;
  const result = response?.result;

  useEffect(() => {
    setExpand(true);
      OnRefresh();
  }, [props.Fare.fareIndex]);

  useEffect(() => {
    // ToggleExpand()
  }, []);

  function OnRefresh() {
    setFetching(true);
    setResponse(undefined);
    setError(false);
    client
      .getAncillaries({
        fareIdentifier: props.Fare.identifier,
        selectedLegConnections: props.SelectedConnections,
      })
      .then((response) => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        setResponse(response);
        if (response.result) {
          setError(false);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  function OnAddService(obj: ServiceAddObj) {
    let newServices = [...addedServices];
    newServices.push(obj);
    props.SetAddedServices(newServices);
    // toast.info(`Service ${obj.Service.text} added`)
  }

  function OnRemoveService(serviceIndex: number) {
    let legServices = [...addedServices];
    legServices.splice(serviceIndex, 1);
    props.SetAddedServices(legServices);
  }

  function OnEditService(index: number, amount: number) {
    let newServices = [...addedServices];
  }
  return (
    <div className="row">
      <div className="col-12 mb-2">
        <AncillaryDescription />
      </div>
      <div className="col-12 mb-2">
        <AncillaryMatrix
          Legs={props.Fare.legs}
          Services={addedServices}
          SelectedConnections={props.SelectedConnections}
          AssignablePassengers={props.Passengers}
          OnRemoveService={OnRemoveService}
          Currency={props.Fare.currency!}
          FarePrice={props.Fare.totalPrice}
        />
      </div>
      <div className="col-12 mb-2">
        <div className="card">
          <div
            className="card-header card-header-primary clickme"
            onClick={ToggleExpand}
          >
            View and select services{" "}
            <span className="float-end">
              <FontAwesomeIcon
                icon={expand ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </span>
          </div>
          <Collapse isOpen={expand}>
            <div className="card-body card-body-primary">
              <div className="row">
                {fetching && (
                  <div className="col-12 mb-2">
                    <WaitingCard />
                  </div>
                )}
                {error && <ErrorDisplay data={response?.responseMetaData} />}
                {result && (
                  <div className="col-12 mb-2">
                    <AncillarySelection
                      SegmentOptions={[]}
                      Model={result}
                      OnAddService={OnAddService}
                      OnEditService={OnEditService}
                      AddedServices={props.AddedServices}
                      PassengerOptions={props.Passengers}
                      IncludedServices={props.Fare.includedServices}
                    />
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <BugReportModal
        Toggle={ToggleBugReportModal}
        IsOpen={bugReportModalOpen}
        MetaData={responseMetaData}
      ></BugReportModal>
    </div>
  );
};

const AncillaryDescription: React.FC<{}> = (props) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div
      className="alert alert-info clickme"
      onClick={() => {
        setShowDescription(!showDescription);
      }}
    >
      <p>
        <FontAwesomeIcon icon={faInfoCircle} />{" "}
        <span className="font-weight-bold">
          How to manage ancillary services
        </span>{" "}
        <span className="float-end">
          <FontAwesomeIcon
            icon={showDescription ? faAngleDoubleUp : faAngleDoubleDown}
          />
        </span>{" "}
        <br />
      </p>
      <Collapse isOpen={showDescription}>
        <p>
          {" "}
          You can add services by selecting a service in the "View and select
          services" area per segment and passenger.<br></br>
          In the "Overview" section, you can see all added services per segment
          and passenger. You can view details and remove a service by selecting
          a service in the overview.
        </p>
        <p className="font-weight-bold">IMPORTANT</p>
        <ul>
          <li>
            Changing a flight connection will reset ALL selected services.
          </li>
          <li>
            A service can be linked to 1 or more segments and applies per
            individual passenger. A service which is linked to more than 1
            segment (e.g. baggage, which is usually linked to all segments in a
            flight) can only be added and removed for all linked segments.
          </li>
        </ul>
      </Collapse>
    </div>
  );
};

export interface ServiceAddObj {
  // Amount: number
  // Passenger: ServicePassengerModel,
  PassengerIndex: number;
  Segments: ServiceSegmentModel[];
  Price: ServicePriceModel;
  Service: ServiceModel;
}
