import { useInfoClient } from "hooks/useHttpClient";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

export const EmailSignupConfirmationPage: React.FC<{}> = props => {
    const infoClient = useInfoClient();
    const { code } = useParams();
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);

    function OnSubmit() {
        setLoading(true);
        infoClient.confirmSignupMail(code!)
            .then(response => {
                setResponse(response)
            })
            .catch(() => {
                setResponse("Your email could not be added.")
            })
            .finally(() => {
                setLoading(false);
            })
    }
    useEffect(() => {
        OnSubmit();
    }, [])


    return <div className="container xl-wrapper content-wrapper">
        <div className="card">
            <div className="card-body">
                {loading ? "Loading, please wait..." : response}

            </div>
        </div>
    </div>
}