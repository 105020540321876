import 'bootstrap/dist/css/bootstrap.css';
import { store } from 'configureStore';

import { Provider } from 'react-redux';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import "react-widgets/styles.css";
import 'Styles/HitchHiker.Styles.css';
import App from './App';


const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);

