import { AgentApisAdressType, FareAPISRequirements, FareBookingPassengerApisAddress } from "WebApiClient";



export interface ApisAddressValidResult {
    Valid: boolean;
    AddressRequired: boolean;
    Street: boolean;
    ZipCode: boolean,
    City: boolean,
    Country: boolean,
    Municipality: boolean;
    MunicipalityRequired: boolean;
    Type: boolean;
    State: boolean;
}






export function PassengerAdressValid(address: FareBookingPassengerApisAddress | undefined, apisRequirenments: FareAPISRequirements): ApisAddressValidResult {



    let addressValid: boolean = true;
    let streetValid: boolean = true;
    let zipCodeValid: boolean = true;
    let cityValid: boolean = true;
    let countryValid: boolean = true;
    let stateValid: boolean = true;
    let typeValid: boolean = true;
    let PassportMunicipalityValid: boolean = true;

    const addressRequired: boolean = false;
    const PassportMunicipalityRequired = apisRequirenments.municipalityForPassportRequired;


    if (address) {
        streetValid = (address.street !== null && address.street !== "");
        if (!streetValid) {
            addressValid = false;

        }

        zipCodeValid = (address.zip !== null && address.zip !== "");
        if (!zipCodeValid) {
            addressValid = false;

        }

        cityValid = (address.city !== null && address.city !== "");
        if (!cityValid) {
            addressValid = false;

        }
        countryValid = (address.country !== null && address.country !== "");
        if (!countryValid) {
            addressValid = false;

        }
        stateValid = (address.state !== null && address.state !== "");
        if (!stateValid) {
            addressValid = false;

        }
        typeValid = (address.type !== AgentApisAdressType.Unknown);
        if (!typeValid) {
            addressValid = false;

        }

        const PassportMunicipalityRequired = apisRequirenments.municipalityForPassportRequired;
        PassportMunicipalityValid = PassportMunicipalityRequired ? (address.municipality !== null && address.municipality !== "") : true;
        if (!PassportMunicipalityValid) {
            addressValid = false;
        }
    }
    else {
        if (addressRequired) {
            addressValid = false;
        }
    }







    const adress: ApisAddressValidResult = {
        Valid: addressValid,
        AddressRequired: addressRequired,
        Street: streetValid,
        ZipCode: zipCodeValid,
        City: cityValid,
        Country: countryValid,
        Municipality: PassportMunicipalityValid,
        MunicipalityRequired: PassportMunicipalityRequired,
        Type: typeValid,
        State: stateValid
    }








    return adress;
}


