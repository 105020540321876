import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faHandHoldingDollar, faMinus, faPlus, faStar, faPlusCircle, faMinusCircle, faCircleMinus, faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';

interface RoomsProps {
    searchData: any;
    setRoomData: any;
    isOpen: any;
}

interface RoomData {
    numRooms: number;
    adults: number;
    children: number;
    Age: number;
    infants: number;
    roomType: string[];
    mealType: string[];
}

const Rooms: React.FC<RoomsProps> = (props) => {
    const [roomData, setRoomData] = useState<RoomData>(props.searchData?.roomGuests);
    const [state, setState] = useState({
        adults: 0,
        children: 0,
    });
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setRoomData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNumericChange = (field: string, operation: string) => {
        setRoomData((prevState: any) => ({
            ...prevState,
            [field]: operation === 'increase' ? prevState[field] + 1 : prevState[field] - 1,
        }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        if (checked) {
            setRoomData((prevState) => ({
                ...prevState,
                roomType: [...prevState.roomType, value],
            }));
        } else {
            setRoomData((prevState) => ({
                ...prevState,
                roomType: prevState.roomType.filter((type) => type !== value),
            }));
        }
    };

    const handleMealTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        if (checked) {
            setRoomData((prevState) => ({
                ...prevState,
                mealType: [...prevState.mealType, value],
            }));
        } else {
            setRoomData((prevState) => ({
                ...prevState,
                mealType: prevState.mealType.filter((type) => type !== value),
            }));
        }
    };

    useEffect(() => {
        // Perform data insertion or further processing here
        props.setRoomData((prevState: any) => ({
            ...prevState,
            roomGuests: {
                numRooms: roomData.numRooms,
                adults: roomData.adults,
                children: roomData.children,
                infants: roomData.infants,
                roomType: roomData.roomType,
                mealType: roomData.mealType,
            },
        }));

        return () => { };
    }, [roomData?.adults, roomData?.children, roomData?.infants, roomData?.numRooms, roomData?.mealType.length]);

    return (
        <section className="dropdown-content roomSection  mt-1">
            <div className="container">
                <form>
                    <div className="form-group d-flex justify justify-content-between align-items-center numRooms pt-3">
                        <h6>Rooms</h6>
                        <input
                            type="number"
                            className="form-control w-50 roomInput"
                            id="numRooms"
                            name="numRooms"
                            min="1"
                            max="5"
                            value={roomData.numRooms}
                            onChange={handleInputChange}
                        />
                    </div>
                    <hr />
                    <div className="form-group d-flex justify justify-content-between align-items-center mb-1">
                        <label htmlFor="adults">Adults</label>
                        <div className='d-flex align-items-center'>
                            <FontAwesomeIcon className='circle-buttonMinus circle-button' type='button' icon={faCircleMinus} onClick={() => {
                                if (state.children !== roomData.adults) {
                                    handleNumericChange('adults', 'decrease')
                                }
                            }} />
                            <span className='AdultValue'>
                                {roomData.adults}
                            </span>
                            <FontAwesomeIcon type='button' className='circle-buttonMinus circle-button' icon={faCirclePlus} onClick={() => {
                                handleNumericChange('adults', 'increase');
                            }} />
                        </div>
                    </div>
                    <div className="form-group d-flex justify justify-content-between align-items-center mb-2">
                        <label htmlFor="children">Children</label>
                        <div className='d-flex align-items-center'>
                            <FontAwesomeIcon type='button' className='circle-buttonMinus circle-button' icon={faMinusCircle} onClick={() => {
                                if (state.children !== roomData.children) {
                                    handleNumericChange('children', 'decrease');
                                }
                            }} />
                            <span className='AdultValue'>
                                {roomData.children}
                            </span>
                            <FontAwesomeIcon type='button' className='circle-buttonMinus circle-button' icon={faPlusCircle} onClick={() => handleNumericChange('children', 'increase')} />
                        </div>
                    </div>
                    {/* <hr /> */}
                    {/* <h6>Meal Type</h6>
                    <div className="form-group d-flex">
                        <div className='checkButton'>
                            <input
                                type="checkbox"
                                className="btn-check"
                                id="checkbox-RO"
                                value="RO"
                                checked={roomData.mealType.includes('RO')}
                                onChange={handleMealTypeChange}
                            />
                            <label className={`btn btn-outline-primary ${roomData.mealType.includes('RO') ? 'active' : ''}`} htmlFor="checkbox-RO">
                                RO
                            </label>
                        </div>
                        <div className='checkButton'>
                            <input
                                type="checkbox"
                                className="btn-check"
                                id="checkbox-BB"
                                value="BB"
                                checked={roomData.mealType.includes('BB')}
                                onChange={handleMealTypeChange}
                            />
                            <label className={`btn btn-outline-primary ${roomData.mealType.includes('BB') ? 'active' : ''}`} htmlFor="checkbox-BB">
                                BB
                            </label>
                        </div>
                        <div className='checkButton'>
                            <input
                                type="checkbox"
                                className="btn-check"
                                id="checkbox-HP"
                                value="HP"
                                checked={roomData.mealType.includes('HP')}
                                onChange={handleMealTypeChange}
                            />
                            <label className={`btn btn-outline-primary ${roomData.mealType.includes('HP') ? 'active' : ''}`} htmlFor="checkbox-HP">
                                HP
                            </label>
                        </div>
                        <div className='checkButton'>
                            <input
                                type="checkbox"
                                className="btn-check"
                                id="checkbox-VP"
                                value="VP"
                                checked={roomData.mealType.includes('VP')}
                                onChange={handleMealTypeChange}
                            />
                            <label className={`btn btn-outline-primary ${roomData.mealType.includes('VP') ? 'active' : ''}`} htmlFor="checkbox-VP">
                                VP
                            </label>
                        </div>
                        <div className='checkButton'>
                            <input
                                type="checkbox"
                                className="btn-check"
                                id="checkbox-AI"
                                value="AI"
                                checked={roomData.mealType.includes('AI')}
                                onChange={handleMealTypeChange}
                            />
                            <label className={`btn btn-outline-primary ${roomData.mealType.includes('AI') ? 'active' : ''}`} htmlFor="checkbox-AI">
                                AI
                            </label>
                        </div>
                    </div> */}
                    {/* <div className="text-lg-end p-2">
                        <div style={{ height: "20px" }}>
                        </div>
                    </div> */}
                </form>
            </div>
        </section>
    );
};

export default Rooms;