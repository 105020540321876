import { DefaultCabinClassState, ICabinClassState, IFareSearchInputState, ISegmentState } from 'components/FareSearch/types/FareSearchTypes';
import * as moment from "moment";
import { AgentFrontendFareSearchOptions, AvailableFaresPassengerInput, AvailableFaresPassengerTypeInput, AvailableFaresSearchRequest, CustomerProfileData } from 'WebApiClient';

function GetInitialSegmentState(isFirstSegment: boolean): ISegmentState {
  let defaultDate: Date | undefined = undefined;
  if (isFirstSegment) {
    defaultDate = new Date()
  }
  // console.log(`default date: ${defaultDate?.toISOString()}`)
  return {
    DepartureInput: "",
    ArrivalInput: "",
    Radius: 0,
    DateInput: defaultDate,
    CabinClasses: DefaultCabinClassState
  };
}

interface SearchQueryParams {
  Branchgroup: string | null;
  Branch: string | null;

}
function GetSearchQueryParams(query: URLSearchParams): SearchQueryParams {
  const branch = query.get("branch");
  const branchgroup = query.get("branchgroup");
  let result: SearchQueryParams = { Branch: branch, Branchgroup: branchgroup };
  return result;
}

export function GetInitialInput(config: AgentFrontendFareSearchOptions, lastSubmitted: AvailableFaresSearchRequest | undefined, customerProfileData: CustomerProfileData | undefined, query: URLSearchParams): IFareSearchInputState {
  let segments: ISegmentState[] = [];
  const queryParams = GetSearchQueryParams(query);
  const customerProfilesCabinClasses = customerProfileData?.flightDetails?.cabinClasses;

  let defaultCabinClasses: ICabinClassState = customerProfilesCabinClasses ?
    {
      Business: customerProfilesCabinClasses.business,
      Economy: customerProfilesCabinClasses.economy,
      First: customerProfilesCabinClasses.first,
      PremiumEconomy: customerProfilesCabinClasses.premiumEconomy
    }
    :
    DefaultCabinClassState;

  //Initial Segments according to config
  const customerProfilesSegments = customerProfileData?.flightDetails?.segments;
  if (customerProfilesSegments && customerProfilesSegments.length > 0) {
    segments = customerProfilesSegments.map((e => {
      let segment: ISegmentState = {
        ArrivalInput: e.arrival ? e.arrival : "",
        CabinClasses: e.cabinClasses ? { Business: e.cabinClasses.business, Economy: e.cabinClasses.business, First: e.cabinClasses.first, PremiumEconomy: e.cabinClasses.premiumEconomy } : defaultCabinClasses,
        DateInput: e.departureDate ? moment({ day: e.departureDate.day, month: e.departureDate.month, year: e.departureDate.year }).toDate() : undefined,
        Radius: e.radius,
        DepartureInput: e.departure ? e.departure : ""
      };
      return segment;
    }))
  }
  else {

    segments = [GetInitialSegmentState(true)];
  }



  const adultInitialInput: AvailableFaresPassengerTypeInput | undefined = config.passengerConfiguration.adultConfigurations.length > 0 ? {
    passengerType: config.passengerConfiguration.adultConfigurations[0].type,
    passengers: [{ dateOfBirth: undefined, optionalDateOfBirth: undefined, frequentFlyerCards: undefined }]
  } : undefined;
  const childInitialInput: AvailableFaresPassengerTypeInput | undefined = config.passengerConfiguration.childConfigurations.length > 0 ? {
    passengerType: config.passengerConfiguration.childConfigurations[0].type,
    passengers: []
  } : undefined;
  const infantInitialInput: AvailableFaresPassengerTypeInput | undefined = config.passengerConfiguration.infantConfigurations.length > 0 ? {
    passengerType: config.passengerConfiguration.infantConfigurations[0].type,
    passengers: []
  } : undefined;
  const availPassengerState: AvailableFaresPassengerInput = {
    adultPassengers: adultInitialInput,
    childPassengers: childInitialInput,
    infantPassengers: infantInitialInput
    // MainPassengers: [{ BirthDate: undefined, Type: "ADT" }],
    // ChildPassengers: [],

    // InfantPassengers: [],


  }


  const overrideMultiChannelBranch = queryParams.Branch ? queryParams.Branch : customerProfileData?.flightDetails?.targetMultiChannelBranch ? customerProfileData.flightDetails.targetMultiChannelBranch : "";
  const overrideMultiChannelBranchGroup = queryParams.Branchgroup ? queryParams.Branchgroup : customerProfileData?.flightDetails?.targetMultiChannelBranchgroup ? customerProfileData.flightDetails.targetMultiChannelBranchgroup : ""

  let cabinClassPerLeg = false;
  if (customerProfileData?.flightDetails?.segments) {
    cabinClassPerLeg = customerProfileData?.flightDetails?.segments.filter(e => e.cabinClasses).length > 0;
  }
  let initialSearch: IFareSearchInputState = {
    AvailableFares: true,
    Segments: segments,
    AvilableFaresPassengers: availPassengerState,
    discountCode: "",
    islandResident: false,
    generalLargeFamily: false,
    specialLargeFamily: false,
    CabinClasses: defaultCabinClasses,
    DirectFlightsOnly: customerProfileData?.flightDetails?.directFlightsOnly ? true : false,
    CabinClassPerLeg: cabinClassPerLeg,
    IncludeAirlines: customerProfileData?.flightDetails?.includeAirlines ? customerProfileData.flightDetails.includeAirlines.map(e => { return { value: e, text: e, image: "" } }) : [],
    SmartAirlines: customerProfileData?.flightDetails?.SmartAirlines ? customerProfileData.flightDetails.SmartAirlines.map(e => { return { value: e, text: e, image: "" } }) : [],
    ExcludeAirlines: customerProfileData?.flightDetails?.excludeAirlines ? customerProfileData.flightDetails.excludeAirlines.map(e => { return { value: e, text: e, image: "" } }) : [],
    overrideMultiChannelBranch: overrideMultiChannelBranch,
    overrideMultiChannelBranchgroup: overrideMultiChannelBranchGroup,
  };
  return initialSearch;
}