import { useLocalizedPrice } from "hooks/useLocalization";
import React, { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Popover, PopoverBody } from "reactstrap";
import { State } from "rootExports/rootReducer";
import { SharedPriceModel, SurchargeDataModel } from "WebApiClient";

export const PriceDisplay: React.FC<{
  price: number;
  currency: any;
  multiplier?: number | undefined;
  passengerCount?: number;
  priceType?: string;
  otherFees?: string;
}> = (props) => {
  const { price, currency, passengerCount, priceType } = props;
  const localizedPrice = useLocalizedPrice(price, currency, props.multiplier);
  const showPassengerPrice = useRef<HTMLTableCellElement>(null);
  const [showPassengerPrices, setshowPassengerPrices] = useState(false);
  const AgencyCalcTooltip = useSelector((state: State) => state.Session.FrontendSettings.showAgencyCalcTooltip);
  return (
    <span
      ref={showPassengerPrice}
      onMouseEnter={() => setshowPassengerPrices(true)}
      onMouseLeave={() => setshowPassengerPrices(false)}
    >
      {passengerCount && (
        <>
          {priceType == "Total Price p.P.:" ? <Popover target={showPassengerPrice} isOpen={showPassengerPrices}>
            <PopoverBody>
              <strong>
                {/* {priceType} ${props.otherFees} p.P */}
                {priceType} {(price / passengerCount).toLocaleString("de-DE", { minimumFractionDigits: 2 })} {currency}`
              </strong>
            </PopoverBody>
          </Popover> : AgencyCalcTooltip ? <Popover target={showPassengerPrice} isOpen={showPassengerPrices}>
            <PopoverBody>
              <strong>
                {priceType} {props.otherFees} p.P
              </strong>
            </PopoverBody>
          </Popover> : null}
        </>



      )}
      {localizedPrice}


    </span>
  );
};
export const SharedPriceDisplay: React.FC<{
  price: SharedPriceModel | undefined;
  multiplier?: number;
}> = (props) => {
  const price = props.price;

  if (price && price.currencyCode.length > 0 && price.valueSpecified) {
    return (
      <SharedPriceValidDisplay price={price} multiplier={props.multiplier} />
    );
  } else {
    return <span></span>;
  }
};
export const SurchargeDisplay: React.FC<{
  price: SurchargeDataModel | undefined;
}> = (props) => {
  const price = props.price;
  if (price) {
    return <SurchargeValidDisplay price={price} />;
  } else {
    return <span></span>;
  }
};
const SharedPriceValidDisplay: React.FC<{
  price: SharedPriceModel;
  multiplier?: number;
}> = (props) => {
  const { currencyCode, isIATA, isIATASpecified, value, valueSpecified } =
    props.price;
  const localizedSinglePrice = useLocalizedPrice(
    value,
    currencyCode,
    undefined
  );
  const localizedTotalPrice = useLocalizedPrice(
    value,
    currencyCode,
    props.multiplier
  );
  const elemRef = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  // return <span>{currency} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency, currencyDisplay: "none" }).format(price)}</span>
  return (
    <Fragment>
      <Popover target={elemRef} isOpen={open} toggle={toggle}>
        <PopoverBody>
          <b>Price details</b> <br />
          Price (Single):{localizedSinglePrice} <br />
          {props.multiplier !== undefined && (
            <Fragment>
              Amount: {props.multiplier.toString()} <br></br>
              Price (Total): {localizedTotalPrice}
            </Fragment>
          )}
        </PopoverBody>
      </Popover>
      <span
        ref={elemRef}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onTouchStart={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        onTouchEnd={() => setOpen(false)}
      >
        {localizedTotalPrice}
      </span>
    </Fragment>
  );
};
const SurchargeValidDisplay: React.FC<{ price: SurchargeDataModel }> = (
  props
) => {
  const {
    equivalentAmount,
    equivalentMinimumSurcharge,
    percentageSurchargeContains,
    percentageSurcharge,
    isForEachSegment,
    isForEachPassenger,
  } = props.price;
  return (
    <span>
      Amount: <SharedPriceDisplay price={equivalentAmount} />
      {equivalentMinimumSurcharge !== undefined && (
        <span>
          {" "}
          | Minimum <SharedPriceDisplay
            price={equivalentMinimumSurcharge}
          />{" "}
        </span>
      )}
      {percentageSurchargeContains.length > 0 && (
        <span> | Contains {percentageSurchargeContains}</span>
      )}
      {percentageSurcharge.length > 0 && (
        <span> | Percentage {percentageSurchargeContains}</span>
      )}
      {isForEachSegment !== undefined && (
        <span>
          {" "}
          | Applies to all segments: {isForEachSegment ? "Yes" : "No"}
        </span>
      )}
      {isForEachPassenger !== undefined && (
        <span>
          {" "}
          | Applies to all passengers: {isForEachPassenger ? "Yes" : "No"}
        </span>
      )}
    </span>
  );
};
