import React from "react";

export const PnrFreeTextCard: React.FC<{ data: string }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Free text</div>
            <div className="card-body">
                <p>{data}</p>
            </div>
        </div>
    );


}
