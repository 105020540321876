import { faPlaneArrival, faPlaneDeparture } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidSegmentAirport } from "components/FareSearch/Business/ValidateSearch";
import { AirportType, dropdownItem, ISegmentState } from "components/FareSearch/types/FareSearchTypes";
import { AgentApiURL } from "Constants";
import React, { useEffect, useState } from "react";
import { ArrowContainer, Popover, PopoverState } from "react-tiny-popover";
import { Card, ListGroup, ListGroupItem } from "reactstrap";
import { ApiCredentialsOptions, PublicServicesClient } from "WebApiClient";
import nextElementFocus from './nextElementFocus';

interface OwnProps {
    SegmentIndex: number;
    Segment: ISegmentState;
    AirportType: AirportType;
    UpdateSegment: (index: number, segment: ISegmentState) => void
    OnSelectNextElem(type: AirportType): void;
    departureCollector?: any;
    setdepartureCollector?: any;
    arrivalCollector?: any;
    isInValidMonth: boolean;
    reference: any;
    tabIndex: number
}

const AirportInput: React.FC<OwnProps> = props => {
    const debug = false;
    const segment = props.Segment;
    const isDeparture: boolean = props.AirportType === AirportType.Departure;
    const PrependIcon: JSX.Element = isDeparture ? <FontAwesomeIcon icon={faPlaneDeparture} /> : <FontAwesomeIcon icon={faPlaneArrival} />;
    const IconTitle: string = isDeparture ? "Outbound" : "Inbound";
    const InputID: string = `segment${props.SegmentIndex}_airport${props.AirportType}`;
    const InputAutoFocus: boolean = isDeparture && props.SegmentIndex === 0;
    const isInValidMonth = props.isInValidMonth;
    let InputValue = isDeparture ? segment.DepartureInput : segment.ArrivalInput;
    const [InputActive, setInputActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState<dropdownItem[]>([])
    const [activeAutoCompleteItem, setActiveAutoCompleteItem] = useState(0);
    let departureAirportValue = ""
    const [depAirportValueDisplay, setDepAirportValueDisplay] = useState(false);
    var isNativeSearch: boolean = false;

    function GetInputValidLabel(): string {
        const isValid = ValidSegmentAirport(InputValue);
        if (isValid) return "is-valid"
        return "is-invalid";
    };
    
    function GetInputValidMonth(): string {
        const isInValid = isInValidMonth;
        if (isInValid) return "is-invalid"
        return "is-valid";

    };
    // is-invalid
    function stringSeparator(val: any) {
        isNativeSearch = true
        let str = `${val}`;
        let depString = str.slice(0, 3);
        let arrString = str.slice(3, 6)
        let dayString = str.slice(6, 8);
        let monthString = str.slice(8, 10);
        if (depString.length > 2 && (typeof depString === 'string')) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                departureL: depString
            }))
        }
        if (arrString.length > 2 && (typeof arrString === 'string')) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                arrivalL: arrString
            }))
        }
        if (dayString) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                DayL: Number(dayString)
            }))
        }
        if (monthString) {
            props.setdepartureCollector((prev: any) => ({
                ...prev,
                MonthL: Number(monthString)
            }))
        }
    };
    function OnSearch(val: string) {
        departureAirportValue = val
        if (departureAirportValue.length > 6) {
            setDepAirportValueDisplay(true);
        }

        if (departureAirportValue.length < 6) {
            setDepAirportValueDisplay(false);
        }

        if ((/^(?=.*\d.*\d.*\d.*\d)[A-Za-z\d]{6,}$/.test(val))) { //more 3 char more than 3 number
            stringSeparator(val)
        }
        if (isDeparture) {
            const segment: ISegmentState = { ...props.Segment, DepartureInput: val };
            props.UpdateSegment(props.SegmentIndex, segment);
        }
        else {
            const segment: ISegmentState = { ...props.Segment, ArrivalInput: val };
            props.UpdateSegment(props.SegmentIndex, segment);
           
        }


        if (val.length > 2) {


            setItems([]);
            setIsLoading(true);
            const client: PublicServicesClient = new PublicServicesClient(new ApiCredentialsOptions(AgentApiURL, ""))
            client.getAirportsByPrefix(val).then((result) => {
                if (result.length > 0) {
                    const map: dropdownItem[] = [];
                    result.forEach(e => {
                        const text = `[${e.iata}] - ${e.cityName} - ${e.name}`
                        map.push({ value: e.iata!, text: text, image: e.countryCode! })
                    })
                    if (!isNativeSearch) {
                        setItems(map);
                    }
                }
                else {
                    setItems([]);
                }
            }).catch(() => {
                setItems([]);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };
    const AirportAutoCompleteOptions = items.map((airport, index) =>
        <ListGroupItem
            className={`btn-sm`}
            key={`ac_${props.AirportType}_${props.SegmentIndex}_${index}`}
            onMouseEnter={() => setActiveAutoCompleteItem(index)}
            active={activeAutoCompleteItem === index}
            onClick={() => { OnAutocompleteClick(index) }}
            tag="button"
            action>
            <img src={`/images/flags/${airport.image.toLowerCase()}.png`} alt="airport" />{` `}
            <span>{airport.text}</span>
            {/* {activeAutoCompleteItem === index &&
                <span className="small float-end">{"  "}<kbd>enter</kbd>,<kbd>tab</kbd> </span>
            } */}
        </ListGroupItem>
    );
    function OnAutocompleteClick(index: number) {
        OnSelectAutocompleteItem();
    };
    function AirportLabel(): string {
        if (isLoading) {
            return "loading..."
        }
        if (InputValue.length < 2) {
            return "Type to see results..."
        }
        if (items.length > 0) {
            return `${items.length.toString()} Airports found`;
        }
        return "Type to see results...";
    };
    function ToolTipContent(popoverState: PopoverState): JSX.Element {
        return <ArrowContainer position={popoverState.position} childRect={popoverState.childRect} popoverRect={popoverState.popoverRect} arrowColor="black" arrowSize={5}>
            <Card className={depAirportValueDisplay ? 'hideAirportList' : ''} >
                <span className="text-center ">{AirportLabel()}</span>
                {/* <CardBody className="small"> */}
                {items.length > 0 &&
                    <ListGroup flush={true}>
                        {AirportAutoCompleteOptions}
                    </ListGroup>
                }
                {/* </CardBody> */}
            </Card>
        </ArrowContainer>
    };
    function OnFocus(e: React.FocusEvent<HTMLInputElement>) {
        e.stopPropagation();
        if (debug) {
            console.log(`Focus: ${isDeparture ? "Departure" : "Arrival"}`)
        }
        setInputActive(true);
        e.target.select();
    };
    function OnKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        const key = event.which;
        if (key === 40 || key === 38 || key === 13 || key === 9) {
            if (!(key === 13 && event.ctrlKey)) {
                // event.preventDefault();
                event.stopPropagation();
                if (key === 40) { //down arrow             
                    if (activeAutoCompleteItem < items.length - 1) {
                        setActiveAutoCompleteItem(activeAutoCompleteItem + 1)
                    }
                }
                if (key === 38) { //up arrow                                       
                    if (activeAutoCompleteItem > 0) {
                        setActiveAutoCompleteItem(activeAutoCompleteItem - 1)
                    }
                }
                if (!event.shiftKey && event.keyCode) {
                    if (key === 13 || key === 9) { // tab/enter
                        OnSelectAutocompleteItem();
                    }
                }
                // if (event.shiftKey && event.keyCode) {
                //     if (key === 9) { // tab/enter
                //         setInputActive(false);
                //     }
                // }
            }
        }
    };
    function OnPopoverClickOutside() {
        if (debug) {
            console.log(`OnPopoverClickOutside: ${isDeparture ? "Departure" : "Arrival"}`)
        }
        setInputActive(false);
    };
    function OnSelectAutocompleteItem() {
        if (debug) {
            console.log(`OnSelectAutocompleteItem: ${isDeparture ? "Departure" : "Arrival"}`)
        }

        if (items[activeAutoCompleteItem] !== undefined) {
            const autoCompleteItem = items[activeAutoCompleteItem];
            const value = autoCompleteItem.text;
            OnValueChanged(value);
        }
        setInputActive(false);
        setTimeout(function () { // set timeout to avoid triggering PopoverClick outside event on next input element
            props.OnSelectNextElem(props.AirportType);
        }, 100);
    };
    function OnValueChanged(value: string) {
        if (debug) {
            console.log(`OnValueChanged: ${isDeparture ? "Departure" : "Arrival"}`)
        }

        if (isDeparture) {
            const segment: ISegmentState = { ...props.Segment, DepartureInput: value };
            props.UpdateSegment(props.SegmentIndex, segment);

        }
        else {
            const segment: ISegmentState = { ...props.Segment, ArrivalInput: value };
            props.UpdateSegment(props.SegmentIndex, segment);
        }
    };
    return (
        <React.Fragment>
            <Popover onClickOutside={OnPopoverClickOutside} positions={['bottom', 'right', 'left', 'top']} isOpen={InputActive} containerStyle={{ zIndex: "100" }} content={(popoverState: PopoverState) => (ToolTipContent(popoverState))}>
                <div className="input-group">
                    <span className="input-group-text" title={`${IconTitle} Airport`}>
                        {PrependIcon}
                    </span>
                    <input id={InputID} className={`form-control ${GetInputValidLabel()} ${GetInputValidMonth()}`}
                        tabIndex={props.tabIndex}
                        value={InputValue}
                        onChange={(e) => OnSearch(e.target.value)}
                        onFocus={OnFocus}
                        autoComplete={"off"}
                        autoFocus={InputAutoFocus}
                        onKeyDown={(event) => { OnKeyDown(event); nextElementFocus({ tabIndex: props.tabIndex, reference: { airlineRef: props.reference } }) }}
                        ref={props.reference}
                    />
                </div>
            </Popover>
        </React.Fragment>
    );
};

export default AirportInput;