import React from "react";
import { PnrResponseOSIModel } from "WebApiClient";

export const PnrOsisCard: React.FC<{ data: PnrResponseOSIModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">OSI <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <OsiTable data={data}></OsiTable>
            </div>
        </div>
    );


}

export const OsiTable: React.FC<{ data: PnrResponseOSIModel[] }> = ({ data }) => {
    return <table className="table table-sm table-striped table-hover table-bordered">
        <thead>
            <tr>
                <th className="font-weight-bold">Carrier</th>
                <th className="font-weight-bold">Text</th>
            </tr>
        </thead>
        <tbody>

            {data.map((osi, osiIndex) =>

                <tr key={"osi_"+osiIndex}>
                    <td>{osi.carrier}</td>
                    <td>{osi.text}</td>
                </tr>
            )}
        </tbody>
    </table>
}




