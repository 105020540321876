import { ClassTypeEnumDisplay } from "components/Shared/ClassTypeEnumDisplay";
import { DateDisplay } from "components/Shared/DateDisplay";
import { DiscountEnumDisplay } from "components/Shared/DiscountEnumDisplay";
import { FareSubtypeDisplay } from "components/Shared/FareSubtypeDisplay";
import { QueryFareTypeDisplay } from "components/Shared/FareTypeDisplay";
import { TariffSubtypeEnumDisplay } from "components/Shared/TariffSubtypeEnumDisplay";
import { TariffTypeEnumDisplay } from "components/Shared/TariffTypeEnumDisplay";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvailableFareLegModel, DiscountEnum, FareInfoBoxView, FareResponseRuleModel, FareTypeInformation, LegFareInfoModel, TariffSubTypeEnum, TariffTypeEnum } from "WebApiClient";
import FareLegHeader from "./Fare.Leg.Header";

export const FareInfoBoxModal: React.FC<{ IsOpen: boolean, Toggle: () => void, Legs: AvailableFareLegModel[], Info: FareInfoBoxView; SelectedConnections: number[] }> = props => {
    const { Legs, Info, SelectedConnections } = props;
    return <Modal size="xl" centered={true} scrollable={true} isOpen={props.IsOpen} toggle={props.Toggle}>
        <ModalHeader toggle={props.Toggle}>Fare details</ModalHeader>
        <ModalBody>
            <div className="row">
                {Legs.map((leg, legIndex) =>
                    <div className="col-12 mb-2" key={"Legss" + legIndex}>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <div className="card">
                                    <div className="card-body card-body-secondary">
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <FareLegHeader
                                                    Data={leg.connections[SelectedConnections[leg.index]].connectionHeader!}
                                                    LegIndex={legIndex}
                                                    PlatingCarrierCode={leg.platingCarrier!.code!}
                                                    PlatingCarrierName={leg.platingCarrier!.hint!}
                                                    ShowLegPlatingCarrier={true}
                                                    ToggleFlightTimes={() => { }}
                                                    IsExpanded={false}
                                                />

                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <FareTypeDetails data={leg.fareTypeInformation}></FareTypeDetails>
                                                    </div>
                                                    <div className="col-6">
                                                        {leg.legFareInfo &&
                                                            <LegFareInfoComponent data={leg.legFareInfo} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ModalBody>
    </Modal>
}

const LegFareInfoRuleComponent: React.FC<{ data: FareResponseRuleModel }> = props => {
    const { lastBookingDate, matchCode, maxAge, minAge, minBookingBeforeDep, penalty, specialDiscount } = props.data;
    return <div></div>
}

const LegFareInfoComponent: React.FC<{ data: LegFareInfoModel }> = props => {
    const { bookingClass, farebase, legCabinClass, rule, tariffMatchCode, tariffSubType, tariffType, taxesIgnored, ticket } = props.data;
    const values: { key: string, value: string | JSX.Element }[] = [
        ...bookingClass ? [{ key: "Booking class", value: bookingClass }] : [],
        ...farebase ? [{ key: "Fare base", value: farebase }] : [],
        ...legCabinClass ? [{ key: "Cabin class", value: ClassTypeEnumDisplay(legCabinClass) }] : [],
        ...tariffMatchCode ? [{ key: "Tariff match code", value: tariffMatchCode }] : [],
        ...(tariffSubType && tariffSubType !== TariffSubTypeEnum.None) ? [{ key: "Tariff Sub type", value: TariffSubtypeEnumDisplay(tariffSubType) }] : [],
        ...(tariffType && tariffType !== TariffTypeEnum.None) ? [{ key: "Tariff type", value: TariffTypeEnumDisplay(tariffType) }] : [],
        ...(taxesIgnored !== null && taxesIgnored !== undefined && taxesIgnored === true) ? [{ key: "Taxes ignored", value: taxesIgnored ? "Yes" : "No" }] : [],
        ...rule ? [
            ...rule.lastBookingDate ? [{ key: "Last booking date", value: <DateDisplay date={rule.lastBookingDate} /> }] : [],
            ...rule.matchCode ? [{ key: "Rule match code", value: rule.matchCode }] : [],
            ...(rule.maxAge !== null && rule.maxAge !== undefined && rule.maxAge !== 0 && rule.maxAge !== -1) ? [{ key: "Maximum age", value: rule.maxAge.toString() }] : [],
            ...(rule.minAge !== null && rule.minAge !== undefined && rule.minAge !== 0 && rule.minAge !== -1) ? [{ key: "Minimum age", value: rule.minAge.toString() }] : [],
            ...(rule.minBookingBeforeDep !== null && rule.minBookingBeforeDep !== undefined && rule.minBookingBeforeDep !== 0 && rule.minBookingBeforeDep !== -1) ? [{ key: "Booking: Minimum days before departure", value: rule.minBookingBeforeDep.toString() }] : [],
            ...rule.penalty ? [{ key: "Penalty", value: rule.penalty }] : [],
            ...(rule.specialDiscount && rule.specialDiscount !== DiscountEnum.NoDiscountedFares) ? [{ key: "Special discount", value: DiscountEnumDisplay(rule.specialDiscount) }] : [],

        ] : [],
        ...ticket ? [
            ...ticket.lastTicketingDate ? [{ key: "Last ticketing date", value: <DateDisplay date={ticket.lastTicketingDate} /> }] : [],
            ...(ticket.maxTicketingAfterBooking !== null && ticket.maxTicketingAfterBooking !== undefined && ticket.maxTicketingAfterBooking !== 0 && ticket.maxTicketingAfterBooking !== -1) ? [{ key: "Ticketing: Maximum days after booking ", value: ticket.maxTicketingAfterBooking.toString() }] : [],
            ...(ticket.minTicketingBeforeDeparture !== null && ticket.minTicketingBeforeDeparture !== undefined && ticket.minTicketingBeforeDeparture !== 0 && ticket.minTicketingBeforeDeparture !== -1) ? [{ key: "Ticketing: Minimum days before departure ", value: ticket.minTicketingBeforeDeparture.toString() }] : [],
            ...(ticket.ticketBySupplier !== null && ticket.ticketBySupplier !== undefined) ? [{ key: "Ticketing by supplier", value: ticket.ticketBySupplier ? "Yes" : "No" }] : [],
            ...ticket.ticketOnDepInfo ? [{ key: "Ticket deposit at airport", value: ticket.ticketOnDepInfo }] : [],
        ] : []
    ];
    return <div className="table-responsive">
        <table className="table table-sm table-bordered">
            <tbody>
                {values.map((e, i) =>
                    <tr key={"values" + i}>
                        <td className="font-weight-bold">{e.key}</td>
                        <td>{e.value}</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
}

const FareTypeDetails: React.FC<{ data: FareTypeInformation }> = props => {
    const { agentCompanyID, agentID, corporateCode, fareSubType, fareType, isPercentage, originalFareType, salesPreferenceDisplayName, salesPreferencePriority } = props.data;
    const values: { key: string, value: string }[] = [
        { key: "Original fare type", value: originalFareType },
        ...salesPreferenceDisplayName ? [{ key: "Sales preference name", value: salesPreferenceDisplayName }] : [],
        ...agentCompanyID ? [{ key: "Agent Company ID", value: agentCompanyID }] : [],
        ...agentID ? [{ key: "Agent ID", value: agentID }] : [],
        ...corporateCode ? [{ key: "Corporate Code", value: corporateCode }] : [],
        ...fareSubType ? [{ key: "Fare Sub type", value: FareSubtypeDisplay(fareSubType) }] : [],
        ...fareType ? [{ key: "Fare type", value: QueryFareTypeDisplay(fareType) }] : [],
        ...(isPercentage !== null && isPercentage !== undefined) ? [{ key: "Percentage fare", value: isPercentage ? "Yes" : "No" }] : [],
        ...(salesPreferencePriority !== null && salesPreferencePriority !== undefined && salesPreferencePriority !== 0) ? [{ key: "Sales preference priority", value: salesPreferencePriority.toString() }] : [],
    ];
    return <div className="table-responsive">

        <table className="table table-sm table-bordered">
            <tbody>
                {values.map((e, i) =>
                    <tr key={"valuess2" + i}>
                        <td className="font-weight-bold">{e.key}</td>
                        <td>{e.value}</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
}