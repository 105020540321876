import React from "react";
import { RaisedExceptionModel } from "WebApiClient";
export const RaisedExecptionCard: React.FC<{ data: RaisedExceptionModel }> = ({ data }) => {
    const { crs, source, sourceIP, extendedTransactionID, exceptionText, exceptionCode } = data;
    return <div className="alert alert-danger">
        <div className="row">
            <div className="col-12">An error has occured.</div>
            <div className="col-12">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>Code</td>
                            <td>{exceptionCode}</td>
                        </tr>
                        <tr>
                            <td>Code</td>
                            <td>{exceptionText}</td>
                        </tr>
                        <tr>
                            <td>Transaction ID</td>
                            <td>{extendedTransactionID}</td>
                        </tr>
                        <tr>
                            <td>Source</td>
                            <td>{source}</td>
                        </tr>
                        <tr>
                            <td>Source IP</td>
                            <td>{sourceIP}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {crs &&

                <div className="col-12">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>GDS</td>
                                <td>{crs.gds}</td>
                            </tr>
                            <tr>
                                <td>ID</td>
                                <td>{crs.guid}</td>
                            </tr>
                            <tr>
                                <td>Received from</td>
                                <td>{crs.receivedFrom}</td>
                            </tr>
                            <tr>
                                <td>Terminal country</td>
                                <td>{crs.terminalCountry}</td>
                            </tr>
                            <tr>
                                <td>Terminal PCC</td>
                                <td>{crs.terminalPCC}</td>
                            </tr>
                            <tr>
                                <td>User</td>
                                <td>{crs.user}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>


    </div>
}