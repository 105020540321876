import { AgentApiURL } from "Constants";
import React, { useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import Logo from "Styles/images/airline_not_found.gif";
import { Airline } from "WebApiClient";


export const AirlineObjLogo: React.FC<{ airline: Airline }> = ({ airline }) => {
    return <AirlineLogo IsAlliance={false} PlatingCarrier={airline.code} PlatingCarrierName={airline.hint} />

}

interface AirlineLogoProps {
    PlatingCarrier: string;
    PlatingCarrierName: string;
    className?: string;
    width?: number;
    height?: number;
    IsAlliance: boolean;

}
export const AirlineLogo: React.FC<AirlineLogoProps> = props => {
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const elemRef = useRef<HTMLImageElement>(null);


    function GetLogoPath(): string {
        let result = "";
        if (props.IsAlliance) {
            const allianceCode = props.PlatingCarrier.toLowerCase();
            if (allianceCode === "*a") result = "staralliance";
            if (allianceCode === "*o") result = "oneworld";
            if (allianceCode === "*s") result = "skyteam";
        }
        else {
            result = `${props.PlatingCarrier}`
        }
        return result;
    }
    return (
        <div className={`${props.className ? props.className : ""}`}>
            <Popover target={elemRef} isOpen={open} toggle={toggle}>
                <PopoverBody>
                    {props.PlatingCarrierName}
                </PopoverBody>
            </Popover>
            <img
                alt="plating"
                width={props.width ? props.width : 120}
                height={props.height ? props.height : 40}
                src={`${error ? Logo : `${AgentApiURL}/publicservices/images/airline/${GetLogoPath()}`}`}
                onError={() => setError(true)}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onTouchStart={(e) => { e.stopPropagation(); setOpen(true) }}
                onTouchEnd={() => setOpen(false)}
                ref={elemRef}
            />
        </div>
    );
};