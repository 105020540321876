import { faBug, faCommentExclamation, faCommentsDollar, faEdit, faExclamation, faExclamationTriangle, faMoneyBillWave, faPeopleArrows, faPlusCircle, faQuestionCircle, faRetweet, faRobot, faStickyNote, faStopwatch, faTrash, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ENotificationActionRequiredEnumModel, ENotificationCategoryEnumModel, ENotificationSourceEnumModel, ENotificationStatusEnumModel } from "WebApiClient";
export const NotificationCatergoryDisplay: React.FC<{ category: ENotificationCategoryEnumModel }> = ({ category }) => {

    function GetContent() {
        let label = "";
        let icon: IconDefinition = faQuestionCircle;
        switch (category) {
            case ENotificationCategoryEnumModel.BookingFailed:
                icon = faExclamationTriangle;
                label = "Booking failed";
                break;
            case ENotificationCategoryEnumModel.OCN:
                icon = faRetweet;
                label = "Order Changed";
                break;
            case ENotificationCategoryEnumModel.Other:
                label = "Other";
                break;
            case ENotificationCategoryEnumModel.PaymentRejected:
                icon = faCommentsDollar;
                label = "Payment rejected";
                break;
            case ENotificationCategoryEnumModel.QueueRobot:
                icon = faRobot;
                label = "Queue Robot";
                break;
            case ENotificationCategoryEnumModel.Remark:
                icon = faStickyNote;
                label = "Remark";
                break;
            case ENotificationCategoryEnumModel.Reprice:
                icon = faMoneyBillWave;
                label = "Reprice";
                break;
            case ENotificationCategoryEnumModel.TTL:
                icon = faStopwatch;
                label = "TTL";
                break;
            default:
                label = "unkown"
                break;
        }

        return <span><FontAwesomeIcon icon={icon} /> {label}</span>
    }
    return <span>{GetContent()}</span>
}
export const NotificationSourceDisplay: React.FC<{ source: ENotificationSourceEnumModel }> = ({ source }) => {
    function GetContent() {
        let label = "";
        let icon: IconDefinition = faQuestionCircle;
        switch (source) {
            case ENotificationSourceEnumModel.Extern:
                label = "External";
                icon = faCommentExclamation;
                break;
            case ENotificationSourceEnumModel.Intern:
                icon = faPeopleArrows;
                label = "Internal";
                break;

            default:
                label = "unkown";
                break;
        }

        return <span><FontAwesomeIcon icon={icon} /> {label}</span>
    }
    return <span>{GetContent()}</span>
}
export const NotificationStatusDisplay: React.FC<{ status: ENotificationStatusEnumModel }> = ({ status }) => {
    function GetContent() {
        let label = "";
        let icon: IconDefinition = faQuestionCircle;
        switch (status) {
            case ENotificationStatusEnumModel.Created:
                label = "Created";
                icon = faPlusCircle;
                break;
            case ENotificationStatusEnumModel.Failed:
                label = "Failed";
                icon = faBug;
                break;
            case ENotificationStatusEnumModel.Modified:
                label = "Modified";
                icon = faEdit;
                break;
            case ENotificationStatusEnumModel.Removed:
                icon = faTrash;
                label = "Removed";
                break;
            case ENotificationStatusEnumModel.Unknown:
                label = "Unkown";
                break;


            default:
                label = "Unkown";
                break;
        }

        return <span><FontAwesomeIcon icon={icon} /> {label}</span>
    }
    return <span>{GetContent()}</span>
}
export const NotificationActionRequiredDisplay: React.FC<{ category: ENotificationActionRequiredEnumModel }> = ({ category }) => {
    function GetContent() {
        let label = "";
        let icon: IconDefinition = faExclamation;
        let color = "";
        let iconAmount = 1;
        switch (category) {
            case ENotificationActionRequiredEnumModel.High:
                label = "High priority";
                color = "red";
                iconAmount = 2;
                break;
            case ENotificationActionRequiredEnumModel.Low:
                label = "Low priority";
                color = "green";
                break;
            case ENotificationActionRequiredEnumModel.Medium:
                label = "Medium priority";
                color = "orange";
                iconAmount = 2;
                break;
            case ENotificationActionRequiredEnumModel.None:
                label = "No priority"
                break;
            case ENotificationActionRequiredEnumModel.Urgent:
                label = "URGENT";
                icon = faExclamation;
                color = "red";
                iconAmount = 3;
                break;
            default:
                label = "unkown";
                break;
        }
        let iconArr: JSX.Element[] = [];
        for (let index = 0; index < iconAmount; index++) {
            iconArr.push(<FontAwesomeIcon icon={icon} />)
        }
        return <span style={{ color: color }}>
            {iconArr.map((e, i) =>
                <span key={"iconArr"+i}>{e} </span>
            )}
            {` `}{label}</span>
    }
    return <span>{GetContent()}</span>
}
