import { faAngleDoubleRight, faCalendarAlt, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GuessDate, { GuessDateResult } from "components/FareSearch/Business/GuessDate";
import { useDateFormat, useFormatDate } from "hooks/useLocalization";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ActiveModifiers, DayPicker, Matcher } from "react-day-picker";
import 'react-day-picker/dist/style.css';
import { ArrowContainer, Popover, PopoverPosition, PopoverState } from 'react-tiny-popover';
import { Card } from "reactstrap";
import { InvariantDateModel } from "WebApiClient";
import { GetNullableApiRequestDate } from "./Helpers/DateConverters";
import { Calendar } from '@contentful/f36-components';
import nextElementFocus from "components/FareSearch/component/FlightDetails/nextElementFocus";

interface InvariantDateInputProps extends BaseDateInputProps {
    Value: InvariantDateModel | undefined;
    OnDateChange(value: InvariantDateModel | undefined): void

}
interface DateInputProps extends BaseDateInputProps {
    Value: Date | undefined;
    OnDateChange(value: Date | undefined): void
    isDisble?: boolean
}
interface BaseDateInputProps {
    SelectNextInput?(): void
    InputID?: string
    PopoverPosition?: PopoverPosition[];
    MinDate?: Date;
    MaxDate?: Date;
    InputGroup: boolean;
    InputClassNames?: string;
    PlaceHolder?: string;
    IsDob: boolean;
    booked1?: boolean;
    booked2?: boolean;
    bookedDays1?: any;
    bookedDays2?: any;
    bookedDays3?: any;
    bookedStyle1?: any;
    bookedStyle2?: any;
    bookedStyle3?: any;
    btnRef?: any;
    reference?: any;
    tabIndex?: any;
    required?: string
}

export const InvariantDateInput: React.FC<InvariantDateInputProps> = props => {
    function OnDateChange(date: Date | undefined) {
        const update = GetNullableApiRequestDate(date);
        props.OnDateChange(update);
    }
    const date: Date | undefined = props.Value ? moment({ day: props.Value.day, month: props.Value.month - 1, year: props.Value.year }).toDate() : undefined;
    return <DateInput {...props} OnDateChange={OnDateChange} Value={date} />
}

export const DateInput: React.FC<DateInputProps> = props => {

    const [inputStringValue, setInputStringValue] = useState("");
    const debug = false;
    const [inputDateValue, setInputDateValue] = useState<Date | undefined>(undefined);
    const [guessedDate, setGuessedDate] = useState<GuessDateResult | undefined>(undefined);
    const [inputActive, setInputActive] = useState(false);
    const dateDisplay = useFormatDate(props.Value);
    const dateFormat = useDateFormat();

    useEffect(() => {
        if (props.Value) {
            // console.log("dateDisplay", dateDisplay, props.Value);
            setInputStringValue(dateDisplay);
            setInputDateValue(props.Value);
            // props.btnRef?.current.focus();
        }
        else {
            setInputStringValue("");
            setInputDateValue(props.Value);
        }
    }, [props.Value])

    function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        // console.log("onInputChange fired");
        const value: string = event.target.value;
        setGuessedDate(GuessDate(value, dateFormat, props.MinDate, props.MaxDate, props.IsDob));
        setInputStringValue(value);
    }

    function onInputFocus(event: React.FocusEvent<HTMLInputElement>): void {
        event.target.select()
    }

    function onInputSelect(event: React.SyntheticEvent<HTMLInputElement, Event>) {
        setInputActive(true)
    }

    function OnDayPickerSelection(day: Date | undefined, selectedDay: Date, activeModifiers: ActiveModifiers, e: React.MouseEvent) {
        if (debug) {
            console.log("OnDayPickerSelection fired");
        }
        OnSelectDate(selectedDay);
    }

    function OnSelectDate(date: Date | undefined) {
        if (debug) {
            console.log("OnSelectDate fired");
        }
        setInputActive(false);
        props.OnDateChange(date);
        if (props.SelectNextInput) {
            // console.log("Datepicker selectnext fired");
            props.SelectNextInput();
        }
    }

    function handleYearMonthChange(date: Date) {
        if (debug) {
            console.log("handleYearMonthChange fired");
        }
        setInputDateValue(date);
    }

    function OnPopoverClickOutside(event: MouseEvent) {
        if (debug) {
            console.log("OnPopoverClickOutside fired");
        }
        setInputActive(false);
        OnInputLeave();
    }

    function OnTakeGuessedDateButtonclick() {
        if (guessedDate) {
            OnSelectDate(guessedDate.Result);
        }
    }

    function OnClear() {
        props.OnDateChange(undefined);
    }

    function OnClosePicker() {
        if (debug) {
            console.log("OnClosePicker");
        }
        if (guessedDate) {
            OnSelectDate(guessedDate.Result);
        }
        setInputActive(false);
    }

    const before: Matcher | undefined = props.MinDate ? { before: props.MinDate } : undefined;
    const after: Matcher | undefined = props.MaxDate ? { after: props.MaxDate } : undefined;
    const match: Matcher[] = [
        before ? before : [],
        after ? after : [],
    ];

    // const [booked1, setBooked1] = React.useState(false);
    // const [booked2, setBooked2] = React.useState(false);

    function ToolTipContent(popoverState: PopoverState): JSX.Element {
        // const res = this.GetDateGuess();
        return <ArrowContainer position={popoverState.position} childRect={popoverState.childRect} popoverRect={popoverState.popoverRect} arrowColor="black" arrowSize={2}>
            <div>
                <Card>
                    <button type="button" className="btn btn-outline btn-sm me-2" onClick={OnClosePicker}><FontAwesomeIcon icon={faTimes} /> close</button>
                    <Calendar
                        className="smartCalenderDiv"
                        mode="single"
                        onSelect={OnDayPickerSelection}
                        selected={inputDateValue}
                        modifiers={{ booked1: props.bookedDays1, booked2: props.bookedDays2, booked3: props.bookedDays3 }}
                        modifiersStyles={{ booked1: props.bookedStyle1, booked2: props.bookedStyle2, booked3: props.bookedStyle3 }}
                        // numberOfMonths={1}
                        disabled={match}
                        showOutsideDays={false}
                        // month={inputDateValue}
                        fromMonth={props.MinDate}
                        toMonth={props.MaxDate}
                        fromYear={props.MinDate?.getFullYear()}
                        toYear={props.MaxDate?.getFullYear()}
                        defaultMonth={inputDateValue} />
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-danger" style={{ color: "white" }} onClick={OnClear}><FontAwesomeIcon icon={faTrash} /></button>
                        {guessedDate &&
                            <button type="button" className="btn ms-2 btn-sm btn-primary" onClick={OnTakeGuessedDateButtonclick}>
                                <FontAwesomeIcon icon={faAngleDoubleRight} /> {guessedDate ? guessedDate.ResultString : "type to see autocomplete.."}
                            </button>
                        }
                    </div>

                </Card>
            </div>
        </ArrowContainer>
    }

    function OnInputLeave() {
        if (debug) {
            console.log("Onleave fired")
        }

        if (inputStringValue !== "") {
            if (guessedDate) {
                OnSelectDate(guessedDate.Result);
            }
        }
        else {
            OnSelectDate(undefined);
        }
    }

    function OnInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if ((e.which === 13 || e.which === 9 || e.which === 27)) { // TAB / ENTER / ESC
            setInputActive(false);
            // props.btnRef?.current.focus();
            if (!(e.which === 13 && e.ctrlKey)) {
                if (e.which !== 9) {// TAB
                    e.preventDefault();
                    e.stopPropagation();
                }
                OnInputLeave();
            }

        }

    }

    return <Popover
        positions={props.PopoverPosition !== undefined ? props.PopoverPosition : ['bottom', 'left', 'right', 'top']}
        isOpen={(inputActive)}
        containerStyle={{ zIndex: "999999" }}
        onClickOutside={OnPopoverClickOutside}
        content={(popoverState: PopoverState) => (ToolTipContent(popoverState))}>

        <div className={`${props.InputGroup ? "input-group" : ""}`}>
            {props.InputGroup &&
                <span className="input-group-text" title="Flight Date">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
            }
            <input
                value={inputStringValue}
                tabIndex={props.tabIndex}
                onChange={onInputChange}
                type="text"
                className={`${props.InputClassNames ? props.InputClassNames : "form-control"}`}
                autoComplete={"off"}
                onKeyDown={(e) => { OnInputKeyDown(e); nextElementFocus({ tabIndex: props.tabIndex, reference: { InputDate: props.reference } }) }}
                onFocus={onInputFocus}
                onSelect={onInputSelect}
                id={props.InputID}
                placeholder={props.PlaceHolder !== undefined ? props.PlaceHolder : "Date"}
                ref={props.reference}
                required
                disabled={props.isDisble}
            />
            {/* {debug &&
                <div>DEBUG: {props.Value ? props.Value.toISOString() : ""}</div>
            } */}
        </div>
    </Popover>
}

export default DateInput;