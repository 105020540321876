import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISegmentState } from "components/FareSearch/types/FareSearchTypes";
import React, { useState } from "react";

const SegmentButtons: React.FC<OwnProps> = props => {
  const [addReturnActive, setReturnActive] = useState(false)
  const [removeFlightActive, setremoveFlightActive] = useState(false)




  // function AddReturnFlightPopover(position: Position, targetRect: ClientRect, popOverRect: ClientRect): JSX.Element {
  //   return <ArrowContainer position={position} targetRect={targetRect} popoverRect={popOverRect}>
  //     <Card >
  //       <span className="small"><kbd>enter</kbd> add return flight</span>
  //     </Card>

  //   </ArrowContainer>
  // }


  return (
    <React.Fragment>
      <div className="col-12 col-lg-auto mb-3 mb-lg-1 row">
        {/* 
        {props.NextSegment === undefined && props.Segment.ArrivalInput !== "" && props.Segment.DepartureInput !== "" &&
          <Popover
            position="top"
            isOpen={addReturnActive}
            containerStyle={{ zIndex: "100" }}
            content={({ position, targetRect, popoverRect }) => (AddReturnFlightPopover(position, targetRect, popoverRect))}
          >
            <div className="col" title="add return flight">
              <button
                onClick={() => { setReturnActive(false); props.AddReturnSegment(props.SegmentIndex) }}
                onKeyDown={() => { setReturnActive(false) }}
                onFocus={() => { setReturnActive(true) }}
                onBlur={() => setReturnActive(false)}
                className="btn btn-removeSegment d-flex align-items-center deleteSegment h-100"
              >
                <FontAwesomeIcon icon={faUndo} size="lg" />

                <span className="d-inline d-lg-none pl-1">add return flight</span></button>
            </div>
          </Popover>
        } */}
        {props.SegmentIndex !== 0 &&
          <div className="col" title="remove this flight">
            <button
              onClick={() => { props.RemoveSegment(props.SegmentIndex); setremoveFlightActive(false) }}
              onKeyDown={() => { setremoveFlightActive(false) }}
              onFocus={() => setremoveFlightActive(true)}
              onBlur={() => { setremoveFlightActive(false) }}
              tabIndex={props.SegmentIndex}
              className="btn btn-removeSegment d-flex align-items-center deleteSegment h-100"
            >
              <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "1.5em", color: "red" } as React.CSSProperties} />

              <span className="d-inline d-lg-none pl-1">remove flight</span></button>
          </div>
          // <Popover
          //   position="top"
          //   isOpen={removeFlightActive}
          //   containerStyle={{ zIndex: "100" }}
          //   content={({ position, targetRect, popoverRect }) => (AddRemoveFlightPopover(position, targetRect, popoverRect))}
          // >

          // </Popover>
        }
      </div>

    </React.Fragment>
  );
};

interface OwnProps {
  SegmentIndex: number;
  Segment: ISegmentState;
  NextSegment?: ISegmentState;
  RemoveSegment: (index: number) => void
  AddReturnSegment: (index: number) => void

}


export default SegmentButtons
