import SubRatingPopup from "components/HotelSearch/component/SubRatingPopup";
import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  NavLink,
} from "reactstrap";
import { useAvailableHotelsClient } from "hooks/useHttpClient";
import CustomerInfo from "components/BookingHotel/component/CustomerInfo";
import { HotelsMap } from "components/BookingHotel/component/HotelsMap";
const moment = require("moment");
interface hotelDetails {
  tabId: string;
  hotelData: any;
  isSectionVisible: boolean;
  indexH: number;
  setOpenIndex: any;
  openIndex: any;
  handleItemClick: any;
  HotelSearch: any;
  setCustomerInfo: any;
  customerInfo: any;
}

const HotelDetails: React.FC<hotelDetails> = (props) => {
  const Hotel = useAvailableHotelsClient();
  const [images, setImages] = useState<[] | any>([]);
  const [activeTab, setActiveTab] = React.useState(props.tabId);
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectionVisible, SetIsSelectionVisible] = useState(
    props.isSectionVisible
  );
  const [isSectionVisible, setIsSectionVisible] = useState(
    props.isSectionVisible
  );

  const tabContentRef = useRef<HTMLDivElement | null>(null);
  const MAX_IMAGE_WIDTH = 300;
  const MAX_IMAGE_HEIGHT = 300;
  useEffect(() => {
    setActiveTab(props.tabId);
    setIsSectionVisible(props.isSectionVisible);
    SetIsSelectionVisible(props.isSectionVisible);
  }, [props.tabId, props.isSectionVisible]);

  const amenities = [
    { name: "24h reception", emoji: "🕛" },
    { name: "accessibility", emoji: "♿️" },
    { name: "air condition", emoji: "❄️" },
    { name: "beauty salon", emoji: "💇‍♀️" },
    { name: "bikes", emoji: "🚲" },
    { name: "concierge", emoji: "💼" },
    { name: "hair dryer", emoji: "💇" },
    { name: "internet", emoji: "🌐" },
    { name: "iron", emoji: "🔨" },
    { name: "no smoking", emoji: "🚭" },
    { name: "parking lot", emoji: "🅿️" },
    { name: "pets friendly", emoji: "🐾" },
    { name: "refrigerator", emoji: "🧊" },
    { name: "spa", emoji: "💆" },
    { name: "tv", emoji: "📺" },
    { name: "tv channels (satellite)", emoji: "📡" },
  ];

  const reviews = [
    {
      id: 1,
      author: "John Doe",
      rating: 4,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam non tortor mi.",
    },
    {
      id: 2,
      author: "Jane Smith",
      rating: 5,
      comment:
        "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    },
    // Add more review objects as needed
  ];

  const toggleTab = async (tabId: any) => {
    if (activeTab !== tabId) {
      if (tabId == "tab1") {
        await getImage();
      }
      setActiveTab(tabId);
    }
  };

  useEffect(() => {
    setActiveTab(props.tabId);
  }, [props.tabId]);

  useEffect(() => {
    getImage();
    // getCancelationPolicy()
  }, []);

  const getImage = async () => {
    try {
      const response = await Hotel.GetHotelPicturesAsync(
        props.hotelData.tripAdvisor.id
      );
      setImages(response);
    } catch (error) {
      // Handle any errors that might occur during the asynchronous operation
      console.error("Error fetching hotel images:", error);
    }
  };

  // const getCancelationPolicy = async () => {
  //     try {
  //         const response = await Hotel.GetCancellationTermsAsync(props.hotelData.tripAdvisor.id, props.HotelSearch.searchKeyword);
  //         console.log(response);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  useEffect(() => {
    const closePopupOnOutsideClick = (e: MouseEvent) => {
      if (
        tabContentRef.current &&
        !tabContentRef.current.contains(e.target as Node)
      ) {
        setIsSectionVisible(false);
      }
    };

    document.addEventListener("mousedown", closePopupOnOutsideClick);
    return () => {
      document.removeEventListener("mousedown", closePopupOnOutsideClick);
    };
  }, []);

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const clickedElement = e.target as HTMLElement;
    if (clickedElement.tagName.toLowerCase() !== "img") {
      setIsSectionVisible(false);
    }
  };
  console.log("hotel", props.hotelData.PriceData);

  return (
    <>
      {props.openIndex === props.indexH &&
        isSectionVisible &&
        props.openIndex === props.indexH &&
        isSelectionVisible && (
          <Card>
            <div className="tabPan">
              <Nav tabs className="justify-content-center nav">
                <NavItem>
                  <NavLink
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={() => toggleTab("tab1")}
                  >
                    Photos
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                                <NavLink
                                    className={activeTab === 'tab2' ? 'active' : ''}
                                    onClick={() => toggleTab('tab2')}>
                                    Reviews
                                </NavLink>
                            </NavItem> */}
                <NavItem>
                  <NavLink
                    className={activeTab === "tab3" ? "active" : ""}
                    onClick={() => toggleTab("tab3")}
                  >
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "tab4" ? "active" : ""}
                    onClick={() => toggleTab("tab4")}
                  >
                    Map
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "tab5" ? "active" : ""}
                    onClick={() => toggleTab("tab5")}
                  >
                    Cancellation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "tab6" ? "active" : ""}
                    onClick={() => toggleTab("tab6")}
                  >
                    Book
                  </NavLink>
                </NavItem>
              </Nav>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane className="tabArea" tabId="tab1">
                    <Row>
                      <div
                        className="d-flex flex-wrap"
                        onClick={handleImageClick}
                      >
                        {images.map((img: any, index: number) => {
                          var imageObject: any = new window.Image();
                          imageObject.src = img.url;
                          const width = Math.min(img.width, MAX_IMAGE_WIDTH);
                          const height = Math.min(img.height, MAX_IMAGE_HEIGHT);
                          // img.url is a valid image URL and the image loaded successfully
                          return (
                            <div key={"HotelImages-" + img.url + "-" + index}>
                              <img
                                className="m-2"
                                src={img.url}
                                title={img.title}
                                alt=""
                                width={width}
                                height={height}
                              />
                              <span>{img.title}</span>
                            </div>
                          );
                        })}
                      </div>
                    </Row>
                  </TabPane>
                  {/* <TabPane className='tabArea' tabId="tab2">
                                    <Row>
                                        <div className="reviews-tab-content">
                                       
                                            <div className='text-end'>
                                                <p className='review' style={{ fontWeight: "bold", position: "relative", display: "inline-block", marginRight: "10px" }} onMouseEnter={toggleModal} onMouseLeave={toggleModal}>{props.hotelData?.tripAdvisor?.tripAdvisorData?.rating}
                                                    {
                                                        isOpen &&
                                                        <section >
                                                            <SubRatingPopup ratingData={props.hotelData?.tripAdvisor?.tripAdvisorData?.subRating} isOpen={isOpen} hotelData={props.hotelData} />
                                                        </section>
                                                    }
                                                </p>
                                                <span style={{ fontWeight: "bold", cursor: "pointer" }} >{props.hotelData?.tripAdvisor?.tripAdvisorData?.reviewcount} reviews</span>
                                            </div>
                                            {reviews.map((review) => (
                                                <div key={review.id + review.author + '_review'} className="reviewBox">
                                                    <div className="review-header">
                                                        <h5 className="review-author">{review.author}</h5>
                                                        <div className="review-rating">
                                                           
                                                            {Array(review.rating).fill(<span key={review?.rating}>&#9733;</span>)}
                                                        </div>
                                                    </div>
                                                    <p className="review-comment">{review?.comment}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </Row>
                                </TabPane> */}
                  <TabPane className="tabArea" tabId="tab3">
                    <section className="p-2">
                      <div className="reviewBox">
                        <p className="review-comment">
                          {props.hotelData?.tripAdvisor?.description}
                        </p>
                      </div>
                    </section>
                  </TabPane>
                  <TabPane className="tabArea" tabId="tab4">
                    <HotelsMap hotelData={props.hotelData?.tripAdvisor} />
                  </TabPane>
                  <TabPane className="tabArea" tabId="tab5">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Meal</th>
                          <th scope="col">Room Type</th>
                          <th scope="col">Policy</th>
                          <th scope="col">Cancellation Cost</th>
                          <th scope="col">Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.hotelData.PriceData.map(
                          (hotel: any, index: number) => (
                            <tr key={"hotel" + index + "hotels"}>
                              <td>{hotel.items[0].board}</td>
                              <td>{hotel.items[0].name}</td>
                              {/* <td>{hotel.cancellation.type}</td> */}
                              <td>
                                {/* <strong>{hotel.items[0].name}</strong> (
                                {hotel.items[0].board}) */}
                                <span style={{ padding: "0", margin: "0" }}>
                                  &nbsp;
                                  {hotel.cancellation.type ==
                                    "non-refundable" && (
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="non-refundable"
                                    >
                                      <img
                                        width="35"
                                        src={
                                          require("../../../Styles/images/No-refund.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                  )}
                                  {hotel.cancellation.type ==
                                    "fully-refundable" && (
                                    // `The cancellation period is from ${moment(
                                    //   hotel.cancellation?.frames[0]?.from
                                    // ).format("YYYY-MM-DD")} to ${moment(
                                    //   hotel.cancellation?.frames[0]?.to
                                    // ).format(
                                    //   "YYYY-MM-DD"
                                    // )}, with a penalty is ${
                                    //   hotel.cancellation?.frames[0]?.penalty
                                    //     .currency
                                    // } ${
                                    //   hotel.cancellation?.frames[0]?.penalty
                                    //     .amount
                                    // }`
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="fully-refundable"
                                    >
                                      <img
                                        width="35"
                                        src={
                                          require("../../../Styles/images/Full refund.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                  )}
                                  {hotel.cancellation.type ==
                                    "partially-refundable" && (
                                    // `The cancellation period is from ${moment(
                                    //   hotel.cancellation?.frames[0]?.from
                                    // ).format("YYYY-MM-DD")} to ${moment(
                                    //   hotel.cancellation?.frames[0]?.to
                                    // ).format(
                                    //   "YYYY-MM-DD"
                                    // )}, with a penalty is ${
                                    //   hotel.cancellation?.frames[0]?.penalty
                                    //     .currency
                                    // } ${
                                    //   hotel.cancellation?.frames[0]?.penalty
                                    //     .amount
                                    // }`
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="partially-refundable"
                                    >
                                      <img
                                        width="35"
                                        src={
                                          require("../../../Styles/images/Partial refund.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                  )}
                                </span>
                              </td>
                              <td>
                                {hotel.cancellation.type == "fully-refundable"
                                  ? `${hotel.price.currency} 0`
                                  : `${
                                      hotel.price.currency
                                    } ${hotel.price.amount.toFixed(2)}`}
                              </td>
                              <td>
                                {hotel.cancellation &&
                                hotel.cancellation.type === "fully-refundable"
                                  ? hotel.cancellation.frames &&
                                    hotel.cancellation.frames[1]
                                    ? `Cancellation from ${moment(
                                        hotel.cancellation.frames[1].from
                                      ).format("YYYY-MM-DD")} till ${moment(
                                        hotel.cancellation.frames[1].to
                                      ).format(
                                        "YYYY-MM-DD"
                                      )}, with penalty of ${
                                        hotel.cancellation.frames[1].penalty
                                          .currency
                                      } ${hotel.cancellation.frames[1].penalty.amount.toFixed(
                                        2
                                      )}`
                                    : null
                                  : hotel.cancellation &&
                                    hotel.cancellation.type === "non-refundable"
                                  ? hotel.cancellation.frames &&
                                    hotel.cancellation.frames[0]
                                    ? `Cancellation from ${moment(
                                        hotel.cancellation.frames[0].from
                                      ).format("YYYY-MM-DD")} till ${moment(
                                        hotel.cancellation.frames[0].to
                                      ).format(
                                        "YYYY-MM-DD"
                                      )}, with penalty of ${
                                        hotel.cancellation.frames[0].penalty
                                          .currency
                                      } ${hotel.cancellation.frames[0].penalty.amount.toFixed(
                                        2
                                      )}`
                                    : null
                                  : null}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </TabPane>
                  <TabPane className="tabArea" tabId="tab6">
                    <CustomerInfo
                      setCustomerInfo={props.setCustomerInfo}
                      customerInfo={props.customerInfo}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </div>
          </Card>
        )}
    </>
  );
};

export default HotelDetails;
