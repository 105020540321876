import {
  faPlusCircle,
  faTimes,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PNRPassengerCard } from "components/PNR/Pnr.PassengersCard";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import APISMainInput from "components/Shared/APISInput/APISInput.Main";
import { MainApisValid } from "components/Shared/APISInput/APISInput.Validate.Main";
import { DateDisplay } from "components/Shared/DateDisplay";
import DateInput from "components/Shared/DateInput";
import { GetNullableApiRequestDate } from "components/Shared/Helpers/DateConverters";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  AddElementsToBookingRequestModel,
  AddPassengerAPISToBookingRequest,
  AgentGenderEnum,
  BaseApiResponse,
  BookingIdentifier,
  BookingItemElementsManagementModel,
  FareAPISRequirements,
  InvariantDateModel,
  PnrResponsePassengerModel,
  PnrResponseSegmentModel,
} from "WebApiClient";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";

export const BookingMangerManageAPISElements: React.FC<{
  model: BookingItemElementsManagementModel;
  OnRefresh: () => void;
  BookingIdentifier: BookingIdentifier;
}> = (props) => {
  const {
    bookingSSRElements,
    passengers,
    ssrOptions,
    segments,
    airlineOptions,
  } = props.model;
  const [addQueue, setAddQueue] = useState<AddPassengerAPISToBookingRequest[]>(
    []
  );
  const [deleteQueue, setDeleteQueue] = useState<number[]>([]);
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const [response, setResponse] = useState<BaseApiResponse | undefined>(
    undefined
  );
  const [errorOccured, setErrorOccured] = useState(false);
  const dispatch = useDispatch();

  const apisRequirenments: FareAPISRequirements = {
    apisDuringBooking: true,
    apisAfterBooking: true,
    creditCardForCheckIn: false,
    dob: true,
    fullAPISPassportInformation: false,
    genderRequired: true,
    municipalityForPassportRequired: false,
    none: false,
    passportNumber: false,
    password: false,
    paxNationality: false,
    sameBagsForAllLegs: false,
  };

  function OnRefresh() {
    setAddQueue([]);
    setResponse(undefined);
    props.OnRefresh();
  }
  function OnSubmitAdd() {
    const request: AddElementsToBookingRequestModel = {
      bookingIndentifier: props.BookingIdentifier,
      osi: undefined,
      remarks: undefined,
      services: undefined,
      ssrServices: undefined,
      passengerAPIS: addQueue,
    };
    setFetching(true);
    bookClient
      .addElements(request)
      .then((response) => {
        setResponse(response);
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (response.responseMetaData.errorOccured) {
          setErrorOccured(true);
        } else {
          setErrorOccured(false);
        }
      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  function OnUpdateQueueItem(
    data: AddPassengerAPISToBookingRequest | undefined,
    index: number
  ) {
    if (data) {
      let arrC = [...addQueue];
      arrC[index] = data;
      setAddQueue(arrC);
    } else {
      let arrC = [...addQueue];
      arrC.splice(index, 1);
      setAddQueue(arrC);
    }
  }
  function OnAddQueueItem(r: AddPassengerAPISToBookingRequest) {
    let arrC = [...addQueue];

    arrC.push(r);
    setAddQueue(arrC);
  }

  function SubmitButtonDisabled(): boolean {
    let result = false;
    if (InvalidElements() || addQueue.length === 0 || fetching) {
      result = true;
    }
    return result;
  }
  function InvalidElements(): boolean {
    let result = false;
    if (
      addQueue.filter(
        (e) => MainApisValid(e.apis, apisRequirenments).Valid === false
      ).length > 0
    ) {
      result = true;
    }
    return result;
  }

  return (
    <div className="row">
      {fetching && (
        <div className="col-12">
          <WaitingCard />
        </div>
      )}

      {!fetching && (
        <Fragment>
          {errorOccured && (
            <div className="col-12 mb-2">
              <ErrorDisplay data={response?.responseMetaData} />
            </div>
          )}
          {response && !errorOccured ? (
            <div className="col-12 mb-2">
              <Alert color="success">
                <h4 className="alert-heading">
                  APIS elements successfully modified.
                </h4>
                <button className="btn btn-primary" onClick={OnRefresh}>
                  Refresh
                </button>
              </Alert>
            </div>
          ) : (
            <Fragment>
              <div className="col-12 mb-2">
                <div className="card card-primary">
                  <div className="card-header card-header-primary">
                    Passengers in Booking
                  </div>
                  <div className="card-body card-body-secondary">
                    <div className="row">
                      {passengers.map((pax, pI) => (
                        <PassengerComponent
                          key={"PassengerComponent" + pI}
                          apisRequirenments={apisRequirenments}
                          OnUpdate={OnUpdateQueueItem}
                          OnAdd={OnAddQueueItem}
                          allPassengers={passengers}
                          allSegments={segments}
                          queueItems={addQueue}
                          value={pax}
                        />
                      ))}
                      <div className="col-12 mb-2">
                        <div className="d-grid">
                          <button
                            disabled={SubmitButtonDisabled()}
                            onClick={OnSubmitAdd}
                            className="btn btn-success text-white"
                          >
                            Save changes and submit
                          </button>
                        </div>
                        {InvalidElements() && (
                          <Alert color="danger">
                            Invalid elements. Please correct your input to
                            continue.
                          </Alert>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

const PassengerComponent: React.FC<{
  value: PnrResponsePassengerModel;
  apisRequirenments: FareAPISRequirements;
  queueItems: AddPassengerAPISToBookingRequest[];
  allPassengers: PnrResponsePassengerModel[];
  allSegments: PnrResponseSegmentModel[];
  OnUpdate: (
    update: AddPassengerAPISToBookingRequest | undefined,
    index: number
  ) => void;
  OnAdd: (update: AddPassengerAPISToBookingRequest) => void;
}> = (props) => {
  const [showPaxDetails, setshowPaxDetails] = useState(false);

  const editIndex = props.queueItems.findIndex(
    (e) => e.passengerNumber === props.value.passengerNumber
  );
  const edtiElem: AddPassengerAPISToBookingRequest | undefined =
    editIndex === -1 ? undefined : props.queueItems[editIndex];
  const relatedSSR = props.value.specialServiceRequests.filter((e) =>
    ["docs", "doca", "doco"].includes(e.code.toLowerCase())
  );
  function OnAddQueueItem() {
    const r = props.value;
    const defaultDate: InvariantDateModel | undefined = r.dateOfBirth
      ? GetNullableApiRequestDate(r.dateOfBirth)
      : { day: 1, month: 1, year: 1970 };
    const p: AddPassengerAPISToBookingRequest = {
      apis: { address: undefined, passport: undefined, visa: undefined },
      dateOfBirth: defaultDate,
      firstName: r.firstName,
      gender: AgentGenderEnum.Unknown,
      lastName: r.name,
      middleName: "",
      passengerNumber: r.passengerNumber,
      title: r.title,
    };
    props.OnAdd(p);
  }
  
  function OnEditItem(value: AddPassengerAPISToBookingRequest) {
    props.OnUpdate(value, editIndex);
  }

  function OnRemove() {
    props.OnUpdate(undefined, editIndex);
  }

  return (
    <div className="col-12 mb-2">
      <div className="card">
        <div className="card-header card-header-primary">
          <FontAwesomeIcon icon={faUser} /> [{props.value.passengerType}]{" "}
          {props.value.name},{props.value.firstName}
          <div className="float-end">
            <button
              onClick={() => {
                setshowPaxDetails(!showPaxDetails);
              }}
              className="btn btn-secondary"
            >
              Show/hide all passenger details
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mb-2">
              <div className="card">
                <div className="card-header card-header-primary">
                  Related APIS information
                </div>
                <div className="card-body card-body-secondary">
                  <div className="row">
                    <div className="col-12 mb-2">
                      <p className="font-weight-bold">General</p>
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Date of birth</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{props.value.firstName}</td>
                            <td>{props.value.name}</td>
                            <td>
                              <DateDisplay date={props.value.dateOfBirth} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12 mb-2">
                      {relatedSSR.length > 0 ? (
                        <div>
                          <p className="font-weight-bold">
                            Related SSR elements
                          </p>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Code</th>
                                <th>Text</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {relatedSSR.map((ssr, ssrI) => (
                                <tr key={"relatedSSR_" + ssrI}>
                                  <td>{ssr.code}</td>
                                  <td>{ssr.text}</td>
                                  <td>{ssr.status}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="font-weight-bold">
                          Passenger has no APIS related SSR elements{" "}
                          <span className="font-italic">(DOCS,DOCA,DOCO)</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showPaxDetails && (
              <div className="col-12 mb-2">
                <PNRPassengerCard
                  data={props.value}
                  segments={props.allSegments}
                  passengers={props.allPassengers}
                />
              </div>
            )}
            <div className="col-12">
              {edtiElem ? (
                <APISAddComponent
                  apisRequirenments={props.apisRequirenments}
                  OnUpdate={(val) => {
                    OnEditItem(val);
                  }}
                  value={edtiElem}
                  OnRemove={OnRemove}
                />
              ) : (
                <div className="d-grid">
                  <button className="btn btn-primary" onClick={OnAddQueueItem}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Edit APIS
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 mb-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const APISAddComponent: React.FC<{
  value: AddPassengerAPISToBookingRequest;
  apisRequirenments: FareAPISRequirements;
  OnUpdate: (update: AddPassengerAPISToBookingRequest) => void;
  OnRemove: () => void;
}> = (props) => {
  const { OnUpdate, value, OnRemove, apisRequirenments } = props;
  const { apis, dateOfBirth, firstName, gender, lastName, middleName, title } =
    value;
  const apisValid = MainApisValid(apis, apisRequirenments);
  return (
    <div className="card">
      <div className="card-header card-header-primary">
        Edit APIS
        <div className="float-end">
          <button
            className="btn btn-danger btn-sm text-white"
            onClick={() => {
              OnRemove();
            }}
          >
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </button>
        </div>
      </div>
      <div className="card-body card-body-primary">
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label className="">First name</label>
              <input
                value={firstName}
                onChange={(e) => {
                  OnUpdate({ ...value, firstName: e.target.value });
                }}
                type="text"
                className={`form-control form-control-sm`}
                placeholder="First name"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label className="">Middle name</label>
              <input
                value={middleName}
                onChange={(e) => {
                  OnUpdate({ ...value, middleName: e.target.value });
                }}
                type="text"
                className="form-control form-control-sm pax_firstname"
                placeholder="Middle name(s)"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label className="">Last name</label>
              <input
                value={lastName}
                onChange={(e) => {
                  OnUpdate({ ...value, lastName: e.target.value });
                }}
                type="text"
                className={`form-control form-control-sm`}
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label className="">Title</label>
              <br></br>
              <select
                value={title}
                onChange={(e) => {
                  OnUpdate({ ...value, title: e.target.value });
                }}
                className={`form-select`}
              >
                <option value="">- select -</option>
                <option value="Mr">Mr</option>
                <option value="MrDr">Mr Dr</option>
                <option value="MrProf">Mr Prof</option>
                <option value="MrProfDr">Mr Prof Dr</option>
                <option value="Mrs">Mrs</option>
                <option value="MrsDr">Mrs Dr</option>
                <option value="MrsProf">Mrs Prof</option>
                <option value="MrsProfDr">Mrs Prof Dr</option>
                <option value="Ms">Ms</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label className="">Gender</label>
              <br></br>
              <select
                value={gender}
                className={`form-select `}
                onChange={(e) => {
                  OnUpdate({
                    ...value,
                    gender: e.target.value as any as AgentGenderEnum,
                  });
                }}
              >
                <option value={AgentGenderEnum.Unknown}>- select -</option>
                <option value={AgentGenderEnum.Male}>Male</option>
                <option value={AgentGenderEnum.Female}>Female</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label className="">Date of birth</label>
              <DateInput
                OnDateChange={(date: Date | undefined) => {
                  if (date) {
                    OnUpdate({
                      ...value,
                      dateOfBirth: GetNullableApiRequestDate(date),
                    });
                  }
                }}
                Value={
                  dateOfBirth
                    ? moment({
                        day: dateOfBirth.day,
                        month: dateOfBirth.month - 1,
                        year: dateOfBirth.year,
                      }).toDate()
                    : undefined
                }
                InputGroup={false}
                MaxDate={moment().toDate()}
                IsDob={true}
                MinDate={moment().subtract(120, "years").toDate()}
                InputClassNames={`form-control form-control-sm`}
              />
            </div>
          </div>
          <div className="col-12">
            <APISMainInput
              Apis={apisRequirenments}
              Data={apis}
              OnUpdate={(ud) => {
                OnUpdate({ ...value, apis: ud });
              }}
              Valid={apisValid}
              ShowRemoveButton={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
