import { faAngleDoubleDown, faAngleDoubleUp, faBadgeSheriff } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APISMainInput, { APISMainInputProps } from 'components/Shared/APISInput/APISInput.Main';
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import React from "react";
import { Button, Collapse } from "reactstrap";
import { FareBookingPassengerApis, FareBookingPassengerApisAddress, FareBookingPassengerApisPassport, FareBookingPassengerApisVisa } from "WebApiClient";

export interface IBookingPassengerApisTabProps extends APISMainInputProps {
    IsOpen: boolean;
    Toggle: () => void;
    Next: () => void;
}

const BookingPassengerApisTab: React.FC<IBookingPassengerApisTabProps> = (props) => {
    const apisData = props.Data;
    const IsValid = props.Valid;

    function OnUpdatePassport(passport: FareBookingPassengerApisPassport | undefined) {
        if (apisData) {
            let data = { ...apisData };
            data.passport = passport;
            props.OnUpdate(data);
        }


    }
    function OnUpdateAddress(address: FareBookingPassengerApisAddress | undefined) {
        if (apisData) {

            let data = { ...apisData };
            data.address = address;
            props.OnUpdate(data);
        }

    }
    function OnUpdateVisa(visa: FareBookingPassengerApisVisa | undefined) {
        if (apisData) {
            let data = { ...apisData };
            data.visa = visa;
            props.OnUpdate(data);
        }

    }

    function OnAddApis() {
        let data: FareBookingPassengerApis = { address: undefined, passport: undefined, visa: undefined }
        props.OnUpdate(data);
    }
    function OnRemoveApis() {

        props.OnUpdate(undefined);
    }





    return (
        <div className="row mt-3">
            <div className="col">
                <Button color="secondary" block onClick={props.Toggle} className="text-black" >
                    <h6>
                        <span className="float-start">
                            <ValidIndicatorIcon
                                IsValid={IsValid.Valid}
                                Icon={faBadgeSheriff}
                                NoValueSet={apisData === undefined} />
                            {`APIS`}
                        </span>
                        <span className="float-end"><FontAwesomeIcon icon={props.IsOpen ? faAngleDoubleUp : faAngleDoubleDown} /></span>
                    </h6>
                </Button>
                <Collapse isOpen={props.IsOpen}>
                    <APISMainInput {...props} />
                </Collapse>
            </div>


        </div>

    );
}

export default BookingPassengerApisTab;

