import { DateTimeDisplay } from "components/Shared/DateDisplay";
import React from "react";
import { TicketingDateTypeDataModel } from "WebApiClient";

export const PnrTicketingDatesCard: React.FC<{ data: TicketingDateTypeDataModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Ticketing dates <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">Date</th>
                            <th className="font-weight-bold">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((osi, index) =>
                            <DateItem data={osi} key={"DateI"+index} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );


}

const DateItem: React.FC<{ data: TicketingDateTypeDataModel }> = ({ data }) => {
    const { ticketingDate, ticketingDateType } = data;

    return (
        <tr>
            <td><DateTimeDisplay date={ticketingDate} /></td>
            <td>{ticketingDateType}</td>

        </tr>
    );


}


