import { FareAPISRequirements, FareBookingPassengerApis } from "WebApiClient";
import { ApisAddressValidResult, PassengerAdressValid } from "./APISInput.Validate.Adress";
import { ApisPassportValidResult, PassengerApisPassportValid } from "./APISInput.Validate.Passport";
import { ApisVisaValidResult, PassengerVisaValid } from "./APISInput.Validate.Visa";



export interface ApisValidResult {
    Valid: boolean;
    Passport: ApisPassportValidResult;
    Address: ApisAddressValidResult;
    Visa: ApisVisaValidResult


}

export function MainApisValid(passenger: FareBookingPassengerApis | undefined, fare: FareAPISRequirements): ApisValidResult {
    let apisTabValid = true;
    const passport = passenger?.passport ? passenger.passport : undefined;
    let passportValid: ApisPassportValidResult = PassengerApisPassportValid(passport, fare);
    if (!passportValid.Valid) {
        apisTabValid = false;
    }

    let addressValid: ApisAddressValidResult = PassengerAdressValid(passenger?.address, fare);
    if (!addressValid.Valid) {
        apisTabValid = false;
    }

    let visaValid: ApisVisaValidResult = PassengerVisaValid(passenger?.visa, fare);
    if (!visaValid.Valid) {
        apisTabValid = false;
    }









    const result: ApisValidResult = {
        Valid: apisTabValid,
        Passport: passportValid,
        Address: addressValid,
        Visa: visaValid

    }

    return result;
}