import { faBarcode, faEllipsisH, faMinusCircle, faPlaneArrival, faPlaneDeparture, faPlusCircle, faSearch, faText, faUser, faUserFriends } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BookingMangerPassengersTableCell } from "components/Shared/Helpers/BookingManagement/BookingPassengerHelpers";
import { BookingMangerSegmentsTableCell } from "components/Shared/Helpers/BookingManagement/BookingSegmentsHelpers";
import { GetRemarkLabel } from "components/Shared/RemarkEnumDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { AddElementsToBookingRequestModel, BaseApiResponse, BookingIdentifier, BookingItemElementsManagementModel, EnhancedRemarkModel, PnrResponsePassengerModel, PnrResponseSegmentModel, RemarkTypeModelEnum, RemoveElementsFromBookingRequestModel } from "WebApiClient";

// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";



export const BookingMangerManageRemarkElements: React.FC<{ model: BookingItemElementsManagementModel, OnRefresh: () => void, BookingIdentifier: BookingIdentifier }> = props => {
    const { bookingRemarkElements, passengers, segments } = props.model;
    const [addQueue, setAddQueue] = useState<EnhancedRemarkModel[]>([])
    const [deleteQueue, setDeleteQueue] = useState<number[]>([])

    const defaultRemarkAdd: EnhancedRemarkModel = { passengerSelection: [], remark: "", remarkType: RemarkTypeModelEnum.General, segmentSelection: [], typeCode: "" }
    const [Remark, setRemarkAdd] = useState<EnhancedRemarkModel>(defaultRemarkAdd);
    const bookClient = useBookingClient();
    const [fetching, setFetching] = useState(false);
    const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
    const [errorOccured, setErrorOccured] = useState(false);
    const dispatch = useDispatch();
    function OnRefresh() {
        setAddQueue([]);
        setResponse(undefined);
        props.OnRefresh();
    }
    function ToggleDeleteIndex(index: number) {
        let arrC = [...deleteQueue];
        const iOf = arrC.indexOf(index);
        if (iOf === -1) {
            arrC.push(index);
        }
        else {
            arrC.splice(iOf, 1);
        }
        setDeleteQueue(arrC);
    }
    function OnSubmitAdd() {
        const request: AddElementsToBookingRequestModel = { bookingIndentifier: props.BookingIdentifier, osi: undefined, remarks: addQueue, services: undefined, ssrServices: undefined, passengerAPIS: undefined }
        setFetching(true);
        bookClient.addElements(request)
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                if (response.responseMetaData.errorOccured) {
                    setErrorOccured(true)
                }
                else {
                    setErrorOccured(false);
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
            })
    }

    function OnConfirmDelete() {
        const deleteRemarks: EnhancedRemarkModel[] = [];
        deleteQueue.forEach(index => {
            const allElemAt = allRemark[index];
            deleteRemarks.push(allElemAt)
        })
        const request: RemoveElementsFromBookingRequestModel = { bookingIndentifier: props.BookingIdentifier, osi: undefined, remarks: deleteRemarks, services: undefined, ssrServices: undefined }
        setFetching(true);
        bookClient.removeElements(request)
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                if (response.responseMetaData.errorOccured) {
                    setErrorOccured(true)
                }
                else {
                    setErrorOccured(false);
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
            })
    }

    function OnUpdateQueueItem(data: EnhancedRemarkModel | undefined, index: number) {
        if (data) {
            let arrC = [...addQueue];
            arrC[index] = data;
            setAddQueue(arrC);
        }
        else {
            let arrC = [...addQueue];
            arrC.splice(index, 1);
            setAddQueue(arrC);
        }
    }
    function OnAddQueueItem() {
        let arrC = [...addQueue];
        arrC.push(Remark);
        setAddQueue(arrC);
        setRemarkAdd(defaultRemarkAdd);
    }

    function SubmitButtonDisabled(): boolean {
        let result = false;
        if (InvalidElements() || addQueue.length === 0 || fetching) {
            result = true;
        }
        return result;
    }
    function InvalidElements(): boolean {
        let result = false;
        if (addQueue.filter(e => e.remark.length === 0).length > 0) {
            result = true;
        }
        return result;
    }

    function GetAllRemark(): EnhancedRemarkModel[] {
        let result: EnhancedRemarkModel[] = [];
        result = result.concat(bookingRemarkElements);
        // passengers.forEach(p => {
        //     result = result.concat(p.rem)
        // })
        return result;
    }
    const allRemark = GetAllRemark();

    return <div className="row">
        {fetching &&
            <div className="col-12">
                <WaitingCard />
            </div>
        }

        {!fetching &&
            <Fragment>

                {errorOccured &&
                    <div className="col-12 mb-2">
                        <ErrorDisplay data={response?.responseMetaData} />
                    </div>
                }
                {(response && !errorOccured) ?
                    <div className="col-12 mb-2">
                        <Alert color="success">
                            <h4 className="alert-heading">Remark elements successfully modified.</h4>
                            <button className="btn btn-primary" onClick={OnRefresh}>Refresh</button>
                        </Alert>
                    </div> :
                    <Fragment>
                        <div className="col-12 mb-2">
                            <div className="card card-primary">
                                <div className="card-header card-header-primary">Add Remark elements</div>
                                <div className="card-body card-body-secondary">
                                    <div className="row">

                                        <RemarkAddComponent allPassengers={passengers} allSegments={segments} value={Remark} OnUpdate={(update) => { setRemarkAdd(update) }} OnAdd={OnAddQueueItem} />
                                        {addQueue.length > 0 &&
                                            <Fragment>

                                                <div className="col-12">
                                                    <div className="content-divider-dark"></div>
                                                </div>
                                                <div className="col-12">
                                                    <p>{addQueue.length} Remark will be added to booking</p>
                                                </div>
                                                {addQueue.map((item, index) =>
                                                    <RemarkAddComponent key={"aque_"+index} allPassengers={passengers} allSegments={segments} value={item} OnUpdate={(update) => { OnUpdateQueueItem(update, index) }} OnRemove={() => { OnUpdateQueueItem(undefined, index) }} />

                                                )}
                                            </Fragment>

                                        }


                                        <div className="col-12 mb-2">
                                            <div className="d-grid">

                                                <button disabled={SubmitButtonDisabled()} onClick={OnSubmitAdd} className="btn btn-success text-white">Confirm and add Remark elements to booking</button>
                                            </div>
                                            {InvalidElements() &&
                                                <Alert color="danger">
                                                    Invalid elements. Please correct your input to continue.
                                                </Alert>

                                            }
                                        </div>
                                    </div>
                                </div>



                            </div>



                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header card-header-primary">
                                    Remark Elements in Booking

                                </div>
                                <div className="card-body card-body-secondary">
                                    <div className="row">
                                        <div className="col-12">
                                            <button disabled={deleteQueue.length === 0} className="btn btn-primary" onClick={OnConfirmDelete}>Remove selection from booking</button>
                                        </div>
                                        <div className="col-12">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Remark</th>
                                                        <th>Type</th>
                                                        <th>Code</th>
                                                        <th>Segments</th>
                                                        <th>Passengers</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allRemark.map((e, i) =>
                                                        <tr key={"armk_"+i}>
                                                            <td><input type="checkbox" checked={deleteQueue.includes(i)} onChange={() => { ToggleDeleteIndex(i) }}></input></td>
                                                            <td>{e.remark.replace(/-\d+/, '')}</td>
                                                            <td>{GetRemarkLabel(e.remarkType)}</td>
                                                            <td>{e.typeCode}</td>
                                                            <BookingMangerSegmentsTableCell numbers={e.segmentSelection} segments={segments} />
                                                            <BookingMangerPassengersTableCell remark={e?.remark} numbers={e.passengerSelection} pnrPassengers={passengers} />

                                                        </tr>
                                                    )}


                                                </tbody>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </Fragment>
                }
            </Fragment>
        }

    </div>

}



const RemarkAddComponent: React.FC<{ value: EnhancedRemarkModel, allPassengers: PnrResponsePassengerModel[], allSegments: PnrResponseSegmentModel[], OnUpdate: (update: EnhancedRemarkModel) => void, OnRemove?: () => void, OnAdd?: () => void }> = props => {
    const { OnUpdate, value, OnAdd, OnRemove } = props;
    const { passengerSelection, remark, remarkType, segmentSelection, typeCode } = value;
    const [showSegmentsSelection, setShowSegmentsSelection] = useState(false);
    const [showPassengerSelection, setShowPassengerSelection] = useState(false);
    const [showExtended, setShowExtended] = useState(false);
    const remarkTextValid = remark.length > 0;

    function TogglePassengerSelection(id: number) {
        const indexOf = passengerSelection.indexOf(id);
        let arrC = [...passengerSelection];
        if (indexOf === -1) {
            arrC.push(id);
        }
        else {
            arrC.splice(indexOf, 1);
        }
        OnUpdate({ ...value, passengerSelection: arrC });
    }
    function ToggleSegmentSelection(id: number) {
        const indexOf = segmentSelection.indexOf(id);
        let arrC = [...segmentSelection];
        if (indexOf === -1) {
            arrC.push(id);
        }
        else {
            arrC.splice(indexOf, 1);
        }
        OnUpdate({ ...value, segmentSelection: arrC });
    }

    return <div className="col-12 mb-2 p-1">
        <div className="card">
            <div className="card-body card-body-primary">
                <div className="row">
                    <div className="col-5 mb-2">
                        <div className="input-group">
                            <span className="input-group-text" title="Code">
                                <FontAwesomeIcon icon={faBarcode} />
                            </span>


                            <select value={remarkType} onChange={e => { OnUpdate({ ...value, remarkType: e.target.value as any as RemarkTypeModelEnum }) }} className={`form-select`}>
                                <option value={RemarkTypeModelEnum.None}>None</option>
                                <option value={RemarkTypeModelEnum.Coded}>Coded</option>
                                <option value={RemarkTypeModelEnum.Confidential}>Confidential</option>
                                <option value={RemarkTypeModelEnum.General}>General</option>
                                <option value={RemarkTypeModelEnum.Historical}>Historical</option>
                                <option value={RemarkTypeModelEnum.Invoice}>Invoice</option>
                                <option value={RemarkTypeModelEnum.Itinerary}>Itinerary</option>
                                <option value={RemarkTypeModelEnum.Other}>Other</option>

                            </select>
                        </div>


                    </div>

                    <div className="col-5 mb-2">
                        <div className="input-group">
                            <span className="input-group-text" title="Remark">
                                <FontAwesomeIcon icon={faText} />
                            </span>


                            <input value={remark} type="text" className={`form-control ${remark.length > 0 ? "is-valid" : "is-invalid"}`} onChange={(e) => { OnUpdate({ ...value, remark: e.target.value }) }}></input>
                            {!remarkTextValid &&
                                <div className="invalid-feedback">
                                    Please enter a message.
                                </div>
                            }
                        </div>
                    </div>


                    <div className="col-2 mb-2">
                        {OnAdd &&
                            <div className="d-grid">

                                <button onClick={OnAdd} className="btn btn-primary"><FontAwesomeIcon icon={faPlusCircle} /> add</button>
                            </div>
                        }
                        {OnRemove &&
                            <div className="d-grid">

                                <button onClick={OnRemove} className="btn btn-danger"><FontAwesomeIcon icon={faMinusCircle} /> remove</button>
                            </div>
                        }

                    </div>
                    <div className="col-12 mb-2">
                        <div className="d-grid">

                            <button className="btn btn-sm btn-secondary" onClick={() => { setShowExtended(!showExtended) }}><FontAwesomeIcon icon={faSearch} /> {showExtended ? "Hide" : "Show"} detailed options for element</button>
                        </div>
                    </div>
                    {showExtended &&
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body row">
                                    <div className="col-4 mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text" title="Code">
                                                Type code
                                            </span>


                                            <input value={typeCode} type="text" className="form-control" onChange={(e) => { OnUpdate({ ...value, typeCode: e.target.value }) }}></input>

                                        </div>


                                    </div>
                                    <div className="col-4 mb-2">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group">
                                                    <span className="input-group-text" title="Passengers">
                                                        <FontAwesomeIcon icon={faUserFriends} />
                                                    </span>


                                                    <input readOnly value={passengerSelection.length === 0 ? `All passengers (default)` : `${passengerSelection.length} / ${props.allPassengers.length}`} type="text" className="form-control" ></input>
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <p>Select only...</p>
                                                {props.allPassengers.map((pOption, poI) =>
                                                    <div key={"poI_"+poI} onClick={() => { TogglePassengerSelection(pOption.passengerNumber) }} className="form-check">
                                                        <input onChange={() => { }} checked={passengerSelection.includes(pOption.passengerNumber)} type="checkbox" className="form-check-input" />
                                                        <label className="form-check-label" ><FontAwesomeIcon icon={faUser} /> {pOption.name}, {pOption.firstName}</label>
                                                    </div>

                                                )}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-4 mb-2">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group">
                                                    <span className="input-group-text" title="Segments">
                                                        <FontAwesomeIcon icon={faPlaneDeparture} />
                                                    </span>


                                                    <input readOnly value={segmentSelection.length === 0 ? `All segments (default)` : `${segmentSelection.length} / ${props.allSegments.length}`} type="text" className="form-control"></input>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <p>Select only...</p>
                                                {props.allSegments.map((pOption, poI) =>
                                                    <div key={"allseg_"+poI} onClick={() => { ToggleSegmentSelection(pOption.segmentNumber) }} className="form-check">
                                                        <input onChange={() => { }} checked={segmentSelection.includes(pOption.segmentNumber)} type="checkbox" className="form-check-input" />
                                                        <label className="form-check-label" ><FontAwesomeIcon icon={faPlaneDeparture} /> {pOption.departure.iata} <FontAwesomeIcon icon={faEllipsisH} /> <FontAwesomeIcon icon={faPlaneArrival} /> {pOption.arrival.iata}</label>
                                                    </div>

                                                )}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    }


                </div>
            </div>
        </div>

    </div>
}


