import React from "react";
import { PNRServiceDataModel } from "WebApiClient";

export const PnrServicesCard: React.FC<{ data: PNRServiceDataModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Services <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">Carrier</th>
                            <th className="font-weight-bold">Chargeable</th>
                            <th className="font-weight-bold">Issued</th>
                            <th className="font-weight-bold">Passengers</th>
                            <th className="font-weight-bold">Segments</th>
                            <th className="font-weight-bold">Service code</th>
                            <th className="font-weight-bold">Service ID</th>
                            <th className="font-weight-bold">Service Text</th>
                            <th className="font-weight-bold">Status</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((osi, index) =>
                            <ServiceItem data={osi} key={"serItm"+index} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );


}

const ServiceItem: React.FC<{ data: PNRServiceDataModel }> = ({ data }) => {
    const { carrierCode, chargeable, isIssued, passengerNumbers, segmentNumbers, serviceCode, serviceID, serviceText, status } = data;

    return (
        <tr>
            <td>{carrierCode}</td>
            <td>{chargeable}</td>
            <td>{isIssued ? "Yes" : "no"}</td>
            <td>{passengerNumbers.join(",")}</td>
            <td>{segmentNumbers.join(",")}</td>
            <td>{serviceCode}</td>
            <td>{serviceID}</td>
            <td>{serviceText}</td>
            <td>{status}</td>

        </tr>
    );


}


