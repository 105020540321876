import React from "react";
import { DiscountResponseDataModel } from "WebApiClient";

export const PnrSpecialDiscountsCard: React.FC<{ data: DiscountResponseDataModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Special discounts <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">Code</th>
                            <th className="font-weight-bold">ID</th>
                            <th className="font-weight-bold">Passengers</th>
                            <th className="font-weight-bold">Segments</th>
                            <th className="font-weight-bold">Text</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((osi, index) =>
                            <DiscountItem data={osi} key={"DisI"+index} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );


}

const DiscountItem: React.FC<{ data: DiscountResponseDataModel }> = ({ data }) => {
    const { code, discountID, passengerSelections, segmentSelections, text } = data;

    return (
        <tr>
            <td>{code}</td>
            <td>{discountID}</td>
            <td>{passengerSelections.join(",")}</td>
            <td>{segmentSelections.join(",")}</td>
            <td>{text}</td>


        </tr>
    );


}


