
import * as moment from "moment";
import { AgentFrontendPassengerTypeConfig } from "WebApiClient";


export interface CheckAgeRangeResult {
    IsInRange: boolean;
    AgeOnDate: number;
    Months: number;
    Days: number;
    // InvalidDate:boolean;
    // FallBack:string
}
export function IsDobInAgeRange(dob: Date | undefined, date: Date | undefined, config: AgentFrontendPassengerTypeConfig): CheckAgeRangeResult {

    const ageonDate: moment.Duration = GetDobAgeOnDate(dob, date);
    let inRange = true;
    if (config.minAgeRequired && (ageonDate.years() < config.minAge)) inRange = false;
    if (config.maxAgeRequired && (ageonDate.years() > config.maxAge)) inRange = false;
    return { IsInRange: inRange, AgeOnDate: ageonDate.years(), Months: ageonDate.months(), Days: ageonDate.days() };
}

export function GetDobAgeOnDate(value: Date | undefined, dateToDiff: Date | undefined): moment.Duration {
    const parsed = moment(value);
    const _date = dateToDiff ? moment(dateToDiff) : moment();
    // const age = _date.diff(parsed, 'years');
    var diffDuration = moment.duration(_date.diff(parsed));
    return diffDuration;
}



// export function GetDefaultDob(config: PassengerAgeConfiguration): Date | undefined {
//     if (config.AgeRequired) {
//         return moment.default().subtract(config.MinAge, "years").toDate();
//     }
//     return undefined;

// }
export function GetDefaultDob(config: AgentFrontendPassengerTypeConfig): Date | undefined {
    if (config.ageRequired) {
        return moment().subtract(config.minAge, "years").toDate();
    }
    return undefined;

}