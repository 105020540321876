import { faAngleDoubleDown, faAngleDoubleUp, faCircle, faClock, faCouch, faPlaneArrival, faPlaneDeparture, faRandom, faSuitcase, faTrash, faUtensils, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { FarePriceOverviewCard } from "components/Fare/FarePriceOverview";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { Fragment, useState } from "react";
import { Collapse } from "reactstrap";
import { AvailableFareLegModel, ServiceGroupTypeEnumModel } from "WebApiClient";
import { AssignablePassengerOption } from "./AncillarySelection";

interface AncillaryMatrixProps {
    FarePrice: number,
    Currency: string,
    AssignablePassengers: AssignablePassengerOption[]
    Legs: AvailableFareLegModel[];
    SelectedConnections: number[];
    Services: ServiceAddObj[];
    OnRemoveService(serviceIndex: number): void

}
interface SelectedServiceReference {
    LegIndex: number,
    ServiceIndex: number

}



export const AncillaryMatrix: React.FC<AncillaryMatrixProps> = ({ Legs, Services, SelectedConnections, AssignablePassengers, OnRemoveService, Currency, FarePrice }) => {
    const [expand, setExpand] = useState(false);
    const ToggleExpand = () => { setExpand(!expand) };


    return <div className="card">
        <div className="card-header card-header-primary clickme" onClick={ToggleExpand}>Overview <span className="float-end"><FontAwesomeIcon icon={expand ? faAngleDoubleUp : faAngleDoubleDown} /></span></div>
        <Collapse isOpen={expand}>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-2">
                        <FarePriceOverviewCard AssignablePassengers={AssignablePassengers} Currency={Currency} FarePrice={FarePrice} Services={Services} />
                    </div>
                    <div className="col-12 mb-2">
                        <div className="w-auto">

                            <table className="table table-sm table-hover table-bordered">
                                <thead>
                                    <tr>

                                        <th className="w-auto"></th>
                                        {AssignablePassengers.map((p, pIndex) =>
                                            <th key={"pIndex"+pIndex}>
                                                {p.Label}
                                            </th>
                                        )}
                                    </tr>
                                </thead>

                                {Legs.map((leg, legIndex) => {
                                    const conn = leg?.connections[SelectedConnections[legIndex]];
                                    return <tbody key={"legIndex"+legIndex} style={{borderColor:"darkgray"}}>
                                        {conn?.segments.map((seg, sIndex) =>
                                            <tr key={"s"+sIndex}>
                                                <td className="w-25">
                                                    <div className="row">
                                                        <div className="col-12 mb-2">
                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <FontAwesomeIcon icon={faPlaneDeparture} /> {seg.departureAirport.name} ({seg.departureAirport.iata})
                                                                </div>
                                                                <div className="col-12 mb-2">
                                                                    <FontAwesomeIcon icon={faClock} /> <DateTimeDisplay date={seg.departureDate} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <FontAwesomeIcon icon={faPlaneArrival} /> {seg.arrivalAirport.name} ({seg.arrivalAirport.iata})
                                                                </div>
                                                                <div className="col-12 mb-2">
                                                                    <FontAwesomeIcon icon={faClock} /> <DateTimeDisplay date={seg.arrivalDate} />
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </td>
                                                {AssignablePassengers.map((p, pIndex) =>

                                                    <td className="pl-3 pr-3" key={"pidx"+pIndex} style={{ overflow: "hidden" }}>

                                                        <div className="row">
                                                            {Services.map((service, sIndex) => {
                                                                return <Fragment key={"sIndex"+sIndex}>
                                                                    {(service.PassengerIndex === pIndex && service.Segments.filter(e => e.arrival.toLowerCase() === seg.arrivalAirport.iata.toLowerCase() && e.departure.toLowerCase() === seg.departureAirport.iata.toLowerCase()).length > 0) &&
                                                                        <ServiceDetails serviceAdd={service} OnRemoveService={OnRemoveService} ServiceIndex={sIndex} OnSelectService={() => { }} />

                                                                    }
                                                                </Fragment>
                                                            }
                                                            )}
                                                        </div>

                                                    </td>


                                                )}
                                            </tr>
                                        )}

                                    </tbody>
                                }
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>

    </div>
}

interface ServiceDetailsProps {
    serviceAdd: ServiceAddObj,
    OnRemoveService(serviceIndex: number): void,
    ServiceIndex: number,
    OnSelectService(service: SelectedServiceReference | undefined): void
}
const ServiceDetails: React.FC<ServiceDetailsProps> = ({ serviceAdd, OnRemoveService, ServiceIndex }) => {
    const { PassengerIndex, Price, Segments, Service } = serviceAdd;
    const [isSelected, setIsSelected] = useState(false);
    const maxTextLength = 200;

    function _OnSelect() {
        setIsSelected(!isSelected);

    }
    return <div className={`col-12 mb-2`}>
        <div className={`row bg-secondary ${isSelected ? "border rounded-lg" : ""}`}>

            <div className="col-12 mb-2">
                <span className="text-break">
                    <button className="btn btn-primary btn-sm" onClick={() => _OnSelect()}>
                        <ServiceIcon type={Service.type} /> {" "}
                        {Service.text.length > 0 ?
                            <span>
                                {(Service.text.length > maxTextLength && !isSelected) ? (Service.text.slice(0, maxTextLength) + " ...") : Service.text}
                            </span> :
                            <span>
                                {(Service.serviceDescription.length > maxTextLength && !isSelected) ? (Service.serviceDescription.slice(0, maxTextLength) + " ...") : Service.serviceDescription}
                            </span>
                        }

                    </button>

                </span>

            </div>
            <div className="col-12 mb-2">

                <Collapse isOpen={isSelected}>
                    <div className="row">
                        <div className="col-12 mb-2">
                            <SharedPriceDisplay price={Price.equivalentPrice} />
                        </div>
                        <div className="col-12 mb-2">
                            Linked to {Segments.length} segments
                        </div>
                        <div className="col-12 mb-2">
                            <button className="btn btn-secondary btn-sm" onClick={() => { OnRemoveService(ServiceIndex) }}><FontAwesomeIcon icon={faTrash} /> remove from {Segments.length} segments</button>
                        </div>
                    </div>

                </Collapse>
            </div>
        </div>

    </div>
}


const ServiceIcon: React.FC<{ type: ServiceGroupTypeEnumModel }> = props => {
    function GetIcon(): IconDefinition {
        let icon = faCircle;
        switch (props.type) {
            case ServiceGroupTypeEnumModel.Baggage:

                icon = faSuitcase;
                break;
            case ServiceGroupTypeEnumModel.Meal:

                icon = faUtensils;
                break;
            case ServiceGroupTypeEnumModel.Seat:

                icon = faCouch;
                break;
            case ServiceGroupTypeEnumModel.Transfer:

                icon = faRandom;
                break;
        }
        return icon;
    }
    return <FontAwesomeIcon icon={GetIcon()}></FontAwesomeIcon>

}


