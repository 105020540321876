import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { Fragment, useState } from "react";
import { ServicePriceModel, ServiceSegmentModel } from "WebApiClient";
import { GroupedPassenger } from "./Ancillary";

interface AncillaryRelationProps {
  GroupedPassenger: GroupedPassenger;
  OnAddService(obj: ServiceAddObj): void;
  AddedServices: ServiceAddObj[];
  AllSegments: ServiceSegmentModel[];
  isServiceAdded: any;
}

export const AncillaryRelation: React.FC<AncillaryRelationProps> = ({
  GroupedPassenger,
  AddedServices,
  OnAddService,
  AllSegments,
  isServiceAdded,
}) => {
  const { Passenger, Prices, Service, PassengerOptions } = GroupedPassenger;
  function _OnAddService(
    price: ServicePriceModel,
    segments: ServiceSegmentModel[],
    passenger: number
  ) {
    const addObj: ServiceAddObj = {
      Price: price,
      PassengerIndex: passenger,
      Segments: segments,
      Service: Service,
    };
    OnAddService(addObj);
    isServiceAdded(true);
  }

  function ServiceAlreadyAdded(
    price: ServicePriceModel,
    segments: ServiceSegmentModel[],
    passenger: number
  ): boolean {
  
    let result = false;
    let filtered = AddedServices.filter(
      (e) =>
        e.PassengerIndex === passenger &&
        e.Price.priceID === price.priceID &&
        e.Service.bookingCode === Service.bookingCode
    );
    if (filtered.length > 0) {
      filtered.forEach((element) => {
        element.Segments.forEach((s) => {
          segments.forEach((ss) => {
            if (
              ss.arrival.toLowerCase() === s.arrival.toLowerCase() &&
              ss.departure.toLowerCase() == s.departure.toLowerCase()
            ) {
              result = true;
            }
          });
        });
      });
      return result;
    }
    return false;
  }

  return (
    <div className="col-12 mb-2">
      <div className="card">
        <div className="card-body card-body-primary">
          <div className="row">
            <div className="col-12 mb-2">
              <h4>{Passenger.typeLabel}</h4>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12"></div>
                {Prices.map((price, priceIndex) => (
                  <div className="col-12 mb-2" key={"price" + priceIndex}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            Price for {price.Segments.length} of{" "}
                            {AllSegments.length} segments
                          </div>
                          <div className="col">
                            <table className="table table-sm">
                              <thead>
                                <tr>
                                  <th>From</th>
                                  <th>To</th>
                                </tr>
                              </thead>
                              <tbody>
                                {price.Segments.map((s, i) => (
                                  <tr key={"segI" + i}>
                                    <td>{s.departure}</td>
                                    <td>{s.arrival}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <Fragment>
                        <div className="card-footer">
                          {PassengerOptions.map((o, i) => (
                            <div className="mb-2" key={"pass" + i}>
                              {price.Price !== undefined &&
                              price.Price.priceID.length > 0 ? (
                                <div className="d-grid">
                                  <button
                                    disabled={ServiceAlreadyAdded(
                                      price.Price!,
                                      price.Segments,
                                      o.Index
                                    )}
                                    className="btn btn-primary"
                                    onClick={() => {
                                      _OnAddService(
                                        price.Price!,
                                        price.Segments,
                                        o.Index
                                      );
                                    }}
                                  >
                                    Add for {o.Label}{" "}
                                    <SharedPriceDisplay
                                      price={price.Price?.equivalentPrice}
                                    />{" "}
                                  </button>
                                </div>
                              ) : (
                                <span>included</span>
                              )}
                            </div>
                          ))}
                        </div>
                      </Fragment>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
