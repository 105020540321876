import { AvailableFareModel, FareBookingPassengerView } from "WebApiClient";

export interface BookPassengerValidatioOtherTabResult {
    Valid: boolean;
    // Password:boolean;
    // PasswordRequired:boolean;
}


export function PassengerOtherValid(passenger: FareBookingPassengerView, fare: AvailableFareModel): BookPassengerValidatioOtherTabResult {
    let OtherTabValid = true;
    const other: BookPassengerValidatioOtherTabResult = {
        Valid: OtherTabValid,
    }
    return other;
}