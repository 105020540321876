import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faBaby, faChevronDoubleDown, faChevronDoubleUp, faChild, faMale, faPlaneDeparture, faPlusCircle, faTimes, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IsDobInAgeRange } from 'components/FareSearch/Business/DateHelpers';
import { dropdownItem, ISegmentState } from "components/FareSearch/types/FareSearchTypes";
import DateInput from 'components/Shared/DateInput';
import { AgentApiURL } from 'Constants';
import { useDateFormat } from 'hooks/useLocalization';
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Multiselect } from 'react-widgets';
import { Collapse } from 'reactstrap';
import { AgentFrontendFareSearchOptions, AgentFrontendPassengerTypeConfig, ApiCredentialsOptions, AvailableFaresPassengerInput, AvailableFaresPassengerTypeInput, AvailableFaresPassengerTypeInputDetails, BonusProgramModel, PublicServicesClient } from 'WebApiClient';
import _ from 'lodash';

interface PassengerTypeInputProps {
  Config: AgentFrontendPassengerTypeConfig[];
  Data: AvailableFaresPassengerTypeInput;
  TotalPassengerAmount: number;
  OnUpdate: (input: AvailableFaresPassengerTypeInput) => void;
  Segments: ISegmentState[];
  Icon: IconDefinition,
  IconSize?: SizeProp,
  MaximumTotalPassengers: number,
  isDisable: boolean
}

export const PassengerTypeInput: React.FC<PassengerTypeInputProps> = props => {
  const { Config, Data, TotalPassengerAmount, OnUpdate, Segments, Icon, IconSize, MaximumTotalPassengers } = props;
  const amount = Data?.passengers.length;

  const removeButtonDisabled = amount === 0 || TotalPassengerAmount === 1;
  const addButtonDisabled = TotalPassengerAmount === MaximumTotalPassengers;
  const formatDate = useDateFormat();
  const currentConfig = Config[Config.findIndex(e => e.type === Data.passengerType)]

  const bookingRedirect = useSelector(
    (state: any) => state.bookingRedirectSession.value
  );

  // const passengers = useMemo(() => bookingRedirect?.bookingSegmentPassengerData || [], [bookingRedirect?.bookingSegmentPassengerData]);

  // const memoizedOnPassengerAdd = useCallback(() => {
  //   OnPassengerAdd();
  // }, [OnPassengerAdd]);

  // useEffect(() => {
  //   passengers.forEach((passenger: any) => {
  //     if (passenger.type === "ADT") {
  //       memoizedOnPassengerAdd();
  //     }
  //   });
  // }, [passengers, memoizedOnPassengerAdd]);


  function OnTypeChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    const value = e.target.value;
    const updatedState: AvailableFaresPassengerTypeInput = { ...Data, passengerType: value }
    OnUpdate(updatedState);
  }

  function OnPassengerRemove() {
    let newPassengers: AvailableFaresPassengerTypeInputDetails[] = [...Data.passengers];
    newPassengers.splice(0, 1)
    const updatedState: AvailableFaresPassengerTypeInput = { ...Data, passengers: newPassengers };
    OnUpdate(updatedState);
  }
  function OnPassengerAdd() {
    let newPassengers: AvailableFaresPassengerTypeInputDetails[] = [...Data.passengers];
    newPassengers.push({ dateOfBirth: undefined, optionalDateOfBirth: undefined, frequentFlyerCards: undefined });
    const updatedState: AvailableFaresPassengerTypeInput = { ...Data, passengers: newPassengers };
    OnUpdate(updatedState);
  }

  function OnDateOfBirthChange(value: Date | undefined, index: number) {
    let newPassengers: AvailableFaresPassengerTypeInputDetails[] = [...Data.passengers];
    newPassengers[index] = { ...newPassengers[index], dateOfBirth: value };
    const updatedState: AvailableFaresPassengerTypeInput = { ...Data, passengers: newPassengers };
    OnUpdate(updatedState);
  }

  function OnAddFrequentFlyer(index: number) {
    let newPassengers: AvailableFaresPassengerTypeInputDetails[] = [...Data.passengers];
    let newPax = { ...newPassengers[index] };
    let ffList = newPax.frequentFlyerCards ? [...newPax.frequentFlyerCards] : [];
    let addFF: BonusProgramModel = { carrierCodes: [], extensions: "", holderFirstName: "", holderLastName: "", holderTitle: "", programNumber: "", programType: "" };
    ffList.push(addFF);
    newPassengers[index] = { ...newPassengers[index], frequentFlyerCards: ffList };
    const updatedState: AvailableFaresPassengerTypeInput = { ...Data, passengers: newPassengers };
    OnUpdate(updatedState);
  }

  function OnEditFrequentFlyer(paxIndex: number, ffIndex: number, data: BonusProgramModel | undefined) {
    let newPassengers: AvailableFaresPassengerTypeInputDetails[] = [...Data.passengers];
    let newPax = { ...newPassengers[paxIndex] };
    let ffList = newPax.frequentFlyerCards ? [...newPax.frequentFlyerCards] : [];
    if (data === undefined) {
      ffList.splice(ffIndex, 1);
    }
    else {
      ffList[ffIndex] = data;
    }
    newPassengers[paxIndex] = { ...newPassengers[paxIndex], frequentFlyerCards: ffList };
    const updatedState: AvailableFaresPassengerTypeInput = { ...Data, passengers: newPassengers };
    OnUpdate(updatedState);
  }

  function GetSegmentAgeDisplay(segmentIndex: number, segment: ISegmentState, dob: Date | undefined, config: AgentFrontendPassengerTypeConfig) {
    const ageCheck = IsDobInAgeRange(dob, segment.DateInput, config);
    // const icon: JSX.Element = ageCheck.IsInRange ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faExclamationTriangle} color="Tomato" />;

    return <p>
      {segment.DateInput &&
        <React.Fragment>
          <span className="badge badge-light">{segmentIndex + 1}</span>
          <FontAwesomeIcon icon={faPlaneDeparture} />
          <span className="font-italic">{moment(segment.DateInput).format(formatDate)}{" "}</span>
          {/* {icon} */}
          {` (${ageCheck.AgeOnDate}y ${ageCheck.Months}m ${ageCheck.Days}d)`}
        </React.Fragment>
      }
    </p>
  }


  return <div>
    {(Config.length > 0) &&
      <div className="clearfix py-1 bb-dotted-1" >
        <div className="paxTyp float-start">
          <div className="input-group">
            <span className="input-group-text" title="Radius">
              <FontAwesomeIcon icon={Icon} size={IconSize} />
            </span>
            <select style={{ fontSize: "1em" }} value={Data.passengerType} className="form-select pl-1" onChange={OnTypeChanged} >
              {Config.map((passenger, index) =>
                <option value={passenger.type} key={"pId" + index}>{passenger.label} {passenger.ageLabel.length > 0 && passenger.ageLabel}</option>
              )}
            </select>
          </div>
        </div>
        <div className="paxTyp__Amount float-end">
          <div className="amount__Digit">
            <input type="button" disabled={removeButtonDisabled} onClick={OnPassengerRemove} value="-" />
          </div>
          <div className="amount__Digit_Counter">{amount}</div>
          <div className="amount__Digit">
            <input disabled={addButtonDisabled} type="button" onClick={() => OnPassengerAdd()} value="+" />
          </div>
        </div>
      </div>
    }


    {Data.passengers.map((passenger, passengerIndex) =>
      <div className="py-1 px-2 bb-dotted-1 bg-light" key={`p_${passengerIndex}`}>
        <div className="paxTyp clearfix">
          <span className="float-start" > {Data.passengerType} {passengerIndex + 1} Date of birth {currentConfig.ageRequired ? "(Required)" : "(Optional)"}</span>
          <span className="float-end">
            <DateInput
              Value={passenger?.dateOfBirth}
              OnDateChange={(value) => OnDateOfBirthChange(value, passengerIndex)}
              MaxDate={moment().toDate()}
              MinDate={moment().subtract(120, "years").toDate()}
              InputGroup={false}
              IsDob={true}
              isDisble={props.isDisable}
            />
          </span>
        </div>
        {passenger?.frequentFlyerCards &&
          <div className='row mt-2'>
            {passenger.frequentFlyerCards.map((ffCard, ffIndex) =>
              <div key={ffIndex} className="col-12 mb-2">
                <FrquentFlyperInput index={ffIndex} OnUpdate={e => { OnEditFrequentFlyer(passengerIndex, ffIndex, e) }} data={ffCard} />
              </div>
            )}
          </div>
        }
        <div className="paxTyp clearfix mb-1" onClick={() => { OnAddFrequentFlyer(passengerIndex) }}>
          <div className="d-grid">

            <button className='btn btn-outline btn-sm'><FontAwesomeIcon icon={faPlusCircle} /> Add frequent flyer card</button>
          </div>
        </div>
        {currentConfig.ageRequired &&
          <React.Fragment>
            {Segments.map((segment, segmentIndex) =>
              <div key={`s_${segmentIndex}`}>
                {GetSegmentAgeDisplay(segmentIndex, segment, passenger?.dateOfBirth, currentConfig)}
              </div>

            )}
          </React.Fragment>
        }
      </div>
    )}
  </div>
}

interface PassengersInputProps {
  Passengers: AvailableFaresPassengerInput,
  PassengersAmount: number
  Segments: ISegmentState[];
  UpdateAvailFaresPassengers: (passengers: AvailableFaresPassengerInput) => void;
  Configuration: AgentFrontendFareSearchOptions
  isDisable: boolean
}

export const PassengersInput: React.FC<PassengersInputProps> = props => {
  const passengers = props.Passengers;
  const adultPassengers = passengers.adultPassengers;
  const childPassengers = passengers.childPassengers;
  const infantPassengers = passengers.infantPassengers;
  const adultConfig = props.Configuration.passengerConfiguration.adultConfigurations;
  const childConfig = props.Configuration.passengerConfiguration.childConfigurations
  const infantConfig = props.Configuration.passengerConfiguration.infantConfigurations

  function OnAdultStateUpdate(data: AvailableFaresPassengerTypeInput) {
    const updatedState: AvailableFaresPassengerInput = { ...props.Passengers, adultPassengers: data };
    props.UpdateAvailFaresPassengers(updatedState);
  }
  function OnChildStateUpdate(data: AvailableFaresPassengerTypeInput) {
    const updatedState: AvailableFaresPassengerInput = { ...props.Passengers, childPassengers: data };
    props.UpdateAvailFaresPassengers(updatedState);
  }
  function OnInfantStateUpdate(data: AvailableFaresPassengerTypeInput) {
    const updatedState: AvailableFaresPassengerInput = { ...props.Passengers, infantPassengers: data };
    props.UpdateAvailFaresPassengers(updatedState);
  }
  function OnStateUpdate(updatedPassengers: any) {
    const updatedState = { ...props.Passengers, ...updatedPassengers };
    props.UpdateAvailFaresPassengers(updatedState);
  }
  const bookingRedirect = useSelector(
    (state: any) => state.bookingRedirectSession.value
  );


  const addPassenger = () => {
    if (bookingRedirect?.bookingSegmentPassengerData?.length) {
      if (bookingRedirect.bookingSegmentPassengerData?.length != 1) {
        const updatedPassengers: any = {};

        bookingRedirect.bookingSegmentPassengerData.forEach((passenger: any) => {
          if (passenger.type === adultPassengers?.passengerType) {
            const newPassengers = [...(adultPassengers?.passengers ?? []), { dateOfBirth: undefined, optionalDateOfBirth: undefined, frequentFlyerCards: undefined }];
            updatedPassengers.adultPassengers = { ...adultPassengers, passengers: newPassengers };
          } else if (passenger.type === childPassengers?.passengerType) {
            const newPassengers = [...(childPassengers?.passengers ?? []), { dateOfBirth: undefined, optionalDateOfBirth: undefined, frequentFlyerCards: undefined }];
            updatedPassengers.childPassengers = { ...childPassengers, passengers: newPassengers };
          } else if (passenger.type === infantPassengers?.passengerType) {
            const newPassengers = [...(infantPassengers?.passengers ?? []), { dateOfBirth: undefined, optionalDateOfBirth: undefined, frequentFlyerCards: undefined }];
            updatedPassengers.infantPassengers = { ...infantPassengers, passengers: newPassengers };
          }
        });

        if (!_.isEmpty(updatedPassengers)) {
          OnStateUpdate(updatedPassengers);
        }
      }
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      addPassenger();
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [bookingRedirect?.bookingSegmentPassengerData?.length, adultPassengers?.passengerType]);


  return (<div>
    {adultPassengers &&
      <PassengerTypeInput Config={adultConfig} Data={adultPassengers} OnUpdate={OnAdultStateUpdate} TotalPassengerAmount={props.PassengersAmount} Segments={props.Segments} Icon={faMale} IconSize={"lg"} MaximumTotalPassengers={props.Configuration.maximumTotalAmountOfPassengers} isDisable={props.isDisable} />
    }
    {childPassengers &&
      <PassengerTypeInput Config={childConfig} Data={childPassengers} OnUpdate={OnChildStateUpdate} TotalPassengerAmount={props.PassengersAmount} Segments={props.Segments} Icon={faChild} MaximumTotalPassengers={props.Configuration.maximumTotalAmountOfPassengers} isDisable={props.isDisable} />
    }
    {infantPassengers &&
      <PassengerTypeInput Config={infantConfig} Data={infantPassengers} OnUpdate={OnInfantStateUpdate} TotalPassengerAmount={props.PassengersAmount} Segments={props.Segments} Icon={faBaby} MaximumTotalPassengers={props.Configuration.maximumTotalAmountOfPassengers} isDisable={props.isDisable} />
    }
  </div>
  );
};

export const FrquentFlyperInput: React.FC<{ data: BonusProgramModel, OnUpdate: (data: BonusProgramModel | undefined) => void, index: number }> = props => {
  const { carrierCodes, extensions, holderFirstName, holderLastName, holderTitle, programNumber, programType } = props.data;
  const [carrier, setcarrier] = useState(carrierCodes);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<dropdownItem[]>([])
  const [collpaseOpen, setCollapseOpen] = useState(false);

  useEffect(() => {
    let arr: any = [];
    carrier.map((itr) => {
      if (itr.includes("{")) {
        let i = JSON.parse(itr);
        if (i && typeof (i) === "object") {
          if (!arr.includes(itr)) {
            arr.push(i.value);
          }
        }
      } else {
        arr.push(itr);
      }
    })
    setcarrier(arr)
    props.OnUpdate({ ...props.data, carrierCodes: arr })
  }, [])

  function OnSearch(val: string) {
    if (val.length > 1) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(new ApiCredentialsOptions(AgentApiURL, ""))
      client.getAirlinesByPrefix(val).then((result) => {
        if (result.length > 0) {
          const map: dropdownItem[] = [];
          result.forEach(e => {
            map.push({ value: e.code!, text: e.hint!, image: "XXXX" })
          })
          setItems(map);
        }
        else {
          setItems([]);
        }
      }).catch(() => {
        setItems([]);
      }).finally(() => {
        setIsLoading(false);
      })
    }

  }


  function OnIncludeUpdate(e: any[]) {
    setItems([]);
    let sArr: string[] = [];
    e.forEach(v => {
      if (v.value) {
        sArr.push(v.value);
      }
      else {
        sArr.push(v);
      }
    })
    props.OnUpdate({ ...props.data, carrierCodes: sArr })
  }

  const ListItem = ({ item }: any) => (
    <span>
      <img width={60} height={20} src={`${AgentApiURL}/publicservices/images/airline/${item.value}`} alt="airlineimage"></img>
      <strong> {item.text} ({item.value})</strong>
    </span>
  );

  function airlineData(item: any) {
    let j = [];
    let iString = JSON.stringify(item);
    let i = JSON.parse(iString);
    j.push(i);
    return j.map((itr: any) => {
      if (itr.length > 2) {
        let airCode = JSON.parse(itr)
        return airCode.value
      }
      return itr
    })

  }

  return <div className='card'>
    <div className='card-body card-body-primary'>
      <div className='row'>
        <div className='col-12 mb-2'>
        </div>
        <div className='col-12 mb-2'>
          <input title='Program number' placeholder='Program number' className={`form-control form-control-sm ${programNumber.length === 0 ? "is-invalid" : "is-valid"}`} value={programNumber} onChange={(e => { props.OnUpdate({ ...props.data, programNumber: e.target.value }) })}></input>
        </div>
        <div className='col-12 mb-2'>
          <Multiselect
            containerClassName={`form-control form-control-sm ${carrierCodes.length === 0 ? "is-invalid" : "is-valid"}`}
            onChange={(e) => OnIncludeUpdate(e)}
            value={carrierCodes}
            dataKey="ffairlines"
            textField={(item: any) => `${airlineData(item)}`}
            busy={isLoading} filter={false}
            data={items} onSearch={(e) => OnSearch(e)}
            renderListItem={ListItem}
            placeholder="Airlines"
            messages={{ emptyList: "type to see airlines..." }}

          />

        </div>
        <div className='col-12'>
          <div className="d-grid">
            <div className='btn-group btn-group-sm'>
              <button className='btn btn-outline btn-sm me-2' onClick={() => { setCollapseOpen(!collpaseOpen) }}><FontAwesomeIcon icon={collpaseOpen ? faChevronDoubleUp : faChevronDoubleDown} /> show more options</button>
              <button className='btn btn-danger btn-sm' onClick={() => { props.OnUpdate(undefined) }}><FontAwesomeIcon icon={faTimes} /> remove</button>
            </div>
          </div>
        </div>
        <Collapse isOpen={collpaseOpen}>
          <div className='col-12 mt-1'>
            <div className='row mb-1'>
              <div className='col-12'>Holder</div>
              <div className='col-4'>
                <input title='Title' placeholder='Title' className={`form-control form-control-sm`} value={holderTitle ?? ""} onChange={(e => { props.OnUpdate({ ...props.data, holderTitle: e.target.value }) })}></input>
              </div>
              <div className='col-4'>
                <input title='First name' placeholder='First name' className={`form-control form-control-sm`} value={holderFirstName ?? ""} onChange={(e => { props.OnUpdate({ ...props.data, holderFirstName: e.target.value }) })}></input>
              </div>
              <div className='col-4'>
                <input title='last name' placeholder='Last name' className={`form-control form-control-sm`} value={holderLastName ?? ""} onChange={(e => { props.OnUpdate({ ...props.data, holderLastName: e.target.value }) })}></input>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <input title='Program type' placeholder='Program type' className={`form-control form-control-sm`} value={programType ?? ""} onChange={(e => { props.OnUpdate({ ...props.data, programType: e.target.value }) })}></input>
              </div>
              <div className='col-6'>
                <input title='Extensions' placeholder='Extensions' className={`form-control form-control-sm`} value={extensions ?? ""} onChange={(e => { props.OnUpdate({ ...props.data, extensions: e.target.value }) })}></input>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  </div>

  {/* </div> */ }
}






