import { DateTimeDisplay } from "components/Shared/DateDisplay";
import React from "react";
import { PNRResponseInfoModel } from "WebApiClient";

export const PnrInfosCard: React.FC<{ data: PNRResponseInfoModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Infos <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">Status</th>
                            <th className="font-weight-bold">CRS</th>
                            <th className="font-weight-bold">PNR type</th>
                            <th className="font-weight-bold">Filekey</th>
                            <th className="font-weight-bold">Session</th>
                            <th className="font-weight-bold">Created</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((info, index) =>
                            <InfoItem key={"infoI_"+index} data={info} />
                        )}
                    </tbody>
                </table>

            </div>
        </div>
    );


}

const InfoItem: React.FC<{ data: PNRResponseInfoModel }> = ({ data }) => {
    const { bookingStatus, createdDate, crs, passengerNameRecordType, pnrType, recordLocator, session, unknownPNRType } = data;

    return (
        <tr>
            <td>{bookingStatus}</td>
            <td>{crs.length > 0 ? crs : "No data"}</td>
            <td>{passengerNameRecordType.length > 0 ? passengerNameRecordType : unknownPNRType}</td>
            <td>{recordLocator}</td>
            <td>{session}</td>
            <td>{<DateTimeDisplay date={createdDate} />}</td>
        </tr>
    );


}


