import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { BaseApiResponseMetaData } from "WebApiClient"
import { APIMetaDataDisplay } from "./APIMetaDataDisplay"

interface BugReportModalProps {
    IsOpen: boolean;
    Toggle: () => void
    MetaData?: BaseApiResponseMetaData
}
export const BugReportModal: React.FC<BugReportModalProps> = props => {
    return <Modal size="xl" scrollable={true} isOpen={props.IsOpen} toggle={props.Toggle}>
        <ModalHeader toggle={props.Toggle}>Bug report</ModalHeader>
        <ModalBody>
            {props.MetaData &&
                <APIMetaDataDisplay data={props.MetaData} />
            }
        </ModalBody>
        <ModalFooter>
            {/* <Button color="primary" onClick={FetchRules}>Refresh</Button>{' '} */}
            <Button color="primary" onClick={props.Toggle}>close</Button>
        </ModalFooter>
    </Modal>
}