import { AvailableFaresPassengerInput } from "WebApiClient";



export interface dropdownItem {
  text: string;
  value: string;
  image: string;
}
export interface rdcData {
  code: string;
  description: string;
}
// Segments
export interface IFareSearchInputState {
  AvailableFares: boolean;
  DirectFlightsOnly: boolean;
  CabinClassPerLeg: boolean;
  Segments: ISegmentState[];
  IncludeAirlines: dropdownItem[];
  discountCode:string;
  islandResident: boolean;
  generalLargeFamily: boolean;
  specialLargeFamily: boolean;
  ExcludeAirlines: dropdownItem[];
  SmartAirlines: dropdownItem[];
  AvilableFaresPassengers: AvailableFaresPassengerInput;
  CabinClasses: ICabinClassState;
  // PassengerModalOpen: boolean;
  overrideMultiChannelBranch: string,
  overrideMultiChannelBranchgroup: string

}
export const DefaultCabinClassState: ICabinClassState = {
  Economy: true,
  PremiumEconomy: false,
  Business: false,
  First: false,
}
export interface ICabinClassState {
  Economy: boolean;
  PremiumEconomy: boolean;
  Business: boolean;
  First: boolean;
}

export interface ISegmentState {
  DepartureInput: string;
  ArrivalInput: string;
  Radius: number;
  DateInput: Date | any;
  CabinClasses: ICabinClassState;
}



export interface FareDisplayPassengerState {
  Label: string;
  Checked: boolean;
  Value: number
}




export interface IPassengerMainTypeOption {
  Label: string;
  Type: number;

}

export interface AvailFareRequestPassengerInformation {
  BirthDate: Date | undefined
  Type: string
}

export enum AirportType {
  Departure, Arrival
}











