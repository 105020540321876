import { FareSubTypeEnum } from "WebApiClient";

export function FareSubtypeDisplay(t: FareSubTypeEnum): string {
    let display = "";
    switch (t) {
        case FareSubTypeEnum.None:
            display = "None";
            break;
        case FareSubTypeEnum.Normal:
            display = "Normal";
            break;
        case FareSubTypeEnum.Preferred:
            display = "Preferred";
            break;

    }
    return display;
}
