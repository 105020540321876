import React from "react";
import { SingleBookingContact, SingleBookingContactPhone, SingleBookingContactPhoneType, SingleBookingContactType } from "WebApiClient";


function GetContactDisplayName(type: SingleBookingContactType): string {
  let result: string = "Unkown";
  switch (type) {
    case SingleBookingContactType.Contact:
      result = "Contact";
      break;
    case SingleBookingContactType.EmailOnly:
      result = "Email only";
      break;
    case SingleBookingContactType.Invoice:
      result = "Invoice";
      break;
    case SingleBookingContactType.PhoneOnly:
      result = "Phone only";
      break;
    case SingleBookingContactType.Unknown:
      result = "Unknown";
      break;

  }
  return result;
}

export const ContactTypeDisplay: React.FC<{ contact: SingleBookingContactType }> = ({ contact }) => {

  return <span>{GetContactDisplayName(contact)}</span>
}

interface BookingVersionItemDetailsContactProps {
  Title: string;
  Data: SingleBookingContact;
  OnChange?: (contact: SingleBookingContact) => void;
  OnDelete?: (type: SingleBookingContactType) => void;
  OnSubmit?: (contact: SingleBookingContact) => void;
  OnSetEdit?: (contact: SingleBookingContact) => void;
  OnStopEdit?: () => void;

}
export function GetPhoneDisplayName(type: SingleBookingContactPhoneType): string {
  let result: string = "Unkown";
  switch (type) {
    case SingleBookingContactPhoneType.Mobile:
      result = "Mobile";
      break;
    case SingleBookingContactPhoneType.Home:
      result = "Home";
      break;
    case SingleBookingContactPhoneType.Fax:
      result = "Fax";
      break;
    case SingleBookingContactPhoneType.Email:
      result = "Email";
      break;
    case SingleBookingContactPhoneType.Business:
      result = "Business";
      break;
    case SingleBookingContactPhoneType.Agent:
      result = "Agent";
      break;
  }
  return result;
}

const PhoneTypeInput: React.FC<{ type: SingleBookingContactPhoneType, OnChange?: (type: SingleBookingContactPhoneType) => void }> = ({ type, OnChange }) => {
  const options: SingleBookingContactPhoneType[] = [SingleBookingContactPhoneType.Agent, SingleBookingContactPhoneType.Business, SingleBookingContactPhoneType.Email, SingleBookingContactPhoneType.Fax, SingleBookingContactPhoneType.Home, SingleBookingContactPhoneType.Mobile, SingleBookingContactPhoneType.Unknown]
  function OnUpdate(e: React.ChangeEvent<HTMLSelectElement>) {
    if (OnChange) {
      const eventVal = e.target.value;
      const updateVal = parseInt(eventVal) as SingleBookingContactPhoneType;
      OnChange(updateVal)
    }
  }
  return <select disabled={OnChange === undefined} value={type} onChange={OnUpdate} className={`${!OnChange ? "form-control-plaintext" : "form-select"}`}>
    {options.map((option, index) =>
      <option key={"options"+index} value={option}>{GetPhoneDisplayName(option)}</option>
    )}
  </select>
}

const BookingVersionItemDetailsContact: React.FC<BookingVersionItemDetailsContactProps> = ({ Title, Data, OnChange: OnEdit, OnDelete, OnSubmit, OnSetEdit, OnStopEdit }) => {

  const isReadonly = OnEdit === undefined;
  const formInputClass = isReadonly ? "form-control-plaintext" : "form-control";
  const colClass = "col-xs-12 col-md-6"


  function OnUpdate(data: SingleBookingContact) {
    if (OnEdit) {
      OnEdit(data)
    }
  }

  function OnAddPhone() {
    if (OnEdit) {
      if (Data.phoneNumbers) {
        let phonecp: SingleBookingContactPhone[] = [...Data.phoneNumbers];
        phonecp.push({ area: "", countryCode: "", number: "", type: SingleBookingContactPhoneType.Email })
        const stcopy: SingleBookingContact = { ...Data, phoneNumbers: phonecp };
        OnEdit(stcopy);
      }


    }
  }
  function OnRemovePhone(index: number) {
    if (OnEdit) {
      if (Data.phoneNumbers) {
        let phonecp: SingleBookingContactPhone[] = [...Data.phoneNumbers];
        phonecp.splice(index, 1);
        const stcopy: SingleBookingContact = { ...Data, phoneNumbers: phonecp };
        OnEdit(stcopy);
      }
    }
  }
  function OnEditPhone(index: number, data: SingleBookingContactPhone) {
    if (OnEdit) {
      if (Data.phoneNumbers) {
        let phonecp: SingleBookingContactPhone[] = [...Data.phoneNumbers];
        phonecp[index] = data;
        const stcopy: SingleBookingContact = { ...Data, phoneNumbers: phonecp };
        OnEdit(stcopy);
      }
    }
  }

  return (
    <div className="card">
      <div className="card-header card-header-primary"><ContactTypeDisplay contact={Data?.contactType ?? SingleBookingContactType.Unknown} />
        {OnDelete && <button className="btn btn-danger float-end" onClick={() => OnDelete(Data.contactType)}>Delete</button>}
        {OnSetEdit && <button className="btn btn-primary float-end" onClick={() => OnSetEdit(Data)}>Edit</button>}
      </div>
      <div className="card-body card-body-secondary">
        <div className="row">


          <React.Fragment>
            {((Data.firstName !== "") || !isReadonly) &&
              <div className={colClass}>
                <div className="form-group">
                  <label className="font-weight-bold">First name</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.firstName} onChange={(e) => OnUpdate({ ...Data, firstName: e.target.value })} />
                </div>
              </div>
            }
            {((Data.lastName !== "") || !isReadonly) &&
              <div className={colClass}>
                <div className="form-group">
                  <label className="font-weight-bold">Last name</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.lastName} onChange={(e) => OnUpdate({ ...Data, lastName: e.target.value })} />
                </div>
              </div>
            }

            {((Data.street !== "") || !isReadonly) &&
              <div className={colClass}>
                <div className="form-group">
                  <label className="font-weight-bold">Street</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.street} onChange={(e) => OnUpdate({ ...Data, street: e.target.value })} />
                </div>
              </div>
            }
            {((Data.zipCode !== "") || !isReadonly) &&
              <div className={colClass}>
                <div className="form-group">
                  <label className="font-weight-bold">ZIP</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.zipCode} onChange={(e) => OnUpdate({ ...Data, zipCode: e.target.value })} />
                </div>
              </div>
            }
            {((Data.city !== "") || !isReadonly) &&
              <div className={colClass}>
                <div className="form-group">
                  <label className="font-weight-bold">City</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.city} onChange={(e) => OnUpdate({ ...Data, city: e.target.value })} />
                </div>
              </div>
            }
            {((Data.companyName !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">Company name</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.companyName} onChange={(e) => OnUpdate({ ...Data, companyName: e.target.value })} />
                </div>
              </div>
            }

            {((Data.additionalStreet1 !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">Additional Street 1</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.additionalStreet1} onChange={(e) => OnUpdate({ ...Data, additionalStreet1: e.target.value })} />
                </div>
              </div>
            }
            {((Data.additionalStreet2 !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">Additional Street 2</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.additionalStreet2} onChange={(e) => OnUpdate({ ...Data, additionalStreet2: e.target.value })} />
                </div>
              </div>
            }

            {((Data.region !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">Region</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.region} onChange={(e) => OnUpdate({ ...Data, region: e.target.value })} />
                </div>
              </div>
            }
            {((Data.country !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">Country</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.country} onChange={(e) => OnUpdate({ ...Data, country: e.target.value })} />
                </div>
              </div>
            }
            {((Data.countryCode !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">Country code</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.countryCode} onChange={(e) => OnUpdate({ ...Data, countryCode: e.target.value })} />
                </div>
              </div>
            }
            {((Data.idCardNumber !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">ID card number</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.idCardNumber} onChange={(e) => OnUpdate({ ...Data, idCardNumber: e.target.value })} />
                </div>
              </div>
            }
            {((Data.idCardType !== "") || !isReadonly) &&
              <div className={colClass}>

                <div className="form-group">
                  <label className="font-weight-bold">ID card type</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.idCardType} onChange={(e) => OnUpdate({ ...Data, idCardType: e.target.value })} />
                </div>
              </div>
            }
            {((Data.password !== "") || !isReadonly) &&
              <div className={colClass}>
                <div className="form-group">
                  <label className="font-weight-bold">Password</label>
                  <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={Data.password} onChange={(e) => OnUpdate({ ...Data, password: e.target.value })} />
                </div>
              </div>
            }

            <div className={"col-12"}>
              <div className="card">
                <div className="card-header">
                  Contact numbers {OnEdit && <button className="btn btn-primary" onClick={OnAddPhone}>Add</button>}
                </div>
                <div className="card-body">
                  <div className="row">
                    {Data.phoneNumbers.map((phone, index) =>
                      <div className="col-12 mb-2" key={"phoneNumbers"+index}>
                        <div className="card">
                          <div className="card-body">

                            <div className="row">
                              <div className={colClass}>
                                <div className="form-group">
                                  <label className="font-weight-bold">Type</label>
                                  <PhoneTypeInput type={phone.type} OnChange={OnEdit ? (e) => OnEditPhone(index, { ...phone, type: e }) : undefined} />
                                </div>
                              </div>
                              {((phone.area !== "") || !isReadonly) &&
                                <div className={colClass}>
                                  <div className="form-group">
                                    <label className="font-weight-bold">Area</label>
                                    <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={phone.area} onChange={(e) => OnEditPhone(index, { ...phone, area: e.target.value })} />
                                  </div>
                                </div>
                              }
                              {((phone.countryCode !== "") || !isReadonly) &&
                                <div className={colClass}>
                                  <div className="form-group">
                                    <label className="font-weight-bold">Country code</label>
                                    <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={phone.countryCode} onChange={(e) => OnEditPhone(index, { ...phone, countryCode: e.target.value })} />
                                  </div>
                                </div>
                              }
                              {((phone.number !== "") || !isReadonly) &&
                                <div className={colClass}>
                                  <div className="form-group">
                                    <label className="font-weight-bold">Number</label>
                                    <input type="text" className={`${formInputClass}`} readOnly={isReadonly} value={phone.number} onChange={(e) => OnEditPhone(index, { ...phone, number: e.target.value })} />
                                  </div>
                                </div>
                              }
                              <div className={colClass}>{OnEdit && <button className="btn btn-danger" onClick={() => OnRemovePhone(index)}>Remove</button>}</div>
                            </div>
                            {/* <Col sm={3}><p className="font-weight-bold">{GetPhoneDisplayName(phone.)}</p></Col> */}
                          </div>
                        </div>
                      </div>
                    )}


                  </div>

                </div>
              </div>
            </div>

          </React.Fragment>


        </div>



      </div>
      <div className="card-footer">
        {(OnSubmit) &&
          <button className="btn btn-primary float-end" onClick={() => OnSubmit(Data)}>Submit</button>
        }
        {(OnStopEdit) &&
          <button className="btn btn-danger float-end" onClick={OnStopEdit}>close</button>
        }
      </div>
    </div>

  );


}

export default BookingVersionItemDetailsContact;