import { faAngleDoubleDown, faAngleDoubleUp, faFingerprint } from '@fortawesome/pro-light-svg-icons';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faAngleDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import React, { Fragment } from "react";
import { Button, Collapse } from "reactstrap";
import { AgentFormOfIdentificationDocumentType, FareAPISRequirements, FarebookingFormOfIdentifikationModel } from "WebApiClient";
import { BookPassengerValidatioFoidTabResult } from "./FareBooking.Validate.Passenger.Foid";


export interface IBookingPassengerGeneralTabProps {
    Data: FarebookingFormOfIdentifikationModel | undefined;
    OnUpdate: (data: FarebookingFormOfIdentifikationModel | undefined) => void;
    Valid: BookPassengerValidatioFoidTabResult;
    Apis: FareAPISRequirements
    IsOpen: boolean;
    Toggle: () => void;
    Next: () => void;
}

const BookingPassengerFoidTab: React.FC<IBookingPassengerGeneralTabProps> = props => {
    const foid = props.Data;




    function OnAddFoid() {
        const newFoid: FarebookingFormOfIdentifikationModel = {
            carrierCode: "",
            documentID: "",
            documentType: AgentFormOfIdentificationDocumentType.CreditCard,
            issuer: ""
        }
        props.OnUpdate(newFoid)
    }
    function OnRemoveFoid() {
        props.OnUpdate(undefined);
    }

    function OnDocumentTypeUpdate(value: string) {
        if (foid) {
            let data: FarebookingFormOfIdentifikationModel | undefined = { ...foid, documentType: parseInt(value) };
            props.OnUpdate(data);
        }
    }
    function GetDocumentTypeClassName(): string {
        let result = "";

        result = props.Valid.DocumentType ? "is-valid" : "is-invalid";

        return result;
    }


    function OnDocumentIDUpdate(value: string) {
        if (foid) {
            let data: FarebookingFormOfIdentifikationModel | undefined = { ...foid, documentID: value };
            props.OnUpdate(data);
        }
    }
    function GetDocumentIDClassName(): string {
        let result = "";

        result = props.Valid.DocumentID ? "is-valid" : "is-invalid";

        return result;
    }



    const IsValid = props.Valid;



    return (
        <div className="row mt-3">
            <div className="col">
                <Button color="secondary" block onClick={props.Toggle} className="text-black" >
                    <h6>
                        <span className="float-start">
                            <ValidIndicatorIcon
                                IsValid={IsValid.Valid}
                                Icon={faFingerprint}
                                NoValueSet={foid === undefined}
                            />
                            {` Form of identification`}

                        </span>
                        <span className="float-end"><FontAwesomeIcon icon={props.IsOpen ? faAngleDoubleUp : faAngleDoubleDown} /></span>
                    </h6>
                </Button>
                <Collapse isOpen={props.IsOpen}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {foid &&
                                    <Fragment>
                                        <div className="col-12 mb-2">
                                            <button className="btn btn-danger" onClick={OnRemoveFoid}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> Remove Form of identification</button>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group form-row align-items-center">
                                                <label className="col-12 col-md-4 col-lg-3 col-xl-2">Document type</label>
                                                <div className="col-12 col-md col-lg-4">
                                                    <select value={foid.documentType} onChange={(e) => OnDocumentTypeUpdate(e.target.value)} className={`form-select ${GetDocumentTypeClassName()}`}>

                                                        <option value={AgentFormOfIdentificationDocumentType.Passport}>Passport</option>
                                                        <option value={AgentFormOfIdentificationDocumentType.CreditCard}>Credit card</option>
                                                        <option value={AgentFormOfIdentificationDocumentType.DriversLicense}>Drivers license</option>
                                                        <option value={AgentFormOfIdentificationDocumentType.FrequentFlyerNumber}>Frequent flyer number</option>
                                                        <option value={AgentFormOfIdentificationDocumentType.LocalDefinedIDNumber}>Local defined ID number</option>
                                                        <option value={AgentFormOfIdentificationDocumentType.NationalDefinedIDNumber}>National defined ID number</option>
                                                    </select>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-12">
                                            <div className="form-group form-row align-items-center">
                                                <label className="col-12 col-md-4 col-lg-3 col-xl-2">Document ID</label>
                                                <div className="col-12 col-md col-lg-4">
                                                    <input type="text" value={foid.documentID} onChange={(e) => OnDocumentIDUpdate(e.target.value)} className={`form-control ${GetDocumentIDClassName()}`} />
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                {!foid &&
                                    <div className="col-12 mb-2">
                                        <button className="btn btn-primary" onClick={OnAddFoid}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add</button>
                                    </div>
                                }
                                <div className="col-12 mt-2">
                                    <Button color="secondary text-dark" onClick={props.Next}>
                                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                                        <span> Next</span>
                                    </Button>
                                </div>
                            </div>




                        </div>
                    </div>
                </Collapse>
            </div>
        </div>

    );
}

export default BookingPassengerFoidTab;

