import { ContactTypeDisplay, GetPhoneDisplayName } from "components/BookingDetails/component/Booking.ContactCard";
import React from "react";
import { SingleBookingContact } from "WebApiClient";

export const PnrContactsCard: React.FC<{ data: SingleBookingContact[] }> = ({ data }) => {
    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Contacts <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">ID</th>
                            <th className="font-weight-bold">Type</th>
                            <th className="font-weight-bold">Country</th>
                            <th className="font-weight-bold">City</th>
                            <th className="font-weight-bold">Street</th>
                            <th className="font-weight-bold">Name</th>
                            <th className="font-weight-bold">Phone(s)</th>
                            <th className="font-weight-bold"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((info, index) =>
                            <PnrContactRow data={info} index={index} key={"PRNContact_"+index} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export const PnrContactRow: React.FC<{ data: SingleBookingContact, index: any }> = ({ data, index }) => {
    const { additionalStreet1, additionalStreet2, city, companyName, contactType, country, countryCode, firstName, gender, id, idCardNumber, idCardType, lastName, password, phoneNumbers, region, street, title, zipCode } = data;
    return (
        <tr>
            <td>{index + 1}</td>
            <td><ContactTypeDisplay contact={contactType} /></td>
            <td>{countryCode} {country}</td>
            <td>
                {zipCode} {city}
                {region.length > 0 &&
                    <span>
                        <br></br>
                        {region}
                    </span>
                }
            </td>
            <td>
                {street}
                {additionalStreet1.length > 0 &&
                    <span>
                        <br></br>
                        {additionalStreet1}
                    </span>
                }
                {additionalStreet2.length > 0 &&
                    <span>
                        <br></br>
                        {additionalStreet2}
                    </span>
                }

            </td>
            <td>
                {companyName.length > 0 &&
                    <span>{companyName} <br></br></span>
                }
                {title} {lastName}{firstName.length > 0 && <span>, {firstName}</span>}
            </td>
            <td>
                {phoneNumbers.length > 0 &&
                    <table className="table table-sm table-striped table-hover table-bordered">
                        <thead className="">
                            <tr>
                                <th className="font-weight-bold">Type</th>
                                <th className="font-weight-bold">Number</th>
                            </tr>
                        </thead>
                        <tbody>

                            {phoneNumbers.map((phone, phoneIndex) =>
                                <tr key={"Phn_"+phoneIndex}>
                                    <td>{GetPhoneDisplayName(phone.type)}</td>
                                    <td>{phone.number}</td>

                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </td>
        </tr>
    );


}