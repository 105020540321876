
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { SessionSlice } from 'rootExports/SessionReducer'
import { airlineCodeSlice } from 'rootExports/AirlineCodeReducer'
import { bookingRedirectSlice } from 'rootExports/BookingRedirectReducer'
const reducer = {bookingRedirectSession:bookingRedirectSlice.reducer, airlineSession: airlineCodeSlice.reducer, Session: SessionSlice.reducer }
export const store = configureStore({
  reducer: reducer,
  devTools: true
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
