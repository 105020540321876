import { FaresFilterState, FilterCriteria, FreeBaggageFilterCriteria, IncludedServiceMainFilterCriteria, LegMaxStopFilterCriteria, LegTimeFilterCriteria } from "components/AvailableFares/types/AvailableFaresTypes";
import { GetMaingroupName } from "components/Shared/IncludedServiceIcon";
import { AvailableFareModel, DefaultAvailFaresfilterSettings } from 'WebApiClient';

export function BuildFaresFilter(fares: AvailableFareModel[], filter: DefaultAvailFaresfilterSettings): FaresFilterState {
    let departureAirports: FilterCriteria[] = [];
    let arrivalAirports: FilterCriteria[] = [];
    let airlines: FilterCriteria[] = [];
    let alliances: FilterCriteria[] = [];
    let legsMaxStopAmount: LegMaxStopFilterCriteria[] = [];
    let legDepartureTimes: LegTimeFilterCriteria[] = [];
    let legArrivalTimes: LegTimeFilterCriteria[] = [];
    let includedServices: IncludedServiceMainFilterCriteria[] = [];
    let fareTypes: FilterCriteria[] = [];
    let allotmentTypes: FilterCriteria[] = [];
    let queryPccs: FilterCriteria[] = [];
    let bookingPccs: FilterCriteria[] = [];

    let sources: FilterCriteria[] = [];
    let cabinClasses: FilterCriteria[] = [];
    const AllotmentTypesActive: boolean = true;
    const AllAirportsActive: boolean = true;
    const AllAirlinesActive: boolean = true;
    const AllCabinClassesActive: boolean = true;
    const AllAlliancesActive: boolean = true;
    const AllIncludedServicesActive: boolean = false;
    const AllFareTypesActive: boolean = true;
    const AllQueryPCCActive: boolean = true;
    const AllBookingPCCActive: boolean = true;
    const AllTicketPCCActive: boolean = true;
    const AllSourceActive: boolean = true;

    fares.forEach(fare => {

        fare.legs.forEach((leg, legIndex) => {
            let faretype = "Unknown";
            if (leg.fareTypeInformation.salesPreferenceDisplayName) {
                faretype = leg.fareTypeInformation.salesPreferenceDisplayName;
            }
            else {
                faretype = leg.fareTypeInformation.originalFareType;
            }
            if (!fareTypes.some(x => x.Value === faretype)) {
                fareTypes.push({ Label: faretype, Value: faretype, Checked: true })
            }

        })

        fare.fareInfoBox?.queryPccs?.forEach(e => {
            if (!queryPccs.some(x => x.Value === e)) {
                queryPccs.push({ Label: e, Value: e, Checked: true })
            }
        })
        fare.fareInfoBox?.bookingPccs?.forEach(e => {
            if (!bookingPccs.some(x => x.Value === e)) {
                bookingPccs.push({ Label: e, Value: e, Checked: true })
            }
        })


        fare.fareInfoBox?.sources?.forEach(e => {
            if (!sources.some(x => x.Value === e)) {
                sources.push({ Label: e, Value: e, Checked: true })
            }
        })


        fare.legs.forEach((leg, legIndex) => {
            if (!legsMaxStopAmount.some(x => x.LegIndex === legIndex)) {
                legsMaxStopAmount.push({ LegIndex: legIndex, MaxStopSelection: 9999, MaxStopduration: 30 })
            }
            if (!legDepartureTimes.some(x => x.LegIndex === legIndex)) {
                legDepartureTimes.push({ LegIndex: legIndex, MinHour: 0, MaxHour: 24 })
            }
            if (!legArrivalTimes.some(x => x.LegIndex === legIndex)) {
                legArrivalTimes.push({ LegIndex: legIndex, MinHour: 0, MaxHour: 24 })
            }
            if (leg.platingCarrier) {
                if (leg.platingCarrier.code && leg.platingCarrier.code !== "") {
                    if (!airlines.some(x => x.Value === leg.platingCarrier!.code!)) {
                        airlines.push({
                            Value: leg.platingCarrier!.code!,
                            Label: `${leg.platingCarrier!.hint} (${leg.platingCarrier!.code!})`,
                            Checked: true
                        })
                    }

                }
                if (leg.platingCarrier.allianceCode && leg.platingCarrier!.allianceCode !== "") {
                    if (!alliances.some(x => x.Value === leg.platingCarrier!.allianceCode)) {
                        alliances.push({
                            Value: leg.platingCarrier.allianceCode!,
                            Label: `${leg.platingCarrier!.allianceName}`,
                            Checked: true
                        })
                    }

                }
            }

            leg.connections!.forEach(connection => {

                connection.segments!.forEach((segment, index) => {
                    const isfirstSegment = index === 0;
                    const isLastSegment = index === (connection.segments!.length - 1);

                    if (!cabinClasses.some(x => x.Value === segment.cabinClassName)) {
                        cabinClasses.push({ Value: segment.cabinClassName!, Label: segment.cabinClassName!, Checked: true })
                    }

                    const segmentAllotment = segment.allotmentInformation;
                    if (segmentAllotment) {
                        segmentAllotment.types.forEach(t => {
                            if (!allotmentTypes.some(x => x.Value === t)) {
                                allotmentTypes.push({ Value: t, Label: t, Checked: true })
                            }
                        })
                    }

                    if (isfirstSegment) {
                        if (!departureAirports.some(x => x.Value === segment.departureAirport!.iata!)) {
                            departureAirports.push({
                                Value: segment.departureAirport!.iata!,
                                Label: `${segment.departureAirport!.name!} (${segment.departureAirport!.iata!})`,
                                Checked: true
                            })
                        }

                    }

                    if (isLastSegment) {
                        if (!arrivalAirports.some(x => x.Value === segment.arrivalAirport!.iata!)) {
                            arrivalAirports.push({
                                Value: segment.arrivalAirport!.iata!,
                                Label: `${segment.arrivalAirport!.name!} (${segment.arrivalAirport!.iata!})`,
                                Checked: true
                            })
                        }
                    }

                    if (segment.includedServices) {
                        segment.includedServices.forEach((mainGroup) => {
                            if (mainGroup.services) {
                                mainGroup.services.forEach((subservice) => {
                                    if (!includedServices.some(x => x.Maingroup === mainGroup.type)) {
                                        includedServices.push({
                                            Maingroup: mainGroup.type,
                                            MainLabel: GetMaingroupName(mainGroup.type),
                                            Values: []
                                        })

                                    }
                                    if (!includedServices.filter(x => x.Maingroup === mainGroup.type)[0].Values.some(v => v.Value.toLowerCase() === subservice.hint!.toLowerCase())) {
                                        includedServices.filter(x => x.Maingroup === mainGroup.type)[0].Values.push({
                                            Icon: subservice,
                                            Value: subservice.hint!,
                                            Label: subservice.hint!,
                                            Checked: false
                                        })
                                    }

                                })
                            }
                        })

                    }

                })
            })
        })

        includedServices.sort((x, y) => x.Maingroup > y.Maingroup ? 1 : -1);
        includedServices.forEach((group) => {
            group.Values.sort((x, y) => x.Label > y.Label ? 1 : -1);
        })





    })
    const result: FaresFilterState = {
        CabinClasses: cabinClasses,
        SelectAllAirlinesActive: AllAirlinesActive,
        Airlines: airlines,
        SelectAllAirportsActive: AllAirportsActive,
        DepartureAirports: departureAirports,
        ArrivalAirports: arrivalAirports,
        Alliances: alliances,
        IncludedService: includedServices,
        LegsMaxStopAmount: legsMaxStopAmount,
        ArrivalTime: legArrivalTimes,
        DepartureTime: legDepartureTimes,
        FareTypes: fareTypes,
        FreeBaggage: FreeBaggageFilterCriteria.All,
        SelectAllAlliancesActive: AllAlliancesActive,
        SelectAllCabinClassesActive: AllCabinClassesActive,
        SelectAllFareTypesActive: AllFareTypesActive,
        SelectAllQueryPCCActive: AllQueryPCCActive,
        SelectAllBookingPCCActive: AllBookingPCCActive,
        SelectAllTicketPCCActive: AllTicketPCCActive,
        SelectAllSourceActive: AllSourceActive,
        SelectAllIncludedServicesActive: AllIncludedServicesActive,
        QueryPCCs: queryPccs,
        BookingPCCs: bookingPccs,
        Sources: sources,
        SelectAllAllotmentTypesActive: AllotmentTypesActive,
        AllotmentTypes: allotmentTypes
    }
    return result;
}







