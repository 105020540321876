import { DiscountEnum } from "WebApiClient";

export function DiscountEnumDisplay(t: DiscountEnum): string {
    let display = "";
    switch (t) {
        case DiscountEnum.NoDiscountedFares:
            display = "None";
            break;
        case DiscountEnum.SpainGeneralLargeFamilyDiscount:
            display = "Spain general large family discount";
            break;
        case DiscountEnum.SpainIslandsResidentialDiscount:
            display = "Spain islands residential discount";
            break;
        case DiscountEnum.SpainSpecialLargeFamilyDiscount:
            display = "Spain special large family discount";
            break;




    }
    return display;
}
