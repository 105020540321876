import { AgentApiURL } from "Constants";
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, Progress } from "reactstrap";
import { State } from "rootExports/rootReducer";
import { WaitScreenProgress } from "../types/SessionTypes";

const CustomerWaitScreen: React.FC<AllProps> = props => {
    return (
        <React.Fragment>
            {props.WaitScreenID &&
                <Modal centered={true} isOpen={props.ShowWaitScreen} zIndex={99999999} size={"lg"} >
                    <ModalBody>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <img src={`${AgentApiURL}/publicservices/images/waitscreen/${props.WaitScreenID}`} className="img-fluid" alt="CustomerWaitscreen" width="100%" />
                            </div>
                            <div className="col-12 mb-2">

                                <Progress animated value={props.Info.Percentage}>{props.Info.Label}</Progress>
                            </div>

                        </div>
                    </ModalBody>
                    {/* <ModalFooter></ModalFooter> */}
                </Modal>
            }
            {/* <div className="modal show mx-auto" id="waitScreenModal" role="dialog" style={style}>
                        <div className="modal-dialog modal-xl modal-dialog-centered justify-content-center" role="document">
                            <div className="modal-content">

                                
                            </div>

                        </div>
                    </div> */}



        </React.Fragment>
    );
};

type AllProps = PropsFromState & DispatchProps;

interface PropsFromState {
    WaitScreenID: string | undefined;
    ShowWaitScreen: boolean;
    Info: WaitScreenProgress;
}
const mapStateToProps = (state: State) => ({
    WaitScreenID: state.Session.FrontendSettings ? state.Session.FrontendSettings.waitScreenID : undefined,
    ShowWaitScreen: state.Session.ShowCustomerWaitScreen,
    Info: state.Session.WaitscreenProgress
});

interface DispatchProps {


}
const mapDispatchToProps = () => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerWaitScreen);
