import { ClassTypeEnum } from "WebApiClient";

export function ClassTypeEnumDisplay(t: ClassTypeEnum): string {
    let display = "";
    switch (t) {
        case ClassTypeEnum.All:
            display = "All";
            break;
        case ClassTypeEnum.Business:
            display = "Business";
            break;
        case ClassTypeEnum.Economy:
            display = "Economy";
            break;
        case ClassTypeEnum.First:
            display = "First";
            break;
        case ClassTypeEnum.None:
            display = "None";
            break;
        case ClassTypeEnum.PremiumEconomy:
            display = "Premium Economy";
            break;




    }
    return display;
}
