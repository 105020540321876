
import * as moment from "moment";

export interface GuessDateResult {
    ResultString: string;
    Result: Date;


}


export function IsInRange(date: string, dateFormat: string, minDate: string | undefined, maxDate: string | undefined): boolean {
    let isInRange: boolean = true;
    dateFormat = dateFormat.toUpperCase();
    const parsedDate: moment.Moment = moment(date, dateFormat);
    if (minDate) {
        const parsedMin: moment.Moment = moment(minDate, dateFormat);
        const isBefore: boolean = parsedDate.isBefore(parsedMin);
        if (isBefore) {
            isInRange = false;
            return false;
        }
    }
    if (maxDate) {
        const parsedMax: moment.Moment = moment(maxDate, dateFormat);
        const isAfter: boolean = parsedDate.isAfter(parsedMax);
        if (isAfter) {
            isInRange = false;
            return false;
        }
    }
    return isInRange;

}

export default function GuessDate(input: string, dateFormat: string, minDate: Date | undefined, maxDate: Date | undefined, isDob: boolean): GuessDateResult {
    dateFormat = dateFormat.toUpperCase();
    let resultString = "";
    let resultMoment = moment();


    let _minDate: moment.Moment | undefined = undefined;
    let _maxDate: moment.Moment | undefined = undefined;

    if (minDate !== undefined) {

        _minDate = moment(minDate, dateFormat);
    }
    if (maxDate !== undefined) {

        _maxDate = moment(maxDate, dateFormat);
    }



    let parsedTry = moment(input, dateFormat);
    // console.log("- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ")
    // console.log(`Guessdeparture: Input->${input} | DateFormat: ${dateFormat} | Min:${minDate} | Max:${maxDate}`)
    // console.log(`First guess => ${parsedTry.format(dateFormat)}`)

    if (parsedTry.isValid()) {
        if (_minDate) {
            if (parsedTry.isBefore(_minDate, "day")) {


                if (!isDob) {
                    parsedTry.add(1, "year");
                }
                if (parsedTry.isBefore(_minDate, "day")) {


                    parsedTry = _minDate;
                }
            }

        }
        if (_maxDate) {

            if (parsedTry.isAfter(_maxDate, "day")) {


                parsedTry = _maxDate;

            }
        }
    }
    else {
        // console.log(`NOT VALID`)
        if (isDob) {
            if (_maxDate) {

                parsedTry = _maxDate;
            }
            else {
                parsedTry = moment();
            }
        }
        else {
            if (_minDate) {

                parsedTry = _minDate;
            }
            else {
                parsedTry = moment();
            }
        }

    }
    // console.log("- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ")
    // console.log("                                                                                     ")
    // console.log("                                                                                     ")

    resultMoment = parsedTry;
    resultString = resultMoment.format(dateFormat);
    const result: GuessDateResult = {
        Result: parsedTry.toDate(),
        ResultString: parsedTry.format(dateFormat)
    };
    return result;



}







