import { faEdit, faEnvelope, faHistory, faTimes, faTrash, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BaseApiResponse, BookingIdentifier, NotificationDataModel, UpdateNotificationModel } from "WebApiClient";
import { NotificationActionRequiredDisplay, NotificationCatergoryDisplay, NotificationSourceDisplay, NotificationStatusDisplay } from "./NotifcationEnumDisplay";
import { UpdateNotificationComponent } from "./UpdateNotification";
export const RenderNotification: React.FC<{ notification: NotificationDataModel, BookingIdentifier: BookingIdentifier, OnRefresh: () => void }> = props => {
    const { actionRequired, branch, branchGroup, cartID, cartVersion, category, confidential, extension, notificationID, readOnly, source, status, text, timeStamp, title, userName, version, web } = props.notification;
    const bookClient = useBookingClient();
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const [errorOccured, setErrorOccured] = useState(false);
    const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
    const [updateModel, setUpdateModel] = useState<UpdateNotificationModel | undefined>(undefined);
    const [statusMessage, setStatusMessage] = useState("");
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    function CreateUpdateModel() {
        setUpdateModel({ identifier: props.BookingIdentifier, notificationID: notificationID, branch: branch, actionRequired: actionRequired, branchgroup: branchGroup, category: category, confidential: confidential ? confidential : false, readOnly: readOnly ? readOnly : false, text: text, title: title, username: userName });
    }
    function OnDeleteNotification() {
        setFetching(true);
        bookClient.deleteNotification({ identifier: props.BookingIdentifier, notificationID: notificationID })
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                if (response.responseMetaData.errorOccured) {
                    setStatusMessage("Notification could not be deleted!");
                    setErrorOccured(true);

                }
                else {

                    setStatusMessage("Notification deleted.");
                    setErrorOccured(false);
                    props.OnRefresh();
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
            })
    }

    function OnUpdateConfirmed() {
        setUpdateModel(undefined);
        props.OnRefresh();
    }

    return <div className="card card-primary">
        <div className="card-header card-header-primary">


            <span>

                <NotificationActionRequiredDisplay category={actionRequired} /> -{` `}
            </span>


            {title}


            <span className="float-end">
                Category: <NotificationCatergoryDisplay category={category} />
            </span>


        </div>
        <div className="card-body card-body-secondary">

            {errorOccured &&
                <div className="row">
                    <div className="col-12 mb-2">

                        {statusMessage}
                    </div>
                    <div className="col-12">

                        <ErrorDisplay data={response?.responseMetaData}></ErrorDisplay>
                    </div>
                </div>
            }

            <div className="row">
                {!updateModel &&
                    <Fragment>

                        <div className="col-12 mb-2">
                            <p>
                                <FontAwesomeIcon icon={faEnvelope} /> {text}
                            </p>
                            <p><span className="font-weight-bold"><NotificationSourceDisplay source={source} /></span> notification, received at <span className="font-weight-bold"><DateTimeDisplay date={timeStamp} /></span> </p>
                            <p><FontAwesomeIcon icon={faUsers} /> For {userName.length > 0 ? <span>User <span className="font-weight-bold">{userName}</span></span> : <span className="font-weight-bold">all users</span>} {branch.length > 0 && ` | Branch: ${branch}`} {branchGroup.length > 0 && ` | Branchgroup: ${branchGroup}`} </p>
                            <p><FontAwesomeIcon icon={faHistory} /> Version {version} | Status: <NotificationStatusDisplay status={status} /> | ID: {notificationID}</p>
                        </div>

                        <div className="col-12 mb-2">
                            {confidential &&
                                <p>CONFIDENTIAL</p>
                            }
                            {readOnly &&
                                <p>READONLY</p>
                            }

                        </div>

                        <div className="col-12 mb-2">
                            <div className="d-grid">

                                <button className="btn btn-primary btn-sm" onClick={CreateUpdateModel}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                            </div>
                        </div>
                        <div className="col-12 mb-2">
                            {!deleteConfirmation ?
                                <div className="d-grid">

                                    <button className="btn btn-secondary btn-sm" onClick={() => { setDeleteConfirmation(true) }}><FontAwesomeIcon icon={faTrash} /> Delete</button>
                                </div> :
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <p>Are you sure?</p>
                                        <div className="d-grid">

                                            <button className="btn btn-primary btn-sm mr-2" onClick={OnDeleteNotification}><FontAwesomeIcon icon={faTrash} /> Yes, delete notification</button>
                                            <button className="btn btn-danger btn-sm  text-white" onClick={() => { setDeleteConfirmation(false) }}><FontAwesomeIcon icon={faTimes} /> Cancel</button>

                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </Fragment>

                }
                {updateModel &&
                    <Fragment>

                        <div className="col-12 mb-2">
                            <UpdateNotificationComponent OnUpdate={(val) => { setUpdateModel(val) }} notification={updateModel} OnConfirm={OnUpdateConfirmed} OnCancel={() => { setUpdateModel(undefined) }} />

                        </div>
                    </Fragment>
                }
            </div>


        </div>
    </div>
}