import React from "react";

const FastFilter: React.FC<FastFilterProps> = () => {
  return (
    <React.Fragment>
      {/* <div id="fare__fastfilter_container">
        <div className="row">
          <div className="fare__fastfilter_box fare__fastfilter_box_cheapest clearfix col-4">
            <div className="fare__fastfilter_header text-truncate">CHEAPEST <span className="d-none d-md-inline">FLIGHT</span></div>
            <div className="fare__fastfilter_price">0.00</div>
            <div className="fare__fastfilter_duration text-truncate">&#216
            <span className="dynamic_value_duration">-</span>
              <br className="d-block d-md-none" /><span className="ml-0 ml-md-1 fare__fastfilter_stops">
                (<span className="dynamic_value_stops">-</span> Stops overall)</span>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};



interface FastFilterProps {

}

export default FastFilter
