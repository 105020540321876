import React from "react";
import { PnrResponsePaymentModel } from "WebApiClient";

export const PnrResponsePaymentCard: React.FC<{ data: PnrResponsePaymentModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Payments <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">Text</th>
                            <th className="font-weight-bold">Passengers</th>
                            <th className="font-weight-bold">Segments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((osi, index) =>
                            <PaymentItem data={osi} key={"osiI_"+index} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );


}

const PaymentItem: React.FC<{ data: PnrResponsePaymentModel }> = ({ data }) => {
    const { passengerNumbers, paymentText, segmentNumbers } = data;

    return (
        <tr>
            <td>{paymentText}</td>
            <td>{passengerNumbers.join(",")}</td>
            <td>{segmentNumbers.join(",")}</td>

        </tr>
    );


}


