import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { useState } from "react";
import { Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { BookingIdentifier, BookingItemAddedServiceModel, BookingItemServicePassengerModel, BookingItemServicesManagementModel, PnrResponseSegmentModel } from "WebApiClient";
import { AddBookingServices } from "./AddBookingServices";
import { RemoveBookingServices } from "./RemoveBookingServices";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";

interface BookingMangerManageServicesProps {
    model: BookingItemServicesManagementModel;
    Identifier: BookingIdentifier;
    OnRefresh: () => void;

}

export const BookingMangerManageServices: React.FC<BookingMangerManageServicesProps> = ({ model, Identifier, OnRefresh }) => {
    const { addedServices, availableServices, availableServicesPrices, errorMessages, passengers, segments } = model;

    const [tab, setTab] = useState<number>(0);
    return <div className="card-body">

        <Nav tabs>

            <NavItem>
                <NavLink
                    className={`${tab === 0 ? "activeTab" : ""} clickme`}
                    onClick={() => { setTab(0); }}>
                    <FontAwesomeIcon icon={faPlusCircle} />{` `}
                        Add services
                    </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={`${tab === 1 ? "activeTab" : ""} clickme`}
                    onClick={() => { setTab(1); }}
                    disabled={addedServices.length === 0}>
                    <FontAwesomeIcon icon={faMinusCircle} />{` `}
                        Remove services
                    </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
            <TabPane tabId={0}>
                <AddBookingServices model={model} Identifier={Identifier} OnRefresh={OnRefresh} />
            </TabPane>
            <TabPane tabId={1}>
                <RemoveBookingServices model={model} Identifier={Identifier} OnRefresh={OnRefresh} />
            </TabPane>
        </TabContent>
    </div>
}

const AddedService: React.FC<{ model: BookingItemAddedServiceModel, passengers: BookingItemServicePassengerModel[], segments: PnrResponseSegmentModel[], OnRemove: (index: number) => void; index: number, IsMarked: boolean }> = ({ model, passengers, segments, OnRemove, index, IsMarked }) => {
    const { addedServiceID, bookingType, bookingTypeCode, bookingTypeID, bookingTypeText, carrier, exchangeable, extensions, lastEMDDate, notValidAfter, notValidBefore, passengerID, price, refundable, segmentNumber, serviceCode, serviceText } = model;

    const [expanded, setExpanded] = useState(false);


    return <div>
        <div className="card">
            <div className="card-body">
                <div className="row">

                    <div className="col-12">
                        <form>
                            <div className="form-check">
                                <input checked={IsMarked} onChange={() => { OnRemove(index) }} className="form-check-input" type="checkbox" />
                            </div>
                        </form>
                    </div>
                    <div className="col-12">
                        <Collapse isOpen={expanded}>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    {IsMarked ? "MARKED" : ""} Passenger: {passengerID} | Segments: {segmentNumber.join(", ")} | Service: {bookingTypeCode} / {serviceCode} / Price: <SharedPriceDisplay price={price} /> | {serviceText}
                                </div>
                                <div className="col-12 mb-2">
                                    <button className="btn btn-primary" onClick={() => { OnRemove(index) }}>Cancel</button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const PassengerDisplay: React.FC<{ x: BookingItemServicePassengerModel }> = ({ }) => {
    return <div className="card">
    </div>
}






