import React, { useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface hotelMap {
  hotelData: any;
}
const HotelsMap: React.FC<hotelMap> = (props) => {
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: props.hotelData.lat,
    lng: props.hotelData.lon,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyClDjmzYXWCUe-_WXyF-fwk4iGyUyaRhKU">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

interface HotelMapProps {
  hotelData: Array<{ latitude: number; longitude: number; name: string }>;
}

const HotelMap: React.FC<HotelMapProps> = (props) => {
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  useEffect(() => {
    if (window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      props.hotelData.forEach((hotel) => {
        bounds.extend({ lat: hotel.latitude, lng: hotel.longitude });
      });

      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }
  },[props.hotelData]);

  const mapRef: any = React.useRef<GoogleMap | null>(null);
  return (
    <LoadScript googleMapsApiKey="AIzaSyClDjmzYXWCUe-_WXyF-fwk4iGyUyaRhKU">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={
          props.hotelData[0]
            ? {
                lat: props.hotelData[0].latitude,
                lng: props.hotelData[0].longitude,
              }
            : { lat: 0, lng: 0 }
        }
        zoom={10}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        {props.hotelData.map((hotel) => (
          <Marker
            key={`${hotel.latitude}-${hotel.longitude}`}
            position={{ lat: hotel.latitude, lng: hotel.longitude }}
            title={hotel?.name}
            
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export { HotelMap, HotelsMap };
