import { AgentApisPassportType, FareAPISRequirements, FareBookingPassengerApisPassport } from "WebApiClient";

export interface ApisPassportValidResult {
    Valid: boolean;
    FullPassportInfoRequired: boolean;
    TypeRequired: boolean;
    Type: boolean;
    Number: boolean;
    NumberRequired: boolean;
    Country: boolean;
    CountryRequired: boolean;
    Nationality: boolean;
    NationalityRequired: boolean;
    ExpiryDateRequired: boolean;
    ExpiryDate: boolean;
    IssueDate: boolean;
    IssueDateRequired: boolean;
    PlaceOfBirth: boolean;
    PlaceOfBirthRequired: boolean;

}
// const defaultApisPassportValid:ApisPassportValidResult = {
//     Valid:true,
//     FullPassportInfoRequired:false,
//     NumberRequired:false,
// }



export function PassengerApisPassportValid(passengerApis: FareBookingPassengerApisPassport | undefined, apisRequirenments: FareAPISRequirements): ApisPassportValidResult {
    const passportInput = passengerApis;
    const isSet = passportInput !== undefined;
    const passportRequired = apisRequirenments.fullAPISPassportInformation || isSet;
    const passportTypeRequired = passportRequired || apisRequirenments.passportNumber;
    const passportExpiryDateRequired = passportRequired;
    const passportIssueDateRequired = passportRequired;
    const passportNumberRequired = passportRequired || apisRequirenments.passportNumber
    const placeOfBirthRequired = passportRequired;
    const nationalityRequired = passportRequired || apisRequirenments.paxNationality;
    const countryRequired = passportRequired;




    let PassportTabValid = true;
    let countryValid = true;
    let passportTypeValid = true;
    let passportExpiryDateValid = true;
    let passportIssueDateValid = true;
    let passportNumberValid = true;
    let placeOfBirthValid = true;
    let nationalityValid = true;

    if (passportInput) {
        countryValid = countryRequired ? (passportInput.country !== "") : true;
        if (!countryValid) {
            PassportTabValid = false;
        }

        passportTypeValid = passportTypeRequired ? (passportInput?.type !== AgentApisPassportType.Unkown) : true;
        if (!countryValid) {
            PassportTabValid = false;
        }

        passportExpiryDateValid = passportExpiryDateRequired ? (passportInput?.expiryDate !== undefined && passportInput?.expiryDate !== null) : true;
        if (!passportExpiryDateValid) {
            PassportTabValid = false;
        }

        passportIssueDateValid = passportIssueDateRequired ? (passportInput?.issueDate !== undefined && passportInput?.issueDate !== null) : true;
        if (!passportIssueDateValid) {
            PassportTabValid = false;
        }

        placeOfBirthValid = placeOfBirthRequired ? (passportInput?.placeOfBirth !== null && passportInput?.placeOfBirth !== "") : true;
        if (!placeOfBirthValid) {
            PassportTabValid = false;
        }

        passportNumberValid = passportNumberRequired ? (passportInput?.number !== null && passportInput?.number !== "") : true;
        if (!passportNumberValid) {
            PassportTabValid = false;
        }

        nationalityValid = nationalityRequired ? (passportInput?.nationality !== null && passportInput?.nationality !== "") : true;
        if (!nationalityValid) {
            PassportTabValid = false;

        }
    }
    else {
        PassportTabValid = apisRequirenments.fullAPISPassportInformation ? false : true
    }





    const result: ApisPassportValidResult = {
        Valid: PassportTabValid,
        FullPassportInfoRequired: passportRequired,
        Type: passportTypeValid,
        TypeRequired: passportTypeRequired,
        Country: countryValid,
        CountryRequired: countryRequired,
        ExpiryDate: passportExpiryDateValid,
        ExpiryDateRequired: passportExpiryDateRequired,
        IssueDate: passportIssueDateValid,
        IssueDateRequired: passportIssueDateRequired,
        Number: passportNumberValid,
        NumberRequired: passportNumberRequired,
        NationalityRequired: nationalityRequired,
        Nationality: nationalityValid,
        PlaceOfBirth: placeOfBirthValid,
        PlaceOfBirthRequired: placeOfBirthRequired
    }

    return result;
}