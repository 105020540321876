import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
interface subRating {
    ratingData: any;
    isOpen: boolean;
    hotelData: any
}

const SubRatingPopup: React.FC<subRating> = props => {
    // console.log("props------", props?.ratingData);

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    return (
        <div className="rating-container">
            <div className='text-end'>
                <p className='review' style={{ fontWeight: "bold", position: "relative", display: "inline-block", marginRight: "10px", marginBottom:"0px" }}  >{props.hotelData?.tripAdvisor?.tripAdvisorData?.rating}</p>
                <span style={{ fontWeight: "bold", cursor: "pointer" }} >{props.hotelData?.tripAdvisor?.tripAdvisorData?.reviewcount} reviews</span>
            </div>

           <hr/>
            {props?.ratingData !== undefined ?
                Object.entries(props?.ratingData).map(([category, rating]) => (
                    <div key={category + rating} className="rating-line">
                        <div className="category">{capitalizeFirstLetter(category)}</div>
                        <div className="rating-bar">
                            <div className="rating-progress" style={{ width: `${Number(rating) * 20}%` }}></div>
                        </div>
                        <div className="rating-value">{String(rating)}</div>
                    </div>
                )) :
                <span>No Ratings Found</span>
            }
        </div>
    )
}

export default SubRatingPopup