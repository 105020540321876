import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { AncillaryComponent, ServiceAddObj } from "components/Fare/AncillariesModal";
import React from "react";
import { AvailableFareModel } from "WebApiClient";

export interface FareBookingAncillariesProps {
    SelectedConnections: number[];
    SetSelectedConnection: (legindex: number, connectionIndex: number) => void
    Fare: AvailableFareModel;
    AddedServices: ServiceAddObj[]
    SetAddedServices(services: ServiceAddObj[]): void;
    Passengers: AssignablePassengerOption[]
}

const FareBookingAncillaries: React.FC<FareBookingAncillariesProps> = props => {
    return (
        <React.Fragment>
            <AncillaryComponent Fare={props.Fare} SelectedConnections={props.SelectedConnections} SetSelectedConnection={props.SetSelectedConnection} AddedServices={props.AddedServices} SetAddedServices={props.SetAddedServices} Passengers={props.Passengers} />
        </React.Fragment>

    );
}

export default FareBookingAncillaries;
