import * as React from 'react';
import { Alert } from 'reactstrap';

export interface IUnauthorizedPageProps {
}

export default function UnauthorizedPage(props: IUnauthorizedPageProps) {
    return (
        <div className="container xl-wrapper content-wrapper">
            <div className="row">
                <div className="col-12">
                    <Alert color="danger">
                        <h4 className="alert-heading">Unauthorized</h4>
                        <p>You cannot access this ressource.</p>
                    </Alert>
                </div>
            </div>
        </div>
    );
}
