import * as moment from "moment";
import { InvariantDateModel } from "WebApiClient";
export function GetNullableApiRequestDate(input: Date | undefined): InvariantDateModel | undefined {

    if (input) {
        const date = moment(input);
        const result: InvariantDateModel = { day: date.date(), month: date.month() + 1, year: date.year() }
        return result;
    }

    else return undefined;
}

export function GetApiRequestDate(input: Date): InvariantDateModel {
    const date = moment(input);
    const result: InvariantDateModel = { day: date.date(), month: date.month() + 1, year: date.year() }
    return result;
}