import { BookingMangerManageServices } from "components/ManageBookingServices/ManageBookingServices";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  BookingIdentifier,
  BookingItemModel,
  BookingServicesManagementApiResponse,
} from "WebApiClient";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";

interface BookingMangerManageServicesModalProps {
  Booking: BookingItemModel;
  Toggle: () => void;
  IsOpen: boolean;
}

const BookingMangerManageServicesModal: React.FC<
  BookingMangerManageServicesModalProps
> = (props) => {
  const booking = props.Booking;
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const [response, setResponse] = useState<
    BookingServicesManagementApiResponse | undefined
  >(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const result = response?.result;

  function OnFetch() {
    const identifier: BookingIdentifier =
      booking.latestVersion?.bookingIdentifier;
    if (identifier) {
      setFetching(true);
      bookClient
        .getServiceManagement(identifier)
        .then((response) => {
          setResponse(response);
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          if (!response.result) {
            setErrorOccured(true);
          } else {
            setErrorOccured(false);
          }
        }).catch(() => {
          setErrorOccured(true);
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }

  useEffect(() => {
    if (props.IsOpen) {
      OnFetch();
    }
  }, [props.IsOpen]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        centered
        toggle={props.Toggle}
        isOpen={props.IsOpen || fetching}>
        <ModalHeader toggle={props.Toggle}>
          Manage services for &nbsp;
          <strong>{props.Booking.latestVersion.generalInfo.filekey}</strong>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {fetching && (
              <div className="col-12">
                <WaitingCard />
              </div>
            )}
            {!fetching && (
              <React.Fragment>
                {errorOccured && (
                  <ErrorDisplay data={response?.responseMetaData} />
                )}

                {result && (
                  <Fragment>
                    <div className="col-12 mb-2">
                      <BookingMangerManageServices
                        model={result}
                        Identifier={
                          props.Booking.latestVersion.bookingIdentifier
                        }
                        OnRefresh={OnFetch}
                      />
                    </div>
                  </Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={props.Toggle}>
            close
          </Button>
          <Button color="primary" onClick={OnFetch}>
            Refresh
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default BookingMangerManageServicesModal;
