import { AppSideBar } from "components/General/AppSidebar";
import { AppTopBar } from "components/General/AppTopbar";
import { VersionDifferenceModal } from "components/General/VersionDifferenceModal";
import CustomerWaitScreen from "components/General/WaitScreens/CustomerWaitScreen";
import GeneralWaitScreen from "components/General/WaitScreens/GeneralWaitScreen";
import { GetDeployInfo } from "hooks/GetAppInfo";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { State } from "rootExports/rootReducer";
import { Session_Reducer_SetDeployInfo } from "rootExports/SessionReducer";
import { AppRoutes } from "routes/index";
import { Environment } from "./Constants";
import { b2cSettingsText } from "WebApiClient";

const App: React.FC<{}> = ({ }) => {
  const session = useSelector((state: State) => state.Session);
  // const VersionDifferenceToBackend = session.VersionDifferenceToBackend;
  // const VersionDifferenceToBackend = true;

  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
        showFareHeader=Off
        showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\nshowHotelTab=None\nshowCabinContainer=All`;
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  let settingArr = otherSettings.split("\n");
  var sideBarSetting = settingArr[0].split("/");
  var siteBarPosition = sideBarSetting[0];

  var siteBarColors: any = sideBarSetting[1] == undefined ? { "textColor": "#ffffff", "bgColor": "#242424" } : JSON.parse(sideBarSetting[1]);
  var setting = siteBarColors;
  const dispatch = useDispatch();
  const isLoggedIn = session.isLoggedIn;

  useEffect(() => {
    GetDeployInfo().then((info) => {
      dispatch(Session_Reducer_SetDeployInfo(info));
      console.log(
        `Environment ${info.DeployedEnvironment} and Build configuration ${Environment} detected. AgentPlus is ready.`
      );
    });
  }, [siteBarColors?.textColor, siteBarColors?.bgColor, siteBarPosition]);
  const B2cDynamicCss = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);
  const B2CSettings: b2cSettingsText = (() => {
    try {
      return JSON.parse(B2cDynamicCss || "{\"HotelTab\":\"All\",\"AirPortSearchType\":\"All\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowDateInput\":\"Left\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":true},\"Business\":{\"Checked\":true,\"preSelect\":true},\"First\":{\"Checked\":true,\"preSelect\":true}},\"showMarqueeFrom\":\"2024-05-20T11:54\",\"showMarqueeText\":\"\",\"showMarqueeTo\":\"2024-05-20T11:55\",\"Style\":\"\",\"priceOption\":\"pricePerPerson\"}");
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();

  const showMarquee: any = B2CSettings;

  const startTime = new Date(showMarquee?.showMarqueeFrom);
  const endTime = new Date(showMarquee?.showMarqueeTo);
  const [showMarqueeState, setShowMarqueeState] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const currentTime = new Date();
      if (currentTime >= startTime && currentTime <= endTime) {
        setShowMarqueeState(true);
      } else {
        setShowMarqueeState(false);
      }
    };
    // Check the time immediately and then every second
    checkTime();
    const intervalId = setInterval(checkTime, 1000);
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [startTime, endTime]);

  return (
    <React.Fragment>
      {showMarqueeState && (
        <div className="marquee-container">
          <div className="marquee-text">{showMarquee?.showMarqueeText}</div>
        </div>
      )}

      <GeneralWaitScreen></GeneralWaitScreen>
      <CustomerWaitScreen></CustomerWaitScreen>
      <div className="container-fluid overflow-hidden">
        <div className={`row vh-100 overflow-auto`}>
          {isLoggedIn && siteBarPosition == "menuPosition=L" ? (
            <div
              style={{
                color: `${setting.textColor}`,
                background: `${setting.bgColor}`,
              }}
              className="col-12 col-md-2 col-xl-auto px-sm-2 px-0 navbar-dark d-flex sticky-top h-md-100 navbar-dark widthSideBar"
            >
              <AppSideBar settings={siteBarColors} />
            </div>
          ) : null}
          <main
            className={
              siteBarPosition == "menuPosition=N"
                ? "col-12 col-md-12 col-xl d-flex flex-column"
                : "col-12 col-md-10 col-xl d-flex flex-column"
            }
          >
            <div className="row">
              {isLoggedIn && (
                <div>
                  {/* {!checkPrams ? null : <AppTopBar></AppTopBar>} */}
                  <AppTopBar settings={siteBarColors} />
                </div>
              )}
            </div>
            <div className="mt-1" style={{ minHeight: "90vh" }}>
              <VersionDifferenceModal />
              <AppRoutes />
            </div>
          </main>
          {isLoggedIn && siteBarPosition == "menuPosition=R" ? (
            <div className="col-12 col-md-2 col-xl-auto px-sm-2 px-0 navbar-dark d-flex sticky-top h-md-100">
              <AppSideBar settings={siteBarColors} />
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </React.Fragment>
  );
};

export default App;
