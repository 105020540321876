import { IFareSearchInputState, ISegmentState } from "components/FareSearch/types/FareSearchTypes";
import * as moment from "moment";
import { AgentFrontendFareSearchOptions, AgentFrontendPassengerTypeConfig, AvailableFaresPassengerInput, AvailableFaresPassengerTypeInputDetails } from "WebApiClient";
import { IsDobInAgeRange } from "./DateHelpers";
export function ValidSegmentAirport(InputValue: string): boolean {
    if (InputValue.length === 3 && InputValue.match(/[aA-zZ]{3}/)) return true;
    const matches = InputValue.match(/\[[aA-zZ]{3}\]/)
    if (matches !== null) {
        return true
    }
    return false;
}
export function ValidateSearch(input: IFareSearchInputState, config: AgentFrontendFareSearchOptions): boolean {
    let isValid = true;
    //segments
    input.Segments.forEach((segment) => {
        if (ValidSegmentAirport(segment.ArrivalInput) === false) {
            isValid = false;
            return;
        }
        if (ValidSegmentAirport(segment.DepartureInput) === false) {
            isValid = false;
            return;
        }
    })
    input.AvilableFaresPassengers.adultPassengers?.passengers.forEach((p) => {
        if (IsValidPax(p) === false) {
            isValid = false;
        }
    })
    input.AvilableFaresPassengers.childPassengers?.passengers.forEach((p) => {
        if (IsValidPax(p) === false) {
            isValid = false;
        }
    })
    input.AvilableFaresPassengers.infantPassengers?.passengers.forEach((p) => {
        if (IsValidPax(p) === false) {
            isValid = false;
        }
    })
    if (input.AvailableFares) {
        if (ValidateAvailFaresPassengers(input.AvilableFaresPassengers, input.Segments, config) === false) {
            isValid = false;
        }
    }
    return isValid;
}
function IsValidPax(pax: AvailableFaresPassengerTypeInputDetails): boolean {
    let result = true;
    if (pax?.frequentFlyerCards) {
        pax?.frequentFlyerCards.forEach(ff => {
            if (ff.programNumber.length === 0) {
                result = false;
            }
            if (ff.carrierCodes.length === 0) {
                result = false;
            }
        })
    }
    return result;
}
export function ValidateDobOnDepartureDates(value: Date | undefined, ageConfig: AgentFrontendPassengerTypeConfig, segments: ISegmentState[]) {
    let isInSegmentAgeRange = true;
    if (moment(value).isValid()) {

        segments.forEach((e) => {
            if (IsDobInAgeRange(value, e.DateInput, ageConfig).IsInRange === false)
                isInSegmentAgeRange = false;
            return;
        })
    }
    else {
        isInSegmentAgeRange = false;
    }
    return isInSegmentAgeRange;
}

export function ValidateAvailFaresPassengers(passengers: AvailableFaresPassengerInput, segments: ISegmentState[], configuration: AgentFrontendFareSearchOptions): boolean {
    let result: boolean = true;
    // if (passengers.childPassengers) {
    //     const ageConfig = configuration.passengerConfiguration.childConfigurations[configuration.passengerConfiguration.childConfigurations.findIndex(e => e.type === passengers.childPassengers?.passengerType)];

    //     passengers.childPassengers.passengers?.forEach((dob) => {
    //         const validDob = ValidateDobOnDepartureDates(dob.dateOfBirth, ageConfig, segments);
    //         if (validDob === false) {
    //             result = false;
    //             return;
    //         }
    //     })
    // }
    // if (passengers.infantPassengers) {
    //     const ageConfig = configuration.passengerConfiguration.infantConfigurations[configuration.passengerConfiguration.infantConfigurations.findIndex(e => e.type === passengers.infantPassengers?.passengerType)];
    //     passengers.infantPassengers.passengers.forEach((passenger) => {

    //         const validDob = ValidateDobOnDepartureDates(passenger.dateOfBirth, ageConfig, segments);
    //         if (validDob === false) {
    //             result = false;
    //             return;
    //         }
    //     })

    // }
    return result;
}