import React from "react";
import { CalculationRow_DisplaySettings } from "WebApiClient";


interface FareCalculationHeaderProps {
    Data: CalculationRow_DisplaySettings

}
const FareCalculationHeader: React.FC<FareCalculationHeaderProps> = props => {
const displaySettings = props.Data;

    return (<React.Fragment>
        <thead>
            <tr>
                <th>{"Passenger(s)"}</th>
                <th className="text-right">{displaySettings.grossDisplayName} p.P.</th>

                {displaySettings.showNet &&
                    <th className="text-right d-none d-md-table-cell">{displaySettings.netDisplayName} p.P.</th>
                }
                {displaySettings.showNetNet &&
                    <th className="text-right d-none d-md-table-cell">{displaySettings.netNetDisplayName} p.P.</th>
                }
                {displaySettings.showQSurcharge &&
                    <th className="text-right d-none d-md-table-cell">{displaySettings.qSurchargeDisplayName} p.P.</th>
                }
                <th className="text-right">Taxes p.P.</th>
                <th className="text-right">Total</th>
            </tr>
        </thead>
    </React.Fragment>
    );
};


export default FareCalculationHeader;