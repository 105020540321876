import React, { Fragment } from "react";
import { FareAPISRequirements, FareBookingPassengerApis, FareBookingPassengerApisAddress, FareBookingPassengerApisPassport, FareBookingPassengerApisVisa } from "WebApiClient";
import BookingPassengerApisAddress from "./APISInput.Adress";
import BookingPassengerApisPassport from "./APISInput.Passport";
import { ApisValidResult } from "./APISInput.Validate.Main";
import ApisVisaInput from "./APISInput.Visa";

export interface APISMainInputProps {
    Data: FareBookingPassengerApis | undefined;
    OnUpdate: (data: FareBookingPassengerApis | undefined) => void;
    Valid: ApisValidResult;
    Apis: FareAPISRequirements
    ShowRemoveButton: boolean;
}

const APISMainInput: React.FC<APISMainInputProps> = (props) => {
    const apisData = props.Data;






    const IsValid = props.Valid;
    function OnUpdatePassport(passport: FareBookingPassengerApisPassport | undefined) {
        if (apisData) {

            let data = { ...apisData };
            data.passport = passport;
            props.OnUpdate(data);
        }


    }
    function OnUpdateAddress(address: FareBookingPassengerApisAddress | undefined) {
        if (apisData) {

            let data = { ...apisData };
            data.address = address;
            props.OnUpdate(data);
        }

    }
    function OnUpdateVisa(visa: FareBookingPassengerApisVisa | undefined) {
        if (apisData) {
            let data = { ...apisData };
            data.visa = visa;
            props.OnUpdate(data);
        }

    }

    function OnAddApis() {
        let data: FareBookingPassengerApis = { address: undefined, passport: undefined, visa: undefined }
        props.OnUpdate(data);
    }
    function OnRemoveApis() {

        props.OnUpdate(undefined);
    }





    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="alert alert-info">
                            <p>By law, several countries worldwide ask airlines to transmit passenger data via the system known as “Advance Passenger Information System” (APIS). Mandatory fields are marked with a star.</p>
                        </div>
                    </div>

                    {!apisData &&
                        <div className="col-12 mb-2">
                            <div className="d-grid">
                                <button className="btn btn-primary" onClick={OnAddApis}>Add APIS</button>
                            </div>
                        </div>

                    }
                    {apisData &&

                        <Fragment>
                            {props.ShowRemoveButton &&
                                <div className="col-12 mb-2">
                                    <div className="d-grid">
                                        <button className="btn btn-danger" onClick={OnRemoveApis}>Remove APIS information</button>
                                    </div>
                                </div>
                            }
                            <div className="col-12 mb-2">
                                <BookingPassengerApisPassport Data={apisData.passport} IsValid={props.Valid.Passport} OnUpdatePassenger={OnUpdatePassport} />
                            </div>
                            <div className="col-12 mb-2">
                                <ApisVisaInput Data={apisData.visa} IsValid={props.Valid.Visa} OnUpdatePassenger={OnUpdateVisa} />
                            </div>
                            <div className="col-12 mb-2">
                                <BookingPassengerApisAddress Data={apisData.address} IsValid={props.Valid.Address} OnUpdatePassenger={OnUpdateAddress} />
                            </div>
                        </Fragment>
                    }
                </div>




            </div>
        </div>

    );
}

export default APISMainInput;

