import { BookingAddressBehavior } from "WebApiClient";
import { AvailableFareModel } from "./../../../WebApiClient";
import { useEffect, useState } from "react";

export interface BookOtherValidationResult {
  Valid: boolean;
  WebfarePasswordRequired: boolean;
  WebfarePassword: boolean;
  EmergencyPhone: boolean;
  paymentBOFDetails: any;
}

export const DefaultBookOtherValidationResult: BookOtherValidationResult = {
  Valid: false,
  WebfarePasswordRequired: false,
  WebfarePassword: false,
  EmergencyPhone: false,
  paymentBOFDetails: [],
};

export function OtherValid(
  fare: AvailableFareModel,
  webfarePassword: string,
  emergencyPhone: string,
  webfarePasswordbehavior: BookingAddressBehavior,
  emergencyPhoneBehavior: BookingAddressBehavior,
  paymentBOFDetails: any,
  paymentDataBackend: any
): BookOtherValidationResult {
  let otherValid = true;
  const [paymentCheckValidation, setPaymentCheckValidation] = useState(false);
  const apisRequirenments = fare.apisRequirenments!;
  const webfarePasswordRequired =
    webfarePasswordbehavior !== BookingAddressBehavior.UseBranchAdress &&
    apisRequirenments.password;
  const webFarePasswordValid: boolean = webfarePasswordRequired
    ? webfarePassword !== null && webfarePassword !== ""
    : true;

  if (!webFarePasswordValid) {
    otherValid = false;
  }

  const emergencyPhoneRequired =
    emergencyPhoneBehavior !== BookingAddressBehavior.UseBranchAdress;
  const emergencyPhoneValid: boolean = emergencyPhoneRequired
    ? emergencyPhone !== null && emergencyPhone !== ""
    : true;
  if (!emergencyPhoneValid) {
    otherValid = false;
  }

  useEffect(() => {
    if (paymentDataBackend?.length) {
      setPaymentCheckValidation(
        validateInputValues(paymentDataBackend, paymentBOFDetails).includes(
          false
        )
      );
    }
  }, [paymentBOFDetails]);

  function validateInputValues(rules: any, values: any[]) {
    let finalBoolean = []; // Initialize to collect boolean results
  
    for (let i = 0; i < 20; i++) {
      let allValid = true; // Track validation for this set of rules
  
      for (const rule of rules) {
        const code = rule[`Code${i}`]; // Dynamically access the code
        if (code !== undefined) {
          for (const obj of values) {
            const value = obj[code];
            if (
              rule[`Minlen${i}`] == null ||
              rule[`MaxLen${i}`] == null ||
              rule[`Minlen${i}`] == "0" ||
              rule[`MaxLen${i}`] == "0"
            ) {
              continue; // Skip this validation if length constraints are not defined
            }
  
            if (value) {
              if (
                value?.length < rule[`Minlen${i}`] ||
                value?.length > rule[`MaxLen${i}`]
              ) {
                allValid = false; // Validation failed for this rule
                break;
              }
            } else {
              allValid = false; // Missing value
              break;
            }
          }
        }
      }
  
      finalBoolean.push(allValid); // Push the result for this set of rules
    }
  
    return finalBoolean;
  }

  const result: BookOtherValidationResult = {
    Valid: otherValid,
    WebfarePassword: webFarePasswordValid,
    EmergencyPhone: emergencyPhoneValid,
    WebfarePasswordRequired: webfarePasswordRequired,
    paymentBOFDetails: !paymentCheckValidation,
  };
  return result;
}
