import CountrySelect from "components/Shared/CountrySelect";
import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { Adress, BookingAddressBehavior } from "WebApiClient";
import { defaultAdress } from "../types/FareBookingTypes";
import { BookAddressValidationResult } from "./FareBooking.Validate.Address";

export interface FareBookingAddressProps {
  Address: Adress | undefined;
  Invoice: Adress ;
  AddressBehavior: BookingAddressBehavior;
  OnUpdateAddress: (address: Adress | undefined) => void;
  OnUpdateInvoice: (address: Adress ) => void;
  AdressValid: BookAddressValidationResult;
  InvoiceValid: BookAddressValidationResult;
  Icon: JSX.Element;
  IsOpen: boolean;
  Passengers?: any;
}

const InputAddress: React.FC<{
  Address: Adress;
  OnUpdateAddress: (address: Adress) => void;
  IsValid: BookAddressValidationResult;
  isDisabled: boolean;
  Title: string;
  Passengers?: any;
  OnRemove?: () => void;
}> = ({ Address, OnUpdateAddress, IsValid, isDisabled, Title, OnRemove }) => {
  function OnTitleUpdate(value: string) {
    let data = { ...Address };
    data.title = value;
    OnUpdateAddress(data);
  }
  function OnFirstNameUpdate(value: string) {
    let data = { ...Address };
    data.firstName = value;
    OnUpdateAddress(data);
  }

  function OnLastNameUpdate(value: string) {
    let data = { ...Address };
    data.lastName = value;
    OnUpdateAddress(data);
  }

  function OnStreetUpdate(value: string) {
    let data = { ...Address };
    data.street = value;
    OnUpdateAddress(data);
  }

  function OnZIPUpdate(value: string) {
    let data = { ...Address };
    data.zip = value;
    OnUpdateAddress(data);
  }

  function OnCityUpdate(value: string) {
    let data = { ...Address };
    data.city = value;
    OnUpdateAddress(data);
  }

  function OnCountryUpdate(value: string) {
    let data = { ...Address };
    data.country = value;
    OnUpdateAddress(data);
  }

  return (
    <div className="card">
      <div className="card-header card-header-primary">
        {Title}{" "}
        {/* {OnRemove && (
          <span className="float-end">
            <button onClick={OnRemove} className="btn btn-danger btn-sm">
              remove
            </button>
          </span>
        )} */}
      </div>
      <div className="card-body card-body-primary">
        {isDisabled && (
          <div className="row">
            <div className="col-12">
              <Alert color="info">
                This information is managed by your organization.
              </Alert>
            </div>
          </div>
        )}
        <div className="form-group form-row align-items-center">
          <label className="col-12 col-md-4 col-lg-3 col-xl-2">Title*</label>
          <div className="col-12 col-md-6 col-lg-4 mb-2">
            {/* <select disabled={isDisabled} value={Address.title ? Address.title : ""} onChange={(e) => OnTitleUpdate(e.target.value)} className={`form-select ${IsValid.Title ? "is-valid" : "is-invalid"}`}>
                            <option value="">- select -</option>
                            <option value="Mr">Mr</option>
                            <option value="MrDr">Mr Dr</option>
                            <option value="MrProf">Mr Prof</option>
                            <option value="MrProfDr">Mr Prof Dr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="MrsDr">Mrs Dr</option>
                            <option value="MrsProf">Mrs Prof</option>
                            <option value="MrsProfDr">Mrs Prof Dr</option>
                            <option value="Ms">Ms</option>
                        </select> */}
            <input
              disabled={isDisabled}
              value={Address.title ? Address.title : ""}
              onChange={(e) => OnTitleUpdate(e.target.value)}
              className={`form-control ${IsValid.Title ? "is-valid" : "is-invalid"
                }`}
            />
          </div>
        </div>

        <div className="form-group form-row align-items-center">
          <label className="col-12 col-md-4 col-lg-3 col-xl-2 align-middle">
            Full name*
          </label>
          <div className="col-12 col-md-6 col-lg-2 align-middle mb-2">
            <input
              value={Address.firstName ? Address.firstName : ""}
              onChange={(e) => OnFirstNameUpdate(e.target.value)}
              type="text"
              className={`form-control ${IsValid.FirstName ? "is-valid" : "is-invalid"
                }`}
              disabled={isDisabled}
              placeholder="First name"
            />
          </div>

          <div className="col-12 col-md-6 offset-md-4 col-lg-2 offset-lg-0 mt-2 mt-lg-0 align-middle mb-2">
            <input
              value={Address.lastName ? Address.lastName : ""}
              onChange={(e) => OnLastNameUpdate(e.target.value)}
              type="text"
              className={`form-control ${IsValid.LastName ? "is-valid" : "is-invalid"
                }`}
              disabled={isDisabled}
              placeholder="Last name"
            />
          </div>
        </div>

        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - STREET - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <div className="form-group form-row align-items-center">
          <label className="col-12 col-md-4 col-lg-3 col-xl-2">Street*</label>
          <div className="col-12 col-md-6 col-lg-4 mb-2">
            <input
              value={Address.street}
              onChange={(e) => OnStreetUpdate(e.target.value)}
              type="text"
              disabled={isDisabled}
              className={`form-control ${IsValid.Street ? "is-valid" : "is-invalid"
                }`}
            />
          </div>
        </div>
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // STREET - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ZIP - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <div className="form-group form-row align-items-center">
          <label className="col-12 col-md-4 col-lg-3 col-xl-2">Zip code*</label>
          <div className="col-12 col-md-4 col-lg-2 mb-2">
            <input
              value={Address.zip}
              onChange={(e) => OnZIPUpdate(e.target.value)}
              type="text"
              disabled={isDisabled}
              className={`form-control ${IsValid.Zip ? "is-valid" : "is-invalid"
                }`}
            />
          </div>
        </div>
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // ZIP - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CITY - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <div className="form-group form-row align-items-center">
          <label className="col-12 col-md-4 col-lg-3 col-xl-2">City*</label>
          <div className="col-12 col-md-6 col-lg-4 mb-2">
            <input
              value={Address.city}
              onChange={(e) => OnCityUpdate(e.target.value)}
              type="text"
              disabled={isDisabled}
              className={`form-control  ${IsValid.City ? "is-valid" : "is-invalid"
                }`}
            />
          </div>
        </div>

        <div className="form-group form-row align-items-center">
          <label className="col-12 col-md-4 col-lg-3 col-xl-2">Country*</label>
          <div className="col-12 col-md-6 col-lg-4 mb-2">
            <CountrySelect
              Value={Address.country!}
              Onchange={OnCountryUpdate}
              Valid={IsValid.Country}
              ID={`bacountry`}
              Required={true}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const FareBookingAddress: React.FC<FareBookingAddressProps> = ({
  Address,
  OnUpdateAddress,
  AdressValid,
  AddressBehavior,
  Icon,
  Invoice,
  OnUpdateInvoice,
  InvoiceValid,
  IsOpen,
  Passengers
}) => {

  const isDisabled = AddressBehavior === BookingAddressBehavior.UseBranchAdress;
  const [holdCustomAdress, setHoldCustomAdress] = useState<any>(undefined)
  function OnAddAddress() {
    OnUpdateAddress(Invoice);
  }
  function OnRemoveAddress() {
    setHoldCustomAdress(Address)
    OnUpdateAddress(undefined);
  }
  const [isCollapsed, setIsCollapsed] = useState(!IsOpen);
  const [toggleDelivery, setToggleDelivery] = useState(false)
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    OnUpdateAddress(Address == undefined ? Invoice : Address)
  }, [])

  const toggleDeliveryVisibility = () => {
    setToggleDelivery(!toggleDelivery)
  }

  useEffect(() => {
    if (toggleDelivery == true && holdCustomAdress == undefined) {
      OnAddAddress()
    }
    if (toggleDelivery == true && holdCustomAdress != undefined) {
      OnUpdateAddress(holdCustomAdress)
    }
    if (toggleDelivery == false) {
      OnRemoveAddress()
    }
  }, [toggleDelivery])
  return (
    <div className="card">
      <div className="card-header card-header-primary" onClick={toggleCollapse}>
        {Icon} Invoice & Delivery  
      </div>
      <div
        className={`collapse ${isCollapsed ? "" : "show"}`}
        id="collapseExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-12 mb-2">
              <InputAddress
                Address={Invoice}
                isDisabled={false}
                IsValid={InvoiceValid}
                OnUpdateAddress={OnUpdateInvoice}
                Title="Invoice"
                Passengers={Passengers}
              />
            </div>
            <div className="col-12" style={{ padding: '0px 18px' }}>
              <div className="d-grid">
                <button className="btn btn-primary" onClick={toggleDeliveryVisibility}>
                  <span className="d-flex justify-content-center align-items-center" style={{ gap: '8px' }}>
                    <input
                      className="ml-2 custom-checkbox"
                      type="checkbox"
                      checked={toggleDelivery}
                      style={{ scale: "1.5" }}
                      
                    />
                    <span>Add different delivery address from invoice</span>
                  </span>
                </button>
              </div>
            </div>

            <div className="col-12">
              {Address && (
                <InputAddress
                  Address={Address}
                  isDisabled={isDisabled}
                  IsValid={AdressValid}
                  OnUpdateAddress={OnUpdateAddress}
                  Title="Delivery"
                  OnRemove={OnRemoveAddress}
                  Passengers={Passengers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FareBookingAddress;
