import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: { bookingRedirect: false, checkActiveBtnClicked: "", bookingSegmentsData: [], bookingSegmentsDataCustom: {}, bookingSegmentPassengerData: [], recordLocator: '' } }
export const bookingRedirectSlice = createSlice({
    name: 'bookingRedirect',
    initialState,
    reducers: {
        booking_Redirect_Reducer(state, action) {
            state.value = action.payload
        },
    },
})

export const { booking_Redirect_Reducer } = bookingRedirectSlice.actions