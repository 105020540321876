export interface DeployInfo {
    DeployedGitCommitHashShort: string,
    DeployedGitCommitHashFull: string,
    DeployedGitBranch: string,
    DeployedEnvironment: string,
    BuildNumber: string,
    Version: string,
}

export const DevelopmentDeployInfo: DeployInfo = { BuildNumber: "0", DeployedEnvironment: "local", DeployedGitBranch: "", DeployedGitCommitHashFull: "", DeployedGitCommitHashShort: "", Version: "local" };
export async function GetDeployInfo(): Promise<DeployInfo> {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return DevelopmentDeployInfo;
    } else {
        let fetchFile = await fetch("deployinfo.json");
        let content = await fetchFile.json();
        return content as DeployInfo;

    }

}
