
import { faBan, faPlane, faBallotCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dropdownItem, IFareSearchInputState } from "components/FareSearch/types/FareSearchTypes";
import { AgentApiURL } from 'Constants';
import React, { useEffect, useState } from "react";
import { Multiselect } from "react-widgets";
import { ApiCredentialsOptions, PublicServicesClient } from 'WebApiClient';
import { State } from "rootExports/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { airline_Code_Reducer } from 'rootExports/AirlineCodeReducer';


const Airlines: React.FC<OwnProps> = (props) => {

  var otherSettings: string = useSelector((state: State) => state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ? state.Session.FrontendSettings.otherSettingsAsKeyValueText == "menuPosition=L\nshowFareHeader=Off" ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse" : "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse" : state.Session.FrontendSettings.otherSettingsAsKeyValueText);
  const Dispatch = useDispatch()
  const isAdmin = useSelector((state: State) => state.Session.isAdmin);

  let settingArr = otherSettings.split('\n');
  let smartCalender = settingArr[2] == undefined || null ? 'showSmartCalender=None/n[]/nfalse/nfalse' : settingArr[2];
  let calender = smartCalender.split('/n');
  let showCalender = calender[0];
  let airline: [] = JSON.parse(calender[1]);
  let flight = JSON.parse(calender[2])
  let allotement = JSON.parse(calender[3])
  const [flightDate, setFlightDate] = useState(flight);
  const [allotementDate, setAllotementDate] = useState(allotement);
  const BodyClass: string = props.IsModifySearch ? "" : "card-body card-body-secondary";
  const ContainerClass: string = props.IsModifySearch ? "" : "card h-100";
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<dropdownItem[]>([]);
  const airlineData = useSelector((state: any) => state.airlineSession.value);


  //rebook state
  const bookingRedirect = useSelector(
    (state: any) => state.bookingRedirectSession.value
  );

  useEffect(() => {
    if (airline.length) {
      Dispatch(airline_Code_Reducer(airline))
    }
  }, [])

  function OnSearch(val: string) {
    if (val.length > 1) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(new ApiCredentialsOptions(AgentApiURL, ""))

      client.getAirlinesByPrefix(val).then((result) => {
        if (result.length > 0) {
          const map: dropdownItem[] = [];
          result.forEach(e => {
            map.push({ value: e.code!, text: e.hint!, image: "XXXX" })
          })
          setItems(map);
        }
        else {
          setItems([]);
        }
      }).catch(() => {
        setItems([]);
      }).finally(() => {
        setIsLoading(false);
      })
    }
  }

  const ListItem = ({ item }: any) => (
    <span>
      <img width={60} height={20} src={`${AgentApiURL}/publicservices/images/airline/${item.value}`} alt="airlineimage"></img>
      <strong> {item.text} ({item.value})</strong>
    </span>
  );

  function OnIncludeUpdate(e: any[]) {
    setItems([]);
    const mapped = e as dropdownItem[];
    const newState: IFareSearchInputState = { ...props.State, IncludeAirlines: mapped };
    props.Update(newState);
  };

  function includeAirlineRebook() {
    if (bookingRedirect?.bookingRedirect == true && bookingRedirect?.checkActiveBtnClicked == "Rebook") {
      //include airline
      let airlineCollector = [];
      airlineCollector = [{ value: bookingRedirect?.bookingSegmentsData[0]?.airline.code, text: bookingRedirect?.bookingSegmentsData[0]?.airline.hint, image: 'XXXX' }]
      setItems([]);
      const newStateRebook1: IFareSearchInputState = { ...props.State, IncludeAirlines: airlineCollector };
      //segment
      let fileDetailsSegment = newStateRebook1.Segments[0];

      fileDetailsSegment.DepartureInput = bookingRedirect?.bookingSegmentsDataCustom?.DepartureInput == "" ? bookingRedirect?.bookingSegmentsData?.length == 1 ?
        `[${bookingRedirect?.bookingSegmentsData[0].departure.iata}] - ${bookingRedirect?.bookingSegmentsData[0].departure.cityName} - ${bookingRedirect?.bookingSegmentsData[0].departure.name}` :
        `[${bookingRedirect?.bookingSegmentsData[0].departure.iata}] - ${bookingRedirect?.bookingSegmentsData[0].departure.cityName} - ${bookingRedirect?.bookingSegmentsData[0].departure.name}` :
        bookingRedirect?.bookingSegmentsDataCustom?.DepartureInput;

      fileDetailsSegment.ArrivalInput = bookingRedirect?.bookingSegmentsDataCustom?.ArrivalInput == "" ? bookingRedirect?.bookingSegmentsData?.length == 1 ?
        `[${bookingRedirect?.bookingSegmentsData[0].arrival.iata}] - ${bookingRedirect?.bookingSegmentsData[0].arrival.cityName} - ${bookingRedirect?.bookingSegmentsData[0].arrival.name}` :
        `[${bookingRedirect?.bookingSegmentsData[bookingRedirect?.bookingSegmentsData?.length - 1].arrival.iata}] - ${bookingRedirect?.bookingSegmentsData[bookingRedirect?.bookingSegmentsData?.length - 1].arrival.cityName} - ${bookingRedirect?.bookingSegmentsData[bookingRedirect?.bookingSegmentsData?.length - 1].arrival.name}` :
        bookingRedirect?.bookingSegmentsDataCustom?.ArrivalInput;

      fileDetailsSegment.DateInput = bookingRedirect?.bookingSegmentsDataCustom?.DateInput == undefined ? new Date(bookingRedirect?.bookingSegmentsData[0].departureDate) :
        bookingRedirect?.bookingSegmentsDataCustom?.DateInput
      const newStateRebook2: IFareSearchInputState = { ...newStateRebook1, Segments: [fileDetailsSegment] };
      props.Update(newStateRebook2);
    }
  }

  useEffect(() => {
    includeAirlineRebook()
  }, [bookingRedirect?.bookingRedirect])

  function OnSmartAirlineUpdate(e: any[]) {
    setItems([]);
    const mapped = e as dropdownItem[];
    const newState: IFareSearchInputState = { ...props.State, SmartAirlines: mapped };
    props.Update(newState);
    Dispatch(airline_Code_Reducer(newState.SmartAirlines));
  };

  function OnExcludeUpdate(e: any[]) {
    setItems([]);
    const mapped = e as dropdownItem[];
    const newState: IFareSearchInputState = { ...props.State, ExcludeAirlines: mapped };
    props.Update(newState);
  }

  const resetAirline = () => {
    Dispatch(airline_Code_Reducer(airline))
  }

  return (
    <React.Fragment>
      <div className="col-12 col-lg-6 mt-4">
        <div className={`${ContainerClass}`}>
          {!props.IsModifySearch &&
            <div className="card-header card-header-primary">Airlines</div>
          }
          <div className={`${BodyClass}`}>
            <div className="input-group mb-1">
              <span className="input-group-text">
                <span className="fa-stack">
                  <FontAwesomeIcon icon={faPlane} className="fa-stack-1x" />
                </span>
              </span>
              <span className="w-75">
                <Multiselect
                  containerClassName="form-control"
                  onChange={(e) => OnIncludeUpdate(e)}
                  value={props.State.IncludeAirlines}
                  textField={(item: any) => `${item.text} (${item.value})`}
                  busy={isLoading} filter={false}
                  data={items} onSearch={(e) => OnSearch(e)}
                  renderListItem={ListItem}
                  placeholder="include airlines"
                  dataKey="includeairlines"
                  messages={{ emptyList: "type to see airlines..." }}
                  className="airlinesAdmin" />
              </span>
            </div>
            <div className="input-group mb-1">
              <span className="input-group-text" title="Exclude Airlines">
                <span className="fa-stack">
                  <FontAwesomeIcon icon={faPlane} className="fa-stack-1x" />
                  <FontAwesomeIcon icon={faBan} className="fa-stack-2x" />
                </span>
              </span>
              <span className="w-75">
                <Multiselect
                  containerClassName="form-control"
                  onChange={(e) => OnExcludeUpdate(e)}
                  value={props.State.ExcludeAirlines}
                  textField={(item: any) => `${item.text} (${item.value})`}
                  busy={isLoading} filter={false}
                  data={items} onSearch={(e) => OnSearch(e)}
                  renderListItem={ListItem}
                  placeholder="exclude airlines"
                  dataKey="excludeairlines"
                  messages={{ emptyList: "type to see airlines..." }}
                  className="airlinesAdmin" />
              </span>
            </div>
            {(showCalender !== "showSmartCalender=None") && (showCalender == "showSmartCalender=All" || isAdmin) && (flightDate || allotementDate) ? (
              <div className="input-group mb-1">
                <span className="input-group-text">
                  <span className="fa-stack">
                    <FontAwesomeIcon icon={faBallotCheck} className="fa-stack-1x" />
                  </span>
                </span>
                <span className="w-75">
                  <Multiselect
                    containerClassName="form-control"
                    onChange={(e) => OnSmartAirlineUpdate(e)}

                    textField={(item: any) => `${item.text} (${item.value})`}
                    busy={isLoading} filter={false}
                    value={airlineData}
                    data={items} onSearch={(e) => OnSearch(e)}
                    renderListItem={ListItem}
                    placeholder="calender airlines"
                    dataKey="includeairlines"
                    messages={{ emptyList: "type to see airlines..." }}
                    // style={{ pointerEvents: "none"}}
                    className="airlinesAdmin"

                  />
                </span>
                {/* {
                !airlineData?.length && <Button variant='outlined' onClick={() => resetAirline() }>Reset</Button> 
              } */}
              </div>) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

interface OwnProps {
  IsModifySearch: boolean,
  State: IFareSearchInputState,
  Update: (state: IFareSearchInputState) => void
}

export default Airlines;