import { PriceDisplay } from "components/Shared/PriceDisplay";
import React from "react";
import { CalculationRow_Complete, CalculationRow_DisplaySettings } from "WebApiClient";
import { ServiceAddObj } from "./AncillariesModal";

interface FareCalculationFooterProps {
    DisplaySettings: CalculationRow_DisplaySettings
    Data: CalculationRow_Complete
    Currency: string;
    AddedServices: ServiceAddObj[];
}
const FareCalculationFooter: React.FC<FareCalculationFooterProps> = props => {
    const values = props.Data.rawValues!;
    const displaySettings = props.DisplaySettings;

    function GetTotalFarePrice(): number {
        const allServices = props.AddedServices.flat();
        const prices = allServices.filter(e => e !== undefined && e.Price.equivalentPrice !== undefined).map(e => e.Price.equivalentPrice!.value);
        const total = prices.reduce(function (a, b) {
            return a + b;
        }, values.total);
        return total;
    }

    return (<tr className="font-weight-bold">

        <td className="font-weight-bold">Total</td>
        <td className="text-right"><PriceDisplay price={values.gross} currency={props.Currency} /></td>
        {displaySettings.showNet &&
            <td className="text-right d-none d-md-table-cell"><PriceDisplay price={values.net} currency={props.Currency} /></td>
        }
        {displaySettings.showNetNet &&
            <td className="text-right d-none d-md-table-cell"><PriceDisplay price={values.netNet} currency={props.Currency} /></td>
        }
        {displaySettings.showQSurcharge &&
            <td className="text-right d-none d-md-table-cell"><PriceDisplay price={values.qSurcharge} currency={props.Currency} /></td>
        }
        <td className="text-right"><PriceDisplay price={values.taxes} currency={props.Currency} /></td>
        <td className="text-right"><PriceDisplay price={GetTotalFarePrice()} currency={props.Currency} /></td>
    </tr>
    );
};


export default FareCalculationFooter;