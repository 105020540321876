import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export interface KeyBoardShortcutDescription {
    Key: string,
    Description: string
}
export const KeyBoardModal: React.FC<{ open: boolean, toggle: () => void, Shortcuts: KeyBoardShortcutDescription[] }> = props => {
    const { open, toggle } = props;

    return <Modal scrollable={true} centered isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Keyboard shortcuts for query</ModalHeader>
        <ModalBody>
            <table className="table table-striped">
                <tbody>
                    {props.Shortcuts.map((shortcut, index) =>
                        <tr key={"short_" + index}>
                            <td>{shortcut.Description}</td>
                            <td>{shortcut.Key}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggle} color="primary">close</Button>
        </ModalFooter>
    </Modal>
}