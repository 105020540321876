import { useEffect, useState } from 'react';

export function useBootstrap5MediaQuery(): string {
    const matchesXS = useMediaQuery('(max-width: 575px)') ? "XS" : "";
    const matchesSM = useMediaQuery('(min-width: 576px) and (max-width: 767px)') ? "SM" : "";
    const matchesMD = useMediaQuery('(min-width: 768px) and (max-width: 991px)') ? "MD" : "";
    const matchesLG = useMediaQuery('(min-width: 992px) and (max-width: 1199px)') ? "LG" : "";
    const matchesXL = useMediaQuery('(min-width: 1200px) and (max-width: 1399px)') ? "XL" : "";
    const matchesXXL = useMediaQuery('(min-width: 1400px)') ? "XXL" : "";

    const arr: string[] = [matchesXS, matchesSM, matchesMD, matchesLG, matchesXL, matchesXXL];

    return arr.filter(e => e !== "").join(",");
}
export function useMediaQuery(query: string): boolean {
    const getMatches = (query: string): boolean => {
        // Prevents SSR issues
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches
        }
        return false
    }

    const [matches, setMatches] = useState<boolean>(getMatches(query))

    function handleChange() {
        setMatches(getMatches(query))
    }

    useEffect(() => {
        const matchMedia = window.matchMedia(query)

        // Triggered at the first client-side load and if query changes
        handleChange()

        // Listen matchMedia
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange)
        } else {
            matchMedia.addEventListener('change', handleChange)
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange)
            } else {
                matchMedia.removeEventListener('change', handleChange)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return matches
}

