import { faCalendar, faClock, faCogs, faDollarSign, faInfoSquare, faMoneyCheck, faPlane, faPlaneArrival, faPlaneDeparture, faTicket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BookingStatusLabel } from 'components/BookingManager/component/BookingStatusLabel';
import FareCalculation from 'components/Fare/Fare.Calculation';
import { DateDisplay, DateTimeDisplay, TimeDisplay } from 'components/Shared/DateDisplay';
import { SharedPriceDisplay } from 'components/Shared/PriceDisplay';
import React from "react";
import { Calculation_Result, SingleBookingGeneralInfo } from '../../../WebApiClient';
interface BookingVersionItemDetailsGeneralProps {
  Data: SingleBookingGeneralInfo;
  CalculationResult: Calculation_Result | undefined
}
export const BookingGeneralCard: React.FC<BookingVersionItemDetailsGeneralProps> = ({ Data, CalculationResult }) => {
  const {
    bookingDate, ticketMethod, statusCode, source,
    responsibleAgent, platingCarrier, filekey, externalReference, distributionChannel,
    crs, cartId, branchgroup, branch, bookingVersion,
    bookingUTC, bookingType, bookingStatus, bookingId,
    destination, firstDepartureDate,
    lastArrivalDate, mainPassengerFirstName,
    mainPassengerLastName, origin, paymentInfo,
    ticketTimeLimit, ticketingDate, totalPrice, totalTax } = Data;

  return (
    <div className="card">
      <div className="card-header card-header-primary">General booking data</div>
      <div className="card-body">
        <div className='row'>
          <div className='col-12 mb-2'>
            <h6> <FontAwesomeIcon icon={faInfoSquare} /> Summary</h6>
            <table className="table table-sm table-hover table-bordered">
              <thead>
                <tr>
                  <th className="font-weight-bold">Filekey</th>
                  <th className="font-weight-bold">Main Passenger</th>
                  <th className="font-weight-bold">Booking status</th>
                  <th className="font-weight-bold">Booking date</th>
                  <th className="font-weight-bold">TTL</th>
                  <th className="font-weight-bold">Ticketing date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{filekey}</td>
                  <td>{mainPassengerLastName}, {mainPassengerFirstName}</td>
                  <td><BookingStatusLabel Status={bookingStatus} /></td>
                  <td><DateTimeDisplay date={bookingDate} /></td>
                  <td><DateTimeDisplay date={ticketTimeLimit} /></td>
                  <td><DateTimeDisplay date={ticketingDate} /></td>
                </tr>
              </tbody>
            </table>
            {/* <div className='card'>
              <div className='card-header card-header-primary'>
                Summary
              </div>
              <div className='card-body'>
               
              </div>
            </div> */}
          </div>
          <div className='col-12 mb-2'>
            <h6><FontAwesomeIcon icon={faPlane} /> Flight</h6>
            <table className="table table-sm  table-hover table-bordered">
              <thead>
                <tr>
                  <th className="font-weight-bold">Plating Carrier</th>
                  <th className="font-weight-bold">First Departure</th>
                  <th className="font-weight-bold">Last Arrival</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{platingCarrier.code} ({platingCarrier.hint})</td>
                  <td>
                    <FontAwesomeIcon icon={faPlaneDeparture} /> {origin.name} ({origin.iata}) <br />
                    <FontAwesomeIcon icon={faCalendar} />&nbsp;&nbsp;<DateDisplay date={firstDepartureDate} /> <br />

                    <FontAwesomeIcon icon={faClock} /> <TimeDisplay date={firstDepartureDate} />
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faPlaneArrival} /> {destination.name} ({destination.iata}) <br />
                    <FontAwesomeIcon icon={faCalendar} />&nbsp;&nbsp;<DateDisplay date={lastArrivalDate} /> <br />
                    <FontAwesomeIcon icon={faClock} /> <TimeDisplay date={lastArrivalDate} />
                  </td>



                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 mb-2">
            <h6><FontAwesomeIcon icon={faDollarSign} /> Calculation</h6>
            {CalculationResult ?
              <FareCalculation AddedServices={[]} AssignalbePassengers={[]} OriginalFareIndex={0} Currency={totalPrice?.currencyCode ?? ""} Data={CalculationResult} Show={true} /> :
              <div>
                <table className="table table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th className="font-weight-bold">Total price incl. Tax</th>
                      <th className="font-weight-bold">Tax</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><SharedPriceDisplay price={totalPrice} /></td>
                      <td><SharedPriceDisplay price={totalTax} /></td>
                      <td>No further information available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
          </div>
          {crs &&
            <div className='col-12 mb-2'>
              <h6><FontAwesomeIcon icon={faCogs} /> CRS</h6>
              <table className="table table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th className="font-weight-bold">GDS</th>
                    <th className="font-weight-bold">PCC</th>
                    <th className="font-weight-bold">Country</th>
                    <th className="font-weight-bold">Received from</th>
                    <th className="font-weight-bold">User</th>
                    <th className="font-weight-bold">ID</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{crs.gds}</td>
                    <td>{crs.terminalPCC}</td>
                    <td>{crs.terminalCountry}</td>
                    <td>{crs.receivedFrom}</td>
                    <td>{crs.user}</td>
                    <td>{crs.guid}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
          {ticketMethod &&

            <div className='col-12 mb-2'>
              <h6><FontAwesomeIcon icon={faTicket} /> Ticket method</h6>
              <table className="table table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th className="font-weight-bold">PCC</th>
                    <th className="font-weight-bold">Order</th>
                    <th className="font-weight-bold">Category</th>
                    <th className="font-weight-bold">Queue</th>
                    <th className="font-weight-bold">Branch access</th>


                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{ticketMethod.ticketPcc}</td>
                    <td>{ticketMethod.ticketOrder}</td>
                    <td>{ticketMethod.ticketCategory}</td>
                    <td>{ticketMethod.ticketQueue}</td>
                    <td>{ticketMethod.enableBranchAccess !== undefined ? ticketMethod.enableBranchAccess ? "Yes" : "No" : ""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
          {paymentInfo &&

            <div className='col-12 mb-2'>
              <h6><FontAwesomeIcon icon={faMoneyCheck} /> Payment</h6>
              <table className="table table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th className="font-weight-bold">Type</th>
                    <th className="font-weight-bold">Virtual provider</th>
                    <th className="font-weight-bold">Credit card infos</th>
                    <th className="font-weight-bold">Bank</th>


                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{paymentInfo.paymentType}</td>
                    <td>{paymentInfo.virtualPaymentProvider}</td>
                    <td>
                      {paymentInfo.number.length > 0 && <span>{paymentInfo.number} <br /></span>}
                      {paymentInfo.holderName.length > 0 && <span>{paymentInfo.holderName} <br /></span>}
                      {paymentInfo.creditCardType.length > 0 && <span>{paymentInfo.creditCardType} <br /></span>}
                      {paymentInfo.creditCardExpiryDate && <span>Exp. <DateDisplay date={paymentInfo.creditCardExpiryDate} /> <br /></span>}
                    </td>
                    <td>
                      {paymentInfo.bankName.length > 0 && <span>{paymentInfo.bankName} <br /></span>}
                      {paymentInfo.bankCodeNumber.length > 0 && <span>{paymentInfo.bankCodeNumber} <br /></span>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        </div>
        {/* <div className="row">


          {paymentInfo &&
            <div className="col-12 mb-2">
              <BookingVersionItemDetailsPayment Data={paymentInfo} />
            </div>
          }

        </div> */}

      </div>
    </div>
  );


}
{/* <div className="col col-xs-12 mb-2">
            <div className="card card-primary">
              <div className="card-header card-header-primary">Summary</div>
              <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                  <thead>
                    <tr>
                      <th className="font-weight-bold">Filekey</th>
                      <th className="font-weight-bold">Main Passenger</th>
                      <th className="font-weight-bold">Booking status</th>
                      <th className="font-weight-bold">Booking date</th>
                      <th className="font-weight-bold">Total price</th>
                      <th className="font-weight-bold">Total tax</th>
                      <th className="font-weight-bold">TTL</th>
                      <th className="font-weight-bold">Ticketing date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>{filekey}</td>
                    <td>{mainPassengerLastName}, {mainPassengerFirstName}</td>
                    <td><BookingStatusLabel Status={bookingStatus} /></td>
                    <td><DateTimeDisplay date={bookingDate} /></td>
                    <td><SharedPriceDisplay price={totalPrice} /></td>
                    <td><SharedPriceDisplay price={totalTax} /></td>
                    <td><DateTimeDisplay date={ticketTimeLimit} /></td>
                    <td><DateTimeDisplay date={ticketingDate} /></td>

                    </tr>
                  </tbody>
                </table>
                <div className="table-responsive">
                  <table className="table w-auto">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Filekey</td>
                        <td>{filekey}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Main Passenger</td>
                        <td>{mainPassengerLastName}, {mainPassengerFirstName}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Booking Status</td>
                        <td><BookingStatusLabel Status={bookingStatus} /></td>
                      </tr>


                      <tr>
                        <td className="font-weight-bold">Plating carrier</td>
                        <td>{platingCarrier.code} ({platingCarrier.hint})</td>
                      </tr>

                      <tr>
                        <td className="font-weight-bold">Booking Date</td>
                        <td><DateTimeDisplay date={bookingDate} /></td>
                      </tr>

                      <tr>
                        <td className="font-weight-bold">Price incl. tax</td>
                        <td><SharedPriceDisplay price={totalPrice} /></td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Total tax</td>
                        <td><SharedPriceDisplay price={totalTax} /></td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Ticketing time limit</td>
                        <td><DateTimeDisplay date={ticketTimeLimit} /></td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Ticketing date</td>
                        <td><DateTimeDisplay date={ticketingDate} /></td>
                      </tr>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div> */}

{/* <div className="col col-xs-12 mb-2">
            <div className="card card-primary">
              <div className="card-header card-header-primary">Flight</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table w-auto">
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Departure Airport</td>
                        <td>{origin.name} ({origin.iata})</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Departure date</td>
                        <td><DateTimeDisplay date={firstDepartureDate} /></td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Arrival Airport</td>
                        <td>{destination.name} ({destination.iata})</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Arrival date</td>
                        <td><DateTimeDisplay date={lastArrivalDate} /></td>
                      </tr>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div> */}
{/* {crs &&
            <div className="col col-xs-12 mb-2">
              <div className="card card-primary">
                <div className="card-header card-header-primary">CRS</div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table w-auto">
                      <tbody>
                        <tr>
                          <td className="font-weight-bold">GDS</td>
                          <td>{crs.gds}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">PCC</td>
                          <td>{crs.terminalPCC}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Country</td>
                          <td>{crs.terminalCountry}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Received from</td>
                          <td>{crs.receivedFrom}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">User</td>
                          <td>{crs.user}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">ID</td>
                          <td>{crs.guid}</td>
                        </tr>


                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          } */}

{/* {ticketMethod &&
            <div className="col col-xs-12 mb-2">
              <div className="card card-primary">
                <div className="card-header card-header-primary">Ticket method</div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table w-auto">
                      <tbody>
                        <tr>
                          <td className="font-weight-bold">Category</td>
                          <td>{ticketMethod.ticketCategory}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Order</td>
                          <td>{ticketMethod.ticketOrder}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">PCC</td>
                          <td>{ticketMethod.ticketPcc}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Queue</td>
                          <td>{ticketMethod.ticketQueue}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Enable branch access</td>
                          <td>{ticketMethod.enableBranchAccess !== undefined ? ticketMethod.enableBranchAccess ? "Yes" : "No" : ""}</td>
                        </tr>



                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          } */}