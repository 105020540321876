import { faSort, faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  AgentShoppingCartSort,
  AscDescEnum,
  BookingItemModel,
  BookingMangerFilterRequestData,
  ShoppingCartSortModifier,
} from "WebApiClient";
import { ShoppingCartModel } from "../types/BookingManagerTypes";
// import { FieldOrdered, GetSortOrder, OrderBookings } from "./BookingManager.Table.GetSortOrder";
import BookingMangerTablerow, {
  EmptyBookingMangerTableRow,
} from "./BookingManager.Table.Row";

// import "./BookingManager.Table.styles.css"

interface BookingMangerTableProps {
  IsFetching: boolean;
  GroupByShoppingCart: boolean;
  UpdateBooking: (data: BookingItemModel, bookingID: string) => void;
  OnSubmit: (filter: BookingMangerFilterRequestData) => void;
  PageAmount: number;
  filter: BookingMangerFilterRequestData;
  Bookings: BookingItemModel[];
  HideTestBookings: boolean;
  autoRefresh?: string;
  cardView: boolean;
}

const BookingMangerTable: React.FC<BookingMangerTableProps> = (props) => {
  const filter = props.filter;
  const Bookings = props.HideTestBookings
    ? props.Bookings.filter(
        (e) =>
          e.latestVersion.generalInfo.filekey.toLocaleLowerCase() !== "testkey"
      )
    : props.Bookings;
  const isFetching = props.IsFetching;
  const [bool, setBool] = useState(false);

  function SetOrdering(field: AgentShoppingCartSort) {
    if (!isFetching) {
      const ordered: ShoppingCartSortModifier | undefined =
        filter.sortModifiers!.find((e) => e.field === field);
      let newModifiers = [...filter.sortModifiers!];
      if (ordered === undefined) {
        newModifiers.push({ field: field, ascDesc: AscDescEnum.Ascending });
      } else {
        let index = newModifiers.findIndex((e) => e.field === field);
        if (ordered.ascDesc === AscDescEnum.Ascending) {
          newModifiers[index] = {
            ...newModifiers[index],
            ascDesc: AscDescEnum.Descending,
          };
        } else {
          newModifiers.splice(index, 1);
        }
      }
      let newFilter: BookingMangerFilterRequestData = {
        ...filter,
        sortModifiers: newModifiers,
      };
      props.OnSubmit(newFilter);
    }
  }

  function RenderShoppingCartRow(
    data: ShoppingCartModel,
    cartIndex: number
  ): JSX.Element {
    console.log("data---", data.Bookings);
    return (
      <React.Fragment>
        <tr>
          <td className="bg-white text-center" colSpan={11}>
            <span className="text-muted">{data.CartID}</span>{" "}
          </td>
        </tr>
        {data.Bookings.map((booking, index) => (
          <tr key={`bookingrow_${cartIndex}_${index}`}>
            <BookingMangerTablerow
              Booking={booking}
              CardView={false}
              UpdateBooking={(e) => {
                props.UpdateBooking(
                  e,
                  e.latestVersion.bookingIdentifier.bookingId
                );
              }}
              Fetching={props.IsFetching}
              IndexRow={index}
              showBool={setBool}
              bool={bool}
            />
          </tr>
        ))}
        <tr className="bg-white" style={{ height: "30px" }}>
          <td colSpan={11}></td>
        </tr>
      </React.Fragment>
    );
  }

  function RenderShoppingCard(
    data: ShoppingCartModel,
    cartIndex: number
  ): JSX.Element {
    return (
      <React.Fragment>
        {data.Bookings.map((booking, index) => (
          <BookingMangerTablerow
            Booking={booking}
            CardView={false}
            UpdateBooking={(e) => {
              props.UpdateBooking(
                e,
                e.latestVersion.bookingIdentifier.bookingId
              );
            }}
            Fetching={props.IsFetching}
            IndexRow={index}
            showBool={setBool}
            bool={bool}
          />
        ))}
      </React.Fragment>
    );
  }

  function RenderBookingList(): JSX.Element {
    const ordered = Bookings;
    if (props.GroupByShoppingCart) {
      let shoppingcarts: ShoppingCartModel[] = [];
      ordered.forEach((booking) => {
        const cartID = booking.latestVersion!.generalInfo!.cartId!;
        const indexOf = shoppingcarts.findIndex((e) => e.CartID === cartID);
        if (indexOf === -1) {
          let cart: ShoppingCartModel = { CartID: cartID, Bookings: [booking] };
          shoppingcarts.push(cart);
        } else {
          shoppingcarts[indexOf].Bookings.push(booking);
        }
      });

      return (
        <React.Fragment>
          {shoppingcarts.map((cart, index) => (
            <tbody
              style={{ borderBottom: "solid black 0.2em" }}
              key={`sc_${index}`}
            >
              {props.Bookings.length === 0 && props.IsFetching && (
                <tr>
                  <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
                </tr>
              )}
              {RenderShoppingCartRow(cart, index)}
            </tbody>
          ))}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <tbody>
            {props.Bookings.length === 0 && props.IsFetching && (
              <tr>
                <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
              </tr>
            )}
            {ordered.map((booking, index) => (
              <tr key={`bookingrow_${index}`}>
                <BookingMangerTablerow
                  CardView={false}
                  Booking={booking}
                  UpdateBooking={(e) => {
                    props.UpdateBooking(
                      e,
                      e.latestVersion.bookingIdentifier.bookingId
                    );
                  }}
                  Fetching={props.IsFetching}
                  IndexRow={index}
                  showBool={setBool}
                  bool={bool}
                />
              </tr>
            ))}
          </tbody>
        </React.Fragment>
      );
    }
  }

  function RenderBookingCard(): JSX.Element {
    const ordered = Bookings;
    if (props.GroupByShoppingCart) {
      let shoppingcarts: ShoppingCartModel[] = [];
      ordered.forEach((booking) => {
        const cartID = booking.latestVersion!.generalInfo!.cartId!;
        const indexOf = shoppingcarts.findIndex((e) => e.CartID === cartID);
        if (indexOf === -1) {
          let cart: ShoppingCartModel = { CartID: cartID, Bookings: [booking] };
          shoppingcarts.push(cart);
        } else {
          shoppingcarts[indexOf].Bookings.push(booking);
        }
      });

      return (
        <React.Fragment>
          {shoppingcarts.map((cart, index) => (
            <div key={"cart" + index}>
              {props.Bookings.length === 0 && props.IsFetching && (
                <>
                  <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
                </>
              )}
              {RenderShoppingCard(cart, index)}
            </div>
          ))}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <>
            {props.Bookings.length === 0 && props.IsFetching && <>{null}</>}
            {ordered.map((booking, index) => (
              <div key={"card" + index}>
                <BookingMangerTablerow
                  CardView={true}
                  Booking={booking}
                  UpdateBooking={(e) => {
                    props.UpdateBooking(
                      e,
                      e.latestVersion.bookingIdentifier.bookingId
                    );
                  }}
                  Fetching={props.IsFetching}
                  IndexRow={index}
                  showBool={setBool}
                  bool={bool}
                />
              </div>
            ))}
          </>
        </React.Fragment>
      );
    }
  }

  function GetArrowIcon(field: AgentShoppingCartSort): JSX.Element {
    const ordered: ShoppingCartSortModifier | undefined =
      filter.sortModifiers!.find((e) => e.field === field);
    let ordericon = faSort;
    let indicator = (
      <span>
        <FontAwesomeIcon icon={ordericon} />
      </span>
    );
    if (ordered !== undefined) {
      const indexOf = filter.sortModifiers!.findIndex((e) => e.field === field);
      ordericon =
        ordered.ascDesc === AscDescEnum.Ascending ? faSortUp : faSortDown;
      indicator = (
        <span>
          <FontAwesomeIcon icon={ordericon} />{" "}
          <span className="badge badge-pill badge-light">{indexOf + 1}</span>
        </span>
      );
    }
    return <span className="float-end">{indicator}</span>;
  }

  return (
    <React.Fragment>
      {/* <div className="table-responsive tableFixHead"> */}

      {!props.cardView ? (
        <div className="">
          <table
            className={`table table-sm table-bordered w-100 table-hover bg-white bMTable ${
              !props.GroupByShoppingCart ? "table-striped" : ""
            }`}
            id="bookinglist__allbookings"
          >
            <BookingMangerTableHeader
              ClassName={"thead-dark clickme"}
              GetArrowIcon={GetArrowIcon}
              SetOrdering={SetOrdering}
              ShowActions={true}
              ShowSyncHeader={true}
            />
            {/* {
            props.IsFetching ?
              <tbody>
                <tr><td colSpan={11} className="text-center" style={{ fontSize: "2em" }}><FontAwesomeIcon icon={faSpinner} spin /> Loading...</td></tr>
              </tbody>
              :
            } */}
            {RenderBookingList()}
          </table>
          {/* <Table responsive striped={!props.GroupByShoppingCart} hover size="sm" bordered className="w-100" color="bg-white" id="bookinglist__allbookings" >

        </Table> */}
        </div>
      ) : (
        RenderBookingCard()
      )}
    </React.Fragment>
  );
};

export const BookingMangerTableHeader: React.FC<{
  SetOrdering(sort: AgentShoppingCartSort): void;
  GetArrowIcon(sort: AgentShoppingCartSort): JSX.Element;
  ClassName: string;
  ShowActions: boolean;
  ShowSyncHeader?: boolean;
  elementVisible?: boolean;
}> = (props) => {
  const { SetOrdering, GetArrowIcon, ClassName, ShowActions, elementVisible } =
    props;
  return (
    <thead className={ClassName}>
      {!elementVisible && (
        <tr>
          {props.ShowSyncHeader && (
            <th style={{ width: "1%" }} className="fw-normal">
              <small>GDS</small>
              <br />
              Sync
            </th>
          )}
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.MainTravellerName)}
          >
            <small>Passenger</small>
            <br />
            Last name {GetArrowIcon(AgentShoppingCartSort.MainTravellerName)}
          </th>
          <th className="align-bottom fw-normal">Filekey</th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.PlatingCarrier)}
          >
            Airline {GetArrowIcon(AgentShoppingCartSort.PlatingCarrier)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.GDS)}
          >
            <small>Source</small>
            <br />
            GDS {GetArrowIcon(AgentShoppingCartSort.GDS)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.PCC)}
          >
            <small>Source</small>
            <br />
            Terminal PCC {GetArrowIcon(AgentShoppingCartSort.PCC)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.BookingStatus)}
          >
            <small>Status</small>
            <br />
            Booking {GetArrowIcon(AgentShoppingCartSort.BookingStatus)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.BookingDate)}
          >
            <small>Date</small>
            <br />
            Booking {GetArrowIcon(AgentShoppingCartSort.BookingDate)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() =>
              SetOrdering(AgentShoppingCartSort.FirstDepartureDate)
            }
          >
            <small>Date</small>
            <br />
            First departure{" "}
            {GetArrowIcon(AgentShoppingCartSort.FirstDepartureDate)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.TicketTimeLimit)}
          >
            <small>Date</small>
            <br />
            TTL {GetArrowIcon(AgentShoppingCartSort.TicketTimeLimit)}
          </th>
          <th
            className="align-bottom fw-normal"
            onClick={() => SetOrdering(AgentShoppingCartSort.TicketingDate)}
          >
            <small>Date</small>
            <br />
            Ticketing {GetArrowIcon(AgentShoppingCartSort.TicketingDate)}
          </th>
          <th
            className="align-bottom fw-normal text-center"
            onClick={() =>
              SetOrdering(AgentShoppingCartSort.NotificationActionRequired)
            }
          >
            Notifications{" "}
            {GetArrowIcon(AgentShoppingCartSort.NotificationActionRequired)}
          </th>
          {ShowActions && (
            <th className="align-bottom fw-normal text-center">Action</th>
          )}
        </tr>
      )}
      {elementVisible && (
        <tr>
          {props.ShowSyncHeader && (
            <th style={{ width: "1%" }}>
              <small>GDS</small>
              <br />
              Sync
            </th>
          )}
          <th className="font-weight-bold">Passenger Last name</th>
          <th className="font-weight-bold">Filekey</th>
          <th className="font-weight-bold">Airline</th>
          <th className="font-weight-bold">Source GDS</th>
          <th className="font-weight-bold">Source Terminal PCC</th>
          <th className="font-weight-bold">Status Booking</th>
          <th className="font-weight-bold">Date Booking</th>
          <th className="font-weight-bold">Date First departure</th>
          <th className="font-weight-bold">Date TTL</th>
          <th className="font-weight-bold">Date Ticketing</th>
          <th className="font-weight-bold">Notifications</th>
          <th className="font-weight-bold">Code</th>
          <th className="font-weight-bold">Text</th>
          <th className="font-weight-bold">Carrier</th>
          <th className="font-weight-bold">Segments</th>
          <th className="font-weight-bold">Passengers</th>
          <th className="font-weight-bold">First Name</th>
          <th className="font-weight-bold">Last Name</th>
          <th className="font-weight-bold">Type</th>
          {/* <th className="font-weight-bold">Gender</th>
    <th className="font-weight-bold">Ticket No.</th>
    <th className="font-weight-bold">Services</th> */}
        </tr>
      )}
    </thead>
  );
};

export default BookingMangerTable;
