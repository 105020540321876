import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BookingItemModel } from "WebApiClient";


interface BookingMangerTableRowReshopModalProps {
  Booking: BookingItemModel,
  Toggle: () => void
  IsOpen: boolean

}
const BookingMangerTableRowReshopModal: React.FC<BookingMangerTableRowReshopModalProps> = props => {
  // const [isFetching, setIsFetching] = useState(false);
  // const [errorOccured, setErrorOccured] = useState(false);
  // const [transactionID, setTransactionID] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  // const dispatch = useDispatch();
  // const client = useBookingClient();
  // const dateFormat: string = useSelector((state: State) => state.Session.FrontendSettings.dateFormat!);
  // const info = props.Booking.Data.latestVersion!;
  // const [date, setDate] = useState<Date | undefined>(moment.unix(props.Booking.Data.latestVersion?.generalInfo?.firstDepartureDateEpoch!).toDate())
  // function OnSearch() {
  //   setIsFetching(true);
  //   let tid = "UNKNOWN";
  //   const request: OrderReshopRequestModel = { ...info.bookingIdentifier as OrderReshopRequestModel, departureDate: date };
  //   client.getOrderReshopOptions(request)
  //     .then((response: OrderReshopOptionsResponseModel) => {
  //       tid = response.transactionID!;
  //       if (response.success) {
  //         setErrorOccured(false);

  //       }
  //       else {
  //         setErrorOccured(true);
  //         setErrorMessage(response.errorMessage!);
  //       }
  //     })
  //     .catch(() => {
  //       setErrorOccured(true);
  //       setErrorMessage("Connection to backend could not be established.")
  //     })
  //     .finally(() => {

  //       setTransactionID(tid);
  //       setIsFetching(false);
  //       dispatch(Session_Reducer_PushTransactionID({ TransactionID: tid, Label: "OrderReshopQuery", Time: GetCurrentTime(), Success: !errorOccured }));
  //     })
  // }
  return (
    <React.Fragment>

      <Modal size="xl" centered toggle={props.Toggle} isOpen={props.IsOpen}>
        <ModalHeader toggle={props.Toggle}>Reshop</ModalHeader>
        <ModalBody>
          {/* <div className="table-responsive">

            <table className={`table table-sm table-bordered w-100 table-hover bg-white`}>
              <BookingMangerTableHeader GetArrowIcon={() => { }} SetOrdering={() => { }} ClassName="" ShowActions={false} />
              <tbody>
                <tr>

                  <BookingMangerTableRowData Booking={props.Booking} />
                </tr>
              </tbody>
            </table>
          </div>
          <div className="form-row">


            <DateInput
              InputClassNames={"form-control"}
              Value={date}
              OnDateChange={(e) => { setDate(e) }}
              InputGroup={true}
              IsDob={false}
              PopoverPosition={["right"]}
              PlaceHolder="new date"

            />


            <div className="col">
              <button className="btn btn-primary" onClick={OnSearch}>Search</button>

            </div>
          </div>
          {errorOccured &&
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">An error has occured.</h4>
              <p>
                {ErrorMessage}
              </p>
              <hr />
              <p>Unable to get reshop options : {errorMessage}. <br></br>
              If this error persists, contact support with the TransactionID
<Button size="sm" color="info" outline onClick={() => { navigator.clipboard.writeText(transactionID).then(() => { toast.success("TransactionID copied to clipboard.") }); }} className="font-weight-bold clickme"> <FontAwesomeIcon icon={faPaste} /> {transactionID} </Button>
              </p>
            </div>
          } */}

        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={props.Toggle}>close</Button>
        </ModalFooter>


      </Modal>

    </React.Fragment>
  );


}













export default BookingMangerTableRowReshopModal



