import React from "react";

interface CabinclassProps {
    readonly CabinClasses: string[];
}
export const CabinClasses: React.FC<CabinclassProps> = props => {

    return (
        <span className="align-top">
            {props.CabinClasses.map((cabinclass, index) => {
                return <CabinClassIcon CabinClass={cabinclass} key={"props.CabinClasses"+index} /> 
            })}

        </span>
    );
};

interface CabinClasIconProps {
    CabinClass: string;
}
const CabinClassIcon: React.FC<CabinClasIconProps> = props => {

    switch (props.CabinClass) {
        case "economy":
            return <span className="class_name class_name_y mx-1">ECONOMY </span>
        case "premiumeconomy":
            return <span className="class_name class_name_p mx-1">PREMIUM</span>
        case "business":
            return <span className="class_name class_name_c mx-1">BUSINESS</span>
        case "first":
            return <span className="class_name class_name_f mx-1">FIRST</span>
        case "economy, business":
            return <React.Fragment><span className="class_name class_name_y mx-1">ECONOMY</span><span> | </span><span className="class_name class_name_c mx-1">BUSINESS</span></React.Fragment>
        default:
            return <span className="class_name bg-white text-dark">UNKNOWN</span>
    }



}




