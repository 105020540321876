import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faExclamationTriangle, faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";

interface ValidIndicatorIconProps {
    IsValid: boolean;
    Icon: IconDefinition;
    NoValueSet: boolean;
}

const ValidIndicatorIcon: React.FC<ValidIndicatorIconProps> = props => {
    return (<React.Fragment>
        <span className="fa-layers fa-fw ">
            <FontAwesomeIcon icon={props.Icon} />
            <FontAwesomeIcon style={{ "--fa-secondary-opacity": 1, "--fa-primary-color": "white" } as CSSProperties}
                icon={props.IsValid ? props.NoValueSet ? faMinusCircle : faCheckCircle : faExclamationTriangle} color={props.IsValid ? props.NoValueSet ? "grey" : "green" : "red"}
                transform="shrink-6 down-6 right-5" />
        </span>
    </React.Fragment>
    );
};

export default ValidIndicatorIcon;