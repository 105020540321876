import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFilter,
  faInfoSquare,
  faKeyboard,
} from "@fortawesome/pro-regular-svg-icons";
import { Paper } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  AvailableFareLegModel,
  AvailableFareModel,
  AvailableFareResultModel,
  FlightData,
  TravelTimeView,
} from "WebApiClient";
import { BuildFaresFilter } from "../business/BuildFilter";
import Fares, { AvailableFaresFare } from "./AvailableFares.Fares";
import FastFilter from "./AvailableFares.FastFilter";
import FaresFilter from "./AvailableFares.Filter";
import { SortFunction } from "./Fare.sortFunction";
import { useNavigate } from "react-router-dom";
import { AgentApiURL } from "Constants";
import Logo from "Styles/images/airline_not_found.gif";
import { currencyFormatter } from "components/Shared/Helpers/CurrencyConverter";
import fareData from "components/FareSearch/component/res";
import {
  KeyBoardModal,
  KeyBoardShortcutDescription,
} from "components/Shared/KeyBoardModal";
import { SetBookingPayload } from "Pages/AvailableFaresPage";
import { State } from "rootExports/rootReducer";
import _ from "lodash";
import { FaresFilterState } from "../types/AvailableFaresTypes";
import { FareAppliesToFilter } from "components/Fare/FareFilter.Applicability";

interface AvailableFaresProps {
  OriginalFare: AvailableFareResultModel;
  data: AvailableFareResultModel;
  BookFare: (payload: SetBookingPayload) => void;
  OnDiscard: () => void;
  OnModify: () => void;
  IsActive: boolean;
}

interface carouselFare {
  carrierCode: string;
  hint: string;
  currency: string;
  totalPrice: number;
  travelStops: number;
  time: FareDuration;
}

interface FareDuration {
  daysStayOver: number;
  hours: number;
  minutes: number;
  addHours: number;
}
var arrCollector: any = [];
var cssActiveChecker: any = [];
var repeatChecker: string[] = [];
export const AvailableFares: React.FC<AvailableFaresProps> = (props) => {
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  let displayHeader = otherSettings.split("\n");
  var DisplayHeaderSection = displayHeader[1];
  DisplayHeaderSection =
    DisplayHeaderSection == "" ? "showFareHeader=Off" : DisplayHeaderSection;
  const { defaultFilterSettings, validUntil, multiChannelRuleInfo } =
    props.data;
  const [fares, setfares] = useState(props.data.fares);

  const [keyBoardModalOpen, setKeyboardModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  function ToggleKeyBoardModal() {
    setKeyboardModalOpen(!keyBoardModalOpen);
  }

  const InitialFilter = BuildFaresFilter(fares, defaultFilterSettings);
  const [filterState, setFilterState] =
    useState<FaresFilterState>(InitialFilter);

  const FilteredFares: AvailableFaresFare[] = useMemo(
    () => ApplyFilterToFares(fares, filterState),
    [fares, filterState]
  );

  const ApplicableFares = useMemo(
    () => FilteredFares.filter((x) => x.Applicability.Applicable === true),
    [FilteredFares]
  );

  const ApplicableFaresAmount = ApplicableFares.length;
  const [hasSalesPreference, setHasSalesPreference] = useState<boolean>(true);
  // const [ShowAllFaresActive, setShowAllFaresActive] = useState(false);
  const [ShowAllLegsActive, setShowAllLegsActive] = useState(false);
  const [CheapestFare, setCheapestFare] = useState<any[]>([]);
  const [CheapestFareA, setCheapestFareA] = useState<any>();
  const [CheapestFareBool, setCheapestFareBool] = useState(false);
  const [SalesFareBool, setSalesFareBool] = useState(false);
  const [FastestFareBool, setFastestFareBool] = useState<boolean>(false);
  const [BestFareBool, setBestFareBool] = useState(false);
  const [ShowAllFlightTimesActive, setShowAllFlightTimesActive] =
    useState(false);
  const [ShowAllCalculationActive, setShowAllCalculationActive] =
    useState(false);
  const [flightDataArray, setFlightDataArray] = useState<FlightData[]>([]);
  // const [filterOpen, setFilterOpen] = useState<boolean>(defaultFilterSettings.defaultOpenBar);
  const TotalFaresAmount = fares.length;
  const [filterName, setFilterName] = useState("");

  const myRefs = useRef<any>([]);
  function ToggleFilter() {
    setFilterOpen(!filterOpen);
  }

  useEffect(() => {
    BuildFaresFilter(fares, defaultFilterSettings);
    setFilterOpen(defaultFilterSettings.defaultOpenBar);
  }, [defaultFilterSettings]);

  useEffect(() => {
    AutoFocusPage();
  }, [props.IsActive]);

  useEffect(() => {
    if (filterName == "best") {
      setCheapestFareA([]);
      BestFareCal();
    }
  }, [filterName.length]);

  useEffect(() => {
    if (filterName == "fastest") {
      setCheapestFareA([]);
      FastestFareCal();
    }
  }, [filterName.length]);

  useEffect(() => {
    if (filterName == "cheapest") {
      setCheapestFareA([]);
      CheapestFareCal();
    }
    // CheapestFareCal();
  }, [filterName.length]);

  useEffect(() => {
    if (SalesFareBool) {
      setCheapestFareA([]);
      salesSort();
    }
  }, [SalesFareBool]);

  useEffect(() => {
    LoadDefaultSlider();
  }, []);

  useEffect(() => {
    const sortedFares = _(flightDataArray)
      .groupBy("carrierCode")
      .mapValues((fares) => _.minBy(fares, "totalPrice"))
      .values()
      .value();
    setCheapestFareA(sortedFares);
  }, [flightDataArray.length]);

  const AddRepeteValu1 = () => {
    repeatChecker = repeatChecker.filter((item) => item !== "f2");
    repeatChecker = repeatChecker.filter((item) => item !== "f3");
    repeatChecker.push("f1");
    setCheapestFareBool(true);
    setFastestFareBool(false);
    setBestFareBool(false);
  };

  const AddRepeteValu2 = () => {
    repeatChecker = repeatChecker.filter((item) => item !== "f1");
    repeatChecker = repeatChecker.filter((item) => item !== "f3");
    repeatChecker.push("f2");
    setCheapestFareBool(false);
    setFastestFareBool(false);
    setBestFareBool(true);
  };

  const AddRepeteValu3 = () => {
    repeatChecker = repeatChecker.filter((item) => item !== "f1");
    repeatChecker = repeatChecker.filter((item) => item !== "f2");
    repeatChecker.push("f3");
    setCheapestFareBool(false);
    setFastestFareBool(true);
    setBestFareBool(false);
  };

  const RemoveAllValu = () => {
    repeatChecker = [];
    setCheapestFareA([]);
    salesSort();
  };

  function salesSort() {
    setfares(
      fares.slice().sort((a: any, b: any) => {
        const priorityComparison =
          b?.legs[0]?.fareTypeInformation?.salesPreferencePriority -
          a?.legs[0]?.fareTypeInformation?.salesPreferencePriority;

        if (priorityComparison !== 0) {
          return priorityComparison;
        }

        return a.totalPrice - b.totalPrice;
      })
    );

    setSalesFareBool(true);
    setSalesFareBool(true);
    const sortedFares = _(flightDataArray)
      .groupBy("carrierCode")
      .mapValues((fares) => _.minBy(fares, "salesPreferencePriority"))
      .values()
      .value();
    setCheapestFareA(sortedFares);
  }

  // Cheapest Sort for Header
  function CheapestFareCal() {
    _.sortBy(fares, ["totalPrice"]);
    fares.sort((a, b) => (a.totalPrice > b.totalPrice ? 1 : -1));
    const CheapFares = fares;

    CheapFares.forEach(function (fare) {
      if (fare.legs[0].platingCarrier?.code) {
        fare.legs.map((leg) => {
          let timeFareLoop: any = [];
          leg.connections.map((con) => {
            return timeFareLoop.push(
              getTotalMinutes(con.connectionHeader?.legTravelTime)
            );
          });
        });
      }
    });

    const sortedFares = _.flatten(
      _.map(_.groupBy(flightDataArray, "carrierCode"), (group) =>
        _.minBy(group, "totalPrice")
      )
    );
    setCheapestFareA(sortedFares);
    setFilterName("cheapest");
  }

  //Best Sort for Header
  function BestFareCal() {
    // sort fare by duration and price
    fares.sort((a, b) =>
      ((a.totalPrice + bestFareSorter(a)) / fares.length) * 100 >
      ((b.totalPrice + bestFareSorter(b)) / fares.length) * 100
        ? 1
        : -1
    );

    function bestFareSorter(singleFare: any): number {
      const time = GetTotalFlightTime(singleFare.legs);
      const totalMinutes = time.addHours * 60 + time.minutes;
      return totalMinutes;
    }

    const bestFlights = _.sortBy(flightDataArray, (flight) => {
      const totalMinutes = flight.time.addHours * 60 + flight.time.minutes;
      return (
        ((flight.totalPrice + totalMinutes) / flightDataArray.length) * 100
      );
    });
    const uniqueBestFlights = _.uniqBy(bestFlights, "carrierCode");
    setCheapestFareA(uniqueBestFlights);
    setFilterName("best");
  }

  //  Fastest Sort For Header
  function FastestFareCal() {
    fares.sort((a, b) => {
      const aMinLegTravelTime = _.meanBy(
        _.flatMap(a.legs, (leg) =>
          _.minBy(
            _.map(leg.connections, (connection) =>
              getTotalMinutes(
                _.get(connection, "connectionHeader.legTravelTime", 0)
              )
            )
          )
        )
      );
      const bMinLegTravelTime = _.meanBy(
        _.flatMap(b.legs, (leg) =>
          _.minBy(
            _.map(leg.connections, (connection) =>
              getTotalMinutes(
                _.get(connection, "connectionHeader.legTravelTime", 0)
              )
            )
          )
        )
      );

      if (aMinLegTravelTime !== bMinLegTravelTime) {
        return aMinLegTravelTime - bMinLegTravelTime;
      } else {
        // Sort connections within each leg based on legTravelTime
        _.forEach(a.legs, (leg) => {
          leg.connections = _.sortBy(leg.connections, (connection) =>
            getTotalMinutes(
              _.get(connection, "connectionHeader.legTravelTime", 0)
            )
          );
        });

        _.forEach(b.legs, (leg) => {
          leg.connections = _.sortBy(leg.connections, (connection) =>
            getTotalMinutes(
              _.get(connection, "connectionHeader.legTravelTime", 0)
            )
          );
        });
        return 0;
      }
    });

    const fastFares = fares;
    setCheapestFare([]);
    fastFares.forEach((fare) => {
      fare.legs.forEach((leg) => {
        let timeFareLoop = [];
        leg.connections.forEach((con) => {
          timeFareLoop.push(
            getTotalMinutes(_.get(con, "connectionHeader.legTravelTime", 0))
          );
        });
      });
    });

    CheapestFare.sort((a, b) => a.time[0] - b.time[0]);
    const sortedFares = _(flightDataArray)
      .groupBy("carrierCode")
      .mapValues((fares) => _.minBy(fares, "totalPrice")) // Find entry with minimum totalPrice for each carrierCode
      .values()
      .sortBy(["time.addHours", "time.minutes"]) // Sort by time
      .value();

    setCheapestFareA(sortedFares);
    console.log("fast-", sortedFares);
    setFilterName("fastest");
  }

  //   function GetTotalFlightTime(legs: AvailableFareLegModel[]): TravelTimeView {
  //     // Make a copy of the legs array
  //     const legsCopy = legs.map(leg => ({ ...leg }));

  //     let result: TravelTimeView = {
  //         minutes: 0,
  //         hours: 0,
  //         addHours: 0,
  //         daysStayOver: 0,
  //     };

  //     legsCopy.forEach((leg, i) => {
  //         const sortedConnections = leg.connections.sort((a: any, b: any) => {
  //             const legTravelTimeA = a.connectionHeader
  //                 ? a.connectionHeader.legTravelTime.hours * 60 +
  //                 a.connectionHeader.legTravelTime.minutes
  //                 : Infinity;
  //             const legTravelTimeB = b.connectionHeader
  //                 ? b.connectionHeader.legTravelTime.hours * 60 +
  //                 b.connectionHeader.legTravelTime.minutes
  //                 : Infinity;
  //             return legTravelTimeA - legTravelTimeB;
  //         });

  //         const legTime: TravelTimeView | undefined =
  //             sortedConnections[0].connectionHeader?.legTravelTime;
  //         if (legTime) {
  //             result.minutes += legTime.minutes;
  //             result.hours += legTime.hours;
  //             result.daysStayOver += legTime.daysStayOver;
  //         }
  //     });

  //     result.addHours = result.daysStayOver * 24 + result.hours;

  //     if (result.minutes >= 60) {
  //         result.addHours += 1;
  //         result.minutes -= 60;
  //     }

  //     console.log(result);

  //     return result;
  // }

  function GetTotalFlightTime(legs: AvailableFareLegModel[]): TravelTimeView {
    return legs.reduce(
      (result, leg) => {
        const sortedConnections = leg.connections.sort((a, b) => {
          const legTravelTimeA = a.connectionHeader?.legTravelTime || {
            addHours: Infinity,
            minutes: Infinity,
          };
          const legTravelTimeB = b.connectionHeader?.legTravelTime || {
            addHours: Infinity,
            minutes: Infinity,
          };
          const totalMinutesA =
            legTravelTimeA.addHours * 60 + legTravelTimeA.minutes;
          const totalMinutesB =
            legTravelTimeB.addHours * 60 + legTravelTimeB.minutes;
          return totalMinutesA - totalMinutesB;
        });

        const legTime = sortedConnections[0]?.connectionHeader?.legTravelTime;
        if (legTime) {
          result.minutes += legTime.minutes;
          result.hours += legTime.addHours;
        }
        return result;
      },
      { minutes: 0, hours: 0, addHours: 0, daysStayOver: 0 }
    );
  }

  useEffect(() => {
    LoadDefaultSlider();
    // setCheapestFareA(updatedFlightDataArray);
  }, [fares]);

  // const LoadDefaultSlider = () => {
  //   const updatedFlightDataMap: { [fareIndex: number]: FlightData } = {};
  //   fares.forEach((fare) => {
  //     const totalTime: TravelTimeView = GetTotalFlightTime(fare.legs);
  //     fare.legs.forEach((leg) => {
  //       const flightData: FlightData = {
  //         carrierCode: leg.carrierCode,
  //         currency: fare.currency,
  //         fareIndex: leg.fareIndex,
  //         hint: leg.platingCarrier?.hint,
  //         totalPrice: fare.totalPrice,
  //         salesPreferencePriority: fare.fareInfoBox.salesPreferencePriority,
  //         time: totalTime,
  //       };
  //       // Store flight data for each fare using the fareIndex as the key
  //       updatedFlightDataMap[leg.fareIndex] = flightData;
  //     });
  //   });

  //   // Convert flight data map to an array before updating the state
  //   const updatedFlightDataArray = Object.values(updatedFlightDataMap);
  //   setFlightDataArray(updatedFlightDataArray);
  // };

  const LoadDefaultSlider = () => {
    const updatedFlightDataArray: FlightData[] = [];

    fares.forEach((fare) => {
      const totalTime: TravelTimeView = GetTotalFlightTime(fare.legs);
      fare.legs.forEach((leg) => {
        const flightData: FlightData = {
          carrierCode: leg.carrierCode,
          currency: fare.currency,
          fareIndex: leg.fareIndex,
          hint: leg.platingCarrier?.hint,
          totalPrice: fare.totalPrice,
          salesPreferencePriority: fare.fareInfoBox.salesPreferencePriority,
          time: totalTime,
        };
        updatedFlightDataArray.push(flightData); // Push each flight data object to the array
      });
    });

    setFlightDataArray(updatedFlightDataArray);
  };

  // Best Sort For Header
  function getTotalMinutes(legTravelTime: any): any {
    const { addHours, minutes } = legTravelTime;
    return addHours * 60 + minutes;
  }

  function OnDiscard() {
    props.OnDiscard();
  }
  // OnModify
  function OnModify() {
    props.OnModify();
  }

  // Toggle All Legs
  function ToggleAllLegs() {
    setShowAllLegsActive(!ShowAllLegsActive);
  }
  // function ToggleAllFares() {
  //   setShowAllFaresActive(!ShowAllFaresActive)
  // }
  // Toggle All Flight Times
  function ToggleAllFlightTimes() {
    setShowAllFlightTimesActive(!ShowAllFlightTimesActive);
  }
  // Toggle All Calculation
  function ToggleAllCalculation() {
    setShowAllCalculationActive(!ShowAllCalculationActive);
  }
  // Toggle Modify Search
  function ToggleModifySearch() {
    props.OnModify();
    setFilterOpen(false);
  }
  // update Fileter
  function UpdateFilter(filter: FaresFilterState) {
    setFilterState(filter);
  }
  // Apply Filter To Fares
  function ApplyFilterToFares(
    fares: AvailableFareModel[],
    filter: FaresFilterState | undefined
  ): AvailableFaresFare[] {
    const res = fares.map((fare: AvailableFareModel) => {
      let result: AvailableFaresFare = {
        Fare: fare,
        Applicability: FareAppliesToFilter(fare, filter!),
      };
      if (result.Applicability.ApplicableConnections) {
        if (
          result.Applicability.ApplicableConnections.length !== fare.legs.length
        ) {
          let a = 0;
        }
      }
      return result;
    });
    return res;
  }

  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "f") {
      ToggleFilter();
    }
    if (e.key === "a") {
      ToggleAllLegs();
    }
    if (e.key === "t") {
      ToggleAllFlightTimes();
    }
    if (e.key === "c") {
      ToggleAllCalculation();
    }
    if (e.key === "m") {
      ToggleModifySearch();
    }
    if (e.key === "r") {
      UpdateFilter(InitialFilter);
    }
  }
  // Auto Focus
  function AutoFocusPage() {
    const x = document.getElementById("faresdisplay");
    if (x) {
      x.focus({ preventScroll: true });
    }
  }

  const EnableCalcDebugger = useSelector(
    (state: State) => state.Session.FrontendSettings.enableCalculationDeugger
  );

  //carousel Cheapest flight
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // carousel functionality when unselected comes all airlines
  useEffect(() => {
    if (arrCollector?.length == 0) {
      DeactivateAllAlliances(true, false);
      cssActiveChecker = [];
    }
    return () => {};
  }, [arrCollector?.length]);
  //cssActiveChecker clean it func()'
  const cssActiveCheckerClean = () => {
    cssActiveChecker = [];
  };
  //toggle all airlines
  function DeactivateAllAlliances(
    BolCheck: boolean,
    isOutsideTriggerCheck: boolean = false
  ) {
    let updatedFilter = { ...filterState };

    updatedFilter.Airlines.forEach((element) => {
      element.Checked = BolCheck;
    });

    if (
      updatedFilter.Airlines.some((el: any) => el.Checked == false) &&
      arrCollector.length == 0
    ) {
      updatedFilter.SelectAllAirlinesActive = false;
    }
    if (updatedFilter.Airlines.every((el: any) => el.Checked == true)) {
      updatedFilter.SelectAllAirlinesActive = true;
    }

    if (isOutsideTriggerCheck) {
      cssActiveChecker = [];
      arrCollector = [];
    }

    UpdateFilter(updatedFilter);
  }
  function Item(props: any) {
    const [error, setError] = useState(false);
    let indexChecker: number = 0;
    //Toggle a particular selected airlines
    function TogglePAirline(
      label: string,
      airlineCode: string,
      activeNumber: number
    ) {
      let updatedFilter = { ...filterState };
      let newString = `${label} (${airlineCode})`;
      let arrayCreated: any = [];
      cssActiveChecker = [];
      indexChecker = activeNumber;
      // eslint-disable-next-line array-callback-return
      arrayCreated = updatedFilter?.Airlines?.filter((el: any) => {
        if (el.Label == newString) {
          return el;
        }
      });
      if (arrCollector.includes(...arrayCreated)) {
        arrCollector = arrCollector.filter((el: any) => el != arrayCreated[0]);
      } else {
        arrCollector.push(...arrayCreated);
      }
      // eslint-disable-next-line array-callback-return
      updatedFilter?.Airlines.map((obj: any, i: number) => {
        // eslint-disable-next-line array-callback-return
        arrCollector.map((itemCheck: any, j: number) => {
          if (obj == itemCheck) {
            obj.Checked = true;
          }
        });
      });
      // UpdateFilter(updatedFilter);
      if (CheapestFareA?.length || CheapestFareA != undefined)
        CheapestFareA.map((data: any, i: number) => {
          return updatedFilter?.Airlines?.filter((el: any): any => {
            if (el.Label == `${data.hint} (${data.carrierCode})`) {
              cssActiveChecker.push(el);
              return el;
            }
          });
        });
    }

    return (
      <Paper
        square
        className={
          cssActiveChecker[props.indexGetter]?.Checked &&
          arrCollector.length != 0
            ? "activeTab"
            : ""
        }
      >
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            padding: "5px",
            marginTop: "5px",
          }}
          onClick={() => {
            // refToggle(props.item[3]);
            DeactivateAllAlliances(false, false);
            TogglePAirline(
              props.item.hint,
              props.item.carrierCode,
              props?.indexGetter
            );
          }}
        >
          <img
            width="120"
            height="40"
            src={`${
              error
                ? Logo
                : `${AgentApiURL}/publicservices/images/airline/${props.item.carrierCode}`
            }`}
            alt="plating"
            onError={() => setError(true)}
          />
          <div style={{ marginLeft: "8px" }}>
            <div className="textEllipseFare">{props.item.hint}</div>
            <div>
              {parseFloat(props.item.totalPrice)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\d(?=(\d{3})+,)/g, "$&.")}{" "}
              <span>{currencyFormatter(props.item.currency)} </span>
            </div>
          </div>
        </div>
      </Paper>
    );
  }

  function salesPreferenceChecker() {
    // Initialize a flag to false
    let hasSalesPreferenceGreaterThanZero = false;
    props.data?.fares?.forEach((dataObject: any, index: any) => {
      if (dataObject.fareInfoBox.salesPreferencePriority > 0) {
        // Set the flag to true if a value greater than 0 is found
        hasSalesPreferenceGreaterThanZero = false;
        setHasSalesPreference(false);
      }
    });
    // Set the state based on the flag value
    return hasSalesPreferenceGreaterThanZero;
  }

  return (
    <div onKeyPress={OnKeyDown} id={"faresdisplay"} tabIndex={0}>
      {DisplayHeaderSection == "showFareHeader=On" && (
        <div>
          <SortFunction
            cssActiveChecker={cssActiveChecker!}
            DeactivateAllAlliances={DeactivateAllAlliances!}
            fares={fares}
            cheapestFare={CheapestFareCal}
            fastestFare={FastestFareCal}
            bestFare={BestFareCal}
            repeatChecker={repeatChecker}
            AddRepeteValu1={AddRepeteValu1}
            AddRepeteValu2={AddRepeteValu2}
            AddRepeteValu3={AddRepeteValu3}
            removeAllValu={RemoveAllValu}
          />
          <div className="w-100 bg-white ">
            <Carousel responsive={responsive}>
              {CheapestFareA != undefined
                ? CheapestFareA.map((item: any, i: any) => (
                    <Item
                      className="active"
                      key={"cheap" + i}
                      item={item}
                      indexGetter={i}
                    />
                  ))
                : []}
            </Carousel>
          </div>
        </div>
      )}

      <FaresFilter
        TotalFaresAmount={TotalFaresAmount}
        FilteredFaresAmount={ApplicableFaresAmount}
        Filter={filterState}
        UpdateFilter={UpdateFilter}
        InitialFilter={InitialFilter}
        IsOpen={filterOpen}
        Toggle={ToggleFilter}
        AllCalculationExpandActive={ShowAllCalculationActive}
        AllCalculationExpandedToggle={ToggleAllCalculation}
        AllFaresExpandActive={ShowAllLegsActive}
        AllFaresExpandedToggle={ToggleAllLegs}
        AllFlightTimesExpandActive={ShowAllFlightTimesActive}
        AllFlightTimesExpandedToggle={ToggleAllFlightTimes}
        cssActiveCheckerClean={cssActiveCheckerClean!}
      />

      <div>
        {TotalFaresAmount > 0 && <FastFilter />}

        <Fares
          Fares={ApplicableFares}
          TotalFaresAmount={TotalFaresAmount}
          ShowAllCalculationActive={ShowAllCalculationActive}
          ShowAllFlightTimesActive={ShowAllFlightTimesActive}
          ShowAllLegsActive={ShowAllLegsActive}
          FaresWhichPassfilter={ApplicableFaresAmount}
          ValidUntil={validUntil}
          BookFare={props.BookFare}
          ShowCalcDebugger={EnableCalcDebugger}
          myRefs={myRefs!}
        />
        {multiChannelRuleInfo && (
          <div className="row">
            <div className="col-12">
              <div className="alert alert-info">
                <FontAwesomeIcon icon={faInfoSquare} />{" "}
                <span className="">
                  <span className="fw-bold">Rule:</span>{" "}
                  {multiChannelRuleInfo.ruleName} |{" "}
                  <span className="fw-bold">Branch:</span>{" "}
                  {multiChannelRuleInfo.branch} |{" "}
                  <span className="fw-bold">Branchgroup:</span>{" "}
                  {multiChannelRuleInfo.branchgroup}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div style={{ position: "absolute", top: "50%", right: "15px" }}>
        <div className="btn-group btn-group-vertical">
          <button
            className="btn btn-lg btn-primary text-white"
            onClick={ToggleFilter}
          >
            <FontAwesomeIcon icon={faFilter} /> {ApplicableFaresAmount} /{" "}
            {TotalFaresAmount}
          </button>
          {/* <button className="btn btn-lg btn-secondary" onClick={() => { window.scrollTo(0, 0) }} style={{ zIndex: 999 }}><FontAwesomeIcon icon={faChevronUp} /></button> */}
          {/* <button className="btn btn-lg btn-cancel" onClick={OnModify}><FontAwesomeIcon icon={faArrowLeft} /></button> */}
        </div>
      </div>

      <div style={{ position: "absolute", bottom: "0", right: "15px" }}>
        <button className="btn  btn-cancel m-3" onClick={ToggleKeyBoardModal}>
          <FontAwesomeIcon icon={faKeyboard} /> Shortcuts
        </button>
        <div className="btn-group btn-group-vertical">
          {/* <button className="btn btn-lg btn-primary text-white" onClick={ToggleFilter}><FontAwesomeIcon icon={faFilter} /> {ApplicableFaresAmount} / {TotalFaresAmount}</button> */}
          {/* <button className="btn btn-lg btn-secondary" onClick={() => { window.scrollTo(0, 0) }} style={{ zIndex: 999 }}><FontAwesomeIcon icon={faChevronUp} /></button> */}
          <button className="btn btn-lg btn-cancel" onClick={OnModify}>
            <FontAwesomeIcon icon={faArrowLeft} /> Modify
          </button>
        </div>
        <KeyBoardModal
          open={keyBoardModalOpen}
          toggle={ToggleKeyBoardModal}
          Shortcuts={shortcuts}
        />
      </div>
    </div>
  );
};

// shortcuts buttons
const shortcuts: KeyBoardShortcutDescription[] = [
  {
    Description: "Open Filter",
    Key: "F",
  },
  {
    Description: "Expand all fares",
    Key: "A",
  },
  {
    Description: "Expand all flight times",
    Key: "T",
  },
  {
    Description: "Expand all calculation",
    Key: "C",
  },
  {
    Description: "Modified Search",
    Key: "M",
  },
  {
    Description: "Reset Filter",
    Key: "R",
  },
];

{
  /* <footer className="mainfooter">
        <div className="btn-row py-2">
          <div className="container xl-wrapper">
            <button className="btn btn-cancel mr-2" onClick={() => { window.scrollTo(0, 0) }} style={{ zIndex: 999 }}><FontAwesomeIcon icon={faChevronUp} /> Go to top</button>
            <button className="btn btn-cancel mr-2" onClick={ToggleKeyBoardModal} style={{ zIndex: 999 }}><FontAwesomeIcon icon={faKeyboard} /> Keyboard Shortcuts</button>
            <button className="btn btn-cancel mr-2" onClick={ToggleFilter} style={{ zIndex: 999 }}><FontAwesomeIcon icon={faFilter} /> Filter results {ApplicableFaresAmount} / {TotalFaresAmount}</button>
            <button className="btn btn-cancel mr-2" onClick={OnModify} style={{ zIndex: 999 }}><FontAwesomeIcon icon={faSearch} /> Modify query</button>
            <button className="btn btn-cancel float-end" onClick={OnDiscard} style={{ zIndex: 999 }}>
              <CountDown.default
                date={validUntil}
                renderer={dprops => !dprops.completed ? <span><FontAwesomeIcon icon={faTrash} /> Discard (Expires in {dprops.formatted.minutes}:{dprops.formatted.seconds})</span> : <span style={{ color: "red" }}><FontAwesomeIcon icon={faAlarmExclamation} /> Fares expired!</span>}
              />
            </button>
          </div>
        </div>
      </footer> */
}
