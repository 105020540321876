import { ApisValidResult, MainApisValid } from "components/Shared/APISInput/APISInput.Validate.Main";
import { AvailableFareModel, FareBookingPassengerView } from "WebApiClient";
import { BookPassengerValidatioFoidTabResult, PassengerFoidValid } from "./FareBooking.Validate.Passenger.Foid";
import { BookPassengerValidationGeneralTabResult, PassengerGeneralValid } from "./FareBooking.Validate.Passenger.General";
import { BookPassengerValidatioOtherTabResult, PassengerOtherValid } from "./FareBooking.Validate.Passenger.Other";



export interface BookPassengerValidationResult {
    Valid: boolean;
    General: BookPassengerValidationGeneralTabResult;
    Foid: BookPassengerValidatioFoidTabResult;
    Other: BookPassengerValidatioOtherTabResult;
    Passport: ApisValidResult;
}

export function PassengerValid(passenger: FareBookingPassengerView, passengerIndex: number, fare: AvailableFareModel, passengers: FareBookingPassengerView[]): BookPassengerValidationResult {
    let passengerValid = true;

    const data = passenger.bookingData;

    const general = PassengerGeneralValid(passenger, fare);
    if (!general.Valid) passengerValid = false;

    const passport = MainApisValid(data.apis, fare.apisRequirenments!);
    if (!passport.Valid) passengerValid = false;


    const foid = PassengerFoidValid(data.formOfIdentification, fare);
    if (!foid.Valid) passengerValid = false;

    const other = PassengerOtherValid(passenger, fare);
    if (!other.Valid) passengerValid = false;

    const result: BookPassengerValidationResult = {
        Valid: passengerValid,
        General: general,
        Foid: foid,
        Other: other,
        Passport: passport
    }

    return result;
}


