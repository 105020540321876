import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import React, { Fragment } from "react";
import { Calculation_Result } from "WebApiClient";
import { ServiceAddObj } from "./AncillariesModal";
import Footer from "./Fare.Calculation.Footer";
import Header from "./Fare.Calculation.Header";
import { AncillaryCalcRow, FareCalculationAdditionalsRow, FareCalculationRow } from "./Fare.Calculation.Row";

interface FareCalculationProps {
    OriginalFareIndex: number;
    Show: boolean;
    Data: Calculation_Result | undefined
    Currency: string;
    AddedServices: ServiceAddObj[];
    AssignalbePassengers: AssignablePassengerOption[];

}
const FareCalculation: React.FC<FareCalculationProps> = props => {

    return (<React.Fragment>

        {props.Show && props.Data && props.Data.displaySettings &&
            <div className="fare__calculation bb-1 bg-lightContrast">
                <table className="table table-sm table-bordered">
                    <Header Data={props.Data.displaySettings} />
                    <tbody>
                        {props.Data!.calculationsByPassengerType!.map((passenger, index) =>
                            <FareCalculationRow key={"PassInd" + index} DisplaySettings={props.Data!.displaySettings!} Data={passenger} Currency={props.Currency} />)
                        }
                        {props.AddedServices.map((service, index) =>
                            <AncillaryCalcRow key={"AncillaryCalcRow" + index} DisplaySettings={props.Data!.displaySettings!} Service={service} Currency={props.Currency} />)
                        }
                        {props.Data?.additionals &&
                            <Fragment>
                                {props.Data.additionals.map((service, index) =>
                                    <FareCalculationAdditionalsRow key={"FareCalculationAdditionalsRow" + index} DisplaySettings={props.Data!.displaySettings!} Data={service} Currency={props.Currency} />)
                                }
                            </Fragment>
                        }
                        <Footer DisplaySettings={props.Data!.displaySettings!} Data={props.Data!.totals!} Currency={props.Currency} AddedServices={props.AddedServices} />
                    </tbody>
                </table>
            </div>
        }
    </React.Fragment>
    );
};


export default FareCalculation;