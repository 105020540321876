import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faCircle,
  faCouch,
  faFrown,
  faRandom,
  faSuitcase,
  faUtensils,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import React, { useEffect, useState } from "react";
import { Alert, Collapse } from "reactstrap";
import {
  ServiceGroupTypeEnumModel,
  ServiceModel,
  ServicePassengerModel,
  ServicePriceModel,
  ServiceSegmentModel,
} from "WebApiClient";
import { Ancillary } from "./Ancillary";
import {
  AssignablePassengerOption,
  GroupedService,
} from "./AncillarySelection";

interface AncillaryGroupProps {
  Group: GroupedService;
  ToggleAll: boolean;
  Passengers: ServicePassengerModel[];
  Prices: ServicePriceModel[];
  Segments: ServiceSegmentModel[];
  ShowIncluded: boolean;
  OnAddService(obj: ServiceAddObj): void;
  AddedServices: ServiceAddObj[];
  PassengerOptions: AssignablePassengerOption[];
  serviceType: any;
  IncludedService: ServiceModel;
}

export const AncillaryGroup: React.FC<AncillaryGroupProps> = ({
  Group,
  ToggleAll,
  Passengers,
  Prices,
  Segments,
  ShowIncluded,
  OnAddService,
  AddedServices,
  PassengerOptions,
  serviceType,
  IncludedService,
}) => {
  const { Services, Type } = Group;
  const [open, setOpen] = useState(false);
  const [toggleAllServices, setToggleAllServices] = useState<boolean>(false);
  // const filteredServices = ShowIncluded ? Services : Services.filter(e => e.bookingCode.length > 0)
  const filteredServices =
    serviceType == "all"
      ? Services
      : serviceType == "inclServ"
      ? Services.filter((e) => e.bookingType === 0)
      : Services.filter((e) => e.bookingType != 0);

  useEffect(() => {
    setOpen(ToggleAll);
  }, [ToggleAll]);

  function Toggle() {
    setOpen(!open);
  }

  return (
    <>
      {filteredServices.length ? (
        <div className="card">
          <AncillaryGroupHeaderComponent
            OnClick={Toggle}
            Open={open}
            Type={Type}
          />
          <Collapse isOpen={open}>
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <button
                    className="btn btn-primary btn-sm float-end"
                    onClick={() => {
                      setToggleAllServices(!toggleAllServices);
                    }}
                  >
                    Expand/Collapse all services
                  </button>
                </div>
                {filteredServices.map((service: any, index: any) => (
                  <div key={"filteredServices" + index} className="col-12 mb-2">
                    <Ancillary
                      Service={service}
                      Passengers={Passengers}
                      Prices={Prices}
                      AllSegments={Segments}
                      ToggleAll={toggleAllServices}
                      OnAddService={OnAddService}
                      AddedServices={AddedServices}
                      PassengerOptions={PassengerOptions}
                    />
                  </div>
                ))}
                {filteredServices.length === 0 && (
                  <div className="col-12 mb-2">
                    <Alert color="info">
                      <h4 className="alert-heading">
                        <FontAwesomeIcon icon={faFrown} /> No services to
                        display. Please change filter to show services
                      </h4>
                    </Alert>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      ) : null}
    </>
  );
};

interface AncillaryGroupHeaderComponentProps {
  Type: ServiceGroupTypeEnumModel;
  OnClick: () => void;
  Open: boolean;
}

const AncillaryGroupHeaderComponent: React.FC<
  AncillaryGroupHeaderComponentProps
> = (props) => {
  function GetIconAndLabel(): AncillaryHeaderInfo {
    let icon = faCircle;
    let label = "Other";
    switch (props.Type) {
      case ServiceGroupTypeEnumModel.Baggage:
        label = "Baggage";
        icon = faSuitcase;
        break;
      case ServiceGroupTypeEnumModel.Meal:
        label = "Meal";
        icon = faUtensils;
        break;
      case ServiceGroupTypeEnumModel.Seat:
        label = "Seat";
        icon = faCouch;
        break;
      case ServiceGroupTypeEnumModel.Transfer:
        label = "Transfer";
        icon = faRandom;
        break;
    }
    return { Icon: icon, Label: label };
  }
  const Data = GetIconAndLabel();

  return (
    <div
      className="card-header card-header-primary bg-secondary px-3 py-2 d-flex align-content-lg-center clickme"
      onClick={props.OnClick}
    >
      <h6 className="w-100 text-center">
        <span>
          {""} <FontAwesomeIcon icon={Data.Icon} /> {Data.Label}
        </span>{" "}
        <span className="float-end mr-0">
          <FontAwesomeIcon
            icon={props.Open ? faAngleDoubleUp : faAngleDoubleDown}
          />
        </span>
      </h6>
    </div>
  );
};

interface AncillaryHeaderInfo {
  Icon: IconProp;
  Label: string;
}
